<template>
	<div>

    <b-row>
      <b-col sm="12" xl="12" md="12" class="mx-0 px-3">
        <div>
          <b-button variant="primary" class="primary" size="sm" @click.stop="disciplinarydialog = true">Add Record <b-icon class="mr-1" icon="plus"></b-icon></b-button>
        </div>
      </b-col>
      <b-col sm="12" xl="12" md="12" class="mx-0 px-3">
        <div class="dynamic-scroller disciplinary">
          <RecycleScroller
            ref="scroller"
            class="scroller"
            :items="disciplinary"
            :item-size="24"
            key-field="id"
            >
            <template  v-slot="{ item,index }">
              <div>
                <div class="tcell">{{ moment(item.created_at).format('MMM Do YYYY') }}</div>
                <div class="tcell">
                  <span v-if="item.customer !== null" class="mb-3" style="font-size:.95rem !important;font-weight:600 !important">{{ item.title }}</span>
                </div>
                <div class="tcell">
                  {{ item.description }}
                </div>
                <div class="tcell text-center">
                      <a class="list-action" :id="`popover-target-1-${item.id}-${index}`">
                      <b-icon class="my-0 py-0" style="cursor:pointer;" icon="chevron-down"></b-icon>
                    </a>
                    <b-popover
                      :target="`popover-target-1-${item.id}-${index}`"
                      triggers="hover blur"
                      placement="left"
                      class="dropdown-menu"
                    >
                      <div class="action-dropdown-menu">
                        <button @click="editDisciplinary(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="pencil"></b-icon>&nbsp;  Edit</button>
                        <button @click="deleteDisciplinary(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="trash"></b-icon>&nbsp;  Remove</button>
                      </div>
                    </b-popover>
                  </div>
              </div>
            </template>
          </RecycleScroller>
      </div>
      </b-col>
    </b-row>


    <!-- add disciplinary modal-->
    <b-modal
      v-model="disciplinarydialog"
      size="disciplinary"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Record Disciplinary</span>
        </div>
      </template>
      <b-container fluid>
        <b-row>
          <b-col sm="12" md="12" class="float_label mb-3">
            
              <b-form-input
                placeholder="Summary*"
                size="sm"
                v-model="item.title"
              ></b-form-input>
            <label class="typo__label float_label_item">Summary*</label>
          </b-col>
          <b-col sm="12" md="12" class="float_label mb-3">
            
              <b-form-input
                placeholder="Description*"
                size="sm"
                v-model="item.description"
              ></b-form-input>
            <label class="typo__label float_label_item">Description*</label>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="createDisciplinary">
            <b-spinner small v-if="loading"></b-spinner> Save
          </b-button>
      </template>
    </b-modal>
    <!-- end add disciplinary -->

    <!-- update disciplinary modal-->
    <b-modal
      v-model="updatedisciplinarydialog"
      size="disciplinary"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Update Disciplinary</span>
        </div>
      </template>
      <b-container fluid>
        <b-row>
          <b-col sm="12" md="12" class="float_label mb-3">
            
              <b-form-input
                placeholder="Summary*"
                size="sm"
                v-model="item.updatetitle"
              ></b-form-input>
            <label class="typo__label float_label_item">Summary*</label>
          </b-col>
          <b-col sm="12" md="12" class="float_label mb-3">
            
              <b-form-input
                placeholder="Description*"
                size="sm"
                v-model="item.updatedescription"
              ></b-form-input>
            <label class="typo__label float_label_item">Description*</label>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="updateDisciplinary">
            <b-spinner small v-if="loading"></b-spinner> Update
          </b-button>
      </template>
    </b-modal>
    <!-- end add disciplinary -->

	</div>
</template>
<style>
  @import "@/assets/table/table";
  .modal-disciplinary{
    max-width:500px !important;
  }
  .action-dropdown-menu .action-dropdown-item:hover{
    background:var(--ab-primary);
    color:#fff;
    border-radius:var(--ab-dropdown-border-radius);
  }
  .action-dropdown-menu{
    padding-right: .5rem;
  }
  .disciplinary .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-template-columns: 10% 20% 50% 20%;
     grid-gap: 0px;
     min-width:100%;
  }
  .disciplinary .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }
</style>
<style scoped>
  @import "@/assets/table/table_scoped";
  .disciplinary .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-template-columns: 10% 20% 50% 20%;
     grid-gap: 0px;
  }
  .disciplinary .scroller{
    height: calc(100vh - 600px);
  }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'
export default {
	name: 'disciplinary',
	components: {},
	data: () => ({
    overlay:false,
    searchfilter:'',
    updatedisciplinarydialog: false,
    disciplinarydialog: false,
		fieldRules: [
      (v) => !!v || 'Field is required'
      ],
    valid: true,
    isBusy: false,
    loading: false,
    disabled: false,
    fields: [
       {
        text: '#',
        align: 'start',
        value: '#',
      },
      { text: 'Date', value: 'date' },
      { text: 'Summary', value: 'summary' },
      { text: 'Description', value: 'description' },
      { text: 'Action', value: 'action' },
     ],
     item:{
       title: "",
       description:"",
       updatetitle: "",
       updatedescription:"",
       disciplinaryID: "",
       _method:"patch"
     },
     page: 1,
     perPage: 6,

	}),
	methods: {
		fetchdisciplines() {
      this.overlay = true
      this.$store
          .dispatch('fetchdisciplines',this.driverid)
          .then(() => {
            this.overlay = false
          })
          .catch(() => {
            this.overlay = false
          })

      },
      createDisciplinary(){

          if (this.$refs.disciplinaryform.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('id',this.$route.params.id)
            fd.append('title',this.item.title)
            fd.append('description',this.item.description)

            axios.post('/disciplinary', fd, config).then(response=>{
              this.loading = false
              this.disabled = false
              if(response.data.status === parseInt(200))
                this.$toast.success("Record added successfully",'Success',{position: 'topRight',timeout: 7000,})
                this.disciplinarydialog = false

                let found = this.$store.state.disciplinaries.find(data => data.id == response.data.disciplinary.id);
                if (!found) {
                  this.$store.state.disciplinaries.unshift(response.data.disciplinary)
                }

              this.item.title = ''
              this.item.description = ''
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

          }

        },
        updateDisciplinary(){

          if (this.$refs.disciplinaryform.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('id',this.$route.params.id)
            fd.append('title',this.item.updatetitle)
            fd.append('description',this.item.updatedescription)
            fd.append('_method',this.item._method)

            axios.post(`/disciplinary/${this.item.disciplinaryID}`, fd, config).then(response=>{
              this.loading = false
              this.disabled = false
              if(response.data.status === parseInt(200))
                this.$toast.success("Record updated successfully",'Success',{position: 'topRight',timeout: 7000,})
                this.updatedisciplinarydialog = false

                let index = this.$store.state.disciplinaries.findIndex(data => data.id == response.data.disciplinary.id);
                if (index > -1) {
                  this.$store.state.disciplinaries.splice(index, 1, response.data.disciplinary);
                }

              this.item.updatetitle = ''
              this.item.updatedescription = ''
              this.item.disciplinaryID = ''
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

          }

        },
      editDisciplinary(id) {
        let index = this.disciplinary.filter(item => item.id === id );
        this.item.updatetitle = index[0].title
        this.item.updatedescription = index[0].description
        this.item.disciplinaryID = index[0].id
        this.updatedisciplinarydialog = true
      },
      deleteDisciplinary(id){
          if(confirm("Are you sure to delete record ?")){
              axios.delete(`/disciplinary/${id}`).then(response=>{
                if(response.data.status === parseInt(200)) {
                  this.$toast.success("Record deleted successfully",'Success',{position: 'topRight',timeout: 7000,})
                  let index = this.$store.state.disciplinaries.findIndex(data => data.id == response.data.disciplinary.id);
                  if (index > -1) {
                    this.$store.state.disciplinaries.splice(index, 1);
                  }
                }
                
              }).catch(error=>{
                  console.log(error)
              })
          }
      }
	},
	props: {
	     value: Boolean,
	     driverid: Number,
	  },
	  computed: {
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    },
      disciplinary() {
        return this.$store.state.disciplinaries.filter(item => {

          this.start = 0

          let title = "",description = "";
          if(item.title != null && item.title != 'null') {title = item.title}
          if(item.description != null && item.description != 'null') {description = item.description}

            if(this.searchfilter == null || this.searchfilter == 'null') {
              this.searchfilter = ''
            }

          return title.toLowerCase().includes(this.searchfilter.toLowerCase()) || description.toLowerCase().includes(this.searchfilter.toLowerCase())
        })
      }
	  },
	mounted() {
    if(this.disciplinary.length == 0)
      this.fetchdisciplines()
	},
	created() {
     
    }
}
</script>