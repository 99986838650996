import { render, staticRenderFns } from "./disciplinary.vue?vue&type=template&id=39e3d590&scoped=true"
import script from "./disciplinary.vue?vue&type=script&lang=js"
export * from "./disciplinary.vue?vue&type=script&lang=js"
import style0 from "./disciplinary.vue?vue&type=style&index=0&id=39e3d590&prod&lang=css"
import style1 from "./disciplinary.vue?vue&type=style&index=1&id=39e3d590&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39e3d590",
  null
  
)

export default component.exports