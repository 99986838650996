<template>

<div class="Signup">
       <v-container fluid fill-height style="margin-top:2rem;background:#f5f5f5 !important">
          <v-layout align-center justify-center>
             <v-flex xs12 sm8 md4 lg3 xl3>
              <v-form  enctype="multipart/form-data" v-model="valid" ref="form" class="forms">
                <v-card class="card-shadow px-3">
                   <v-card-title color="primary">
                    <v-row>
                      <v-col cols="12" class="px-2">
                        <img src="/assets/images/logo/logo.png" style="width:25% !important;text-align:center">
                        <h2 class="card-title fw-bold mt-3">Absolute Cabs 🚀</h2>
                        <p class="card-text mb-2">We make your travel comfortable and enjoyable!</p>
                      </v-col>
                    </v-row>
                  </v-card-title>
                   <v-card-text style="padding:0 !important">
                    <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                         <v-text-field
                            prepend-icon="mdi-account"
                            name="name"
                            label="Full names"
                            v-model="form.name"
                            :rules="fieldRules"
                         ></v-text-field>
                       </v-col>
                       <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                         <v-text-field
                            prepend-icon="mdi-phone"
                            name="telephone"
                            label="Telephone"
                            v-model="form.telephone"
                            :rules="fieldRules"
                         ></v-text-field>
                       </v-col>
                       <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                         <v-text-field
                            prepend-icon="mdi-card"
                            name="idno"
                            label="ID NO"
                            v-model="form.idno"
                            :rules="fieldRules"
                         ></v-text-field>
                       </v-col>
                       <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                         <v-text-field
                            prepend-icon="mdi-email"
                            name="email"
                            label="Email Address"
                            v-model="form.email"
                            :rules="emailRules"
                         ></v-text-field>
                       </v-col>
                       <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                         <v-text-field
                            id="password"
                            prepend-icon="mdi-lock"
                            name="password"
                            label="Password"
                            type="password"
                            v-model="form.password"
                            :rules="fieldRules"
                         ></v-text-field>
                       </v-col>
                       <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                         <v-text-field
                            id="cpassword"
                            prepend-icon="mdi-lock"
                            name="confrim password"
                            label="Confirm Password"
                            type="password"
                            v-model="form.password_confirmation"
                            :rules="fieldRules"
                         ></v-text-field>
                       </v-col>
                     </v-row>
                   </v-card-text>
                   <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary"
                      :loading="loading"
                      :disabled="!valid"
                      @click="create"
                      >Register</v-btn>
                   </v-card-actions>
                   <!-- <p class="text-center" style="padding:1rem"><span>Already have an account?</span><router-link :to="{ name: 'Login' }"><span>&nbsp;Sign in instead</span></router-link></p> -->
                </v-card>
               </v-form>
             </v-flex>
          </v-layout>
       </v-container>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'Signup',
    components: {
    },
    data: () => ({
      fieldRules: [
      (v) => !!v || 'Field is required'
      ],
      valid: true,
      authUser:null,
      valid: true,
      loading: false,
      disabled: false,
      snackbar: false,
      type: 'password',
      showPassword: false,
      showCPassword: false,
      form: {
        name: null,
        email: null,
        telephone: null,
        idno: null,
        password: null,
        password_confirmation: null
      },
      passwordRules: [
      (v) => !!v || 'Password is required'
      ],
      emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 30) || 'Name must be less than 30 characters'
      ]
    }),
    methods: {
        create() {

          if (this.$refs.form.validate()) {

            this.loading = true
            this.disabled = true
            
            this.$store
            .dispatch('signup', {
                name: this.form.name,
                email: this.form.email,
                telephone: this.form.telephone,
                idno: this.form.idno,
                password: this.form.password,
                password_confirmation: this.form.password_confirmation
            })
            .then(() => {

                this.loading = false
                this.disabled = false

              if(this.$store.state.user.status === parseInt(200))
              {

                this.$toast.success('Signup was successful','Success',{position: 'topRight',timeout: 7000,})
                
                this.$router.push({ name: 'Dashboard' })
                // window.location.href = '/'

              }else{
                this.$toast.error('Check your credentials and try again','Error',{position: 'topRight',timeout: 7000,})
              }
            })
            .catch(() => {
                this.loading = false
                 this.disabled = false
                 this.$toast.error('Check your credentials and try again','Error',{position: 'topRight',timeout: 7000,})
                console.log('Unexpected error occured. Please try again!')
            })

          }

        }
    },
    computed: {
        ...mapGetters([
            'isLogged'
            ])
    }
}
</script>