<template>
  <div class="about mt-0">
    <div class="mx-0 px-0" style="position:relative">
      <div class="flex" :class= "[mini ? 'minipadding' : 'expandpanding']">
      	<div class="section_title"><span class="title">Permission&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
      	<div>
      		<b-button variant="primary" class="primary" size="sm" @click.stop="dialog = true">Add Permission <b-icon class="mr-1" icon="plus"></b-icon></b-button>
      	</div>
      </div>
      <b-row class="subsection">
      	<b-col sm="12" xl="12" md="12" class="mx-0 px-3">
      		<div class="dynamic-scroller permission">
            <div class="empty-state" v-if="permissions.length === 0">
              <div class="empty-state__content">
                <div class="empty-state__icon">
                  <img src="@/assets/data.svg" style="width:40%">
                </div>
                <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                <div class="empty-state__message" v-else>No records available.</div>
              </div>
            </div>
      			<RecycleScroller
		          ref="scroller"
		          class="scroller"
		          :items="permissions"
		          :item-size="24"
		          key-field="id"
		        >
		          <template  v-slot="{ item,index }">
		            <div>
    					    <div class="tcell">{{ index + 1 }}</div>
                  <div class="tcell">{{ item.name }}</div>
    					    <div class="tcell text-center">
    					      	<a class="list-action" :id="`popover-target-1-${item.id}-${index}`">
    						  		<b-icon class="my-0 py-0" style="cursor:pointer;" icon="chevron-down"></b-icon>
    						    </a>
    						    <b-popover
      							  :target="`popover-target-1-${item.id}-${index}`"
      							  triggers="hover blur"
      							  placement="left"
      							  class="dropdown-menu"
      							>
      							  <div class="action-dropdown-menu">
      						  		<button @click="editType(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="pencil"></b-icon>&nbsp;  Edit</button>
      						  		<button @click="deleteItem(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="trash"></b-icon>&nbsp;  Remove</button>
      						  	</div>
      							</b-popover>
    					    </div>
    					  </div>
		          </template>
		        </RecycleScroller>
			  </div>
      	</b-col>
      </b-row>
    </div>

    <!-- add kodal -->
    <b-modal
      v-model="dialog"
      size="sm"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Add Permission</span>
        </div>
      </template>
      <b-container fluid>
        <b-row>
          <b-col sm="12" md="12" class="float_label mb-3">
            
              <b-form-input
                placeholder="Permission"
                size="sm"
                v-model="item.permission"
              ></b-form-input>
            <label class="typo__label float_label_item">Permission*</label>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="create">
            <b-spinner small v-if="loading"></b-spinner> Save
          </b-button>
      </template>
    </b-modal>

    <!-- update modal -->
    <b-modal
      v-model="updatedialog"
      size="sm"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Update Permission</span>
        </div>
      </template>
      <b-container fluid>
        <b-row>
          <b-col sm="12" md="12" class="float_label mb-3">
            
              <b-form-input
                placeholder="Permission"
                size="sm"
                v-model="item.updatepermission"
              ></b-form-input>
            <label class="typo__label float_label_item">Permission*</label>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="update">
            <b-spinner small v-if="loading"></b-spinner> Update
          </b-button>
      </template>
    </b-modal>

  </div>
</template>

<style>
	@import "@/assets/table/table";
  .modal-organisation{
    max-width:540px !important;
  }
	.action-dropdown-menu .action-dropdown-item:hover{
		background:var(--ab-primary);
		color:#fff;
		border-radius:var(--ab-dropdown-border-radius);
	}
	.action-dropdown-menu{
		padding-right: .5rem;
	}
  .permission .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-template-columns: 20% 70% 10%;
     grid-gap: 0px;
     min-width:100%;
  }
  .permission .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }
</style>
<style scoped>
	@import "@/assets/table/table_scoped";
  .permission .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-template-columns: 20% 70% 10%;
     grid-gap: 0px;
  }
  
</style>

<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';

 export default {
  name: 'permissionList',
  components: {
  },
  computed: {
	  mini() {
	    return this.$store.state.mini
	  },
    loader() {
    	return this.$store.state.tableloader
    },
    permissions() {
      return this.permission
    },
  },
  data: () => ({
      search: '',
      dialog: false,
      updatedialog: false,
      loading: false,
      permission: [],
      fields: [
         {
          text: '#',
          align: 'start',
          value: '#',
        },
        { text: 'Name', value: 'name' },
        { text: 'Action', value: 'action' },
       ],
      item:{
            permission:"",
            updatepermission: "",
            updateID: "",
            _method:"patch"
        },
  }),
  beforeMount() {
      if(!ability.can('user-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
  mounted() {

  	let headertitle = `<div class="view-titleheader titlehead" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="theader">${value.text}</div>`;
         });
      headertitle +=`</div>`;
      $('.vue-recycle-scroller').prepend(headertitle)

  	if(this.permissions.length == 0) {
        this.getPermission()
      }
  },
  serverPrefetch () {
    // this.getPermission()
  },
  methods: {
  	async getPermission(){
        this.isBusy = true
        await axios.get('/permission').then(response=>{
            this.isBusy = false
            this.permission = response.data.permission
        }).catch(error=>{
            console.log(error)
            this.isBusy = false
        })
      },
       async create(){


          this.loading = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('permission',this.item.permission)

          await axios.post('/permission', fd, config).then(response=>{
            this.loading = false
            this.res = response.status
            if(this.res === parseInt(200))
              this.$toastpopup('success','Success',response.data.message)
              this.getPermission()
              this.clear()
            this.item.permission = ''
          }).catch(error=>{
            this.loading = false
              console.log(error)
          })

        },
        clear () {
          this.item.permission = ''
        },
        async update(){

          this.loading = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('permission',this.item.updatepermission)
          fd.append('_method',this.item._method)

          await axios.post(`/permission/${this.item.updateID}`, fd, config).then(response=>{
            this.loading = false
            this.res = response.status
            if(this.res === parseInt(200))
              this.$toastpopup('success','Success',response.data.message)
              this.updatedialog = false
              this.getPermission()
            this.item.updatepermission = ''
            this.item.updateID = ''
          }).catch(error=>{
            this.loading = false
              console.log(error)
          })

        },
      async editType(id) {
        let index = this.permission.filter(item => item.id === id );
        this.item.updatepermission = index[0].name
        this.item.updateID = index[0].id
        this.updatedialog = true
      },
      deleteItem(id){
            if(confirm("Are you sure to remove permission ?")){
                axios.delete(`/permission/${id}`).then(response=>{
                  this.$toastpopup('success','Success',response.data.message)
                    this.getPermission()
                }).catch(error=>{
                    console.log(error)
                })
            }
        }

  }
};

</script>
