<template>
  <div class="settingswrapper mt-0">
    <div class="mx-0 px-0" style="position:relative">
      <div class="flex" :class= "[mini ? 'minipadding' : 'expandpanding']">
      	<div class="section_title"><span class="title">Settings&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
      </div>
      <b-row class="subsection pt-5" style="display:flex;align-items:center;justify-content:center">
      	<b-col sm="12" xl="8" md="10" lg="9" class="mx-0 px-3">
          <b-card no-body class="settings">
            <b-tabs pills card vertical nav-wrapper-class="w-25">
              <b-tab title="SMS Credentials" active>
                <b-card-text class="px-0 py-0 mx-0">
                  <b-row>
                    <b-col cols="12" style="display:flex;align-items:center;justify-content:flex-end">
                      <b-button variant="primary" class="primary" size="sm" @click.stop="bulksmsdialog = true">Update Details <b-icon class="mr-1" icon="plus"></b-icon></b-button>
                    </b-col>
                    <b-col cols="12" class="mt-2">
                      <table>
                        <tr>
                          <td><strong>Api Key</strong></td>
                          <td><strong>Partner ID</strong></td>
                          <td><strong>Shortcode</strong></td>
                        </tr>
                        <tr>
                          <td><em>{{ $store.state.settingsapikey }}</em></td>
                          <td><em>{{ $store.state.settingspartnerid }}</em></td>
                          <td><em>{{ $store.state.settingsshortcode }}</em></td>
                        </tr>
                      </table>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>
              <b-tab title="Waiting Time">
                <b-card-text class="px-0 py-0">
                  <b-row>
                    <b-col cols="12" style="display:flex;align-items:center;justify-content:flex-end">
                      <b-button variant="primary" class="primary" size="sm" @click.stop="waitingtimedialog = true">Update Waitings <b-icon class="mr-1" icon="plus"></b-icon></b-button>
                    </b-col>
                    <b-col cols="12" class="mt-2">
                      <table>
                        <tr>
                          <td><strong>Vehicle</strong></td>
                          <td><strong>Free Minutes</strong></td>
                          <td><strong>Rate/Minute</strong></td>
                        </tr>
                        <tr v-for="(item,index) in ratecharges" :key="index">
                          <td><span>{{ item.type.type }}</span></td>
                          <td><span>{{ item.minutes }}</span></td>
                          <td><span>{{ item.rate }}</span></td>
                        </tr>
                      </table>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>
              <b-tab title="VAT">
                <b-card-text>
                  <b-row>
                    <b-col cols="12" style="display:flex;align-items:center;justify-content:flex-end">
                      <b-button variant="primary" class="primary" size="sm" @click.stop="vatdialog = true">Update Vat <b-icon class="mr-1" icon="plus"></b-icon></b-button>
                    </b-col>
                    <b-col cols="12" class="mt-2">
                      <table>
                        <tr>
                          <td><strong>VAT</strong></td>
                        </tr>
                        <tr>
                          <td><em>{{ $store.state.settingsvat }}</em></td>
                        </tr>
                      </table>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-card>
      	</b-col>
      </b-row>
    </div>

    <!-- components -->
    <BulkSms v-model="bulksmsdialog"/>
    <WaitingTime v-model="waitingtimedialog"/>
    <Vat v-model="vatdialog"/>

  </div>
</template>

<style>
	@import "@/assets/table/table";
  .modal-customer{
    max-width:480px !important;
  }
	.action-dropdown-menu .action-dropdown-item:hover{
		background:var(--ab-primary);
		color:#fff;
		border-radius:var(--ab-dropdown-border-radius);
	}
	.action-dropdown-menu{
		padding-right: .5rem;
	}
  .customer .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-template-columns: 17.28% 8.28% 18.28% 10.28% 23.28% 10.28% 12.28%;
     grid-gap: 0px;
     min-width:100%;
  }
  .customer .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }
  .settings table td{
    padding:.1rem .8rem !important;
  }
  .settings .card-body{
    padding:1.2rem 1rem !important;
  }
  .settings .card-header{
    padding:0 !important;
  }
  .settings .nav-link.active{
    color:#F9760B !important;
    border-right: 0 !important;
    background:#fff !important;
  }
</style>
<style scoped>
	@import "@/assets/table/table_scoped";
  .customer .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-template-columns: 17.28% 8.28% 18.28% 10.28% 23.28% 10.28% 12.28%;
     grid-gap: 0px;
  }
  
</style>

<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';

import BulkSms from '@/components/settings/BulkSms'
import WaitingTime from '@/components/settings/WaitingTime'
import Vat from '@/components/settings/Vat'

 export default {
  name: 'Settings',
  components: {BulkSms,WaitingTime,Vat},
  computed: {
	  mini() {
	    return this.$store.state.mini
	  },
    loader() {
    	return this.$store.state.tableloader
    },
    ratecharges() {
      return this.$store.state.settingsratecharges
    },
  },
  data: () => ({
      bulksmsdialog: false,
      waitingtimedialog: false,
      vatdialog: false,
      searchfilter: '',
      search: '',
      loading: false,
      fields: [
        { text: 'Name', value: 'name' },
        { text: 'Telephone', value: 'telephone' },
        { text: 'Email', value: 'email' },
        { text: 'Account Type', value: 'account' },
        { text: 'Organisation', value: 'organisation' },
        { text: 'Status', value: 'status' },
        { text: 'Action', value: 'action' },
       ],
       item: {
        apikey: '',
        partnerid: '',
        shortcode: '',
        one_trip_rate:"",
        round_trip_rate:"",
        type_id: "",
        rate: "",
        minutes: "",
        vat: ""
       },
  }),

  mounted() {
  	this.getSettingsDetails()
  },
  beforeMount() {
    if(!ability.can('view-reports', 'all')) {
      this.$router.push({ name: 'Dashboard' })
      return
    }
  },

  methods: {

      getSettingsDetails(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getConfig')
      },

  }
};

</script>
