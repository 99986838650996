<template>
	<div>
	  <!-- client profile modal -->
    <b-modal
      v-model="show"
      size="sm"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Update Distance</span>
        </div>
      </template>
      <b-container fluid>
        <b-row class="mx-0 my-0 py-0">
          <b-col sm="12" md="12" class="float_label">
              <b-form-input
                placeholder="Distance"
                required
                size="sm"
                v-model="distance"
              ></b-form-input>
            <label class="typo__label float_label_item">Distance*</label>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="submit">
            <b-spinner small v-if="loading"></b-spinner> Submit
          </b-button>
      </template>
    </b-modal>
    <!-- end client profile -->
	</div>
</template>

<style scoped>
  .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > th, .v-data-table-header > th {
    font-weight: 500;
    height: 25px !important;
}
.clientprofile td{
  white-space: normal !important; 
}
</style>

<script>
import axios from 'axios'
import Vue from 'vue'

export default {
	name: 'editdistance',
	components: {
	},
	data: () => ({
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        distance:"",
	}),
	methods: {
      submit(){
          // if (this.$refs.form.validate()) {

            this.loading = true
            this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('distance',this.distance)
            fd.append('id',this.bookingid)

            axios.post('/booking/updatedistance', fd, config).then(response=>{

              if(response.data.status === parseInt(201)) {
                this.$toastpopup('success','Success',"Distance updated successfully")
              }
              this.distance = ""
              this.show = false
              this.loading = false
              this.disabled = false
              
            })
            .catch(() => {
              this.loading = false
              this.disabled = false
            })

        // }
      },
	},
	props: {
	     value: Boolean,
	     bookingid: Number
	  },
	  computed: {
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    }
	  },
	mounted() {},
	created() {}
}
</script>