<template>
  <div class="about mt-0">
    <div class="mx-0 px-0" style="position:relative">
      <div class="flex" :class= "[mini ? 'minipadding' : 'expandpanding']">
      	<div class="section_title"><span class="title">Payments&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
      	<div>
      		<b-button variant="primary" class="primary" size="sm"><router-link link :to="{name:'newPayment'}">Add Payment <b-icon class="mr-1" icon="plus"></b-icon></router-link></b-button>
      	</div>
      </div>
      <b-row class="subsection">
      	<b-col sm="12" xl="12" md="12" class="mx-0 px-3">
      		<div class="dynamic-scroller payment">
            <b-row class="mx-2 mb-2 searchsection" style="display:flex;justify-content:flex-end;align-items:center">
              <div class="px-1" style="width:200px !important;padding-top:0 !important;padding-bottom:0 !important;display:flex;">
                <b-form-input
                    placeholder="Search..."
                    size="sm"
                    v-model="searchfilter"
                  ></b-form-input>
              </div>
            </b-row>
            <div class="empty-state" v-if="allpayments.length === 0">
              <div class="empty-state__content">
                <div class="empty-state__icon">
                  <img src="@/assets/data.svg" style="width:40%">
                </div>
                <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                <div class="empty-state__message" v-else>No records available.</div>
              </div>
            </div>
      			<RecycleScroller
		          ref="scroller"
		          class="scroller"
		          :items="allpayments"
		          :item-size="24"
		          key-field="id"
		        >
		          <template  v-slot="{ item,index }">
		            <div>
    					    <div class="tcell"> {{ moment(item.date).format('MMM Do YYYY') }}</div>
                  <div class="tcell">
                    <RouterLink style="color:#F98525 !important;font-weight:500 !important;text-decoration:none !important;font-size: 10px !important;" link
                            :to='{name:"showPayment",params:{id:item.id}}'>{{ item.payment_no }}</RouterLink>
                          </div>
                  <div class="tcell">
                    <div v-if="item.organisation !== null">
                      <span class="badge__secondary" v-b-tooltip.hover.left :title="item.organisation.name">{{ item.organisation.name }}</span>
                    </div>
                    <span v-else>Private</span>
                  </div>
                  <div class="tcell"><span v-if="item.invoice !== null">{{ item.invoice.invoice_no }}</span></div>
                  <div class="tcell">{{ item.mode.name }}</div>
                  <div class="tcell">KES<span style="font-weight:600 !important">{{ item.paidamount | currency(' ') }}</span></div>
    					    <div class="tcell text-center">
    					      	<a class="list-action" :id="`popover-target-1-${item.id}-${index}`">
    						  		<b-icon class="my-0 py-0" style="cursor:pointer;" icon="chevron-down"></b-icon>
    						    </a>
    						    <b-popover
      							  :target="`popover-target-1-${item.id}-${index}`"
      							  triggers="hover blur"
      							  placement="left"
      							  class="dropdown-menu"
      							>
      							  <div class="action-dropdown-menu">
      						  		<button v-if="item.invoice == null"
                              @click="linkpayment(item.id,item.organisation_id, item.payment_no)" class="action-dropdown-item " type="button"><b-icon class="" icon="link-45deg"></b-icon>&nbsp;  Link Payment</button>
                        <button class="action-dropdown-item " type="button"><router-link link :to='{name:"editPayment",params:{id:item.id}}'><b-icon class="" icon="pencil"></b-icon>&nbsp;  Edit</router-link></button>
      						  		<button @click="deletePayment(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="trash"></b-icon>&nbsp;  Remove</button>
      						  	</div>
      							</b-popover>
    					    </div>
    					  </div>
		          </template>
		        </RecycleScroller>

			  </div>
      	</b-col>
      </b-row>
    </div>

    <!-- components -->
    <LinkPayment v-model="linkpaymentdialog"/>
  </div>
</template>

<style>
	@import "@/assets/table/table";
  .modal-payment{
    max-width:750px !important;
  }
	.action-dropdown-menu .action-dropdown-item:hover{
		background:var(--ab-primary);
		color:#fff;
		border-radius:var(--ab-dropdown-border-radius);
	}
	.action-dropdown-menu{
		padding-right: .5rem;
	}
  .payment .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-auto-columns: minmax(14.28%, 14.28%) !important;
     grid-gap: 0px;
     min-width:100%;
  }
  .payment .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }
</style>
<style scoped>
	@import "@/assets/table/table_scoped";
  .payment .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-auto-columns: minmax(14.28%, 14.28%) !important;
     grid-gap: 0px;
  }
  
</style>

<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';

import LinkPayment from '@/components/payments/linkpayment'

 export default {
  name: 'paymentList',
  components: { LinkPayment },
  computed: {
	  mini() {
	    return this.$store.state.mini
	  },
    loader() {
    	return this.$store.state.tableloader
    },
    payments() {
      return this.$store.state.payments.filter(item => {

        this.start = 0

        let invoice_no = "";
        let payment_no = "";
        let reference_no = "";
        let date = "";
        if(item.date !== null) {
          date = item.date
        }
        if(item.payment_no !== null) {
          payment_no = item.payment_no
        }
        if(item.reference_no !== null) {
          reference_no = item.reference_no
        }
        if(item.invoice !== null) {
          invoice_no = item.invoice.invoice_no
        }
        return payment_no.toLowerCase().includes(this.searchfilter.toLowerCase()) || invoice_no.toLowerCase().includes(this.searchfilter.toLowerCase()) || date.includes(this.searchfilter) || reference_no.toLowerCase().includes(this.searchfilter.toLowerCase())
      })
    },
    allpayments() {
      return this.payments
    },
    modes() {
      return this.$store.state.modes
    },
  },
  data: () => ({
      searchfilter: '',
      search: '',
      dialog: false,
      linkpaymentdialog: false,
      loading: false,
      fields: [
       {
        text: 'DATE',
        align: 'start',
        value: 'date',
      },
      { text: 'PAYMENT#', value: 'paymentno' },
      { text: 'ORGANISATION', value: 'organisation' },
      { text: 'INVOICE#', value: 'invoice' },
      { text: 'MODE', value: 'mode' },
      { text: 'AMOUNT', value: 'amount' },
      { text: 'ACTION', value: 'action' },
     ],
    item:{
        invoice_id: null,
        organisationid: null,
        id: "",
        tolinkreceipt: "",
        _method:"patch"
    },
  }),

  mounted() {

  	let headertitle = `<div class="view-titleheader titlehead" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="theader">${value.text}</div>`;
         });
      headertitle +=`</div>`;
      $('.vue-recycle-scroller').prepend(headertitle)

  	if(!this.allpayments || this.allpayments.length == 0) {
        this.getpayments()
      }
      
  },
  beforeMount() {
    if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
  },
  serverPrefetch () {
    // return this.getpayments()
  },
  methods: {
  	async getpayments(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getpayments')
      },
  
      clear () {
        this.item.invoice_id = null
      },

      linkpayment(id, organisationId, paymentNo) {
        this.$store.state.linkpaymentdata = {
          id: id,
          organisationid: organisationId,
          tolinkreceipt: paymentNo
        }
        this.linkpaymentdialog = true
      },
      deletePayment(id){
            if(confirm("Are you sure to delete payment ?")){
                axios.delete(`/payments/${id}`).then(response=>{
                  let index = this.$store.state.payments.findIndex(data => data.id == response.data.payment.id);
                  if (index > -1) {
                    this.$store.state.payments.splice(index, 1);
                  }
                  this.$toastpopup('success','Success','Payment removed successfully')
                }).catch(error=>{
                    console.log(error)
                })
            }
        }
  }
};

</script>
