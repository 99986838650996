<template>
	<div>
		<b-modal
	      v-model="show"
	      size="allbookingsearch"
	      >
	      <template #modal-title>
	        <div class="w-100">
	          <span class="title float-left">Search Booking</span>
	        </div>
	      </template>
	      <b-container fluid>
	        <b-row>

	          <b-col sm="12" md="12" class="float_label mb-2">
	            
	              <b-form-input
	                type="text"
	                placeholder="Passenger name"
	                v-model="item.name"
	                size="sm"
	              ></b-form-input>
	            <label class="typo__label float_label_item">Passenger</label> 
	          </b-col>
	          <b-col sm="12" md="12" class="float_label mb-2">
	            
	              <b-form-input
	                type="text"
	                placeholder="Telephone"
	                v-model="item.telephone"
	                size="sm"
	              ></b-form-input>
	            <label class="typo__label float_label_item">Telephone</label> 
	          </b-col>
	          <b-col sm="12" md="12" class="float_label mb-2">
	           
	              <b-form-input
	                type="text"
	                placeholder="Organisation"
	                v-model="item.company"
	                size="sm"
	              ></b-form-input>
	            <label class="typo__label float_label_item">Organisation</label> 
	          </b-col>

	        </b-row>
	      </b-container>
	      <template #modal-footer>
	          <b-button variant="primary" class="primary" @click="search">
	            <b-spinner small v-if="loading"></b-spinner> Submit
	          </b-button>
	      </template>
	    </b-modal>
	</div>
</template>
<style>
  .modal-allbookingsearch{
    max-width:450px !important;
  }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'

export default {
	name: 'allbookingsearch',
	components: {
	},
	data: () => ({
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        menu:false,
        fields: [
        { text: 'Passenger', value: 'Passenger name', name:'' },
        { text: 'Telephone', value: 'Telephone', telephone:'' },
        { text: 'Company', value: 'Organisation', company:'' }
       ],
       item: {
       	name: '',
       	telephone: '',
       	company: ''
       }

	}),
	methods: {
      search() {
      	  this.loading = true

	      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
	      let fd = new FormData();
	      fd.append('name',this.item.name)
	      fd.append('telephone',this.item.telephone)
	      fd.append('company',this.item.company)

	      axios.post('/allbookings/search', fd, config).then(response=>{
	        this.loading = false
	        if(response.data.status == parseInt(200)) {
	          this.$store.commit('getSearchBookings', response.data)
	          this.show = false
	        }
	        
	      })
	      .catch((error) => {
	        this.loading = false
	        console.log(error)
	      })
      }
	},
	props: {
     value: Boolean
  	},
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  },
	mounted() {

	},
	created() {
     
    }
}
</script>