<template>
  <div class="showinvoicesdetails mt-0">
    <div class="mx-0 px-0" style="position:relative">
      <b-row class="my-0 py-0">
        <b-col cols="12" class="invoice-content">
          <div class="column list-column">
            <div class="fill header list-header d-flex">
              <div class="section_title"><span class="title">All Invoices&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
            </div>
            <div class="fill body list-body d-flex scroll-y">
              <div class="scroll-y scrollbox">
                <ul class="content-list" id="itemList">
                  <li @click="viewInvoice(invoice.id)" v-for="(invoice,index) in itemsForList" :key="index">
                    <div class="list-title">
                      <span>{{ invoice.organisations.length > 0 ? invoice.organisations[0].organisation.name : "Private" }}</span>
                      <span>KES{{ invoice.amount | currency('') }}</span>
                    </div>
                    <div class="list-subtitle">
                      <span><a href="" style="color:#F98525 !important;text-decoration:none !important;font-size: 11.5px !important;font-weight:500 !important">{{ invoice.invoice_no }}</a> | {{ moment(invoice.date).format('MMM Do, YYYY') }}</span>
                      <span style="color:#44C189" v-if="invoice.payments.length > 0">PAID</span>
                      <span v-else>DRAFT</span>
                    </div>
                  </li>
                </ul>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="itemList"
                  v-if="allinvoices.length > 0"
                ></b-pagination>
              </div>
            </div>
          </div>
          <div class="column content-column">
            <div class="fill header list-header d-flex">
              <div class="section_title"><span class="title" style="color:#888888">{{ details !== null ? details.invoice_no : "" }}&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader1" label="Loading..."></b-spinner></div>
            </div>
            <div class="fill subheader list-subheader d-flex">
              <b-button v-if="details !== null && details.payments.length === 0" variant="light" class="light" size="sm"><router-link link :to='{name:"editInvoice",params:{id:item.id}}'><b-icon class="mr-1" icon="pencil"></b-icon> Edit</router-link></b-button>
              <b-button variant="light" class="light" size="sm" id="popover-invoice-show-more"><b-icon class="mr-1" icon="printer"></b-icon> Print</b-button>
              <b-popover
                target="popover-invoice-show-more"
                triggers="hover blur"
                placement="bottom"
                class="dropdown-menu"
              >
                <div class="action-dropdown-menu">
                  <button  class="action-dropdown-item" @click="downloadpdf" type="button"><b-icon class="mr-1" icon="file-earmark-pdf"></b-icon>&nbsp;Pdf</button>
                  <button  class="action-dropdown-item" @click="downloadexcel" type="button"><b-icon class="mr-1" icon="file-earmark-excel"></b-icon>&nbsp;Excel</button>
                </div>
              </b-popover>
              <b-button variant="light" class="light" size="sm" @click="paymentdialog = true"><b-icon class="mr-1" icon="record-circle"></b-icon> Record Payment</b-button>
            </div>
            <div class="fill body list-body d-flex scroll-y">
              <div class="scroll-y scrollbox">
                <div class="invoice-template" v-if="details !== null">
                  <div class="ribbon ribbon-top-right green" v-if="details.payments.length > 0">
                    <span>PAID</span>
                  </div>
                  <div v-else class="ribbon ribbon-top-right grey">
                    <span>DRAFT</span>
                  </div>
                  <b-row class="mt-4">
                    <b-col cols="12" md="6">
                      <img src="/assets/images/logo/logo.png" style="width:50% !important;">
                      <p class="mt-2" style="font-size:.75rem !important;line-height:1.6">P.O Box 6137-00200-Nairobi</br>
                      Kandara Road, Hse 9, Kileleshwa</br>
                      Telephone: 0202011846/2011824</br>
                      Cellphone: 0721400422/0728600654/<br>0733523047/0735009952</br>
                      Email: <a href="mailto:info@absoluteadventuresafaris.com" target="_blank">info@absoluteadventuresafaris.com</a></br>
                      PIN NO: P051199717F | VAT NO: P051199717F</p>

                      <p style="font-size:.92rem !important;margin-top:2rem !important">Bill To</p>
                      <p style="color:#F98525;margin-top:-.8rem !important"><span v-for="(organisation,index) in details.organisations" :key="index" style="font-size:11px !important;text-transform:uppercase !important">{{ organisation.organisation.name }}<br></span></p>
                    </b-col>
                    <b-col cols="12" md="6" style="text-align:right">
                      <h2 style="font-size:1.7rem !important">INVOICE</h2>
                      <p>#{{ details.invoice_no }}</p>
                      <p style="margin-top:3rem !important;font-size:.75rem !important">Balance Due</p>
                      <p style="font-size:1.2rem !important;color:#333 !important;font-weight:500 !important;margin-top:-1.1rem !important">{{ 
                        ( 
                         parseFloat(details.amount )) | currency(' ') }}</p>
                      <p style="margin-top:5rem !important;display:flex;justify-content:end">
                          <table>
                              <tr>
                                <td class="py-2" style="padding-right:3rem !important">Invoice Date:</td>
                                <td class="py-2">{{ moment(details.date).format('Do MMM YYYY') }}</td>
                              </tr>
                              <tr>
                                <td class="py-2" style="padding-right:3rem !important">Due Date:</td>
                                <td class="py-2">{{ moment(details.due_date).format('Do MMM YYYY') }}</td>
                              </tr>
                          </table>
                      </p>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                        cols="12"
                        sm="12"
                        md="12"
                        class="mt-1"
                        v-if="details.custom_invoice === 'false'"
                      >
                      <p style="font-size:12px !important;margin-top:0rem !important;">{{ details.subject !== null && details.subject !== 'null' ? details.subject : "" }}</p>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="12"
                      md="12"
                      class="mt-0 pt-0"
                      v-if="details.custom_invoice === 'false'"
                      >
                      <div class="dynamic-scroller invoiced mt-0 pt-0">

                        <RecycleScroller
                          ref="scroller"
                          class="scroller"
                          :items="details.bookings"
                          :item-size="24"
                          key-field="id"
                        >
                          <template  v-slot="{ item,index }">
                            <div>
                              <div class="tcell">
                                <span v-if="item.booking.passenger_name !== null" v-b-tooltip.hover.left :title="item.booking.passenger_name">
                                  {{ item.booking.passenger_name }}
                                </span>
                                <div v-else style="display:flex;flex-direction:column !important">
                                  <span v-b-tooltip.hover.left :title="passenger.passenger_name" v-for="(passenger,index) in item.booking.passengers" style="display:flex;"><b-icon class="mr-1" icon="dot"></b-icon> {{ passenger.passenger_name }}</span>
                                </div>
                              </div>
                              <div class="tcell" v-b-tooltip.hover.left :title="item.booking.pickup_date">{{ item.booking.pickup_date }} {{ item.booking.pickup_time }}</div>
                              <div class="tcell" v-b-tooltip.hover.left :title="item.booking.travelfrom">
                                {{ item.booking.travelfrom }}
                              </div>
                              <div class="tcell" v-b-tooltip.hover.left :title="item.booking.travelto">
                                {{ item.booking.travelto }}
                              </div>
                              <div class="tcell">
                                <span v-if="item.booking.vehicletype !== null" v-b-tooltip.hover.left :title="item.booking.vehicletype.type">{{ item.booking.vehicletype.type }}</span>
                                <span v-else>N/A</span>
                              </div>
                              <div class="tcell">{{ item.booking.distance | currency('') }}</div><!-- 
                              <div class="tcell">
                                <div v-if="item.booking.organisationrate.length > 0">
                                  <span v-if="parseInt(item.booking.organisationrate[0].base_rate) === 0">{{ Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate)) | currency('') }}</span>
                                  <span v-else-if="parseInt(item.booking.organisationrate[0].base_rate) > 0 && parseInt(((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))) > parseInt(item.booking.organisationrate[0].base_rate)">{{ Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate)) | currency('') }}</span>
                                  <span v-else>{{ item.booking.organisationrate[0].base_rate | currency('') }}</span>
                                </div>
                                <div v-else>0.00</div>
                              </div>

                              <div class="tcell">
                                <div v-if="item.booking.organisationrate.length > 0">
                                  <span v-if="parseInt(item.booking.organisationrate[0].base_rate) === 0">{{ ((Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                                  <span v-else-if="parseInt(item.booking.organisationrate[0].base_rate) > 0 && parseInt(((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))) > parseInt(item.booking.organisationrate[0].base_rate)">{{ ((Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                                  <span v-else>{{ ((item.booking.organisationrate[0].base_rate*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                                </div>
                                <div v-else>0.00</div>
                              </div>
                              <div class="tcell">
                                <div v-if="item.booking.organisationrate.length > 0">
                                  <span v-if="parseInt(item.booking.organisationrate[0].base_rate) === 0">{{ (Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate)) + ((Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                                  <span v-else-if="parseInt(item.booking.organisationrate[0].base_rate) > 0 && parseInt(((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))) > parseInt(item.booking.organisationrate[0].base_rate)">{{ (Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate)) + ((Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                                  <span v-else>{{ (parseInt(item.booking.organisationrate[0].base_rate) + ((item.booking.organisationrate[0].base_rate*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                                </div>
                                <div v-else>0.00</div>
                              </div> -->
                            </div>
                          </template>
                        </RecycleScroller>

                    </div>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                      v-if="details.custom_invoice === 'true'"
                      >
                        <table class="mt-2">
                          <tr style="background:#dedede !important">
                            <td><strong>#</strong></td>
                            <td><strong>Description</strong></td>
                            <td><strong>Qty</strong></td>
                            <td><strong>Rate</strong></td>
                            <td><strong>Amount</strong></td>
                          </tr>
                          <tr v-for="(item,index) in details.items" :key="index">
                            <td>{{ ++index }}</td>
                            <td>{{ item.details }}</td>
                            <td>{{ item.quantity | currency('') }}</td>
                            <td>{{ item.rate | currency('') }}</td>
                            <td>{{ item.amount | currency('') }}</td>
                          </tr>
                        </table>
                      </b-col>
                    <b-col
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                      class="subtotals"
                      >
                        <table v-if="details.custom_invoice === 'false'">
                            <tr>
                              <td><strong>Sub Total</strong></td>
                              <td>
                                {{

                                (details.bookings.reduce( (acc, item) => 
                                  {  
                                      let amt = 0;
                                      if(item.booking.organisationrate.length > 0) {
                                        if(parseInt(item.booking.organisationrate[0].base_rate) === 0) {
                                          amt = Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate));
                                        }else if(parseInt(item.booking.organisationrate[0].base_rate) > 0 && parseInt(((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))) > parseInt(item.booking.organisationrate[0].base_rate)) {
                                          amt = Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate));
                                        }else {
                                          amt = parseInt(item.booking.organisationrate[0].base_rate)
                                        }
                                      }
                                    return acc += amt; 
                                  }, 0) + details.bookings.reduce( (acc, item) => 
                                {  

                                  let amt = 0, waitingfee = 0, subamt = 0;
                                  if(item.booking.waitingfee !== null && item.booking.driver_pickup_date !== null && item.booking.driver_arrival_date !== null) {
                                    if(parseFloat(moment.duration(moment(item.booking.driver_pickup_date+" "+item.booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.booking.driver_arrival_date+" "+item.booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.booking.waitingfee.minutes)) {

                                        waitingfee = Math.round((parseFloat(moment.duration(moment(item.booking.driver_pickup_date+" "+item.booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.booking.driver_arrival_date+" "+item.booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.booking.waitingfee.minutes)) * item.booking.waitingfee.rate);

                                    }
                                  }

                                  if(item.booking.waiting.length > 0) {
                                    subamt = item.booking.waiting.reduce((acc1, value) => { 
                                              let total = 0;
                                              if(value.starttime !== null && value.endtime !== null) {
                                                
                                                if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                                  total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                                }

                                              }

                                              return  acc1 += total
                                            }, 0)
                                  }

                                  amt = subamt + waitingfee;
                                  
                                  return acc += amt; 

                                }, 0)) | currency('')

                              }}
                              </td>
                            </tr>
                            <tr>
                              <td><strong>Vat</strong><span v-if="vat != null">({{ vat.rate }}%)</span></td>
                              <td>
                                {{

                                  ((((details.bookings.reduce( (acc, item) => 
                                    {  
                                        let amt = 0;
                                        if(item.booking.organisation.vat !== '0') {
                                          if(item.booking.organisationrate.length > 0) {
                                            if(parseInt(item.booking.organisationrate[0].base_rate) === 0) {
                                              amt = Math.round(((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate)));
                                            }else if(parseInt(item.booking.organisationrate[0].base_rate) > 0 && parseInt(((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))) > parseInt(item.booking.organisationrate[0].base_rate)) {
                                              amt = Math.round(((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate)));
                                            }else {
                                              amt = parseInt(item.booking.organisationrate[0].base_rate)
                                            }
                                          }
                                        }
                                      return acc += amt; 
                                    }, 0) + details.bookings.reduce( (acc, item) => 
                                  {  

                                    let amt = 0, waitingfee = 0, subamt = 0;
                                    if(item.booking.waitingfee !== null && item.booking.driver_pickup_date !== null && item.booking.driver_arrival_date !== null) {
                                      if(parseFloat(moment.duration(moment(item.booking.driver_pickup_date+" "+item.booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.booking.driver_arrival_date+" "+item.booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.booking.waitingfee.minutes)) {

                                          waitingfee = Math.round((parseFloat(moment.duration(moment(item.booking.driver_pickup_date+" "+item.booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.booking.driver_arrival_date+" "+item.booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.booking.waitingfee.minutes)) * item.booking.waitingfee.rate);

                                      }
                                    }

                                    if(item.booking.waiting.length > 0) {
                                      subamt = item.booking.waiting.reduce((acc1, value) => { 
                                                let total = 0;
                                                if(value.starttime !== null && value.endtime !== null) {
                                                  
                                                  if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                                    total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                                  }

                                                }

                                                return  acc1 += total
                                              }, 0)
                                    }

                                    amt = subamt + waitingfee;
                                    
                                    return acc += amt; 

                                  }, 0))*(vat != null ? vat.rate : 0))/100)) | currency('')

                                }}
                              </td>
                            </tr>
                            <tr>
                              <td><strong>Total KES</strong></td>
                              <td>
                                {{

                                (details.bookings.reduce( (acc, item) => 
                                {  

                                  let amt = 0, waitingfee = 0, subamt = 0;
                                  if(item.booking.waitingfee !== null && item.booking.driver_pickup_date !== null && item.booking.driver_arrival_date !== null) {
                                    if(parseFloat(moment.duration(moment(item.booking.driver_pickup_date+" "+item.booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.booking.driver_arrival_date+" "+item.booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.booking.waitingfee.minutes)) {

                                        waitingfee = Math.round((parseFloat(moment.duration(moment(item.booking.driver_pickup_date+" "+item.booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.booking.driver_arrival_date+" "+item.booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.booking.waitingfee.minutes)) * item.booking.waitingfee.rate);

                                    }
                                  }

                                  if(item.booking.waiting.length > 0) {
                                    subamt = item.booking.waiting.reduce((acc1, value) => { 
                                              let total = 0;
                                              if(value.starttime !== null && value.endtime !== null) {
                                                
                                                if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                                  total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                                }

                                              }

                                              return  acc1 += total
                                            }, 0)
                                  }

                                  amt = subamt + waitingfee;
                                  
                                  return acc += amt; 

                                }, 0) + details.bookings.reduce( (acc, item) => 
                                {  

                                  let amt = 0;

                                  if(item.booking.expressway.length > 0) {
                                    amt = Math.round(item.booking.expressway.reduce((acc1, value) => { return  acc1 += value.rate.rate}, 0))
                                  }
                                  
                                  return acc += amt; 

                                }, 0) + ((((details.bookings.reduce( (acc, item) => 
                                  {  
                                      let amt = 0;
                                      if(item.booking.organisation.vat !== '0') {
                                        if(item.booking.organisationrate.length > 0) {
                                          if(parseInt(item.booking.organisationrate[0].base_rate) === 0) {
                                            amt = Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate));
                                          }else if(parseInt(item.booking.organisationrate[0].base_rate) > 0 && parseInt(((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))) > parseInt(item.booking.organisationrate[0].base_rate)) {
                                            amt = Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate));
                                          }else {
                                            amt = parseInt(item.booking.organisationrate[0].base_rate)
                                          }
                                        }
                                      }
                                    return acc += amt; 
                                  }, 0) + details.bookings.reduce( (acc, item) => 
                                {  

                                  let amt = 0, waitingfee = 0, subamt = 0;
                                  if(item.booking.waitingfee !== null && item.booking.driver_pickup_date !== null && item.booking.driver_arrival_date !== null) {
                                    if(parseFloat(moment.duration(moment(item.booking.driver_pickup_date+" "+item.booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.booking.driver_arrival_date+" "+item.booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.booking.waitingfee.minutes)) {

                                        waitingfee = Math.round((parseFloat(moment.duration(moment(item.booking.driver_pickup_date+" "+item.booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.booking.driver_arrival_date+" "+item.booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.booking.waitingfee.minutes)) * item.booking.waitingfee.rate);

                                    }
                                  }

                                  if(item.booking.waiting.length > 0) {
                                    subamt = item.booking.waiting.reduce((acc1, value) => { 
                                              let total = 0;
                                              if(value.starttime !== null && value.endtime !== null) {
                                                
                                                if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                                  total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                                }

                                              }

                                              return  acc1 += total
                                            }, 0)
                                  }

                                  amt = subamt + waitingfee;
                                  
                                  return acc += amt; 

                                }, 0))*(vat != null ? vat.rate : 0))/100)) + details.bookings.reduce( (acc, item) => 
                                  {  
                                      let amt = 0;
                                      if(item.booking.organisationrate.length > 0) {
                                        if(parseInt(item.booking.organisationrate[0].base_rate) === 0) {
                                          amt = Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate));
                                        }else if(parseInt(item.booking.organisationrate[0].base_rate) > 0 && parseInt(((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))) > parseInt(item.booking.organisationrate[0].base_rate)) {
                                          amt = Math.round((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate));
                                        }else {
                                          amt = parseInt(item.booking.organisationrate[0].base_rate)
                                        }
                                      }
                                    return acc += amt; 
                                  }, 0)) | currency('')

                              }}
                              </td>
                            </tr>
                        </table>
                        <table v-else class="mt-3">
                            <tr>
                              <td><strong>Sub Total</strong></td>
                              <td>{{ (details.items.reduce( (acc, item) => 
                                      {  

                                        return acc += parseFloat(item.quantity)*parseFloat(item.rate); 

                                      }, 0)) | currency('') }}</td>
                            </tr>
                            <tr>
                              <td><strong>Vat</strong><span v-if="vat != null">({{ vat.rate }}%)</span></td>
                              <td>{{ ((((details.items.reduce( (acc, item) => 
                                  {  

                                    return acc += parseFloat(item.quantity)*parseFloat(item.rate); 

                                  }, 0))*(vat != null && details.includevat == 'true' ? vat.rate : 0))/100)) | currency('') }}
                              </td>
                            </tr>
                            <tr>
                              <td><strong>Total</strong></td>
                              <td>
                                {{

                                  (details.items.reduce( (acc, item) => 
                                    {  

                                      return acc += parseFloat(item.quantity)*parseFloat(item.rate); 

                                    }, 0) + ((((details.items.reduce( (acc, item) => 
                                    {  

                                      return acc += parseFloat(item.quantity)*parseFloat(item.rate); 

                                    }, 0))*(vat != null && details.includevat == 'true' ? vat.rate : 0))/100)) ) | currency('')

                                }}
                              </td>
                            </tr>
                        </table>
                      </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

    </div>

    <!-- components -->
    <Search v-model="searchdialog"/>
    <Payment v-model="paymentdialog" :invoiceid="parseInt(item.id)"/>

  </div>
</template>

<style>
	@import "@/assets/table/table";
  .modal-invoice{
    max-width:750px !important;
  }
	.action-dropdown-menu .action-dropdown-item:hover{
		background:var(--ab-primary);
		color:#fff;
		border-radius:var(--ab-dropdown-border-radius);
	}
	.action-dropdown-menu{
		padding-right: .5rem;
	}
  .invoiced .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-auto-columns: minmax(16.666667%, 16.666667%) !important;
     grid-gap: 0px;
     min-width:100%;
  }
  .invoiced .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }

</style>
<style scoped>
	@import "@/assets/table/table_scoped";
  .invoiced .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-auto-columns: minmax(16.666667%, 16.666667%) !important;
     grid-gap: 0px;
  }

  .invoiced .scroller{
    height:30vh !important;
  }
  
</style>

<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';
import { format, parseISO } from 'date-fns'

import Search from '@/components/invoices/search'
import Payment from '@/components/invoices/payment'

 export default {
  name: 'showInvoice',
  components: { Search,Payment },
  computed: {
	  mini() {
	    return this.$store.state.mini
	  },
    loader() {
    	return this.$store.state.tableloader
    },
    loader1() {
      return this.$store.state.tableloader1
    },
    allinvoices() {
      return this.$store.state.showinvoices
    },
    details() {
      return this.$store.state.invoicedetail
    },
    rows() {
      return this.allinvoices.length
    },
    vat() {
      return this.$store.state.vat
    },
    itemsForList() {
      return this.allinvoices.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage,
      );
    }
  },
  data: () => ({
      perPage: 15,
      currentPage: 1,
      daterange: {
        start: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        end: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
      },
      searchfilter: '',
      search: '',
      dialog: false,
      searchdialog: false,
      loading: false,
      paymentdialog: false,
      fields: [
        { text: 'Name', value: 'name' },
        { text: 'Date', value: 'date' },
        { text: 'From', value: 'from' },
        { text: 'To', value: 'to' },
        { text: 'Vehicle', value: 'vehicletype' },
        { text: 'Dist', value: 'distance' },
        // { text: 'Cost', value: 'cost' },
        // { text: 'Vat', value: 'vat' },
        // { text: 'Total', value: 'total' },
       ],
      item:{
         id: 0
      },
  }),

  mounted() {

  	let headertitle = `<div class="view-titleheader titlehead" style="top: 0px;">`;
      this.fields.map(function(value, key) {
            headertitle +=`<div class="theader">${value.text}</div>`;
         });
      headertitle +=`</div>`;
      $('.vue-recycle-scroller').prepend(headertitle)

  	this.item.id = this.$route.params.id
    this.getDetails()
    if(!this.allinvoices || this.allinvoices.length == 0) {
      this.getInvoices()
    }
      
  },
  beforeMount() {
    if(!ability.can('organisation-management', 'all')) {
      this.$router.push({ name: 'Dashboard' })
      return
    }
  },
  serverPrefetch () {},
  watch: {
      details: function(newVal, oldVal) {
        let data = newVal
          if(data !== null) {
            let headertitle = `<div class="view-titleheader titlehead" style="top: 0px;">`;
            this.fields.map(function(value, key) {
                  headertitle +=`<div class="theader">${value.text}</div>`;
               });
            headertitle +=`</div>`;
            $('.titlehead').remove()
            $('.vue-recycle-scroller').prepend(headertitle)
          }
      },
  },
  methods: {
    viewInvoice(id) {
        this.item.id = id
        this.$store.state.itemid = id
        this.getDetails()
      },
      async getInvoices(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getShowInvoices')
      },
      async getDetails() {
        this.$store.state.tableloader1 = true
        await axios.get(`/invoice/vat/${this.item.id}`).then(response=>{
          this.$store.state.tableloader1 = false
            if(response.data.status == parseInt(200)) {
              this.$store.state.vat = response.data.vat
              this.$store.state.invoicedetail = response.data.invoice
            }
        }).catch(error=>{
          this.$store.state.tableloader1 = false
            console.log(error)
        })
      },
      async downloadpdf() {
        this.$store.state.tableloader1 = true
        
        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' };
          let fd = new FormData();
          fd.append('id',this.item.id)

        axios.post('/invoice/download', fd, config).then(response=>{
         this.$store.state.tableloader1 = false
         var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         fileLink.setAttribute('download', `${this.details.organisations[0].organisation.name}-${this.details.invoice_no}.pdf`);
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(error => {
          this.$store.state.tableloader1 = false
          console.log("Unexpected error occured")
        })
      },

      async downloadexcel() {
        this.$store.state.tableloader1 = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'arraybuffer' };
          let fd = new FormData();
          fd.append('id',this.item.id)

        axios.post('/invoice/export-excel', fd, config).then(response=>{
          this.$store.state.tableloader1 = false
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `${this.details.organisations[0].organisation.name}-${this.details.invoice_no}.xlsx`);
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(error => {
          this.$store.state.tableloader1 = false
          console.log(error)
        })
      },
  }
};

</script>
