<template>
  <div class="about mt-0">
    <div class="mx-0 px-0" style="position:relative">
      <div class="flex" :class= "[mini ? 'minipadding' : 'expandpanding']">
      	<div class="section_title"><span class="title">Organisation Rates&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
      	<div>
      		<b-button variant="primary" class="primary" size="sm" @click="edit(0)">Add Rate <b-icon class="mr-1" icon="plus"></b-icon></b-button>
      	</div>
      </div>
      <b-row class="subsection">
      	<b-col sm="12" xl="12" md="12" class="mx-0 px-3">
      		<div class="dynamic-scroller orgrates">
            <b-row class="mx-2 mb-2 searchsection" style="display:flex;justify-content:flex-end;align-items:center">
              <div class="px-1" style="width:200px !important;padding-top:0 !important;padding-bottom:0 !important;display:flex;">
                <b-form-input
                    placeholder="Search..."
                    size="sm"
                    v-model="search"
                  ></b-form-input>
              </div>
            </b-row>
            <div class="empty-state" v-if="allrates.length === 0">
              <div class="empty-state__content">
                <div class="empty-state__icon">
                  <img src="@/assets/data.svg" style="width:40%">
                </div>
                <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                <div class="empty-state__message" v-else>No records available.</div>
              </div>
            </div>
      			<RecycleScroller
		          ref="scroller"
		          class="scroller"
		          :items="allrates"
		          :item-size="24"
		          key-field="id"
		        >
		          <template  v-slot="{ item,index }">
		            <div>
    					    <div class="tcell">{{ item.organisation.name }}</div>
                  <div class="tcell">{{ item.type.type }}</div>
                  <div class="tcell">{{ item.rate }}</div>
                  <div class="tcell">{{ item.base_rate}}</div>
    					    <div class="tcell text-center">
    					      	<a class="list-action" :id="`popover-target-1-${item.id}-${index}`">
    						  		<b-icon class="my-0 py-0" style="cursor:pointer;" icon="chevron-down"></b-icon>
    						    </a>
    						    <b-popover
      							  :target="`popover-target-1-${item.id}-${index}`"
      							  triggers="hover blur"
      							  placement="left"
      							  class="dropdown-menu"
      							>
      							  <div class="action-dropdown-menu">
      						  		<button @click="edit(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="pencil"></b-icon>&nbsp;  Edit</button>
      						  		<button @click="deleteItem(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="trash"></b-icon>&nbsp;  Remove</button>
      						  	</div>
      							</b-popover>
    					    </div>
    					  </div>
		          </template>
		        </RecycleScroller>

			  </div>
      	</b-col>
      </b-row>
    </div>


    <b-modal
      v-model="updatedialog"
      size="orgrates"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left" v-if="item.updateID !== ''">Update Organisation Rate</span>
          <span class="title float-left" v-else>Add Organisation Rate</span>
        </div>
      </template>
      <b-container fluid>
        <b-row>
          <b-col
            sm="12" md="12"
            class="float_label mb-3"
           >
            
            <multiselect ref="organisationmultiselect" 
                    v-model="item.updateorganisation_id"
                     track-by="id" label="name" placeholder="Organisation" :options="organisations" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Organisation*</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12" md="12"
            class="float_label mb-3"
           >
            
            <multiselect ref="vehicletypemultiselect" 
                    v-model="item.updatevehicle_type_id"
                     track-by="id" label="type" placeholder="Vehicle" :options="vehicletypes" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.type }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Vehicle*</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6" class="float_label mb-3">
              <b-form-input
                placeholder="Amout"
                size="sm"
                v-model="item.updaterate"
              ></b-form-input>
            <label class="typo__label float_label_item">Amt*</label>
          </b-col>
          <b-col sm="12" md="6" class="float_label mb-3">
            
              <b-form-input
                placeholder="Base amount"
                size="sm"
                v-model="item.updatebaserate"
              ></b-form-input>
            <label class="typo__label float_label_item">Base*</label>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="update" v-if="item.updateID !== ''">
            <b-spinner small v-if="loading"></b-spinner> Update
          </b-button>
          <b-button variant="primary" class="primary" @click="create" v-else>
            <b-spinner small v-if="loading"></b-spinner> Save
          </b-button>
      </template>
    </b-modal>

  </div>
</template>

<style>
	@import "@/assets/table/table";
  .modal-orgrates{
    max-width:540px !important;
  }
	.action-dropdown-menu .action-dropdown-item:hover{
		background:var(--ab-primary);
		color:#fff;
		border-radius:var(--ab-dropdown-border-radius);
	}
	.action-dropdown-menu{
		padding-right: .5rem;
	}
  .orgrates .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-template-columns: 40% 20% 15% 15% 10%;
     grid-gap: 0px;
     min-width:100%;
  }
  .orgrates .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }
</style>
<style scoped>
	@import "@/assets/table/table_scoped";
  .orgrates .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-template-columns: 40% 20% 15% 15% 10%;
     grid-gap: 0px;
  }
  
</style>

<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';

 export default {
  name: 'organisationRates',
  components: {
  },
  computed: {
	  mini() {
	    return this.$store.state.mini
	  },
    loader() {
    	return this.$store.state.tableloader
    },
    rates() {
        return this.$store.state.rates.filter(item => {
          this.start = 0
          return item.organisation.name.toLowerCase().includes(this.search.toLowerCase())
        })
      },
      allrates() {
        return this.rates
      },
      organisations() {
        return this.$store.state.organisations
      },
      vehicletypes() {
        return this.$store.state.vehicletypes
      }
  },
  data: () => ({
      search: '',
      dialog: false,
      updatedialog: false,
      loading: false,
      fields: [
        { text: 'Organisation', value: 'organisation' },
        { text: 'Vehicle Type', value: 'type' },
        { text: 'Rate(per km)', value: 'rate' },
        { text: 'Base Rate', value: 'baserate' },
        { text: 'Action', value: 'action' },
       ],
      item:{
          updateorganisation_id:"",
          updatevehicle_type_id:"",
          updaterate:"",
          updatebaserate:"",
          updateID: "",
          _method:"patch"
      },
  }),
  beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
  mounted() {

  	let headertitle = `<div class="view-titleheader titlehead" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="theader">${value.text}</div>`;
         });
      headertitle +=`</div>`;
      $('.vue-recycle-scroller').prepend(headertitle)

  	if(this.rates.length == 0) {
        this.getRates()
      }
  },
  serverPrefetch () {
    // this.getRates()
  },
  methods: {
  	getRates(){
      this.$store.state.tableloader = true
      axios.get('/orgrate').then(response=>{
          this.$store.state.tableloader = false
          this.$store.state.vehicletypes = response.data.vehicle_types
          this.$store.state.organisations = response.data.organisation
          this.$store.state.rates = response.data.rates
      }).catch(error=>{
        this.$store.state.tableloader = false
          console.log(error)
      })
    },
    create(){

        this.loading = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('organisation_id',JSON.stringify(this.item.updateorganisation_id))
          fd.append('vehicle_type_id',JSON.stringify(this.item.updatevehicle_type_id))
          fd.append('rate',this.item.updaterate)
          fd.append('baserate',this.item.updatebaserate)

          axios.post('/orgrate', fd, config).then(response=>{
            this.loading = false
            if(response.data.status === 200) {

              let found = this.$store.state.rates.find(data => data.id == response.data.rate.id);
              if (!found) {
                this.$store.state.rates.unshift(response.data.rate)
              }

              this.$toastpopup('success','Success','Rate added successfully')
              this.clear()

              this.updatedialog = false

            }
       
          }).catch(error=>{
            this.loading = false
              console.log(error)
          })

      },
      clear() {
        this.item.updatevehicle_type_id = null
        this.item.updateorganisation_id = null
        this.item.updaterate = ''
        this.item.updatebaserate = ''
      },
      update(){

        this.loading = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('organisation_id',JSON.stringify(this.item.updateorganisation_id))
          fd.append('vehicle_type_id',JSON.stringify(this.item.updatevehicle_type_id))
          fd.append('rate',this.item.updaterate)
          fd.append('baserate',this.item.updatebaserate)
          fd.append('_method',this.item._method)

          axios.post(`/orgrate/${this.item.updateID}`, fd, config).then(response=>{
            this.loading = false
            if(response.data.status === 200) {

              let index = this.$store.state.rates.findIndex(data => data.id == response.data.rate.id);
                if (index > -1) {
                  this.$store.state.rates.splice(index, 1, response.data.rate);
                }

              this.$toastpopup('success','Success','Rate updated successfully')
              this.clear()

              this.updatedialog = false

            }
          }).catch(error=>{
            this.loading = false
              console.log(error)
          })

      },
    edit(id) {
      let index = this.rates.filter(item => item.id === id )
      if(index.length > 0) {

        this.item.updatevehicle_type_id = index[0].type
        this.item.updateorganisation_id = index[0].organisation
        this.item.updaterate = index[0].rate
        this.item.updatebaserate = index[0].base_rate
        this.item.updateID = index[0].id
        this.updatedialog = true

      }else {
        this.item.updateID = ''
        this.clear()
        this.updatedialog = true
      }
      
    },
    deleteItem(id){
          if(confirm("Are you sure to delete rate ?")){
              axios.delete(`/orgrate/${id}`).then(response=>{
                if(response.data.status === 200) {

                  let index = this.$store.state.rates.findIndex(data => data.id == response.data.rate.id);
                  if (index > -1) {
                    this.$store.state.rates.splice(index, 1);
                  }

                  this.$toastpopup('success','Success','Rate updated successfully')
                }
              }).catch(error=>{
                  console.log(error)
              })
          }
      }
  }
};

</script>
