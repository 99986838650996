import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '@/views/HomeView.vue'

Vue.use(VueRouter)

import HomeView from "@/views/HomeView.vue"

// booking routes
import bookingList from "@/views/bookings/AllBookingView.vue"
import addBookingList from "@/views/bookings/AddBookingView.vue"
import todayBookingList from "@/views/bookings/TodayBookingView.vue"
import returnBooking from "@/views/bookings/ReturnBookingView.vue"
import bookingEdit from "@/views/bookings/EditBookingView.vue"
// organisation routes
import orgTypeList from "@/views/organisations/OrganisationTypeList.vue"
import orgCategoryList from "@/views/organisations/OrganisationCategoryList.vue"
import orgProjectList from "@/views/organisations/OrganisationProjectList.vue"
import orgList from "@/views/organisations/OrganisationList.vue"
import organisationRates from "@/views/organisations/OrganisationRateList.vue"
// vehicle routes
import vehicleMakeList from "@/views/vehicles/VehicleMake.vue"
import vehicleTypeList from "@/views/vehicles/VehicleType.vue"
import vehicleOwnershipList from "@/views/vehicles/VehicleOwnership.vue"
import vehicleList from "@/views/vehicles/VehicleList.vue"
import vehicleModelList from "@/views/vehicles/VehicleModel.vue"
// drivers routes
import driverList from "@/views/drivers/DriverList.vue"
import driverProfile from "@/views/drivers/DriverProfile.vue"
// userconfig routes
import customerList from "@/views/userconfig/CustomerList.vue"
import usersList from "@/views/userconfig/UserList.vue"
import roleList from "@/views/userconfig/RoleList.vue"
import permissionList from "@/views/userconfig/PermissionList.vue"
// expressway routes
import EntranceList from "@/views/expressway/EntranceList.vue"
import ExitList from "@/views/expressway/ExitList.vue"
import RateList from "@/views/expressway/RateList.vue"
// finance routes
import customerStatement from "@/views/finance/statement/StatementList.vue"
import invoiceList from "@/views/finance/invoice/InvoiceList.vue"
import invoiceDetail from "@/views/finance/invoice/InvoiceDetail.vue"
import newInvoice from "@/views/finance/invoice/NewInvoice.vue"
import showInvoice from "@/views/finance/invoice/ShowInvoice.vue"
import editInvoice from "@/views/finance/invoice/EditInvoice.vue"
import paymentList from "@/views/finance/payment/PaymentList.vue"
import newPayment from "@/views/finance/payment/NewPayment.vue"
import showPayment from "@/views/finance/payment/ShowPayment.vue"
import editPayment from "@/views/finance/payment/EditPayment.vue"
import creditnotesList from "@/views/finance/creditnote/CreditNoteList.vue"
import newCreditNote from "@/views/finance/creditnote/NewCreditNote.vue"
import showCreditNote from "@/views/finance/creditnote/ShowCreditNote.vue"
import editCreditNote from "@/views/finance/creditnote/EditCreditNote.vue"
import debitnotesList from "@/views/finance/debitnote/DebitNoteList.vue"
import newDebitNote from "@/views/finance/debitnote/NewDebitNote.vue"
import showDebitNote from "@/views/finance/debitnote/ShowDebitNote.vue"
import editDebitNote from "@/views/finance/debitnote/EditDebitNote.vue"
import paymentReversalsList from "@/views/finance/reversal/ReversalList.vue"
import newPaymentReversal from "@/views/finance/reversal/NewReversal.vue"
import editPaymentReversal from "@/views/finance/reversal/EditReversal.vue"
// reports
import Reports from "@/views/reports/FullReport.vue"
import InvoiceReport from '@/views/reports/InvoiceReport.vue'
// settings
import Settings from "@/views/Settings.vue"
import Logs from "@/views/Logs.vue"

import Login from "@/views/Login.vue"
import Signup from "@/views/Signup.vue"
import ForgotPassword from "@/views/forgotpassword.vue"
import ResetPasswordForm from "@/views/resetpassword.vue"

 const routes =  [
    {
      path: '/',
      name: 'HomeView',
      component: HomeView,
      meta: {
        auth: true
      }
    },
    {
      path: '/today-booking/list',
      name: 'TodayBookingView',
      component: todayBookingList,
      meta: {
        auth: true
      }
    },
    {
      path: '/all-bookings',
      name: 'AllBookingView',
      component: bookingList,
      meta: {
        auth: true
      }
    },
    {
      path: '/add-booking',
      name: 'AddBookingView',
      component: addBookingList,
      meta: {
        auth: true
      }
    },
    {
      path: '/new/return/booking',
      name: 'returnBooking',
      component: returnBooking,
      meta: {
        auth: true
      }
    },
    {
      name: 'bookingEdit',
      path: '/booking/:id/edit',
      component: bookingEdit,
      meta: {
        auth: true
      }
    },
    { 
      path: '/forgot/password', 
      name: 'ForgotPassword', 
      component: ForgotPassword, 
      meta: {
        auth: undefined
      }
    },
    { 
      path: '/reset/:token', 
      name: 'ResetPasswordForm', 
      component: ResetPasswordForm, 
      meta: {
        auth: undefined
      } 
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        auth: undefined
      }
    },
    {
      path: '/auth/register',
      name: 'Signup',
      component: Signup,
      meta: {
        auth: undefined
      }
    },
    // organisations
    {
        name: 'orgTypeList',
        path: '/organisation/type/list',
        component: orgTypeList,
        meta: {
          auth: true
        }
    },
    {
        name: 'orgCategoryList',
        path: '/organisation/category/list',
        component: orgCategoryList,
        meta: {
          auth: true
        }
    },

    {
        name: 'orgProjectList',
        path: '/organisation/projects/list',
        component: orgProjectList,
        meta: {
          auth: true
        }
    },
    {
        name: 'orgList',
        path: '/organisation/list',
        component:orgList,
        meta: {
          auth: true
        }
    },

    {
      path: '/organisation/rates',
      name: 'organisationRates',
      component:organisationRates,
      meta: {
        auth: true
      }
    },
    // vehicle routes
    {
      name: 'vehicleMakeList',
      path: '/vehicle/make/list',
      component:vehicleMakeList,
      meta: {
        auth: true
      }

  },
  {
      name: 'vehicleTypeList',
      path: '/vehicle/type/list',
      component: vehicleTypeList,
      meta: {
        auth: true
      }

  },
  {
      name: 'vehicleOwnershipList',
      path: '/vehicle/ownership/list',
      component:vehicleOwnershipList,
      meta: {
        auth: true
      }

  },
  {
      name: 'vehicleList',
      path: '/vehicle/list',
      component: vehicleList,
      meta: {
        auth: true
      },
  },
  {
      name: 'vehicleModelList',
      path: '/vehicle/model/list',
      component:vehicleModelList,
      meta: {
        auth: true
      }
  },
  // drivers
  {
      name: 'driverList',
      path: '/drivers/list',
      component:driverList,
      meta: {
        auth: true
      }
  },
  {
      name: 'driverProfile',
      path: '/driver/:id/profile',
      component:driverProfile,
      meta: {
        auth: true
      }
  },
  // userconfig
  {
      name: 'usersList',
      path: '/users/list',
      component:usersList,
      meta: {
        auth: true
      }
  },
  {
      name: 'roleList',
      path: '/role/list',
      component: roleList,
      meta: {
        auth: true
      }
  },
  {
      name: 'permissionList',
      path: '/permission/list',
      component:permissionList,
      meta: {
        auth: true
      }
  },
  {
      name: 'customerList',
      path: '/customers/list',
      component:customerList,
      meta: {
        auth: true
      }
  },
  // expressway
  { 
    path: '/expressway/entrances', 
    name: 'EntranceList', 
    component: EntranceList, 
    meta: {
      auth: true
    }
  },
  { 
    path: '/expressway/exits', 
    name: 'ExitList', 
    component: ExitList, 
    meta: {
      auth: true
    }
  },
  { 
    path: '/expressway/rates', 
    name: 'RateList', 
    component: RateList, 
    meta: {
      auth: true
    }
  },
  // invoices
  {
    path: '/organisation/invoices',
    name: 'invoiceList',
    component: invoiceList,
    meta: {
      auth: true
    }
  }, 
  {
    path: '/organisation/invoice-details',
    name: 'invoiceDetail',
    component:invoiceDetail,
    meta: {
      auth: true
    }
  }, 
  {
    path: '/organisation/new/invoice',
    name: 'newInvoice',
    component:newInvoice,
    meta: {
      auth: true
    }
  }, 
   {
      name: 'showInvoice',
      path: '/organisation/invoice/:id/details',
      component: showInvoice,
      meta: {
        auth: true
      }
  },
  {
      name: 'editInvoice',
      path: '/organisation/invoice/:id/edit',
      component: editInvoice,
      meta: {
        auth: true
      }
  },
  // payment
  {
    path: '/organisation/payments',
    name: 'paymentList',
    component:paymentList,
    meta: {
      auth: true
    }
  }, 
  {
    path: '/organisation/new/payment',
    name: 'newPayment',
    component: newPayment,
    meta: {
      auth: true
    }
  }, 

   {
      name: 'showPayment',
      path: '/organisation/payment/:id/details',
      component: showPayment,
      meta: {
        auth: true
      }
  },
  {
      name: 'editPayment',
      path: '/organisation/payment/:id/edit',
      component:editPayment,
      meta: {
        auth: true
      }
  },
  // end payments
  // reversal payments
  {
    path: '/organisation/payment-reversals',
    name: 'paymentReversalsList',
    component: paymentReversalsList,
    meta: {
      auth: true
    }
  }, 
  {
    path: '/organisation/new/reversal',
    name: 'newPaymentReversal',
    component:newPaymentReversal,
    meta: {
      auth: true
    }
  },
  {
      name: 'editPaymentReversal',
      path: '/organisation/payment-reversal/:id/edit',
      component: editPaymentReversal,
      meta: {
        auth: true
      }
  },
  // end reversal payments
  // credit notes
  {
    path: '/organisation/credit-notes',
    name: 'creditnotesList',
    component:creditnotesList,
    meta: {
      auth: true
    }
  }, 
  {
    path: '/organisation/new/credit-note',
    name: 'newCreditNote',
    component: newCreditNote,
    meta: {
      auth: true
    }
  }, 
   {
      name: 'showCreditNote',
      path: '/organisation/credit-note/:id/details',
      component: showCreditNote,
      meta: {
        auth: true
      }
  },
  {
      name: 'editCreditNote',
      path: '/organisation/credit-note/:id/edit',
      component: editCreditNote,
      meta: {
        auth: true
      }
  },
  // end credit notes
  // debit notes
  {
    path: '/organisation/debit-notes',
    name: 'debitnotesList',
    component:debitnotesList,
    meta: {
      auth: true
    }
  }, 
  {
    path: '/organisation/new/debit-note',
    name: 'newDebitNote',
    component:newDebitNote,
    meta: {
      auth: true
    }
  }, 
   {
      name: 'showDebitNote',
      path: '/organisation/debit-note/:id/details',
      component:showDebitNote,
      meta: {
        auth: true
      }
  },
  {
      name: 'editDebitNote',
      path: '/organisation/debit-note/:id/edit',
      component: editDebitNote,
      meta: {
        auth: true
      }
  },
  // end credit notes
  // statements
  {
    path: '/organisation/statements',
    name: 'customerStatement',
    component:customerStatement,
    meta: {
      auth: true
    }
  },
  // reports
  {
    path: '/booking/reports',
    name: 'Reports',
    component: Reports,
    meta: {
      auth: true
    }
  },
  {
    path: '/booking/invoice',
    name: 'InvoiceReport',
    component: InvoiceReport,
    meta: {
      auth: true
    }
  },
  // settings
  { 
    path: '/settings', 
    name: 'Settings', 
    component: Settings, 
    meta: {
      auth: true
    }
  },
  // logs
  { 
    path: '/logs', 
    name: 'Logs', 
    component: Logs, 
    meta: {
      auth: true
    }
  },
  ]

export default routes
