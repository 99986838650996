<template>
  <div class="about mt-0">
    <div class="mx-0 px-0">
      <div class="flex" :class= "[mini ? 'minipadding' : 'expandpanding']">
      	<div class="section_title"><span class="title">Add Bookings&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
      	<div>
      		<b-button variant="main" size="sm"><router-link link :to="{name: 'returnBooking'}">Return Booking <b-icon class="mr-1" icon="plus"></b-icon></router-link></b-button>
      	</div>
      </div>
      <b-container fluid class="subsection bookings">
        <b-row class="left-column tflex tflex-row">
          <b-col sm="11" xl="7" xxl="5" md="10" lg="9">
            <div class="tflex tflex-row tflex-space-between formoptions">
              <b-form-checkbox name="check-button" switch v-model="isDelivery">
                Delivery
              </b-form-checkbox>
              <b-form-checkbox name="check-button" switch v-model="bulkchecked">
                Bulk Booking
              </b-form-checkbox>
              <b-form-checkbox name="check-button" switch v-model="skipdays">
                Skip Days
              </b-form-checkbox>
              <b-form-checkbox name="check-button" switch @change="onSwitchchange" v-model="useSubProjects">
                Sub Projects
              </b-form-checkbox>
            </div>
        		<b-form class="mb-4 submitform">
              <b-row class="mb-4">
                <b-col sm="12" md="6" class="float_label">
                  
                    <b-form-datepicker
                      id="datepicker-pickup"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      :hide-header="true"
                      locale="en"
                      placeholder="Pickup date"
                      size="sm"
                      :min="min"
                      v-model="$v.item.date.$model"
                      :state="validateState('date')"
                    ></b-form-datepicker>
                    <label class="typo__label float_label_item">Pickup Date*</label>
                </b-col>
                <b-col sm="12" md="6" class="float_label">
        		      <!-- <b-form-group> -->
        		        <b-form-input
        		          required
                      size="sm"
                      type="tel" 
                      v-mask="'##:##'"
                      placeholder="Pickup time"
                      list="data"
                      v-model="$v.item.time.$model"
                      :state="validateState('time')"
        		        ></b-form-input>
                    <label class="typo__label float_label_item">Pickup Time*</label>
                    <datalist id="data">
                        <option v-for="time in $store.state.times" :value="time" />
                    </datalist>
        		      <!-- </b-form-group> -->
                  
                </b-col>
              </b-row>
              
              <b-row class="mb-4" v-if="skipdays">
                <b-col sm="12" md="12">
                  <b-row>
                    <b-col
                      cols="12"
                      sm="4"
                      md="4"
                      class="my-2"
                      >
                      <b-form-checkbox name="check-button" value="Weekends" switch v-model="item.skipweekends">
                        Skip weekends
                      </b-form-checkbox>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="4"
                      md="4"
                      class="my-2"
                      >
                      <b-form-checkbox name="check-button" value="Monday" switch v-model="item.skipmondays">
                        Skip monday
                      </b-form-checkbox>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="4"
                      md="4"
                      class="my-2"
                      >
                      <b-form-checkbox name="check-button" value="Tuesday" switch v-model="item.skiptuesdays">
                        Skip tuesday
                      </b-form-checkbox>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="4"
                      md="4"
                      class="my-2"
                      >
                      <b-form-checkbox name="check-button" value="Wednesday" switch v-model="item.skipwednesdays">
                        Skip wednesday
                      </b-form-checkbox>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="4"
                      md="4"
                      class="my-2"
                      >
                      <b-form-checkbox name="check-button" value="Thursday" switch v-model="item.skipthursdays">
                        Skip thursday
                      </b-form-checkbox>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="4"
                      md="4"
                      class="my-2"
                      >
                      <b-form-checkbox name="check-button" value="Friday" switch v-model="item.skipfridays">
                        Skip friday
                      </b-form-checkbox>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="4"
                      md="4"
                      class="my-2"
                      >
                      <b-form-checkbox name="check-button" value="Saturday" switch v-model="item.skipsaturdays">
                        Skip saturday
                      </b-form-checkbox>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="4"
                      md="4"
                      class="my-2"
                      >
                      <b-form-checkbox name="check-button" value="Sunday" switch v-model="item.skipsundays">
                        Skip sunday
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mb-4" v-if="bulkchecked">
                  <b-col
                    cols="12"
                    sm="6"
                    md="6"
                    class="float_label"
                  >
                    
                    <multiselect ref="frequencymultiselect" 
                            v-model="item.frequency" placeholder="Select frequency" :options="['Daily', 'Weekly']" :searchable="true" :allow-empty="false" :show-labels="false" :preselectFirst="true">
                      <span slot="noResult">Oops! Record not found.</span>
                      <template slot="singleLabel" slot-scope="{ option }">{{ option }}</template>
                    </multiselect>
                    <label class="typo__label float_label_item">Frequency</label>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="6"
                    md="6"
                    class="float_label"
                    >
                    
                    <b-form-datepicker
                      id="datepicker-end"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      :hide-header="true"
                      locale="en"
                      size="sm"
                      placeholder="Select date"
                      :min="min"
                      v-model="enddate"
                    ></b-form-datepicker>
                    <label class="typo__label float_label_item">End Date</label>
                  </b-col>
              </b-row>
              <b-row class="mb-4" v-for="(passenger, indx) in passengers" :key="indx">
                  <b-col sm="12" md="4">
                    <div v-if="passengers[indx]['isTextField'] === false" class="float_label">
                      
                      <multiselect ref="passengermultiselect"
                              label="name"
                              v-model="passenger.passenger" 
                              :name="`passengers[${indx}][id]`"
                               track-by="id" placeholder="Select passenger" :options="customers" :searchable="true" :allow-empty="false" :show-labels="false" @input="(event) => updateCustomer(event.id,indx)">
                        <div class="showevent" @click="setText(indx)" slot="beforeList"><b-icon class="mr-1" color="#F9780D" icon="plus-circle-fill"></b-icon> New Customer</div>
                        <span slot="noResult">Oops! Record not found.</span>
                        <!-- <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template> -->
                      </multiselect>
                      <label class="typo__label float_label_item">{{ Plabel }}</label>
                    </div>
                    <div class="float_label" v-if="passengers[indx]['isTextField'] === true">
                      
                      <!-- <b-form-group
                        > -->
                        <b-icon @click="setText(indx)" class="resetarrow" icon="arrow-counterclockwise"></b-icon>
                        <b-form-input
                          type="text"
                          placeholder="Passenger"
                          required
                          size="sm"
                          append-icon="mdi-selection-ellipse-arrow-inside"
                          :label="Plabel"
                          v-model="passenger.name" 
                          :name="`passengers[${indx}][name]`"
                        ></b-form-input>
                      <!-- </b-form-group> -->
                      <label class="typo__label float_label_item">{{ Plabel }}</label>
                    </div>
                  </b-col>
                  <b-col sm="12" md="3" class="float_label">
                    
                   <!--  <b-form-group
                      > -->
                      <b-form-input
                        type="text"
                        placeholder="Telephone"
                        v-model="passenger.telephone" 
                        :name="`passengers[${indx}][telephone]`"
                        required
                        size="sm"
                      ></b-form-input>
                      <label class="typo__label float_label_item">Telephone*</label>
                    <!-- </b-form-group> -->
                  </b-col>
                  <b-col sm="12" md="4" class="float_label">
                    
                    <multiselect ref="organisationmultiselect" 
                            v-model="passenger.organisation"
                            :name="`passengers[${indx}][organisation_id]`"
                            @input="(event) => updateOrganisation(event.id,indx)"
                             track-by="id" label="name" placeholder="Select organisation" :options="organisations" :searchable="true" :allow-empty="false" :show-labels="false">
                      <span slot="noResult">Oops! Record not found.</span>
                      <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                    </multiselect>
                    <label class="typo__label float_label_item">Organisation*</label>
                  </b-col>
                  <b-col sm="12" md="1" class="addremoveicons mt-1">
                    <b-icon class="mx-2" @click="addPassenger" color="#F9780D" icon="plus-circle"></b-icon>
                    <b-icon color="#DC3545" @click="removePassenger(indx)" icon="x-circle"></b-icon>
                  </b-col>
              </b-row>
              <b-row class="mb-0" v-if="!useSubProjects && !isDelivery">
                <b-col
                  sm="12" md="6" class="float_label mb-4"
                 >
                  
                  <multiselect ref="projectmultiselect" 
                          v-model="item.project_id"
                          :multiple="true"
                          :preserve-search="true"
                          :close-on-select="false"
                           track-by="id" label="name" placeholder="Select project" :options="projects" :searchable="true" :allow-empty="true" :show-labels="false">
                    <span slot="noResult">Oops! Record not found.</span>
                    <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                  </multiselect>
                  <label class="typo__label float_label_item">Project</label>
                </b-col>          
              </b-row>
              <b-row v-if="useSubProjects && !isDelivery">
                <b-col
                    sm="12"
                    md="6"
                    v-for="(subproject,indx) in subprojects"
                    :key="indx"
                    class="float_label mb-4"
                  >
                    
                      <multiselect
                              v-model="subproject.subproject"
                              :name="`subprojects[${indx}][subproject_id]`"
                              @input="(event) => updateSubprojects(event, indx)"
                               track-by="id" label="name" placeholder="Select sub project" :options="subproject.subprojects" :searchable="true" :allow-empty="true" :show-labels="false">
                        <span slot="noResult">Oops! Record not found.</span>
                        <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                      </multiselect>
                      <label class="typo__label float_label_item">{{ capitalize(subproject.project_name.toLowerCase()) }}</label>
                  </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col
                  sm="12"
                  md="6"
                  class="autosearchlocation float_label"
                  >
                  <label class="no_floating">Pick-up*</label>
                  <GmapAutocomplete @place_changed="onPlaceChangedFrom" :value="item.from"></GmapAutocomplete>
                  
                </b-col>
                <b-col
                  sm="12"
                  md="5"
                  class="autosearchlocation float_label"
                  >
                  <label class="no_floating ">Drop-off*</label>
                  <GmapAutocomplete @place_changed="onPlaceChangedTo" :value="item.to"></GmapAutocomplete>
                  
                </b-col>
                <b-col
                  sm="1"
                  md="1"
                  class="mt-0"
                  style="justify-content: center !important; display: flex !important;align-items:center !important"
                  >
                  <b-icon class="mx-2" @click="shufflestops" style="cursor:pointer" color="#F9780D" icon="plus-circle"></b-icon>
                </b-col>
              </b-row>
              <b-row v-show="hidestops" class="mb-4">
                <b-col
                  sm="6"
                  md="6"
                  class="autosearchlocation float_label"
                  >
                  <label class="no_floating">Add Stop</label>
                  <GmapAutocomplete @place_changed="onPlaceChangedStop1" :value="item.stop1"></GmapAutocomplete>
                  
                </b-col>
                <b-col
                  sm="5"
                  md="5"
                  class="autosearchlocation float_label"
                  >
                  <label class="no_floating">Add Stop</label>
                  <GmapAutocomplete @place_changed="onPlaceChangedStop2" :value="item.stop2"></GmapAutocomplete>
                  
                </b-col>
                <b-col
                  sm="1"
                  md="1"
                  class="mt-0"
                  style="justify-content: center !important; display: flex !important;align-items:center !important"
                  >
                  <b-icon class="mx-2" color="#DC3545" style="cursor:pointer" @click="shufflestops" icon="x-circle"></b-icon>
                </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col sm="12" md="6" class="float_label">
                  <label class="typo__label standard_floating">Trip</label>
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                      id="trip_type"
                      v-model="item.trip_type"
                      :aria-describedby="ariaDescribedby"
                    >
                      <b-form-radio value="1">One Way</b-form-radio>
                      <b-form-radio value="2">Wait and Return</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="6" class="float_label">
                  <label class="typo__label standard_floating">Request</label>
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                      id="request_type"
                      v-model="item.request_type"
                      :aria-describedby="ariaDescribedby"
                    >
                      <b-form-radio value="Official">Official</b-form-radio>
                      <b-form-radio value="Private">Private</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  sm="12" md="6"
                  class="float_label mb-4"
                 >
                  
                  <multiselect ref="vehicletypemultiselect" 
                          v-model="item.vehicle_type"
                           track-by="id" label="type" placeholder="Select vehicle type" :options="vehicletype" :searchable="true" :allow-empty="true" :show-labels="false">
                    <span slot="noResult">Oops! Record not found.</span>
                    <template slot="singleLabel" slot-scope="{ option }">{{ option.type }}</template>
                  </multiselect>
                  <label class="typo__label float_label_item">Vehicle*</label>
                </b-col> 
                <b-col sm="12" md="6" class="float_label mb-4">
                  
                 <!--  <b-form-group
                    > -->
                    <b-form-input
                      type="text"
                      placeholder="Orderedby"
                      v-model="item.orderedby"
                      size="sm"
                    ></b-form-input>
                  <!-- </b-form-group> -->
                  <label class="typo__label float_label_item">Orderedby</label>
                </b-col> 
                <b-col sm="12" md="6" class="float_label mb-4">
                  
                  <!-- <b-form-group
                    > -->
                    <b-form-input
                      type="text"
                      placeholder="Flight details"
                      v-model="item.flight"
                      size="sm"
                    ></b-form-input>
                  <!-- </b-form-group> -->
                  <label class="typo__label float_label_item">Flight</label>
                </b-col> 
                <b-col sm="12" md="6" class="float_label mb-4">
                  
                 <!--  <b-form-group
                    > -->
                    <b-form-input
                      id="remarks"
                      type="text"
                      placeholder="Remarks"
                      v-model="item.remarks"
                      size="sm"
                    ></b-form-input>
                  <!-- </b-form-group> -->
                  <label class="typo__label float_label_item">Remarks</label>
                </b-col> 
              </b-row>
              <b-row>
                <b-col cols="12" style="display:flex;justify-content:flex-end">
                  <b-button variant="primary" @click="proceed" class="primary" :disabled="$v.item.$invalid">
                    Proceed
                  </b-button>
                </b-col>
              </b-row>
    		    </b-form>
        	</b-col>
        </b-row>
      </b-container>
    </div>

    <!-- summary dialog -->
    <b-modal
      v-model="confirmdialog"
      centered 
      size="sm"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Request Summary</span>
        </div>
      </template>
        <table class="table">
          <tr>
            <td style="white-space:nowrap;"><b>Date:</b></td>
            <td>{{ item.date }}</td>
          </tr>
          <tr>
            <td style="white-space:nowrap;"><b>Time:</b></td>
            <td>{{ item.time }}</td>
          </tr>
          <tr>
            <td style="white-space:nowrap;"><b>From:</b></td>
            <td>{{ item.from }}</td>
          </tr>
          <tr v-if="item.stop1 !== ''">
            <td style="white-space:nowrap;"><b>Stop:</b></td>
            <td>{{ item.stop1 }}</td>
          </tr>
          <tr v-if="item.stop2 !== ''">
            <td style="white-space:nowrap;"><b>Stop:</b></td>
            <td>{{ item.stop2 }}</td>
          </tr>
          <tr>
            <td style="white-space:nowrap;"><b>To:</b></td>
            <td>{{ item.to }}</td>
          </tr>
          <tr>
            <td style="white-space:nowrap;"><b>Distance:</b></td>
            <td>{{ (parseFloat(item.trip_type)*((parseFloat(stop1)+parseFloat(stop2)+parseFloat(end)))) | currency('',2) }}Km</td>
          </tr>
          <tr>
              <td style="white-space:nowrap;"><b>Time:</b></td>
              <td>{{ (parseFloat(item.trip_type)*((parseFloat(stop1Time)+parseFloat(stop2Time)+parseFloat(endTime)))) | currency('',2) }}Min</td>
            </tr>
        </table>
        <template #modal-footer>
            <b-button variant="primary" @click="create" class="primary">
              <b-spinner small v-if="loading"></b-spinner> Submit
            </b-button>
        </template>
    </b-modal>

  </div>
</template>

<style>
.autocomplete-input{
  background-image: none !important;
  padding:5px !important;
  height:31px !important;
  width:100% !important;
  font-size:13px;
}
</style>
<style scoped>
</style>

<script>
import Pusher from 'pusher-js'
import $ from 'jquery';
import axios from 'axios'

import {gmapApi} from 'vue2-google-maps'
import {loaded} from 'vue2-google-maps'
import {ability} from '@/services/ability'
import VueTimepicker from 'vue2-timepicker'
// import MapSection from '@/components/bookings/Map'
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

 export default {
  mixins: [validationMixin],
  name: 'AddBookingView',
  components: { VueTimepicker },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(today)

    return {
      value: null,
      isShow:true,
  	  useSubProjects:false,
  	  sub_projects: [],
  	  isDelivery:false,
  	  bulkchecked:false,
  	  skipdays:false,
  	  ratechecked:false,
  	  country: 'ke',
  	  apiKey: 'AIzaSyBOq3xAvXV2SsaJPxNk3iz4XNIKPdz4gRc',
  	  // apiKey: 'AIzaSyC7ys5xYYXQtN7jV0Oz4m8cu1jct9T1jc8',
  	  searchResults: [],
  	  service: null,
  	  map: null,
  	  google:gmapApi,
  	  center: {lat:0, lng:0},
  	  fieldRules: [
  	  (v) => !!v || 'Field is required'
  	  ],
  	  search:null,
  	  customer:null,
  	  valid: true,
  	  menu3:false,
  	  searchvehicle:null,
  	  searchproject:null,
  	  searchfrequency:null,
  	  dialog: false,
  	  updatedialog: false,
  	  progressdialog: false,
  	  breadcrumbs: [
  	    {
  	      text: 'Dashboard',
  	      disabled: false,
  	      href: '/',
  	    },
  	    {
  	      text: 'Today Bookings',
  	      disabled: false,
  	      href: '/today-booking/list',
  	    },
  	    {
  	      text: 'Add Booking',
  	      disabled: false,
  	      href: '',
  	    },
  	  ],
      min: minDate,
  	  menu: false,
  	  menu2: false,
  	  enddate: "",
  	  isTextField: false,
  	  selectcustomer: null, 
      subprojects: [],
  	  item:{
          time: "",
          date: "", 
  	      distance: "",
  	      to: "",
  	      from: "",
  	      fromlat: "",
  	      fromlong: "",
  	      tolat: "",
  	      tolong: "",

  	      stop1: "",
  	      stop1lat: null,
  	      stop1long: null,
  	      stop2: "",
  	      stop2lat: null,
  	      stop2long: null,

  	      trip_type: "1",
  	      request_type: "Official",
  	      project_id: [],
          subproject_id: [],
          project: null,
  	      name: "",
  	      customer_id:null,
  	      telephone:"",
  	      orderedby:"",
  	      pickupdate:"",
  	      pickuptime:"",
  	      organisation_id:null,
  	      vehicle_id:"",
  	      vehicle_type:null,
          vehicle_type_id:2,
  	      flight:"",
  	      frequency:"",
  	      enddate:"",
  	      remarks:"",
  	      one_trip_rate:"",
  	      round_trip_rate:"",
  	      skipweekends: null,
  	      skipmondays: null,
  	      skiptuesdays: null,
  	      skipwednesdays: null,
  	      skipthursdays: null,
  	      skipfridays: null,
  	      skipsaturdays: null,
  	      skipsundays: null,
  	    },
  	    loading: false,
  	   disabled: false,
  	   orgCategory: [],
  	   isBusy: false,
  	   updateModal: null,
  	   confirmdialog: null,
  	   chooseVehicle:[],
  	   chooseOtherVehicle:[],
  	   choosevehicledialog: false,
  	   driverdetailsdialog: false,
  	   booking_id: "",
  	   driverdetails: null,
  	   coords: {lat: 0, lng: 0},
  	    destination: {lat: 0, lng: 0},
  	    labelstart: {
  	      text: "N/A",
  	      color: "#C70E20",
  	      fontWeight: "bold"
  	    },
  	    labelend: {
  	      text: "N/A",
  	      color: "#C70E20",
  	      fontWeight: "bold"
  	    },
  	    passengers: [
  	      {
  	        organisation_id: "",
            id: "",
            name: "",
            passenger: null,
            organisation: null,
            telephone: "",
            isTextField: false
  	      }
  	    ],
  	    icon: null,
  	    imperial: null,
  	    path: null,
  	    currentlat: null,
  	    currentlong: null,
  	    directionsService: null,
  	    directionsDisplay: null,
  	    hidestops:false,
    }
  },
  validations: {
    item:{
      date:{
        required
      },
      time:{
        required
      },
    },
  },

  mounted(){

      this.setLocationLatLng()

      this.$store.state.stop1 = 0
      this.$store.state.stop2 = 0
      this.$store.state.end = 0

      // new customer
      var pusher = new Pusher('3e66b926064c907de413', {
        cluster: 'ap2'
      });
      var channelcustomer = pusher.subscribe('newcustomer');

      channelcustomer.bind('newcustomer', data => {
          this.$store.dispatch('newCustomer', data.data)
      })
      // add booking
      var channel = pusher.subscribe('createBooking');
      channel.bind('bookingCreated', data => {
          this.$store.dispatch('bookingCreated', data.data)
      })
      // edit booking
      var editchannel = pusher.subscribe('editBooking');
      editchannel.bind('bookingEdit', data => {
          this.$store.dispatch('bookingEdit', data.data)
      })
      // delete booking
      var deletechannel = pusher.subscribe('deleteBooking');
      deletechannel.bind('bookingDelete', data => {
          this.$store.dispatch('bookingDelete', data.data)
      })

    },
    created() {
        //do we support geolocation
        if(!("geolocation" in navigator)) {
         console.log('Geolocation is not available.')
         return
        }

        if(this.customers.length == 0 || this.organisations.length == 0 || this.vehicletype.length == 0){
          this.getAddBookings()
        }

    },
    watch: {

    },
    computed: {
      loader() {
        return this.$store.state.tableloader
      },
      mini() {
        return this.$store.state.mini
      },
      Plabel() {
        return !this.isDelivery ? "Name*" : "Name*"
      },
      projects() {
        if(this.passengers.length > 0){

          let res = []
          // let data = this.organisations.filter(organisation => organisation.id === parseInt(this.item.organisation_id) )
          let data = this.organisations.filter(organisation => this.passengers.some(item => parseInt(item.organisation_id) === organisation.id ))

          if(data.length > 0) {
            res = data[0].projects
          }
          return res
        }else{
          return []
        }
                
      },
      addbookingloader() {
        return this.$store.state.addbookingloader
      },
      vehicletype() {
        return this.$store.state.bookingvehicletype
      },
      drivers() {
        return this.$store.state.bookingdrivers
      },
      customers() {
        return this.$store.state.bookingcustomers
      },
      organisations() {
        return this.$store.state.bookingorganisations
      },
      distance() {
        return this.$store.state.distance

      },
      stop1() {
        return this.$store.state.stop1

      },
      stop2() {
        return this.$store.state.stop2

      },
      end() {
        return this.$store.state.end

      },
      distanceTime() {
        return this.$store.state.distanceTime
      },
      stop1Time() {
        return this.$store.state.stop1Time
      },
      stop2Time() {
        return this.$store.state.stop2Time
      },
      endTime() {
        return this.$store.state.endTime
      }
    },

    watch: {
        distance: function(newVal, oldVal) {
            this.$store.state.distance = newVal
        },
        stop1: function(newVal, oldVal) {
            this.$store.state.stop1 = newVal
        },
        stop2: function(newVal, oldVal) {
            this.$store.state.stop2 = newVal
        },
        end: function(newVal, oldVal) {
            this.$store.state.end = newVal
        }
    },
    serverPrefetch () {
      // return this.getAddBookings()
    },
    methods:{
      validateState(name) {
        const { $dirty, $error } = this.$v.item[name];
        return $dirty ? !$error : null;
      },
      clearpickupdate() {
        this.date = ""
      },
      clearenddate() {
        this.enddate = ""
      },
      addPassenger () {
        this.passengers.push({
          organisation_id: "",
          id: "",
          name: "",
          passenger: null,
          organisation: null,
          telephone: "",
          isTextField: false
        })

      },
      removePassenger (index) {
        this.passengers.splice(index, 1);
      },
      onSwitchchange($event) {
        if($event === true || $event === 'true' || $event === 'on') {
          this.item.project_id = []
          this.projects.map(item => {
            this.subprojects.push({
              project_id: item.id,
              project_name: item.name,
              subprojects:item.subprojects,
              subproject_id: "",
              subproject: null
            })
          })
        }else {
          this.sub_projects = []
          this.subprojects = []
        }
      },
      capitalize(name) {
        const finalSentence = name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        return finalSentence
      },
      updateSubprojects(value, index, projectId) {
        let found = this.subprojects[index];
        if(value !== null) {
          if (found) {
            found.subproject_id = value.id
            found.subproject = value
          }
        }else {
          if (found) {
            found.subproject_id = ""
            found.subproject = null
          }
        }
        
      },
      shufflestops() {
        this.hidestops = !this.hidestops
        this.item.stop1 = ""
        this.item.stop1lat = null
        this.item.stop1long = null
        this.item.stop2 = ""
        this.item.stop2lat = null
        this.item.stop2long = null

        this.$store.state.waypoints = []
      },
      setText(indx) {
        
        let find = this.passengers[indx];
        if (find) {
          const container = {};
          container.organisation_id = ''
          container.organisation = null
          container.passenger = null
          container.id = ''
          container.name = ''
          container.telephone = ''
          container.isTextField = !find.isTextField
          this.passengers.splice(indx, 1, container);
        }
        let found = this.passengers[indx];

        if(found.isTextField === true) {
          this.item.telephone = ''
          this.item.customer_id = null
          this.selectcustomer = null

          if (found) {
            const container = {};
            container.organisation_id = ''
            container.organisation = null
            container.passenger = null
            container.id = ''
            container.telephone = ''
            container.name = found.name
            container.isTextField = found.isTextField
            this.passengers.splice(indx, 1, container);
          }

        }
        if(found.isTextField === false) {
          this.item.name = ''
          if (found) {
            const container = {};
            container.organisation_id = ''
            container.organisation = null
            container.passenger = null
            container.id = ''
            container.telephone = ''
            container.name = ''
            container.isTextField = found.isTextField
            this.passengers.splice(indx, 1, container);
          }
        }
      },
      updateCustomer(value,indx) {
        if(value !== null) {

          let index = this.customers.filter(item => item.id === value )

          let found = this.passengers[indx];
          if (found) {
            found.organisation_id = index[0].organisation_id
            found.organisation = index[0].organisation
            found.id = value
            found.name = index[0].name
            found.passenger = index[0]
            found.telephone = index[0].telephone
          }

        }
      },
      updateOrganisation(value,indx) {
        if(value !== null) {
          let index = this.organisations.filter(item => item.id === value )
          let found = this.passengers[indx];
          if (found) {
            found.organisation_id = index[0].id
            found.organisation = index[0]
          }
        }
      },
      setLocationLatLng: function() {
        navigator.geolocation.getCurrentPosition(geolocation => {
          this.center = {
            lat: geolocation.coords.latitude,
            lng: geolocation.coords.longitude
          };
        })
      },
      directionFilter (addressData) {
        this.direction = addressData.name+', '+addressData.locality+', '+addressData.country
      },
      onPlaceChangedFrom (addressData) {
        this.item.fromlat = addressData.geometry.location.lat()
        this.item.fromlong = addressData.geometry.location.lng()
        this.item.from = addressData.name

        this.$store.state.origin = { lat: addressData.geometry.location.lat(), lng: addressData.geometry.location.lng() }
      },
      onPlaceChangedTo (addressData) {
        this.item.tolat = addressData.geometry.location.lat()
        this.item.tolong = addressData.geometry.location.lng()
        this.item.to = addressData.name

        this.$store.state.destination = { lat: addressData.geometry.location.lat(), lng: addressData.geometry.location.lng() }
      },
      onPlaceChangedStop1 (addressData) {
        this.item.stop1lat = addressData.geometry.location.lat()
        this.item.stop1long = addressData.geometry.location.lng()
        this.item.stop1 = addressData.name

        this.$store.state.waypoints.push({
                        location: {lat: addressData.geometry.location.lat(), lng: addressData.geometry.location.lng()},
                        stopover: true
                      });
      },
      onPlaceChangedStop2 (addressData) {
        this.item.stop2lat = addressData.geometry.location.lat()
        this.item.stop2long = addressData.geometry.location.lng()
        this.item.stop2 = addressData.name

        this.$store.state.waypoints.push({
                        location: {lat: addressData.geometry.location.lat(), lng: addressData.geometry.location.lng()},
                        stopover: true
                      });
      },
      onNoResult () {},

     getAddBookings(){

        this.$store.state.tableloader = true

        this.$store
          .dispatch('getAddBookings')
      },

      proceed() {

        if(this.item.fromlat === null || this.item.fromlat === "" || this.item.tolat === null || this.item.tolat === "") {
          this.$toastpopup('warning','Warning','Every location should be picked from google suggestions')
          return
        }
        if(this.passengers.length === 0 || this.item.vehicle_type == null) {
          this.$toastpopup('warning','Warning','Ensure all fields marked * are filled')
          return
        }

        if(this.item.stop1 !== "" && this.item.stop2 !== "") {
            this.$distance(parseFloat(this.item.fromlat), parseFloat(this.item.fromlong), parseFloat(this.item.stop1lat), parseFloat(this.item.stop1long), 'stop1');
            this.$distance(parseFloat(this.item.stop1lat), parseFloat(this.item.stop1long), parseFloat(this.item.stop2lat), parseFloat(this.item.stop2long), 'stop2');
            this.$distance(parseFloat(this.item.stop2lat), parseFloat(this.item.stop2long), parseFloat(this.item.tolat), parseFloat(this.item.tolong),'end');

        }else if(this.item.stop1 !== "" && this.item.stop2 === "") {
          this.$store.state.stop2 = 0
            this.$distance(parseFloat(this.item.fromlat), parseFloat(this.item.fromlong), parseFloat(this.item.stop1lat), parseFloat(this.item.stop1long),'stop1');
            this.$distance(parseFloat(this.item.stop1lat), parseFloat(this.item.stop1long), parseFloat(this.item.tolat), parseFloat(this.item.tolong),'end');

        }else if(this.item.stop1 === "" && this.item.stop2 !== "") {
          this.$store.state.stop1 = 0
            this.$distance(parseFloat(this.item.fromlat), parseFloat(this.item.fromlong), parseFloat(this.item.stop2lat), parseFloat(this.item.stop2long),'stop2');
            this.$distance(parseFloat(this.item.stop2lat), parseFloat(this.item.stop2long), parseFloat(this.item.tolat), parseFloat(this.item.tolong),'end');

        }else if(this.item.stop1 === "" && this.item.stop2 === "") {
          this.$store.state.stop1 = 0
          this.$store.state.stop2 = 0
            this.$distance(parseFloat(this.item.fromlat), parseFloat(this.item.fromlong), parseFloat(this.item.tolat), parseFloat(this.item.tolong),'end');
        }

        this.confirmdialog = true

      },
       create(){

          // if (this) {

            this.loading = true

            const config = { headers: { 'Content-Type': 'multipart/form-data',"Access-Control-Allow-Origin": "*" } };
            let fd = new FormData();
            fd.append('customer_id',this.item.customer_id)
            fd.append('name',this.item.name)
            fd.append('telephone',this.item.telephone)
            fd.append('to',this.item.to)
            fd.append('from',this.item.from)
            fd.append('enddate',this.enddate)
            fd.append('frequency',this.item.frequency)
            fd.append('distance',(parseFloat(this.stop1) + parseFloat(this.stop2) + parseFloat(this.end)))
            fd.append('fromlat',this.item.fromlat)
            fd.append('fromlong',this.item.fromlong)
            fd.append('tolat',this.item.tolat)
            fd.append('tolong',this.item.tolong)

            fd.append('stop1lat',this.item.stop1lat)
            fd.append('stop1long',this.item.stop1long)
            fd.append('stop2lat',this.item.stop2lat)
            fd.append('stop2long',this.item.stop2long)
            fd.append('stop1',this.item.stop1)
            fd.append('stop2',this.item.stop2)

            fd.append('orderedby',this.item.orderedby)
            fd.append('pickupdate',this.item.date)
            fd.append('pickuptime',this.item.time)
            fd.append('flight',this.item.flight)
            fd.append('skipweekends',this.item.skipweekends)
            fd.append('skipmondays',this.item.skipmondays)
            fd.append('skiptuesdays',this.item.skiptuesdays)
            fd.append('skipwednesdays',this.item.skipwednesdays)
            fd.append('skipthursdays',this.item.skipthursdays)
            fd.append('skipfridays',this.item.skipfridays)
            fd.append('skipsaturdays',this.item.skipsaturdays)
            fd.append('skipsundays',this.item.skipsundays)
            fd.append('remarks',this.item.remarks)
            fd.append('one_trip_rate',this.item.one_trip_rate)
            fd.append('round_trip_rate',this.item.round_trip_rate)
            fd.append('organisation_id',this.item.organisation_id)
            fd.append('vehicle_type_id',this.item.vehicle_type_id)
            fd.append('vehicle_type',JSON.stringify(this.item.vehicle_type))
            fd.append('project_id',JSON.stringify(this.item.project_id))
            fd.append('trip_type',this.item.trip_type)
            fd.append('request_type',this.item.request_type)
            fd.append('subprojects',JSON.stringify(this.subprojects))
            fd.append('passengers',JSON.stringify(this.passengers))
            fd.append('useSubProjects',this.item.useSubProjects)
            fd.append('isDelivery',this.isDelivery)

            axios.post('/bookings', fd, config).then(response=>{

              this.confirmdialog = false

              if(response.data.status == parseInt(200)) {
                this.$toastpopup('success','Success','Booking added successfully')
              }

              if(response.data.status == parseInt(201)) {
                this.$toastpopup('success','Success','Booking was successful')
              }

              this.$store.state.waypoints = []
              this.$store.state.origin = null
              this.$store.state.destination = null

                this.item.to= ""
                this.item.from= ""
                this.item.distance= ""
                this.item.fromlat= ""
                this.item.fromlong= ""
                this.item.tolat= ""
                this.item.frequency= ""
                this.item.enddate= ""
                this.item.tolong= ""
                this.item.name= ""
                this.item.telephone=""
                this.item.orderedby=""
                this.item.pickupdate=""
                this.item.pickuptime=""
                this.item.organisation_id=""
                this.item.vehicle_type=null
                this.item.flight=""
                this.item.remarks=""
                this.subprojects = []
                this.item.project_id = []
                this.passengers = []

                this.item.time = ""
                this.item.date = ""

                this.passengers.push({
                  organisation_id: "",
                  id: "",
                  name: "",
                  passenger: null,
                  organisation: null,
                  telephone: "",
                  isTextField: false
                })

                this.item.stop1 = ""
                this.item.stop1lat = null
                this.item.stop1long = null
                this.item.stop2 = ""
                this.item.stop2lat = null
                this.item.stop2long = null

                this.loading = false
              })
              .catch(error => {
                this.loading = false
              })

          // }
      },
      clear () {
      }

    }
};

</script>
