<template>
  <div>
    <div class="tcell" @click="bookingdetailsupdate(item.id)" style="cursor:pointer">
      <div v-if="item.passengers.length === 0" class="name">{{ item.name }}</div>
        <div v-else style="display:flex;flex-direction:column !important;align-items:flex-start;justify-content:flex-start">
          <span v-for="(passenger,index) in item.passengers" class="name" style="display:flex;"> {{ passenger.passenger_name }}</span>
        </div>
    </div>
    <div class="tcell">
      {{ item.time }}
    </div>
    <div class="tcell">
      {{ item.date }}
    </div>
    <div class="tcell">
      <div v-if="item.passengers.length === 0">{{ item.telephone }}</div>
        <div v-else style="display:flex;flex-direction:column !important">
          <span v-for="(passenger,index) in item.passengers" style="display:flex;"> {{ passenger.telephone }}</span>
        </div>
    </div>
    <div class="tcell">
      {{ item.organisation_name }}
    </div>

    <div class="tcell">
      {{ item.from }}
    </div>
    <div class="tcell">
      {{ item.to }}
    </div>
   
    <div class="tcell text-center">
      <!-- @click.stop="openMenu($event,item.id)" -->
      <a class="list-action" :id="`popover-target-1-${item.id}`">
  <b-icon class="my-0 py-0" style="cursor:pointer;" icon="chevron-down"></b-icon>
    </a>
    <b-popover
  :target="`popover-target-1-${item.id}`"
  triggers="hover focus"
  placement="auto"
>
  <template #title>
    <b-button class="close" aria-label="Close">
      <span class="d-inline-block" aria-hidden="true">&times;</span>
    </b-button>
    Interactive Content
  </template>
</b-popover>
    </div>
    <div class="tcell">
        <div v-if="item.vehicles.length > 0" class="name"><span v-for="(res,i) in item.vehicles" :key="i">{{ res.vehicle.name }} - {{ res.vehicle.registration }},</span></div>
        <div v-else>Pending</div>
    </div>
    <div class="tcell">
        {{ item.vehicletype_type }}
    </div>
    <div class="tcell">
      {{ item.request_category }}
    </div>
    <div class="tcell">
      <span v-if="item.status && item.authorization == 'true'">{{ item.status }}</span>
      <span
        v-if="item.authorization == 'false'"
      >
        Locked
      </span>
      <span
            v-if="item.authorization == 'declined'"
          >
            Cancelled
          </span><br>
      <small style="font-size:.69rem;" v-if="item.authorization == 'declined'" v-for="res in item.declined" :key="res.id">
                ::{{ res.reason }}
              </small>
    </div>

    <div class="tcell">
        {{ item.flight }}
    </div>
    <div class="tcell">
      <span
        v-if="item.remarks !== null"
      >
        {{ item.remarks }}
      </span>
    </div>
  </div>
</template>

<script>
import { IdState } from 'vue-virtual-scroller'
export default {
    mixins: [
      IdState({
        // You can customize this
        idProp: vm => vm.item.id,
      }),
    ],
    idState () {
    return {
      popupOpen: false,
    }
  },
  props: ['item', 'index'],

  created () {
    console.log('created')
  },

  destroyed () {
    console.log('destroyed')
  },

  methods: {
  },
}
</script>
