<template>
  <div class="about mt-0">
    <div class="mx-0 px-0" style="position:relative">
      <div class="flex" :class= "[mini ? 'minipadding' : 'expandpanding']">
      	<div class="section_title"><span class="title">Rates&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
      	<div>
      		<b-button variant="primary" class="primary" size="sm" @click.stop="dialog = true">Add Rate <b-icon class="mr-1" icon="plus"></b-icon></b-button>
      	</div>
      </div>
      <b-row class="subsection">
      	<b-col sm="12" xl="12" md="12" class="mx-0 px-3">
      		<div class="dynamic-scroller rates">
            <b-row class="mx-2 mb-2 searchsection" style="display:flex;justify-content:flex-end;align-items:center">
              <div class="px-1" style="width:200px !important;padding-top:0 !important;padding-bottom:0 !important;display:flex;">
                <b-form-input
                    placeholder="Search..."
                    size="sm"
                    v-model="search"
                  ></b-form-input>
              </div>
            </b-row>
            <div class="empty-state" v-if="rates.length === 0">
              <div class="empty-state__content">
                <div class="empty-state__icon">
                  <img src="@/assets/data.svg" style="width:40%">
                </div>
                <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                <div class="empty-state__message" v-else>No records available.</div>
              </div>
            </div>
      			<RecycleScroller
		          ref="scroller"
		          class="scroller"
		          :items="rates"
		          :item-size="24"
		          key-field="id"
		        >
		          <template  v-slot="{ item,index }">
		            <div>
                  <div class="tcell">{{ item.entrance.name }}</div>
                  <div class="tcell">{{ item.exit.name }}</div>
                  <div class="tcell">{{ item.rate }}</div>
    					    <div class="tcell text-center">
    					      	<a class="list-action" :id="`popover-target-1-${item.id}-${index}`">
    						  		<b-icon class="my-0 py-0" style="cursor:pointer;" icon="chevron-down"></b-icon>
    						    </a>
    						    <b-popover
      							  :target="`popover-target-1-${item.id}-${index}`"
      							  triggers="hover blur"
      							  placement="left"
      							  class="dropdown-menu"
      							>
      							  <div class="action-dropdown-menu">
      						  		<button @click="edit(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="pencil"></b-icon>&nbsp;  Edit</button>
      						  		<button @click="deleteItem(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="trash"></b-icon>&nbsp;  Remove</button>
      						  	</div>
      							</b-popover>
    					    </div>
    					  </div>
		          </template>
		        </RecycleScroller>
			  </div>
      	</b-col>
      </b-row>
    </div>

    <!-- add modal -->
    <b-modal
      v-model="dialog"
      size="rates"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Add Rate</span>
        </div>
      </template>
      <b-container fluid>
        <b-row class="mb-4" v-for="(rate, indx) in grouprates" :key="indx">
            <b-col sm="12" md="4">
              <div class="float_label">
                
                <multiselect ref="entrance_idmultiselect"
                        label="name"
                        v-model="rate.entrance_id" 
                        :name="`grouprates[${indx}][entrance_id]`"
                         track-by="id" placeholder="Select Entrance" :options="entrances" :searchable="true" :allow-empty="false" :show-labels="false" @input="(event) => updateEntrance(event,indx)">
                  <span slot="noResult">Oops! Record not found.</span>
                  <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                </multiselect>
                <label class="typo__label float_label_item">Entry*</label>
              </div>
            </b-col>
            <b-col sm="12" md="4" class="float_label">
              
              <multiselect ref="exit_idmultiselect" 
                      v-model="rate.exit_id"
                      :name="`grouprates[${indx}][exit_id]`"
                      @input="(event) => updateExit(event,indx)"
                       track-by="id" label="name" placeholder="Select Exit" :options="exits" :searchable="true" :allow-empty="false" :show-labels="false">
                <span slot="noResult">Oops! Record not found.</span>
                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
              </multiselect>
              <label class="typo__label float_label_item">Exit*</label>
            </b-col>
            <b-col sm="12" md="3" class="float_label">
                
                  <b-form-input
                    type="text"
                    placeholder="Rate"
                    v-model="rate.rate" 
                    :name="`grouprates[${indx}][rate]`"
                    size="sm"
                  ></b-form-input>
                <label class="typo__label float_label_item">Rate*</label>
              </b-col> 
            <b-col sm="12" md="1" class="addremoveicons mt-1">
              <b-icon class="mx-2" @click="addRate" color="#F9780D" icon="plus-circle"></b-icon>
              <b-icon color="#DC3545" @click="removeRate(indx)" icon="x-circle"></b-icon>
            </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="create">
            <b-spinner small v-if="loading"></b-spinner> Save
          </b-button>
      </template>
    </b-modal>

    <!-- update modal -->
    <b-modal
      v-model="updatedialog"
      size="sm"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Update Rate</span>
        </div>
      </template>
      <b-container fluid>
        <b-row>
          <b-col
            sm="12" md="12"
            class="float_label mb-3"
           >
            
            <multiselect ref="entrancemultiselect" 
                    v-model="item.updateentrance_id"
                     track-by="id" label="name" placeholder="Select entrance" :options="entrances" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Entrance*</label>
          </b-col> 
          <b-col
            sm="12" md="12"
            class="float_label mb-3"
           >
            
            <multiselect ref="exitmultiselect" 
                    v-model="item.updateexit_id"
                     track-by="id" label="name" placeholder="Select exit" :options="exits" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Exit*</label>
          </b-col> 
          <b-col sm="12" md="12" class="float_label">
     
              <b-form-input
                type="text"
                placeholder="Rate"
                v-model="item.updaterate" 
                size="sm"
              ></b-form-input>
            <label class="typo__label float_label_item">Rate*</label>
          </b-col> 
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="update">
            <b-spinner small v-if="loading"></b-spinner> Update
          </b-button>
      </template>
    </b-modal>

  </div>
</template>

<style>
	@import "@/assets/table/table";
  .modal-rates{
    max-width:680px !important;
  }
	.action-dropdown-menu .action-dropdown-item:hover{
		background:var(--ab-primary);
		color:#fff;
		border-radius:var(--ab-dropdown-border-radius);
	}
	.action-dropdown-menu{
		padding-right: .5rem;
	}
  .rates .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-template-columns: 25% 25% 25% 25%;
     grid-gap: 0px;
     min-width:100%;
  }
  .rates .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }
</style>
<style scoped>
	@import "@/assets/table/table_scoped";
  .rates .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-template-columns: 25% 25% 25% 25%;
     grid-gap: 0px;
  }
  
</style>

<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';

 export default {
  name: 'EntranceList',
  components: {
  },
  computed: {
	  mini() {
	    return this.$store.state.mini
	  },
    loader() {
    	return this.$store.state.tableloader
    },
    rates() {
      return this.$store.state.expressrates.filter(item => {
        this.start = 0
        return item.entrance.name.toLowerCase().includes(this.search.toLowerCase()) || item.exit.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    entrances() {
      return this.$store.state.expresswayrates
    },
    exits() {
      return this.$store.state.exits
    },
  },
  data: () => ({
      search: '',
      dialog: false,
      updatedialog: false,
      loading: false,
      fields: [
        { text: 'Entrance', value: 'entrance' },
        { text: 'Exit', value: 'exit' },
        { text: 'Rate(Kes)', value: 'rate' },
        { text: 'Action', value: 'action' },
       ],
      item:{
          updateentrance_id:"",
          updateexit_id:"",
          updaterate:"",
          updateID: "",
          _method:"patch"
      },
      grouprates: [
        {
          entrance_id: null,
          exit_id: null,
          rate: ""
        }
      ],
  }),
  beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
  mounted() {

  	let headertitle = `<div class="view-titleheader titlehead" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="theader">${value.text}</div>`;
         });
      headertitle +=`</div>`;
      $('.vue-recycle-scroller').prepend(headertitle)

      this.getRates()
  },
  serverPrefetch () {
    // this.getRates()
  },
  methods: {
  	addRate () {
        this.grouprates.push({
          entrance_id: null,
          exit_id: null,
          rate: ""
        })

      },
      removeRate (index) {
        this.grouprates.splice(index, 1);
      },
      updateEntrance(value,indx) {
        if(value !== null) {
          let found = this.grouprates[indx];
          if (found) {
            found.entrance_id = value
          }
        }
      },
      updateExit(value,indx) {
        if(value !== null) {
          let found = this.grouprates[indx];
          if (found) {
            found.exit_id = value
          }
        }
      },
      getRowClass(item) {
        return 'row-color' 
      },
      async getRates(){
        this.$store.state.tableloader = true
        await axios.get('/expressway/rates').then(response=>{
            this.$store.state.tableloader = false
            this.$store.state.expresswayrates = response.data.entrances
            this.$store.state.exits = response.data.exits
            this.$store.state.expressrates = response.data.rates
        }).catch(error=>{
          this.$store.state.tableloader = false
            console.log(error)
        })
      },
       async create(){

          this.loading = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('rates',JSON.stringify(this.grouprates))

          await axios.post('/expressway/rates', fd, config).then(response=>{
            this.loading = false
            if(response.data.status === 200) {

              this.$toastpopup('success','Success',"Rate added successfully")
              
              this.grouprates = []
              this.addRate()

              this.getRates()

              this.dialog = false

            }
       
          }).catch(error=>{
            this.loading = false
              console.log(error)
          })

        },
        async update(){

          this.loading = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('entrance_id',JSON.stringify(this.item.updateentrance_id))
          fd.append('exit_id',JSON.stringify(this.item.updateexit_id))
          fd.append('rate',this.item.updaterate)
          fd.append('_method',this.item._method)

          await axios.post(`/expressway/rates/${this.item.updateID}`, fd, config).then(response=>{
            this.loading = false
            if(response.data.status === 200) {

              let index = this.$store.state.expresswayrates.findIndex(data => data.id == response.data.rate.id);
                if (index > -1) {
                  this.$store.state.expresswayrates.splice(index, 1, response.data.rate);
                }

              this.$toastpopup('success','Success',"Rate updated successfully")
              this.item.updateexit_id = null
              this.item.updateentrance_id = null
              this.item.updaterate = ''

              this.getRates()

              this.updatedialog = false

            }
          }).catch(error=>{
            this.loading = false
              console.log(error)
          })

        },
      async edit(id) {
        let index = this.rates.filter(item => item.id === id )
        if(index.length > 0) {

          this.item.updateexit_id = index[0].exit
          this.item.updateentrance_id = index[0].entrance
          this.item.updaterate = index[0].rate
          this.item.updateID = index[0].id
          this.updatedialog = true

        }
        
      },
      deleteItem(id){
            if(confirm("Are you sure to remove rate ?")){
                axios.delete(`/expressway/rates/${id}`).then(response=>{
                  if(response.data.status === 200) {

                    let index = this.$store.state.rates.findIndex(data => data.id == response.data.rate.id);
                    if (index > -1) {
                      this.$store.state.rates.splice(index, 1);
                    }

                    this.$toastpopup('success','Success',"Rate removed successfully")
                  }
                }).catch(error=>{
                    console.log(error)
                })
            }
        }

  }
};

</script>
