<template>
	<div class="badges">
    <!-- 15min badge -->
      <b-button
        class="white--text countin15 mr-1"
        style="background:#FFC14D !important"
        size="sm"
        @click="filterRecord('in15')"
      >
        In 15mins <span> ({{ in15count }})</span>
      </b-button>
    <!-- over 15 badge -->
      <b-button
        class="white--text countover15 mr-1"
        style="background:#B9CD99 !important"
        size="sm"
        @click="filterRecord('over15')"
      >
        Over 15mins <span> ({{ over15count }})</span>
      </b-button>
    <!-- late badge -->
      <b-button
        class="white--text countlate mr-1"
        style="background:#FF6666 !important"
        size="sm"
        @click="filterRecord('late')"
      >
        Late <span> ({{ latecount }})</span>
      </b-button>
    <!-- 30min badge -->
      <b-button
        class="white--text countin30 mr-1"
        size="sm"
        @click="filterRecord('in30')"
        style="background:#75BEE6 !important"
      >
        In 30mins <span> ({{ in30count }})</span>
      </b-button>
    <!-- 2hrs badge -->
      <b-button
        class="white--text countin2 mr-1"
        style="background:#FEFEE2 !important"
        size="sm"
        @click="filterRecord('in2')"
      >
        In 2hrs <span> ({{ in2count }})</span>
      </b-button>
    <!-- over2hrs badge -->
      <b-button
        class="black--text countover2 mr-1"
        style="background:#FAFA96 !important"
        size="sm"
        @click="filterRecord('over2')"
      >
        In Over 2hrs <span> ({{ over2count }})</span>
      </b-button>
    <!-- cancelled badge -->
      <b-button
        class="white--text countcancelled mr-1"
        style="background:rgb(192, 192, 192) !important"
        size="sm"
        @click="filterRecord('cancelled')"
      >
        Cancelled <span> ({{ cancelledcount }})</span>
      </b-button>
    <!-- allcount badge -->
      <b-button
        class="white--text countall"
        size="sm"
        @click="filterRecord('')"
      >
        All <span> ({{ allcount }})</span>
      </b-button>
  </div>
</template>
<style>
/*table color variant*/
.countlate{color: #000 !important;background:#FF6666 !important;font-size:10px !important;font-weight:500 !important;}
.countin15{color: #000 !important;background:#FFC14D !important;font-size:10px !important;font-weight:500 !important;}
.countover15{color: #000 !important;background:#B9CD99 !important;font-size:10px !important;font-weight:500 !important;}
.countin30{color: #000 !important;background:#75BEE6 !important;font-size:10px !important;font-weight:500 !important;}
.countin2{color: #000 !important;background:#FEFEE2 !important;font-size:10px !important;font-weight:500 !important;}
.countover2{color: #000 !important;background:#FAFA96 !important;font-size:10px !important;font-weight:500 !important;}
.countcancelled{color: #000 !important;background:rgb(192, 192, 192) !important;font-size:9px !important;font-weight:500 !important;}
.countblack{background:#000000 !important;color: #fff !important;font-size:10px !important;font-weight:500 !important;}
.countall{
    background-image: linear-gradient(to bottom right, rgba(255,45,45.6), rgba(255,165,0,.6), rgba(104,184,227.6), rgba(255,0,0,.6), rgba(192,192,192,.6)) !important;
    font-size:10px !important;font-weight:500 !important;
}
.badges .btn span{
  font-size: 10px !important;
  font-weight: 600 !important;
}
</style>
<script>
import axios from 'axios'
import Vue from 'vue'
import { mapState } from 'vuex'

export default {
	name: 'bookingcounts',
	components: {
	},
	data: () => ({

		
	}),
	methods: {

	  filterRecord(res) {
        this.$store.commit('searchFilter', res)
      },
		
	},
	computed: mapState(['in15count','over15count','in30count','in2count','over2count','latecount','cancelledcount','allcount','dayscount']),

	mounted() {
  },
	created() {}
}
</script>