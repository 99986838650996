<template>
	<div>
		<!-- assign cancel -->
    <b-modal
      v-model="show"
      size="cancelbooking"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Cancel Booking</span>
        </div>
      </template>
      <b-container fluid>
        <b-row v-if="selected">
          <b-col sm="12" md="12" class="mb-1">
            <b-alert show variant="info"><b-icon icon="dot"></b-icon> {{ selected.passengers.length > 0 ? selected.passengers[0].passenger_name : selected.name }}</b-alert>
          </b-col>
          <b-col sm="12" md="12" class="mb-2 float_label mt-3">
            <b-form-textarea
              id="textarea-small"
              size="sm"
              placeholder="Reason"
              v-model="reason"
            ></b-form-textarea>
            <label class="typo__label float_label_item">Reason</label>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="sendcancel">
            <b-spinner small v-if="loading"></b-spinner> Submit
          </b-button>
      </template>
    </b-modal>
    <!-- end cancel -->
	</div>
</template>
<style>
  .modal-cancelbooking{
    max-width:420px !important;
  }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'

export default {
	name: 'cancelbooking',
	components: {
	},
	data: () => ({
		messageclientdialog: false,
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        reason: "",

	}),
	methods: {
		async sendcancel() {
          // if (this.$refs.form.validate()) {

            this.loading = true

              const config = { headers: { 'Content-Type': 'multipart/form-data' } };
              let fd = new FormData();
              fd.append('reason',this.reason)
              fd.append('id',this.selected.id)

              await axios.post('/booking/cancel-booking', fd, config).then(response=>{
                this.loading = false
                this.disabled = false
                if(response.data.status === 200) {
                  this.$store.dispatch('bookingEdit', response.data.data)
                  this.$toastpopup('success','Success',"Booking was cancelled successfully")
                  this.reason = ''
                  this.show = false
                }
              }).catch(error=>{
                this.loading = false
                  console.log(error)
              })

            // }
        },
	},
	props: {
	     value: Boolean,
	     selected: Object
	  },
	  computed: {
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    }
	  },
	mounted() {
	},
	created() {
     
    }
}
</script>