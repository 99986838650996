<script>
import { mapGetters } from 'vuex'

 export default {
  name: 'App',
  components: {
  },

  computed: {
    mini() {
      return this.$store.state.mini
    },
    ...mapGetters([
      'isLogged'
    ]),
  },
  data: () => ({
     
  }),
  mounted() {

  },
  serverPrefetch () {
    },
  methods: {
    togglesidebar() {
      this.$store.state.mini = !this.$store.state.mini;
    },
    logout () {
      this.$store.dispatch('logout')
    }
  }
};
</script>

<template>
  <div id="app">
    <b-container fluid v-if="isLogged">
      <b-row class="navs wrapper">
         <div class="section" :class= "[mini ? 'minipadding' : 'expandpanding', 'mt-15']">
          <b-navbar class="navbar" fixed="top" :class= "[mini ? 'minipadding' : 'expandpanding', 'mt-15']">
            <b-navbar-toggle class="hamburger" target="nav-text-collapse"></b-navbar-toggle>

            <b-navbar-nav class="ml-auto">
           
              <b-nav-item-dropdown right>
                <!-- Using 'button-content' slot -->
                <template #button-content>
                  <span>{{ $store.state.user.user.organisation.name }}</span>
                </template>
                <b-dropdown-item href="#">Profile</b-dropdown-item>
                <b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-navbar>
        </div>
        <div class="sidebar" :class= "[mini ? 'minipadding' : 'expandpanding']">
            <div class="logo-section" :class= "[mini ? 'minipadding' : 'expandpanding']">
                <img src="@/assets/absolutelogo.jpg" alt="logo" v-if="mini">
                <img src="@/assets/logo.png" alt="logo" v-else>
            </div>
            <ul class="sidemenu" :class= "[mini ? 'minimenu' : '']">
              <li class="dropdown">
                  <router-link link :to="{name: 'HomeView'}">
                    <label class="dropdown-btn">
                        <div class="menuicon">
                          <img src="@/assets/svg/home.svg" alt="icon" class="aicon">
                          <span v-show="!mini">Home</span>
                        </div>
                    </label>
                  </router-link>
                </li>
                <li class="dropdown">
                    <input type="checkbox" name="checkbox" id="business" />
                    <label for="business" class="dropdown-btn">
                      <div class="menuicon">
                        <!-- <b-icon class="mr-1" icon="house"></b-icon> -->
                        <img src="@/assets/svg/business.svg" alt="icon" class="aicon">
                        <span v-show="!mini">Business</span>
                      </div>
                      <b-icon v-show="!mini" class="arrow" icon="chevron-down"></b-icon>
                    </label>
                    
                    <ul class="dropdown-content" role="menu">
                      <li><router-link link :to="{name: 'AllBookingView'}">All Bookings</router-link></li>
                      <li><router-link link :to="{name: 'TodayBookingView'}">Today Bookings</router-link></li>
                      <li><router-link link :to="{name: 'AddBookingView'}">Add Booking</router-link></li>
                      <li><router-link link :to="{name: 'returnBooking'}">Return Booking</router-link></li>
                      <li><a href="#">Ratings</a></li>
                    </ul>
                </li>
                <li class="dropdown">
                    <input type="checkbox" name="checkbox" id="organisations" />
                    <label for="organisations" class="dropdown-btn">
                      <div class="menuicon">
                        <img src="@/assets/svg/organisation.svg" alt="icon" class="aicon">
                        <span v-show="!mini">Organisations</span>
                      </div>
                      <b-icon v-show="!mini" class="arrow" icon="chevron-down"></b-icon>
                    </label>
                    <ul class="dropdown-content" role="menu">
                      <li><router-link link :to="{name: 'orgList'}">List</router-link></li>
                      <li><router-link link :to="{name: 'orgProjectList'}">Projects</router-link></li>
                      <li><router-link link :to="{name: 'orgCategoryList'}">Categories</router-link></li>
                      <li><router-link link :to="{name: 'orgTypeList'}">Types</router-link></li>
                      <li><router-link link :to="{name: 'organisationRates'}">Rates</router-link></li>
                    </ul>
                </li>
                <li class="dropdown">
                    <input type="checkbox" name="checkbox" id="vehicles" />
                    <label for="vehicles" class="dropdown-btn">
                      <div class="menuicon">
                        <img src="@/assets/svg/vehicle.svg" alt="icon" class="aicon">
                        <span v-show="!mini">Vehicles</span>
                      </div>
                      <b-icon v-show="!mini" class="arrow" icon="chevron-down"></b-icon>
                    </label>
                    <ul class="dropdown-content" role="menu">
                      <li><router-link link :to="{name: 'vehicleList'}">List</router-link></li>
                      <li><router-link link :to="{name: 'vehicleMakeList'}">Makes</router-link></li>
                      <li><router-link link :to="{name: 'vehicleTypeList'}">Types</router-link></li>
                      <li><router-link link :to="{name: 'vehicleModelList'}">Models</router-link></li>
                      <li><router-link link :to="{name: 'vehicleOwnershipList'}">Ownerships</router-link></li>
                    </ul>
                </li>
                <li class="dropdown">
                    <input type="checkbox" name="checkbox" id="drivers" />
                    <label for="drivers" class="dropdown-btn">
                      <div class="menuicon">
                        <img src="@/assets/svg/driver.svg" alt="icon" class="aicon">
                        <span v-show="!mini">Drivers</span>
                      </div>
                      <b-icon v-show="!mini" class="arrow" icon="chevron-down"></b-icon>
                    </label>
                    <ul class="dropdown-content" role="menu">
                      <li><router-link link :to="{name: 'driverList'}">List</router-link link></li>
                    </ul>
                </li>
                <li class="dropdown">
                    <input type="checkbox" name="checkbox" id="finance" />
                    <label for="finance" class="dropdown-btn">
                      <div class="menuicon">
                        <img src="@/assets/svg/finance.svg" alt="icon" class="aicon">
                        <span v-show="!mini">Finance</span>
                      </div>
                      <b-icon v-show="!mini" class="arrow" icon="chevron-down"></b-icon>
                    </label>
                    
                    <ul class="dropdown-content" role="menu">
                      <li><router-link link :to="{name: 'invoiceList'}">Invoices</router-link></li>
                      <li><router-link link :to="{name: 'invoiceDetail'}">Invoice Details</router-link></li>
                      <li><router-link link :to="{name: 'paymentList'}">Payments</router-link></li>
                      <li><router-link link :to="{name: 'paymentReversalsList'}">Reversals</router-link></li>
                      <li><router-link link :to="{name: 'creditnotesList'}">Credit Notes</router-link></li>
                      <li><router-link link :to="{name: 'debitnotesList'}">Debit Notes</router-link></li>
                      <li><router-link link :to="{name: 'customerStatement'}">Statements</router-link></li>
                    </ul>
                </li>
                <li class="dropdown">
                    <input type="checkbox" name="checkbox" id="reports" />
                    <label for="reports" class="dropdown-btn">
                      <div class="menuicon">
                        <img src="@/assets/svg/report.svg" alt="icon" class="aicon">
                        <span v-show="!mini">Reports</span>
                      </div>
                      <b-icon v-show="!mini" class="arrow" icon="chevron-down"></b-icon>
                    </label>
                    
                    <ul class="dropdown-content" role="menu">
                      <li><router-link link :to="{name: 'Reports'}">Full Reports</router-link></li>
                      <li><router-link link :to="{name: 'InvoiceReport'}">Invoice Reports</router-link></li>
                      <!-- <li><a href="#">Vouchers</a></li> -->
                    </ul>
                </li>
                <li class="dropdown">
                    <input type="checkbox" name="checkbox" id="userconfig" />
                    <label for="userconfig" class="dropdown-btn">
                      <div class="menuicon">
                        <img src="@/assets/svg/userconfig.svg" alt="icon" class="aicon">
                        <span v-show="!mini">User Config</span>
                      </div>
                      <b-icon v-show="!mini" class="arrow" icon="chevron-down"></b-icon>
                    </label>
                    
                    <ul class="dropdown-content" role="menu">
                      <li><router-link link :to="{name: 'usersList'}">User Accounts</router-link></li>
                      <li><router-link link :to="{name: 'roleList'}">Roles</router-link></li>
                      <li><router-link link :to="{name: 'permissionList'}">Permissions</router-link></li>
                      <li><router-link link :to="{name: 'customerList'}">Customers</router-link></li>
                    </ul>
                </li>
                <li class="dropdown">
                    <input type="checkbox" name="checkbox" id="expressway" />
                    <label for="expressway" class="dropdown-btn">
                      <div class="menuicon">
                        <img src="@/assets/svg/expressway.svg" alt="icon" class="aicon">
                        <span v-show="!mini">Expressway</span>
                      </div>
                      <b-icon v-show="!mini" class="arrow" icon="chevron-down"></b-icon>
                    </label>
                    
                    <ul class="dropdown-content" role="menu">
                      <li><router-link link :to="{name: 'EntranceList'}">Entrances</router-link></li>
                      <li><router-link link :to="{name: 'ExitList'}">Exits</router-link></li>
                      <li><router-link link :to="{name: 'RateList'}">Rates</router-link></li>
                    </ul>
                </li>

                <li class="dropdown">
                    <input type="checkbox" name="checkbox" id="configs" />
                    <label for="configs" class="dropdown-btn">
                      <div class="menuicon">
                        <img src="@/assets/svg/config.svg" alt="icon" class="aicon">
                        <span v-show="!mini">Configs</span>
                      </div>
                      <b-icon v-show="!mini" class="arrow" icon="chevron-down"></b-icon>
                    </label>
                    
                    <ul class="dropdown-content" role="menu">
                      <li><router-link link :to="{name: 'Settings'}">Settings</router-link></li>
                      <li><router-link link :to="{name: 'Logs'}">Logs</router-link></li>
                    </ul>
                </li>
            </ul>
            <div id="nav-footer" @click="togglesidebar">
              <b-icon class="arrow" :icon="mini ? 'chevron-right':'chevron-left'" ></b-icon>
            </div>
        </div>
      </b-row>
    </b-container>
    <div class="main" v-if="isLogged" :class= "[mini ? 'minipadding' : 'expandpanding']">
      <main>
        <router-view />
      </main>
    </div>
    <div v-else class="noauth">
      <main>
        <router-view />
      </main>
    </div>
  </div>
</template>
<style lang="scss">

  @import "@/assets/scss/index.scss";
  @import "~bootstrap/dist/css/bootstrap.css";
  @import '~bootstrap-vue/dist/bootstrap-vue.css';

</style>
<style scoped>
.noauth{
  min-height:100vh;
  background:#f6f8f9;
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menuicon{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.menuicon span{
  margin-top: .2rem;
  margin-left: .4rem;
}
.navbar{
  height:48px;
  background:var(--ab-dark);
}
.navs.wrapper .sidebar{
  position: fixed;
  top: 0;
  left: 0;
  background: var(--sidebar-bg);
  /*width: 200px;*/
  overflow: auto;
  border-right: 1px solid #e4e4e4;
  flex-shrink: 0;
  height: 100%;
  /*transition: all 0.3s ease;*/
  overflow: hidden;
  /*transition: background-color .3s ease;*/
}
.navs.wrapper .sidebar ul.sidemenu{
  padding-top:70px;
  height: calc(100vh - 48px);
  overflow-y: auto;
}

.wrapper .section{
  /*width: calc(100% - 200px);
  margin-left: 200px;*/
  /*transition: all 0.3s ease;*/
}

.navs.wrapper .section .top_navbar{
  background: rgb(7, 105, 185);
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 30px;
 
}

/*#app.active .wrapper .sidebar{
  left: -200px;
}*/

#app.active .wrapper .section{
  margin-left: 0;
  width: 100%;
}

/*custom styling*/
.logo-section{
  height:48px;
  text-align: center;
  background:var(--ab-dark);
  position: fixed;
  left:0;
  width:170px;
  z-index:100;
}
.logo-section img{
  width:60%;
}
#nav-footer {
  position: relative;
  width: var(--navbar-width);
  height: 45px;
  background-color: var(--hover-bg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  transition: width 0.2s, height 0.2s;
  cursor: pointer;
}
.main{
  padding-top:48px;
  overflow-x: hidden;
  background:var(--ab-white-shade);
  min-height: 100vh !important;
}
</style>
