<template>
  <div class="about mt-0">
    <div class="mx-0 px-0">
      <div class="flex" :class= "[mini ? 'minipadding' : 'expandpanding']">
      	<div class="section_title"><span class="title">Add Debit Note&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
      	<div>
      	</div>
      </div>
      <b-container fluid class="subsection">
        <b-row>
        	<b-col sm="12" xl="9" md="12" lg="10">
        		<b-form style="background:var(--vt-c-white);" class="px-3 py-3 mb-4">
              <b-row>
                <b-col
                    cols="12"
                    sm="12"
                    md="6"
                  class="float_label mb-3"
                 >
                  
                  <multiselect ref="orgmultiselect" 
                          v-model="$v.item.organisation_id.$model"
                          :state="validateState('organisation_id')"
                          :close-on-select="true"
                          @input="(event) => selectOrg(event.id)"
                           track-by="id" label="name" placeholder="Select organisation" :options="organisations" :searchable="true" :allow-empty="true" :show-labels="false">
                    <span slot="noResult">Oops! Record not found.</span>
                    <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                  </multiselect>
                  <label class="typo__label float_label_item">Organisation*</label>
                </b-col> 
                <b-col
                    cols="12"
                    sm="12"
                    md="6"
                  class="float_label mb-3"
                 >
                  
                  <multiselect ref="invmultiselect" 
                          v-model="item.invoice_no_id"
                          :close-on-select="true"
                           track-by="id" label="invoice_no" placeholder="Select invoice" :options="invoices" :searchable="true" :allow-empty="true" :show-labels="false">
                    <span slot="noResult">Oops! Record not found.</span>
                    <template slot="singleLabel" slot-scope="{ option }">{{ option.invoice_no }}</template>
                  </multiselect>
                  <label class="typo__label float_label_item">Inv #</label>
                </b-col>
                <b-col sm="12" md="6" class="float_label mb-2">
                
                    <b-form-input
                      type="text"
                      placeholder="Amount"
                      v-model="$v.item.amount.$model"
                      :state="validateState('amount')"
                      size="sm"
                    ></b-form-input>
                  <label class="typo__label float_label_item">Amount*</label>
                </b-col> 
                <b-col cols="12" sm="12" md="6" class="float_label mb-2">
                  
                  <b-form-datepicker
                    id="datepicker-invoice"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    :hide-header="true"
                    locale="en"
                    size="sm"
                    placeholder="Select date"
                    v-model="$v.item.date.$model"
                    :state="validateState('date')"
                  ></b-form-datepicker>
                  <label class="typo__label float_label_item">Date*</label>
                </b-col>
                <b-col sm="12" md="6" class="float_label mb-2">
                  
                    <b-form-input
                      type="text"
                      placeholder="Note"
                      v-model="item.notes"
                      size="sm"
                    ></b-form-input>
                  <label class="typo__label float_label_item">Note</label>
                </b-col> 
              </b-row>
    		    </b-form>
        	</b-col>
        </b-row>
        <b-row>
          <b-col sm="12" xl="9" md="12" lg="10">
            <h2 style="font-weight: 400 !important;font-size: .9rem !important;">Organisation Invoices</h2>
          </b-col>
        </b-row>
        <b-row style="margin-bottom:5rem !important">
          <b-col sm="12" xl="9" md="12" lg="10">
            <div class="dynamic-scroller newpayment">
              <RecycleScroller
                ref="scroller"
                class="scroller"
                :items="invoices"
                :item-size="24"
                key-field="id"
              >
                <template  v-slot="{ item,index }">
                  <div>
                    <div class="tcell">
                      {{ moment(item.date).format('MMM Do YYYY') }}
                    </div>
                    <div class="tcell">
                      <RouterLink style="color:#F98525 !important;font-weight:500 !important;text-decoration:none !important;font-size: 10px !important;" link
                              :to='{name:"showInvoice",params:{id:item.id}}'>{{ item.invoice_no }}</RouterLink>
                    </div>
                    <div class="tcell">
                      <span v-b-tooltip.hover.left :title="item.organisations[0].organisation.name">{{ item.organisations[0].organisation.name }}</span>
                    </div>
                    <div class="tcell">{{ moment(item.due_date).format('MMM Do YYYY') }}</div>
                    <div class="tcell">
                      KES<span style="font-weight:600 !important">{{ item.amount | currency(' ') }}</span>
                    </div>
                    <div class="tcell text-center">
                        <a class="list-action" :id="`popover-target-1-${item.id}-${index}`">
                        <b-icon class="my-0 py-0" style="cursor:pointer;" icon="chevron-down"></b-icon>
                      </a>
                      <b-popover
                        :target="`popover-target-1-${item.id}-${index}`"
                        triggers="hover blur"
                        placement="left"
                        class="dropdown-menu"
                      >
                        <div class="action-dropdown-menu">
                          <button class="action-dropdown-item " type="button"><router-link link :to='{name:"editInvoice",params:{id:item.id}}'><b-icon class="" icon="pencil"></b-icon>&nbsp;  Edit</router-link></button>
                          <button @click="deleteInvoice(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="trash"></b-icon>&nbsp;  Remove</button>
                        </div>
                      </b-popover>
                    </div>
                  </div>
                </template>
              </RecycleScroller>
          </div>
          </b-col>
        </b-row>
      </b-container>

      <div class="flex-footer" :class= "[mini ? 'minipadding' : 'expandpanding']">
        <div>
          <b-button variant="primary" class="primary mx-2" @click="save" :disabled="$v.item.$invalid">
            <b-spinner small v-if="loading2"></b-spinner> Save
          </b-button>
          <b-button variant="secondary" class="secondary">
            <router-link link :to="{name: 'debitnotesList'}" style="color:#333 !important">Close</router-link>
          </b-button>
        </div>
      </div>

    </div>

  </div>
</template>

<style>
  @import "@/assets/table/table";
  .modal-newpayment{
    max-width:750px !important;
  }
  .action-dropdown-menu .action-dropdown-item:hover{
    background:var(--ab-primary);
    color:#fff;
    border-radius:var(--ab-dropdown-border-radius);
  }
  .action-dropdown-menu{
    padding-right: .5rem;
  }
  .newpayment .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-auto-columns: minmax(16.6667%, 16.6667%) !important;
     grid-gap: 0px;
     min-width:100%;
  }
  .newpayment .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }
  .newpayment .scroller {
    height: 45vh !important;
  }
</style>
<style scoped>
  @import "@/assets/table/table_scoped";
  .newpayment .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-auto-columns: minmax(16.6667%, 16.6667%) !important;
     grid-gap: 0px;
  }
  
</style>

<script>
import Pusher from 'pusher-js'
import $ from 'jquery';
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import Vue from 'vue'

import {ability} from '@/services/ability'
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

 export default {
  mixins: [validationMixin],
  name: 'newDebitNote',
  components: { },
  data() {
    return {
      date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      fetchloading: false,
      duedate: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      searchfilter:'',
      valid: true,
      fields: [
         {
          text: 'DATE',
          align: 'start',
          value: 'date',
        },
        { text: 'INVOICE#', value: 'invoiceno' },
        { text: 'ORGANISATION', value: 'organisation' },
        { text: 'DUE DATE', value: 'duedate' },
        { text: 'AMOUNT', value: 'amount' },
        { text: 'ACTION', value: 'action' },
       ],
      item:{
         organisation_id: null,
         invoice_no_id: null,
         amount: "",
         notes: "",
         date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      },
      isBusy: false,
      loading: false,
      loading1: false,
      loading2: false,
      organisations: [],
      modes: [],
      selectinvoices: [],
      invoices: []
    }
  },
  validations: {
    item:{
      amount:{
        required
      },
      organisation_id:{
        required
      },
      date:{
        required
      },
    },
  },
  mounted(){

      let headertitle = `<div class="view-titleheader titlehead" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="theader">${value.text}</div>`;
         });
      headertitle +=`</div>`;
      $('.vue-recycle-scroller').prepend(headertitle)

      this.getData()

  },
  computed: {
    loader() {
      return this.$store.state.tableloader
    },
    mini() {
      return this.$store.state.mini
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.item[name];
      return $dirty ? !$error : null;
    },
    async save(){

        this.loading2 = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let fd = new FormData();
        fd.append('organisation_id',JSON.stringify(this.item.organisation_id))
        fd.append('invoice_no_id',JSON.stringify(this.item.invoice_no_id))
        fd.append('amount',this.item.amount)
        fd.append('notes',this.item.notes)
        fd.append('date',this.date)

        axios.post('/debit-notes', fd, config).then(response=>{

          if(response.data.status == parseInt(200)) {

            let found = this.$store.state.debitnotes.find(data => data.id == response.data.debitnote.id);
            if (!found) {
              this.$store.state.debitnotes.unshift(response.data.debitnote)
            }

            this.$toastpopup('success','Success','Debit note submitted successfully')

            this.$router.push({ name: 'debitnotesList' })
          }
            this.loading2 = false
          })
          .catch(error => {
            this.loading2 = false
          })
      },

      async getData(){
        this.$store.state.tableloader = true
        await axios.get('/new/debit-note').then(response=>{
            this.$store.state.tableloader = false
            if(response.data.status == parseInt(200)) {
              this.organisations = response.data.organisations
            }
        }).catch(error=>{
            this.$store.state.tableloader = false
            console.log(error)
        })
      },

      async selectOrg(id) {
        this.$store.state.tableloader = true
        await axios.get(`/debit-note/invoices/${id}`).then(response=>{
            this.$store.state.tableloader = false
            if(response.data.status == parseInt(200)) {
              this.invoices = response.data.invoices
            }
        }).catch(error=>{
            this.$store.state.tableloader = false
            console.log(error)
        })
      },
  
      clear () {
        this.item.organisation_id = ""
        this.item.invoice_no_id = ""
        this.item.amount = ""
        this.item.notes = ""
        this.item.date = ''
      }

  }
};

</script>
