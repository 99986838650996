<template>
	<div>
	  <!-- client profile modal -->
    <b-modal
      v-model="show"
      size="linkpayment"
      >
      <template #modal-title>
        <div class="w-100">
          <div class="section_title"><span class="title">Link Payment&nbsp;&nbsp;<span style="font-size:14px !important;color:#888888">{{ item.tolinkreceipt }}&nbsp;&nbsp;</span></span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
        </div>
      </template>
      <b-container fluid>
        <b-row class="mx-0 my-0 py-0">
          <b-col
              cols="12"
              sm="12"
              md="12"
            class="float_label"
           >
            
            <multiselect ref="invmultiselect" 
                    v-model="$v.item.invoice_no_id.$model"
                    :state="validateState('invoice_no_id')"
                    :close-on-select="true"
                     track-by="id" label="invoice_no" placeholder="Select invoice" :options="invoices" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.invoice_no }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Inv #</label>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="submit" :disabled="$v.item.$invalid">
            <b-spinner small v-if="loading"></b-spinner> Submit
          </b-button>
      </template>
    </b-modal>
    <!-- end client profile -->
	</div>
</template>

<style>
  .modal-linkpayment{
    max-width:450px !important;
  }
</style>

<script>
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import Vue from 'vue'
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
	name: 'linkpayment',
	components: {
	},
	data: () => ({
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        item:{
           invoice_no_id: null,
           organisationid: null,
           id: null,
           tolinkreceipt: ""
        },
       invoices: []
	}),
  validations: {
    item:{
      invoice_no_id:{
        required
      },
    },
  },
  watch: {
      data: function(newVal, oldVal) {
        if(newVal !== null) {
          this.item.id = newVal.id
          this.item.organisationid = newVal.organisationid
          this.item.tolinkreceipt = newVal.tolinkreceipt
          this.getData()
        }
      },
  },
	methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.item[name];
      return $dirty ? !$error : null;
    },
    async getData(){
        this.$store.state.componentloader = true
        await axios.get(`/payment/invoices/${this.item.organisationid}`).then(response=>{
            this.$store.state.componentloader = false
            if(response.data.status == parseInt(200)) {
              this.invoices = response.data.invoices
            }
        }).catch(error=>{
            this.$store.state.componentloader = false
            console.log(error)
        })
      },
    submit(){
      this.loading = true
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      let fd = new FormData();
      fd.append('id',this.item.id)
      fd.append('invoice_no_id',JSON.stringify(this.item.invoice_no_id))

      axios.post('/invoices/link-payment', fd, config).then(response=>{
        if(response.data.status == parseInt(200)) {

          let index = this.$store.state.payments.findIndex(data => data.id == response.data.payment.id);
          if (index > -1) {
            this.$store.state.payments.splice(index, 1, response.data.payment);
          }

          this.$toastpopup('success','Success','Payment linked successfully')
          this.clear()
          this.show = false
        }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    clear() {
      this.item.invoice_no_id = null
    }
	},
	props: {
	     value: Boolean
	  },
	  computed: {
      loader() {
        return this.$store.state.componentloader
      },
      data() {
        return this.$store.state.linkpaymentdata
      },
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    }
	  },
	mounted() {
    this.getData()
  },
	created() {}
}
</script>