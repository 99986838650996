<template>
	<div>
	  <!-- client profile modal -->
    <b-modal
      v-model="show"
      size="waitings"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Add Waitings</span>
        </div>
      </template>
      <b-container fluid>
        <b-row
          v-for="(waiting, indx) in waitings" 
            :key="indx"
            style="display:flex;align-items:center;justify-content:space-between"
            class="mx-0 my-2"
          >
            <b-col sm="12" md="5" class="float_label mb-3">
              
                <b-form-input
                  placeholder="Start time"
                  required
                  size="sm"
                  type="tel" 
                  v-mask="'##:##'"
                  list="data"
                  v-model="waiting.starttime"
                  :name="`waitings[${indx}][starttime]`"
                ></b-form-input>
                <label class="typo__label float_label_item">Start*</label>
                <datalist id="data">
                    <option v-for="time in $store.state.times" :value="time" />
                </datalist>
            </b-col>
            <b-col sm="12" md="5" class="float_label mb-3">
              
                <b-form-input
                  placeholder="End time"
                  required
                  size="sm"
                  type="tel" 
                  v-mask="'##:##'"
                  list="data"
                  v-model="waiting.endtime"
                  :name="`waitings[${indx}][endtime]`"
                ></b-form-input>
                <label class="typo__label float_label_item">End*</label>
                <datalist id="data">
                    <option v-for="time in $store.state.times" :value="time" />
                </datalist>
            </b-col>
          <b-col
              cols="12"
              sm="12"
              md="2"
              >
              <div style="display:flex;align-items:center;justify-content:space-between">
                <b-icon @click="addWaiting" class="mx-2" icon="plus-circle"></b-icon>
                <b-icon @click="removeWaiting(indx)" icon="x-circle"></b-icon>
              </div>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="submit">
            <b-spinner small v-if="loading"></b-spinner> Submit
          </b-button>
      </template>
    </b-modal>
    <!-- end client profile -->
	</div>
</template>

<style>
  .modal-waitings{
    max-width:500px !important;
  }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'
import VueTimepicker from 'vue2-timepicker'

export default {
	name: 'waiting',
	components: {
    VueTimepicker
	},
	data: () => ({
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        waitings: [
          {
            starttime: "",
            endtime: "",
          }
        ],
	}),
	methods: {
    addWaiting () {
        this.waitings.push({
          starttime: "",
          endtime: "",
        })
      },
      removeWaiting (index) {
        this.waitings.splice(index, 1);
      },
    submit() {
      // if (this.$refs.form.validate()) {

          this.loading = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('waitings',JSON.stringify(this.waitings))
          fd.append('id',this.waitingdetails[0].id)

          axios.post('/booking/savewaitings', fd, config).then(response=>{

            if(response.data.status === parseInt(201)) {
              this.$toastpopup('success','Success',"Waiting time added successfully")
            }

            this.waitings = []
            this.waitings.push({
              starttime: "",
              endtime: "",
            })

            this.show = false
            this.loading = false
            
          })
          .catch(() => {
            this.loading = false
          })

      // }
    }
  },
	props: {
	     value: Boolean,
	     waitingdetails: Array
	  },
	  computed: {
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    }
	  },
	mounted() {},
	created() {}
}
</script>