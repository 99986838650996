<template>
	<div>
	  <!-- assign vehicle modal -->
    <b-modal
      v-model="show"
      size="reportsearch"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Search Report</span>
        </div>
      </template>
      <b-container fluid>
        <b-row>

          <b-col cols="12" sm="12" md="6" class="float_label mb-3">
              <label class="typo__label no_floating">Date*</label>
              <date-range-picker
                ref="picker"
                :showWeekNumbers="showWeekNumbers"
                :showDropdowns="showDropdowns"
                :autoApply="autoApply"
                opens="right"
                :always-show-calendars="alwaysshowcalendars"
                v-model="dateRange"
                @select="updateValues"
              >
                  <template v-slot:input="picker" style="min-width: 100%;">
                      <b-form-input
                        placeholder="Select date range"
                        size="sm"
                        type="text"
                        v-model="rangedate"
                      ></b-form-input>
                  </template>
              </date-range-picker>
              
          </b-col>
          <b-col
            cols="12"
            sm="12"
            md="6"
            class="float_label mb-3"
           >
            
            <multiselect ref="orgmultiselect" 
                    :close-on-select="true"
                    v-model="$v.item.organisation_id.$model"
                    :state="validateState('organisation_id')"
                    :multiple="true"
                     track-by="id" label="name" placeholder="Select organisation" :options="organisations" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Organisation*</label>
          </b-col> 
          <b-col
            cols="12"
            sm="12"
            md="6" class="float_label mb-3"
           >
            
            <multiselect ref="projectmultiselect" 
                    v-model="item.project_id"
                    :multiple="true"
                    :preserve-search="true"
                    :close-on-select="true"
                     track-by="id" label="name" placeholder="Select project" :options="projects" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Project</label>
          </b-col>
          <b-col
            cols="12"
            sm="12"
            md="6" class="float_label mb-3"
           >
            
            <multiselect ref="customermultiselect" 
                    v-model="item.customer_id"
                    :multiple="true"
                    :preserve-search="true"
                    :close-on-select="true"
                     track-by="id" label="name" placeholder="Select staff" :options="customers" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Customer</label>
          </b-col>
          <b-col
            cols="12"
            sm="12"
            md="6" class="float_label mb-3"
           >
            
            <multiselect ref="requestmultiselect" 
                    :preserve-search="true"
                    :close-on-select="true"
                    v-model="$v.item.request_category.$model"
                    :state="validateState('request_category')"
                     placeholder="Select request category" :options="requests" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
            </multiselect>
            <label class="typo__label float_label_item">Request*</label>
          </b-col>
          <b-col
            cols="12"
            sm="12"
            md="6" class="float_label mb-3"
           >
            
            <multiselect ref="statusmultiselect" 
                    :preserve-search="true"
                    :close-on-select="true"
                    v-model="item.status"
                     placeholder="Select status" :options="statuses" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
            </multiselect>
            <label class="typo__label float_label_item">Status</label>
          </b-col>

        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="fetchreport" :disabled="$v.item.$invalid">
            <b-spinner small v-if="loading"></b-spinner> Submit
          </b-button>
      </template>
    </b-modal>
  <!-- end assign vehicle -->
	</div>
</template>
<style>
  .modal-reportsearch{
    max-width:700px !important;
  }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
	name: 'reportdialog',
	components: { DateRangePicker },
	data: () => ({
    valid: true,
    isBusy: false,
    loading: false,
    disabled: false,
    item: {
      request_category: '',
      status: '',
      organisation_id: [],
      project_id: [],
      customer_id: []
     },
    requests: ['Client', 'Delivery'],
    statuses: ['Waiting', 'Active', 'Cancelled', 'Dropped'],
    dateRange: {
      startDate: null,
      endDate: null
     },
    singleDatePicker: true,
    showWeekNumbers: true,
    showDropdowns: true,
    autoApply: true,
    alwaysshowcalendars: true,
    date: "",
    dates: {},
    daterange: {
      start: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      end: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
    },
    rangedate: "",

	}),
  validations: {
    item:{
      organisation_id:{
        required
      },
      request_category: {
        required
      }
    },
  },
	methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.item[name];
      return $dirty ? !$error : null;
    },
    updateValues(val) { 
      this.daterange.start = Vue.filter('date')(val.startDate)
      this.daterange.end = Vue.filter('date')(val.endDate)
      this.rangedate = this.daterange.start+" - "+this.daterange.end
    },
    fetchreport(){
      this.loading = true

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      let fd = new FormData();
      fd.append('id',JSON.stringify(this.item.organisation_id))
      fd.append('request_category',this.item.request_category)
      fd.append('status',this.item.status)
      fd.append('project_id',JSON.stringify(this.item.project_id))
      fd.append('customer_id',JSON.stringify(this.item.customer_id))
      fd.append('start',this.daterange.start)
      fd.append('end',this.daterange.end)

      axios.post('/booking/admin/reports', fd, config).then(response=>{
        this.loading = false
        if(response.data.status == parseInt(200)) {
          this.$store.state.allbookingreports = response.data.bookings
          this.$store.state.vat = response.data.vat
          this.$store.state.reportdata = {
              organisation_id: this.item.organisation_id,
              project_id: this.item.project_id,
              customer_id: this.item.customer_id,
              request_category: this.item.request_category,
              status: this.item.status,
              start: this.daterange.start,
              end: this.daterange.end
            }
        }
        this.show = false
        
      })
      .catch(() => {
        this.loading = false
        console.log("Unexpected error occured")
      })
    },
	},
	props: {
     value: Boolean
  },
  computed: {
    // organisations() {
    //   return this.$store.state.minorganisations
    // },
    organisations() {
      return this.$store.state.reportorganisations
    },
    allcustomers() {
      return this.$store.state.reportcustomers
    },
    projects() {
      if(this.item.organisation_id.length > 0){
        let projects = []
        let data = this.organisations.filter(organisation => this.item.organisation_id.map((val) => val.id ).includes(organisation.id) )
        for (let i in data) {
          if(data[i].projects.length > 0) {
            projects.push(...data[i].projects);
          }
        }

        return projects
      }else{
        return []
      }       
    },
    customers() {
      if(this.item.organisation_id.length > 0){
        let data = this.allcustomers.filter(customer => this.item.organisation_id.includes(customer.organisation_id) )
        return data
      }else{
        return []
      }       
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    },
  },
	mounted() { 
    this.$store.state.allbookingreports = [] 
  },
	created() {
     
    }
}
</script>