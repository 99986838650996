<template>
  <div class="about mt-0">
    <div class="mx-0 px-0" style="position:relative">
      <div class="flex" :class= "[mini ? 'minipadding' : 'expandpanding']">
      	<div class="section_title"><span class="title">Reversals&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
      	<div>
      		<b-button variant="primary" class="primary" size="sm"><router-link link :to="{name:'newPaymentReversal'}">Add Reversal <b-icon class="mr-1" icon="plus"></b-icon></router-link></b-button>
      	</div>
      </div>
      <b-row class="subsection">
      	<b-col sm="12" xl="12" md="12" class="mx-0 px-3">
      		<div class="dynamic-scroller reversals">
            <b-row class="mx-2 mb-2 searchsection" style="display:flex;justify-content:flex-end;align-items:center">
              <div class="px-1" style="width:200px !important;padding-top:0 !important;padding-bottom:0 !important;display:flex;">
                <b-form-input
                    placeholder="Search..."
                    size="sm"
                    v-model="searchfilter"
                  ></b-form-input>
              </div>
            </b-row>
            <div class="empty-state" v-if="allpaymentreversals.length === 0">
              <div class="empty-state__content">
                <div class="empty-state__icon">
                  <img src="@/assets/data.svg" style="width:40%">
                </div>
                <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                <div class="empty-state__message" v-else>No records available.</div>
              </div>
            </div>
      			<RecycleScroller
		          ref="scroller"
		          class="scroller"
		          :items="allpaymentreversals"
		          :item-size="24"
		          key-field="id"
		          >
		          <template  v-slot="{ item,index }">
		            <div>
    					    <div class="tcell"> {{ moment(item.date).format('MMM Do YYYY') }}</div>
                  <div class="tcell">
                    <span style="color:#F98525 !important;font-weight:500 !important;text-decoration:none !important;">{{ item.reversal_no }}</span>
                  </div>
                  <div class="tcell">
                    <div v-if="item.organisation !== null">
                      <span class="badge__secondary" v-b-tooltip.hover.left :title="item.organisation.name">{{ item.organisation.name }}</span>
                    </div>
                    <span v-else>Private</span>
                  </div>
                  <div class="tcell">
                    <RouterLink v-if="item.payment !== null" style="color:#F98525 !important;font-weight:600 !important;text-decoration:none !important;font-size:10px !important;" link
                            :to='{name:"showPayment",params:{id:item.payment.id}}'>{{ item.payment.payment_no }}</RouterLink>
                  </div>
                  <div class="tcell">{{ item.notes }}</div>
                  <div class="tcell">KES<span style="font-weight:500 !important">{{ item.amount | currency(' ') }}</span></div>
    					    <div class="tcell text-center">
    					      	<a class="list-action" :id="`popover-target-1-${item.id}-${index}`">
    						  		<b-icon class="my-0 py-0" style="cursor:pointer;" icon="chevron-down"></b-icon>
    						    </a>
    						    <b-popover
      							  :target="`popover-target-1-${item.id}-${index}`"
      							  triggers="hover blur"
      							  placement="left"
      							  class="dropdown-menu"
      							>
      							  <div class="action-dropdown-menu">
      						  		<button v-if="item.invoice == null"
                              @click="undoreversal(item.id, item.payment.payment_no)" class="action-dropdown-item " type="button"><b-icon class="" icon="arrow-counterclockwise"></b-icon>&nbsp;  Undo Reversal</button>
                        <button class="action-dropdown-item " type="button"><router-link link
                              :to='{name:"editPaymentReversal",params:{id:item.id}}'><b-icon class="" icon="pencil"></b-icon>&nbsp;  Edit</router-link></button>
      						  		<button @click="deleteReversal(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="trash"></b-icon>&nbsp;  Remove</button>
      						  	</div>
      							</b-popover>
    					    </div>
    					  </div>
		          </template>
		        </RecycleScroller>

			  </div>
      	</b-col>
      </b-row>
    </div>

  </div>
</template>

<style>
	@import "@/assets/table/table";
  .modal-reversals{
    max-width:750px !important;
  }
	.action-dropdown-menu .action-dropdown-item:hover{
		background:var(--ab-primary);
		color:#fff;
		border-radius:var(--ab-dropdown-border-radius);
	}
	.action-dropdown-menu{
		padding-right: .5rem;
	}
  .reversals .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-auto-columns: minmax(14.28%, 14.28%) !important;
     grid-gap: 0px;
     min-width:100%;
  }
  .reversals .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }
</style>
<style scoped>
	@import "@/assets/table/table_scoped";
  .reversals .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-auto-columns: minmax(14.28%, 14.28%) !important;
     grid-gap: 0px;
  }
  
</style>

<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';

 export default {
  name: 'paymentReversalsList',
  components: { },
  computed: {
	  mini() {
	    return this.$store.state.mini
	  },
    loader() {
    	return this.$store.state.tableloader
    },
    paymentreversals() {
      return this.$store.state.paymentreversals.filter(item => {

        this.start = 0

        let payment_no = "";
        let reversal_no = "";
        let date = "";
        if(item.date !== null) {
          date = item.date
        }
        if(item.payment !== null) {
          payment_no = item.payment.payment_no
        }
        if(item.reversal_no !== null) {
          reversal_no = item.reversal_no
        }
        return payment_no.toLowerCase().includes(this.searchfilter.toLowerCase()) || date.includes(this.searchfilter) || reversal_no.toLowerCase().includes(this.searchfilter.toLowerCase())
      })
    },
    allpaymentreversals() {
      return this.paymentreversals
    },
  },
  data: () => ({
      searchfilter: '',
      search: '',
      dialog: false,
      loading: false,
      fields: [
       {
        text: 'DATE',
        align: 'start',
        value: 'date',
      },
      { text: 'REVERSAL#', value: 'reversalno' },
      { text: 'ORGANISATION', value: 'organisation' },
      { text: 'PAYMENT NO', value: 'paymentno' },
      { text: 'NOTES', value: 'note' },
      { text: 'AMOUNT', value: 'amount' },
      { text: 'ACTION', value: 'action' },
     ],
  }),

  mounted() {

  	let headertitle = `<div class="view-titleheader titlehead" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="theader">${value.text}</div>`;
         });
      headertitle +=`</div>`;
      $('.vue-recycle-scroller').prepend(headertitle)

  	if(!this.allpaymentreversals || this.allpaymentreversals.length == 0) {
      this.getPaymentReversal()
    }
      
  },
  beforeMount() {
    if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
  },
  serverPrefetch () {
    // return this.getPaymentReversal()
  },
  methods: {
  	async getPaymentReversal(){
      this.$store.state.tableloader = true
      this.$store
        .dispatch('getPaymentReversal')
    },
    deleteReversal(id){
        if(confirm("Are you sure to removed payment ?")){
            axios.delete(`/reversals/${id}`).then(response=>{
              let index = this.$store.state.paymentreversals.findIndex(data => data.id == response.data.reversal.id);
              if (index > -1) {
                this.$store.state.paymentreversals.splice(index, 1);
              }
              this.$toastpopup('success','Success','Reversal removed successfully')
            }).catch(error=>{
                console.log(error)
            })
        }
    },
    undoreversal(id, paymentno) {
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      let fd = new FormData();
      fd.append('id',id)
      fd.append('payment_no',paymentno)

      this.$store.state.tableloader = true

      axios.post('/reversal/undo', fd, config).then(response=>{
        this.$store.state.tableloader = false
        if(response.data.status == parseInt(200)) {

          this.$toastpopup('success','Success','Reversal undone successfully')
        }
        })
        .catch(error => {
          this.$store.state.tableloader = false
        })
    },
  }
};

</script>
