<template>
  <div class="settingswrapper mt-0">
    <div class="mx-0 px-0" style="position:relative">
      <div class="flex" :class= "[mini ? 'minipadding' : 'expandpanding']">
      	<div class="section_title"><span class="title">Logs&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
      </div>
      <b-row class="subsection pt-5" style="display:flex;align-items:center;justify-content:center">
      	<b-col sm="12" xl="9" md="12" lg="9" class="mx-0 px-3">
          <b-card no-body class="logs">

            <b-tabs pills card vertical nav-wrapper-class="w-25">
              <b-tab title="Invoices" active @click="onchange('inv')">
                <b-card-text class="px-0 py-0 mx-0">
                  <b-row>
                    <b-col cols="12" class="mt-2">
                      <div class="">
                        <div class="empty-state" v-if="logs.length === 0">
                          <div class="empty-state__content">
                            <div class="empty-state__icon" style="width:100px !important;height:100px !important">
                              <img src="@/assets/data.svg" style="width:40%">
                            </div>
                            <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                            <div class="empty-state__message" v-else>No records available.</div>
                          </div>
                        </div>
                        <ul class="content-list" id="itemList" v-if="logs.length > 0">
                          <li v-for="(invoice,index) in itemsForList" :key="index">
                            <div>
                              <span>{{ moment(invoice.created_at).format('Do MMM YYYY H:m:s') }}</span>
                              <span style="font-weight:normal !important;text-transform:capitalize">{{ invoice.log }} - #{{ invoice.invoice !== null ? invoice.invoice.invoice_no : '' }}</span>
                            </div>
                            <div><em>Action By: {{ invoice.user !== null ? invoice.user.name : '' }}</em></div>
                          </li>
                        </ul>
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          aria-controls="itemList"
                          v-if="logs.length > 0"
                        ></b-pagination>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>

              <b-tab title="Credit Note" @click="onchange('cr')">
                <b-card-text class="px-0 py-0 mx-0">
                  <b-row>
                    <b-col cols="12" class="mt-2">
                      <div class="">
                        <div class="empty-state" v-if="logs.length === 0">
                          <div class="empty-state__content">
                            <div class="empty-state__icon" style="width:100px !important;height:100px !important">
                              <img src="@/assets/data.svg" style="width:40%">
                            </div>
                            <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                            <div class="empty-state__message" v-else>No records available.</div>
                          </div>
                        </div>
                        <ul class="content-list" id="itemList" v-if="logs.length > 0">
                          <li v-for="(credit,index) in itemsForList" :key="index">
                            <div>
                              <span>{{ moment(credit.created_at).format('Do MMM YYYY H:m:s') }}</span>
                              <span style="font-weight:normal !important;text-transform:capitalize">{{ credit.log }} - #{{ credit.creditnote !== null ? credit.creditnote.credit_note_no : '' }}</span>
                            </div>
                            <div><em>Action By: {{ credit.user !== null ? credit.user.name : '' }}</em></div>
                          </li>
                        </ul>
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          aria-controls="itemList"
                          v-if="logs.length > 0"
                        ></b-pagination>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>

              <b-tab title="Debit Note" @click="onchange('dr')">
                <b-card-text class="px-0 py-0 mx-0">
                  <b-row>
                    <b-col cols="12" class="mt-2">
                      <div class="">
                        <div class="empty-state" v-if="logs.length === 0">
                          <div class="empty-state__content">
                            <div class="empty-state__icon" style="width:100px !important;height:100px !important">
                              <img src="@/assets/data.svg" style="width:40%">
                            </div>
                            <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                            <div class="empty-state__message" v-else>No records available.</div>
                          </div>
                        </div>
                        <ul class="content-list" id="itemList" v-if="logs.length > 0">
                          <li v-for="(debit,index) in itemsForList" :key="index">
                            <div>
                              <span>{{ moment(debit.created_at).format('Do MMM YYYY H:m:s') }}</span>
                              <span style="font-weight:normal !important;text-transform:capitalize">{{ debit.log }} - #{{ debit.debitnote !== null ? debit.debitnote.debit_note_no : '' }}</span>
                            </div>
                            <div><em>Action By: {{ debit.user !== null ? debit.user.name : '' }}</em></div>
                          </li>
                        </ul>
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          aria-controls="itemList"
                          v-if="logs.length > 0"
                        ></b-pagination>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>

              <b-tab title="Payments" @click="onchange('pymnt')">
                <b-card-text class="px-0 py-0 mx-0">
                  <b-row>
                    <b-col cols="12" class="mt-2">
                      <div class="">
                        <div class="empty-state" v-if="logs.length === 0">
                          <div class="empty-state__content">
                            <div class="empty-state__icon" style="width:100px !important;height:100px !important">
                              <img src="@/assets/data.svg" style="width:40%">
                            </div>
                            <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                            <div class="empty-state__message" v-else>No records available.</div>
                          </div>
                        </div>
                        <ul class="content-list" id="itemList" v-if="logs.length > 0">
                          <li v-for="(payment,index) in itemsForList" :key="index">
                            <div>
                              <span>{{ moment(payment.created_at).format('Do MMM YYYY H:m:s') }}</span>
                              <span style="font-weight:normal !important;text-transform:capitalize">{{ payment.log }} - #{{ payment.payment !== null ? payment.payment.payment_no : '' }}</span>
                            </div>
                            <div><em>Action By: {{ payment.user !== null ? payment.user.name : '' }}</em></div>
                          </li>
                        </ul>
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          aria-controls="itemList"
                          v-if="logs.length > 0"
                        ></b-pagination>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>

              <b-tab title="Reversals" @click="onchange('rev')">
                <b-card-text class="px-0 py-0 mx-0">
                  <b-row>
                    <b-col cols="12" class="mt-2">
                      <div class="">
                        <div class="empty-state" v-if="logs.length === 0">
                          <div class="empty-state__content">
                            <div class="empty-state__icon" style="width:100px !important;height:100px !important">
                              <img src="@/assets/data.svg" style="width:40%">
                            </div>
                            <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                            <div class="empty-state__message" v-else>No records available.</div>
                          </div>
                        </div>
                        <ul class="content-list" id="itemList" v-if="logs.length > 0">
                          <li v-for="(reversal,index) in itemsForList" :key="index">
                            <div>
                              <span>{{ moment(reversal.created_at).format('Do MMM YYYY H:m:s') }}</span>
                              <span style="font-weight:normal !important;text-transform:capitalize">{{ reversal.log }} - #{{ reversal.reversal !== null ? reversal.reversal.reversal_no : '' }}</span>
                            </div>
                            <div><em>Action By: {{ reversal.user !== null ? reversal.user.name : '' }}</em></div>
                          </li>
                        </ul>
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          aria-controls="itemList"
                          v-if="logs.length > 0"
                        ></b-pagination>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>
            </b-tabs>

          </b-card>
      	</b-col>
      </b-row>
    </div>

  </div>
</template>

<style>
	@import "@/assets/table/table";
	.action-dropdown-menu .action-dropdown-item:hover{
		background:var(--ab-primary);
		color:#fff;
		border-radius:var(--ab-dropdown-border-radius);
	}
	.action-dropdown-menu{
		padding-right: .5rem;
	}

  .customer .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }
  .logs table td{
    padding:.1rem .8rem !important;
  }
  .logs .card-body{
    padding:1.2rem 1rem !important;
  }
  .logs .card-header{
    padding:0 !important;
  }
  .logs .nav-link.active{
    color:#F9760B !important;
    border-right: 0 !important;
    background:#fff !important;
  }
</style>
<style scoped>
	@import "@/assets/table/table_scoped";
  .customer .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-template-columns: 17.28% 8.28% 18.28% 10.28% 23.28% 10.28% 12.28%;
     grid-gap: 0px;
  }
  
</style>

<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';

 export default {
  name: 'Logs',
  components: {},
  computed: {
	  mini() {
	    return this.$store.state.mini
	  },
    loader() {
    	return this.$store.state.tableloader
    },
    ratecharges() {
      return this.$store.state.settingsratecharges
    },
    logs() {
      return this.$store.state.logs
    },
    itemsForList() {
      return this.logs.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage,
      );
    },
    rows() {
      return this.logs.length
    },
  },
  data: () => ({
      bulksmsdialog: false,
      waitingtimedialog: false,
      vatdialog: false,
      searchfilter: '',
      search: '',
      loading: false,
      fields: [
        { text: 'Name', value: 'name' },
        { text: 'Telephone', value: 'telephone' },
        { text: 'Email', value: 'email' },
        { text: 'Account Type', value: 'account' },
        { text: 'Organisation', value: 'organisation' },
        { text: 'Status', value: 'status' },
        { text: 'Action', value: 'action' },
       ],
       item: {
        apikey: '',
        partnerid: '',
        shortcode: '',
        one_trip_rate:"",
        round_trip_rate:"",
        type_id: "",
        rate: "",
        minutes: "",
        vat: ""
       },
       perPage: 15,
      currentPage: 1
  }),

  mounted() {
  	this.getLogs()
  },
  beforeMount() {
    if(!ability.can('view-reports', 'all')) {
      this.$router.push({ name: 'Dashboard' })
      return
    }
  },

  methods: {

    onchange(val) {
      this.$store.state.logs = []
      this.$store.state.tableloader = true
      this.$store
        .dispatch('getLogs',val)
    },

    getLogs(){
      this.$store.state.tableloader = true
      this.$store
        .dispatch('getLogs','inv')
    },

  }
};

</script>
