<template>
  <div class="about mt-0">
    <div class="mx-0 px-0" style="position:relative">
      <div class="flex" :class= "[mini ? 'minipadding' : 'expandpanding']">
      	<div class="section_title"><span class="title">Invoices&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
      	<div>
      		<b-button variant="primary" class="primary" size="sm"><router-link link :to="{name:'newInvoice'}">Add Invoice <b-icon class="mr-1" icon="plus"></b-icon></router-link></b-button>
      	</div>
      </div>
      <b-row class="subsection">
      	<b-col sm="12" xl="12" md="12" class="mx-0 px-3">
      		<div class="dynamic-scroller invoice">
            <b-row class="mx-2 mb-2 searchsection" style="display:flex;justify-content:flex-end;align-items:center">
              <div class="px-1" style="width:200px !important;padding-top:0 !important;padding-bottom:0 !important;display:flex;">
                <b-form-input
                    placeholder="Search..."
                    size="sm"
                    v-model="searchfilter"
                  ></b-form-input>
              </div>
            </b-row>
            <div class="empty-state" v-if="allinvoices.length === 0">
              <div class="empty-state__content">
                <div class="empty-state__icon">
                  <img src="@/assets/data.svg" style="width:40%">
                </div>
                <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                <div class="empty-state__message" v-else>No records available.</div>
              </div>
            </div>
      			<RecycleScroller
		          ref="scroller"
		          class="scroller"
		          :items="allinvoices"
		          :item-size="24"
		          key-field="id"
		        >
		          <template  v-slot="{ item,index }">
		            <div>
    					    <div class="tcell"> {{ moment(item.date).format('MMM Do YYYY') }}</div>
                  <div class="tcell">
                    <RouterLink style="color:#F98525 !important;font-weight:500 !important;text-decoration:none !important;font-size: 10px !important;" link
                            :to='{name:"showInvoice",params:{id:item.id}}'>{{ item.invoice_no }}</RouterLink>
                          </div>
                  <div class="tcell">
                    <div v-if="item.organisations.length > 0">
                      <span class="badge__secondary" v-b-tooltip.hover.left :title="organisation.organisation.name" v-for="(organisation,index) in item.organisations" :key="index">{{ organisation.organisation.name }}</span>
                    </div>
                    <span v-else>Private</span>
                  </div>
                  <div class="tcell">{{ item.status }}</div>
                  <div class="tcell">{{ moment(item.due_date).format('MMM Do YYYY') }}</div>
                  <div class="tcell">KES <span style="font-weight:600 !important">{{ item.amount | currency(' ') }}</span></div>
    					    <div class="tcell text-center">
    					      	<a class="list-action" :id="`popover-target-1-${item.id}-${index}`">
    						  		<b-icon class="my-0 py-0" style="cursor:pointer;" icon="chevron-down"></b-icon>
    						    </a>
    						    <b-popover
      							  :target="`popover-target-1-${item.id}-${index}`"
      							  triggers="hover blur"
      							  placement="left"
      							  class="dropdown-menu"
      							>
      							  <div class="action-dropdown-menu">
      						  		<button @click="reverseInvoice(item.id,item.invoice_no)" class="action-dropdown-item " type="button"><b-icon class="" icon="arrow-counterclockwise"></b-icon>&nbsp;  Reverse Invoice</button>
                        <button class="action-dropdown-item " type="button"><router-link link :to='{name:"editInvoice",params:{id:item.id}}'><b-icon class="" icon="pencil"></b-icon>&nbsp;  Edit</router-link></button>
      						  		<button @click="deleteInvoice(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="trash"></b-icon>&nbsp;  Remove</button>
      						  	</div>
      							</b-popover>
    					    </div>
    					  </div>
		          </template>
		        </RecycleScroller>

			  </div>
      	</b-col>
      </b-row>
    </div>

    <!-- components -->
    <Reverse v-model="reverseinvoicesdialog"/>

  </div>
</template>

<style>
	@import "@/assets/table/table";
  .modal-invoice{
    max-width:750px !important;
  }
	.action-dropdown-menu .action-dropdown-item:hover{
		background:var(--ab-primary);
		color:#fff;
		border-radius:var(--ab-dropdown-border-radius);
	}
	.action-dropdown-menu{
		padding-right: .5rem;
	}
  .invoice .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-auto-columns: minmax(14.28%, 14.28%) !important;
     grid-gap: 0px;
     min-width:100%;
  }
  .invoice .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }
</style>
<style scoped>
	@import "@/assets/table/table_scoped";
  .invoice .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-auto-columns: minmax(14.28%, 14.28%) !important;
     grid-gap: 0px;
  }
  
</style>

<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';
import Reverse from '@/components/invoices/reverse'

 export default {
  name: 'invoiceList',
  components: {
    Reverse
  },
  computed: {
	  mini() {
	    return this.$store.state.mini
	  },
    loader() {
    	return this.$store.state.tableloader
    },
    invoices() {
      return this.$store.state.invoices.filter(item => {

        this.start = 0

        let invoice_no = "";
        let organisation = "";
        let amount = "";
        let date = "";
        // if(item.organisation !== null) {
        //   organisation = item.organisation.name
        // }
        if(item.invoice_no !== null) {
          invoice_no = item.invoice_no
        }
        if(item.amount !== null) {
          amount = item.amount
        }
        if(item.date !== null) {
          date = item.date
        }
        return invoice_no.toLowerCase().includes(this.searchfilter.toLowerCase()) || date.includes(this.searchfilter)
      })
    },
    allinvoices() {
      return this.invoices
    },
    organisations() {
      return this.$store.state.organisations
    },
  },
  data: () => ({
      searchfilter: '',
      search: '',
      reverseinvoicesdialog: false,
      loading: false,
      fields: [
         {
          text: 'DATE',
          align: 'start',
          value: 'date',
        },
        { text: 'INVOICE#', value: 'invoiceno' },
        { text: 'ORGANISATION NAME', value: 'organisation' },
        { text: 'STATUS', value: 'status' },
        { text: 'DUE DATE', value: 'duedate' },
        { text: 'AMOUNT', value: 'amount' },
        { text: 'ACTION', value: 'action' },
       ],
       reversefields: [
         {
          text: 'DATE',
          align: 'start',
          value: 'date',
        },
        { text: 'Time', value: 'time' },
        { text: 'Ref', value: 'ref' },
        { text: 'From', value: 'from' },
        { text: 'To', value: 'to' },
        { text: 'Passenger', value: 'passenger' },
        { text: 'Amount', value: 'amount' },

       ],
      item:{
        invoice_id: "",
        invoice_no: ""
      },
  }),

  mounted() {

  	let headertitle = `<div class="view-titleheader titlehead" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="theader">${value.text}</div>`;
         });
      headertitle +=`</div>`;
      $('.vue-recycle-scroller').prepend(headertitle)

  	if(!this.allinvoices || this.allinvoices.length == 0) {
      this.getInvoices()
    }
      
  },
  beforeMount() {
    if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
  },
  serverPrefetch () {
    // return this.getInvoices()
  },
  methods: {
  	getInvoices(){
      this.$store.state.tableloader = true
      this.$store
        .dispatch('getInvoices')
    },

    deleteInvoice(id){
        if(confirm("Are you sure to delete invoice ?")){
            axios.delete(`/invoices/${id}`).then(response=>{
              let index = this.$store.state.invoices.findIndex(data => data.id == response.data.invoice.id);
              if (index > -1) {
                this.$store.state.invoices.splice(index, 1);
              }
              this.$toastpopup('success','Success','Invoice removed successfully')
            }).catch(error=>{
                console.log(error)
            })
        }
    },
    reverseInvoice(id,invoice_no){

      this.$store.state.reversebookings = []

      this.$store.state.reversedetails = {
        "invoice_id":id,
        "invoice_no":invoice_no
      }

      this.reverseinvoicesdialog = true
    }
  }
};

</script>
