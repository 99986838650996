<template>
  <div class="showinvoicesdetails mt-0">
    <div class="mx-0 px-0" style="position:relative">
      <b-row class="my-0 py-0">
        <b-col cols="12" class="invoice-content">
          <div class="column list-column">
            <div class="fill header list-header d-flex">
              <div class="section_title"><span class="title">All Payments&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
            </div>
            <div class="fill body list-body d-flex scroll-y">
              <div class="scroll-y scrollbox">
                <ul class="content-list" id="itemList">
                  <li @click="viewPayment(payment.id)" v-for="(payment,index) in itemsForList" :key="index">
                    <div class="list-title">
                      <span>{{ payment.organisation !== null ? payment.organisation.name : "Private" }}</span>
                      <span>KES{{ payment.paidamount | currency('') }}</span>
                    </div>
                    <div class="list-subtitle">
                      <span><a href="" style="color:#F98525 !important;text-decoration:none !important;font-size: 11.5px !important;font-weight:500 !important">{{ payment.payment_no }}</a> | {{ moment(payment.date).format('MMM Do, YYYY') }}</span>
                      <span style="color:#44C189" v-if="payment.invoice !== null">CLOSED</span>
                      <span v-else>OPEN</span>
                    </div>
                  </li>
                </ul>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="itemList"
                  v-if="allpayments.length > 0"
                ></b-pagination>
              </div>
            </div>
          </div>
          <div class="column content-column">
            <div class="fill header list-header d-flex">
              <div class="section_title"><span class="title" style="color:#888888">{{ details !== null ? details.payment_no : "" }}&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader1" label="Loading..."></b-spinner></div>
            </div>
            <div class="fill subheader list-subheader d-flex">
              <b-button variant="light" class="light" size="sm"><router-link link :to='{name:"editPayment",params:{id:item.id}}'><b-icon class="mr-1" icon="pencil"></b-icon> Edit</router-link></b-button>
              <b-button variant="light" class="light" size="sm" id="popover-invoice-show-more"><b-icon class="mr-1" icon="printer"></b-icon> Print</b-button>
              <b-popover
                target="popover-invoice-show-more"
                triggers="hover blur"
                placement="bottom"
                class="dropdown-menu"
              >
                <div class="action-dropdown-menu">
                  <button  class="action-dropdown-item" @click="downloadpdf" type="button"><b-icon class="mr-1" icon="file-earmark-pdf"></b-icon>&nbsp;Pdf</button>
                </div>
              </b-popover>
              <b-button v-if="details !== null && details.invoice === null" variant="light" class="light" size="sm" @click="linkpayment(details.id,details.organisation_id,details.payment_no)"><b-icon class="mr-1" icon="record-circle"></b-icon> Link Payment</b-button>
            </div>
            <div class="fill body list-body d-flex scroll-y">
              <div class="scroll-y scrollbox">
                <div class="invoice-template" v-if="details !== null">
                  <div class="ribbon ribbon-top-right green" v-if="details.invoice !== null">
                    <span>CLOSED</span>
                  </div>
                  <div v-else class="ribbon ribbon-top-right grey">
                    <span>OPEN</span>
                  </div>
                  <b-row class="mt-4">
                    <b-col cols="12" md="6">
                      <img src="/assets/images/logo/logo.png" class="mt-2" style="width:60% !important;">
                    </b-col>
                    <b-col cols="12" md="6" style="text-align:right">
                      <p class="mt-2" style="font-size:.75rem !important;line-height:1.6">P.O Box 6137-00200-Nairobi</br>
                      Kandara Road, Hse 9, Kileleshwa</br>
                      Telephone: 0202011846/2011824</br>
                      Cellphone: 0721400422/0728600654/<br>0733523047/0735009952</br>
                      Email: <a href="mailto:info@absoluteadventuresafaris.com" target="_blank">info@absoluteadventuresafaris.com</a></br>
                      PIN NO: P051199717F | VAT NO: P051199717F</p>
                    </b-col>
                  </b-row>
                  <hr class="mt-0">
                  <b-row class="mb-3 mt-0 pt-2 text-center"><b-col cols="12"><span class="title"><u>PAYMENT RECEIPT</u></span></b-col></b-row>

                  <b-row>
                    <b-col cols="12" sm="12" lg="7" md="6">
                      <p style="margin-top:2rem !important;">
                        <table>
                          <tr>
                            <td style="padding:.4rem !important;width:200px !important">Payment #</td>
                            <td style="border-bottom:1px solid #dedede !important">{{ details.payment_no }}</td>
                          </tr>
                          <tr>
                            <td style="padding:.4rem !important;width:200px !important">Payment Date</td>
                            <td style="border-bottom:1px solid #dedede !important">{{ moment(details.date).format('Do MMM YYYY') }}</td>
                          </tr>
                          <tr>
                            <td style="padding:.4rem !important;width:200px !important">Reference Number</td>
                            <td style="border-bottom:1px solid #dedede !important">{{ details.reference_no }}</td>
                          </tr>
                          <tr>
                            <td style="padding:.4rem !important;width:200px !important">Payment Mode</td>
                            <td style="border-bottom:1px solid #dedede !important">{{ details.mode.name }}</td>
                          </tr>
                        </table>
                      </p>
                    </b-col>
                    <b-col cols="12" sm="12" lg="5" md="6" class="mt-5" style="display:flex;justify-content:center;align-items:center">
                      <div style="width:100%;height:140px !important;background:#78ae54;display:flex;justify-content:center;align-items:center;flex-direction:column;color:#fff">
                        <small style="font-size:.9rem !important">Amount Received</small>
                        <p style="font-size:1.4rem !important">KES{{ details.paidamount | currency(' ') }}</p>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="6">
                      <p style="font-size:.92rem !important;margin-top:2rem !important">Bill To</p>
                      <p style="color:#F98525;margin-top:-.8rem !important"><span style="font-size:11px !important;text-transform:uppercase !important">{{ details.organisation.name }}</span></p>
                    </b-col>
                  </b-row>
                  <b-row v-if="details.invoice !== null" class="mt-3 pb-2">
                    <b-col cols="12" lg="12">
                      <hr class="mt-0">
                    </b-col>
                    <b-col cols="12" lg="12" class="mt-1">
                      <span style="font-weight:500 !important" class="title mb-2">Payment for</span>
                      <p>
                        <table>
                          <tr style="background:#eff0f1">
                            <td style="padding:.7rem !important;font-size:12px !important;font-weight:500 !important">Invoice Number</td>
                            <td style="padding:.7rem !important;font-size:12px !important;font-weight:500 !important">Invoice Date</td>
                            <td style="padding:.7rem !important;font-size:12px !important;font-weight:500 !important">Invoice Amount</td>
                            <td style="padding:.7rem !important;font-size:12px !important;font-weight:500 !important">Payment Amount</td>
                          </tr>
                          <tr>
                            <td style="padding:.7rem !important;font-size:12px !important">{{ details.invoice.invoice_no }}</td>
                            <td style="padding:.7rem !important;font-size:12px !important">{{ moment(details.invoice.date).format('Do MMM, YYYY') }}</td>
                            <td style="padding:.7rem !important;font-size:12px !important">KES{{ details.invoice.amount | currency(' ') }}</td>
                            <td style="padding:.7rem !important;font-size:12px !important">KES{{ details.paidamount | currency(' ') }}</td>
                          </tr>
                        </table>
                      </p>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

    </div>

    <!-- components -->
    <Search v-model="searchdialog"/>
    <LinkPayment v-model="paymentdialog"/>

  </div>
</template>

<style>
	@import "@/assets/table/table";
  .modal-showpayment{
    max-width:750px !important;
  }
	.action-dropdown-menu .action-dropdown-item:hover{
		background:var(--ab-primary);
		color:#fff;
		border-radius:var(--ab-dropdown-border-radius);
	}
	.action-dropdown-menu{
		padding-right: .5rem;
	}
  .showpayment .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-auto-columns: minmax(11.1111%, 11.1111%) !important;
     grid-gap: 0px;
     min-width:100%;
  }
  .showpayment .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }

</style>
<style scoped>
	@import "@/assets/table/table_scoped";
  .showpayment .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-auto-columns: minmax(11.1111%, 11.1111%) !important;
     grid-gap: 0px;
  }

  .showpayment .scroller{
    height:30vh !important;
  }
  
</style>

<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';
import { format, parseISO } from 'date-fns'

import LinkPayment from '@/components/payments/linkpayment'

 export default {
  name: 'showPayment',
  components: { LinkPayment },
  computed: {
	  mini() {
	    return this.$store.state.mini
	  },
    loader() {
    	return this.$store.state.tableloader
    },
    loader1() {
      return this.$store.state.tableloader1
    },
    payments() {
      return this.$store.state.showpayments
    },
    allpayments() {
      return this.payments
    },
    details() {
      return this.$store.state.paymentdetail
    },
    rows() {
      return this.allpayments.length
    },
    itemsForList() {
      return this.allpayments.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage,
      );
    }
  },
  data: () => ({
      perPage: 15,
      currentPage: 1,
      daterange: {
        start: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        end: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
      },
      searchfilter: '',
      search: '',
      dialog: false,
      searchdialog: false,
      loading: false,
      paymentdialog: false,
      fields: [
         {
          text: '#',
          align: 'start',
          value: '#',
        },
        { text: 'Date', value: 'date' },
        { text: 'Time', value: 'time' },
        { text: 'Ref', value: 'ref' },
        { text: 'From', value: 'from' },
        { text: 'To', value: 'to' },
        { text: 'Passenger', value: 'passenger' },
        { text: 'RequestType', value: 'requesttype' },
        { text: 'VehicleType', value: 'vehicletype' },
        { text: 'Distance(Km)', value: 'distance' },
        { text: 'Amount', value: 'amount' },
       ],
      item:{
         id: 0,
         organisationid: null,
         tolinkreceipt: ""
      },
  }),

  mounted() {

    this.item.id = this.$route.params.id
    this.getDetails()
    if(!this.allpayments || this.allpayments.length == 0) {
      this.getpayments()
    }
      
  },
  beforeMount() {
    if(!ability.can('organisation-management', 'all')) {
      this.$router.push({ name: 'Dashboard' })
      return
    }
  },
  serverPrefetch () {},
  watch: {
    details: function(newVal, oldVal) {},
  },
  methods: {
    linkpayment(id,organisationId,paymentNo) {
      this.$store.state.linkpaymentdata = {
        id: id,
        organisationid: organisationId,
        tolinkreceipt: paymentNo
      }
      this.paymentdialog = true
    },
    viewPayment(id) {
      this.item.id = id
      this.getDetails()
    },
    async getpayments(){
      this.$store.state.tableloader = true
      this.$store
        .dispatch('getShowPayments')
    },
    async getDetails() {
      this.$store.state.tableloader1 = true
      await axios.get(`/viewsinglepayment/${this.item.id}`).then(response=>{
        this.$store.state.tableloader1 = false
          if(response.data.status == parseInt(200)) {
            this.$store.state.paymentdetail = response.data.payment
          }
      }).catch(error=>{
        this.$store.state.tableloader1 = false
          console.log(error)
      })
    },
    async downloadpdf() {
      this.$store.state.tableloader1 = true
      
      const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' };
        let fd = new FormData();
        fd.append('id',this.item.id)

      axios.post('/payment/download', fd, config).then(response=>{
       this.$store.state.tableloader1 = false
       var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
       var fileLink = document.createElement('a');
       fileLink.href = fileURL;
       fileLink.setAttribute('download', `${this.details.organisation.name}-${this.details.payment_no}.pdf`);
       document.body.appendChild(fileLink);
       fileLink.click();
        
      })
      .catch(error => {
        this.$store.state.tableloader1 = false
        console.log(error)
      })
    },
  }
};

</script>
