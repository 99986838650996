<template>
  <div class="about mt-0">
    <div class="mx-0 px-0">
      <div class="flex" :class= "[mini ? 'minipadding' : 'expandpanding']">
      	<div class="section_title"><span class="title">Add Reversal&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
      	<div>
      	</div>
      </div>
      <b-container fluid class="subsection">
        <b-row>
        	<b-col sm="12" xl="9" md="12" lg="10">
        		<b-form style="background:var(--vt-c-white);" class="px-3 py-3 mb-4">
              <b-row>
                <b-col
                    cols="12"
                    sm="12"
                    md="6"
                  class="float_label mb-3"
                 >
                  
                  <multiselect ref="orgmultiselect" 
                          v-model="$v.item.organisation_id.$model"
                          :state="validateState('organisation_id')"
                          :close-on-select="true"
                          @input="(event) => selectOrg(event.id)"
                           track-by="id" label="name" placeholder="Select organisation" :options="organisations" :searchable="true" :allow-empty="true" :show-labels="false">
                    <span slot="noResult">Oops! Record not found.</span>
                    <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                  </multiselect>
                  <label class="typo__label float_label_item">Organisation*</label>
                </b-col> 
                <b-col
                    cols="12"
                    sm="12"
                    md="6"
                  class="float_label mb-3"
                 >
                  <multiselect ref="payment_idmultiselect" 
                  v-model="$v.item.payment_id.$model"
                          :state="validateState('payment_id')"
                          :close-on-select="true"
                          @input="(event) => selectPayment(event.id)"
                           track-by="id" label="payment_no" placeholder="Select invoice" :options="payments" :searchable="true" :allow-empty="true" :show-labels="false">
                    <span slot="noResult">Oops! Record not found.</span>
                    <template slot="singleLabel" slot-scope="{ option }">{{ option.payment_no }}</template>
                  </multiselect>
                  <label class="typo__label float_label_item">Payment #*</label>
                </b-col>
                <b-col sm="12" md="6" class="float_label mb-2">
                    <b-form-input
                      type="text"
                      placeholder="Amount"
                      v-model="$v.item.amount.$model"
                      :state="validateState('amount')"
                      size="sm"
                    ></b-form-input>
                  <label class="typo__label float_label_item">Amount*</label>
                </b-col> 
                <b-col cols="12" sm="12" md="6" class="float_label mb-2">
                  
                  <b-form-datepicker
                    id="datepicker-invoice"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    :hide-header="true"
                    locale="en"
                    size="sm"
                    placeholder="Select date"
                    v-model="$v.item.date.$model"
                    :state="validateState('date')"
                  ></b-form-datepicker>
                  <label class="typo__label float_label_item">Date*</label>
                </b-col>
                <b-col sm="12" md="6" class="float_label mb-2">
          
                    <b-form-input
                      type="text"
                      placeholder="Note"
                      v-model="item.notes"
                      size="sm"
                    ></b-form-input>
                  <label class="typo__label float_label_item">Note</label>
                </b-col> 
              </b-row>
    		    </b-form>
        	</b-col>
        </b-row>
      </b-container>

      <div class="flex-footer" :class= "[mini ? 'minipadding' : 'expandpanding']">
        <div>
          <b-button variant="primary" class="primary mx-2" @click="save" :disabled="$v.item.$invalid">
            <b-spinner small v-if="loading1"></b-spinner> Save
          </b-button>
          <b-button variant="secondary" class="secondary">
            <router-link link :to="{name: 'paymentReversalsList'}" style="color:#333 !important">Close</router-link>
          </b-button>
        </div>
      </div>

    </div>

  </div>
</template>

<style>
  @import "@/assets/table/table";
  .modal-newpayment{
    max-width:750px !important;
  }
  .action-dropdown-menu .action-dropdown-item:hover{
    background:var(--ab-primary);
    color:#fff;
    border-radius:var(--ab-dropdown-border-radius);
  }
  .action-dropdown-menu{
    padding-right: .5rem;
  }
  .newpayment .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-auto-columns: minmax(16.6667%, 16.6667%) !important;
     grid-gap: 0px;
     min-width:100%;
  }
  .newpayment .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }
  .newpayment .scroller {
    height: 45vh !important;
  }
</style>
<style scoped>
  @import "@/assets/table/table_scoped";
  .newpayment .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-auto-columns: minmax(16.6667%, 16.6667%) !important;
     grid-gap: 0px;
  }
  
</style>

<script>
import Pusher from 'pusher-js'
import $ from 'jquery';
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import Vue from 'vue'

import {ability} from '@/services/ability'
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

 export default {
  mixins: [validationMixin],
  name: 'newPaymentReversal',
  components: { },
  data() {
    return {
      date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      fetchloading: false,
      duedate: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      searchfilter:'',
      valid: true,
      fields: [
         {
          text: 'DATE',
          align: 'start',
          value: 'date',
        },
        { text: 'INVOICE#', value: 'invoiceno' },
        { text: 'ORGANISATION', value: 'organisation' },
        { text: 'DUE DATE', value: 'duedate' },
        { text: 'AMOUNT', value: 'amount' },
        { text: 'ACTION', value: 'action' },
       ],
      item:{
         organisation_id: null,
         payment_id: null,
         amount: "",
         notes: "",
         date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      },
      isBusy: false,
      loading: false,
      loading1: false,
      loading2: false,
      organisations: [],
      payments: [],
    }
  },
  validations: {
    item:{
      amount:{
        required
      },
      organisation_id:{
        required
      },
      payment_id:{
        required
      },
      date:{
        required
      },
    },
  },
  mounted(){

      let headertitle = `<div class="view-titleheader titlehead" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="theader">${value.text}</div>`;
         });
      headertitle +=`</div>`;
      $('.vue-recycle-scroller').prepend(headertitle)

      this.getData()

  },
  computed: {
    loader() {
      return this.$store.state.tableloader
    },
    mini() {
      return this.$store.state.mini
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.item[name];
      return $dirty ? !$error : null;
    },
    async save(){

        this.loading1 = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let fd = new FormData();
        fd.append('organisation_id',JSON.stringify(this.item.organisation_id))
        fd.append('payment_id',JSON.stringify(this.item.payment_id))
        fd.append('amount',this.item.amount)
        fd.append('notes',this.item.notes)
        fd.append('date',this.item.date)

        axios.post('/reversals', fd, config).then(response=>{

          if(response.data.status == parseInt(200)) {

            let found = this.$store.state.paymentreversals.find(data => data.id == response.data.reversal.id);
            if (!found) {
              this.$store.state.paymentreversals.unshift(response.data.reversal)
            }

            this.$toastpopup('success','Success','Reversal submitted successfully')

            this.$router.push({ name: 'paymentReversalsList' })
          }
            this.loading1 = false
          })
          .catch(error => {
            this.loading1 = false
          })
      },

      async getData(){
        this.$store.state.tableloader = true
        await axios.get('/new/reversal').then(response=>{
            this.$store.state.tableloader = false
            if(response.data.status == parseInt(200)) {
              this.organisations = response.data.organisations
            }
        }).catch(error=>{
            this.$store.state.tableloader = false
            console.log(error)
        })
      },

      async selectOrg(id) {
        this.$store.state.tableloader = true
        await axios.get(`/reversal/payment/${id}`).then(response=>{
            this.$store.state.tableloader = false
            if(response.data.status == parseInt(200)) {
              this.payments = response.data.payments
            }
        }).catch(error=>{
            this.$store.state.tableloader = false
            console.log(error)
        })
      },

      async selectPayment(id) {
        let index = this.payments.filter(item => item.id === id )
        this.item.amount = index[0].paidamount
      },

  }
};

</script>
