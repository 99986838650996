<template>
  <div class="about mt-0">
    <div class="mx-0 px-0" style="position:relative">
      <div class="flex" :class= "[mini ? 'minipadding' : 'expandpanding']">
      	<div class="section_title"><span class="title">Invoice Report&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
      	<div>
          <b-button variant="secondary" id="popover-invoice-show-more" class="secondary mx-2" size="sm"><b-icon icon="three-dots"></b-icon></b-button>
          <b-popover
            target="popover-invoice-show-more"
            triggers="hover blur"
            placement="bottom"
            class="dropdown-menu"
          >
            <div class="action-dropdown-menu">
              <button  class="action-dropdown-item" @click="showSearchDialog" type="button"><b-icon class="" icon="search"></b-icon>&nbsp;  Search</button>
              <button  class="action-dropdown-item" @click="exportexcel" type="button"><b-icon class="" icon="file-earmark-excel"></b-icon>&nbsp;  Excel</button>
              <button  class="action-dropdown-item" @click="exportpdf" type="button"><b-icon class="" icon="file-earmark-excel"></b-icon>&nbsp;  Pdf</button>
              <button  class="action-dropdown-item" @click="exportvoucher" type="button"><b-icon class="" icon="file-earmark-excel"></b-icon>&nbsp;  Voucher</button>
            </div>
          </b-popover>

      	</div>
      </div>

      <b-row class="subsection mb-0 pb-0">
        <div class="mx-3">
          <b-alert show variant="secondary" class="secondary summaryAlert px-4">
            <div class="mb-2">
              <span class="summaryTitle">Payment Summary <span v-if="reportdata.organisation_id.length > 0">| {{ reportdata.organisation_id[0].name }}</span></span>
            </div>
            <div class="summaryTotals">
              <div class="totals">
                <span class="font-normal">Sub Total</span>
                <span class="font-medium">KES{{

                            (this.calcbookingreports.reduce( (acc, item) => 
                              {  
                                  let amt = 0;
                                  if(item.organisationrate.length > 0) {
                                    if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                      amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                    }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                      amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                    }else {
                                      amt = parseInt(item.organisationrate[0].base_rate)
                                    }
                                  }
                                return acc += amt; 
                              }, 0) + this.calcbookingreports.reduce( (acc, item) => 
                            {  

                              let amt = 0, waitingfee = 0, subamt = 0;
                              if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                                if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                    waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                                }
                              }

                              if(item.waiting.length > 0) {
                                subamt = item.waiting.reduce((acc1, value) => { 
                                          let total = 0;
                                          if(value.starttime !== null && value.endtime !== null) {
                                            
                                            if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                              total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                            }

                                          }

                                          return  acc1 += total
                                        }, 0)
                              }

                              amt = subamt + waitingfee;
                              
                              return acc += amt; 

                            }, 0)) | currency('')

                          }}</span>
              </div>
              <div class="mx-5 arrow-right"></div>
              <div class="totals">
                <span class="font-normal">Vat</span>
                <span class="font-medium">KES{{

                            ((((this.calcbookingreports.reduce( (acc, item) => 
                              {  
                                  let amt = 0;
                                  if(item.organisation.vat !== '0') {
                                    if(item.organisationrate.length > 0) {
                                      if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                        amt = Math.round(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)));
                                      }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                        amt = Math.round(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)));
                                      }else {
                                        amt = parseInt(item.organisationrate[0].base_rate)
                                      }
                                    }
                                  }
                                return acc += amt; 
                              }, 0) + this.calcbookingreports.reduce( (acc, item) => 
                            {  

                              let amt = 0, waitingfee = 0, subamt = 0;
                              if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                                if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                    waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                                }
                              }

                              if(item.waiting.length > 0) {
                                subamt = item.waiting.reduce((acc1, value) => { 
                                          let total = 0;
                                          if(value.starttime !== null && value.endtime !== null) {
                                            
                                            if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                              total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                            }

                                          }

                                          return  acc1 += total
                                        }, 0)
                              }

                              amt = subamt + waitingfee;
                              
                              return acc += amt; 

                            }, 0))*(vat != null ? vat.rate : 0))/100)) | currency('')

                          }}</span>
              </div>
              <div class="mx-5 arrow-right"></div>
              <div class="totals">
                <span class="font-normal">Total</span>
                <span class="font-medium">KES{{

                            (this.calcbookingreports.reduce( (acc, item) => 
                            {  

                              let amt = 0, waitingfee = 0, subamt = 0;
                              if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                                if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                    waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                                }
                              }

                              if(item.waiting.length > 0) {
                                subamt = item.waiting.reduce((acc1, value) => { 
                                          let total = 0;
                                          if(value.starttime !== null && value.endtime !== null) {
                                            
                                            if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                              total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                            }

                                          }

                                          return  acc1 += total
                                        }, 0)
                              }

                              amt = subamt + waitingfee;
                              
                              return acc += amt; 

                            }, 0) + this.calcbookingreports.reduce( (acc, item) => 
                            {  

                              let amt = 0;

                              if(item.expressway.length > 0) {
                                amt = Math.round(item.expressway.reduce((acc1, value) => { return  acc1 += value.rate.rate}, 0))
                              }
                              
                              return acc += amt; 

                            }, 0) + ((((this.calcbookingreports.reduce( (acc, item) => 
                              {  
                                  let amt = 0;
                                  if(item.organisation.vat !== '0') {
                                    if(item.organisationrate.length > 0) {
                                      if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                        amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                      }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                        amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                      }else {
                                        amt = parseInt(item.organisationrate[0].base_rate)
                                      }
                                    }
                                  }
                                return acc += amt; 
                              }, 0) + this.calcbookingreports.reduce( (acc, item) => 
                            {  

                              let amt = 0, waitingfee = 0, subamt = 0;
                              if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                                if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                                    waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                                }
                              }

                              if(item.waiting.length > 0) {
                                subamt = item.waiting.reduce((acc1, value) => { 
                                          let total = 0;
                                          if(value.starttime !== null && value.endtime !== null) {
                                            
                                            if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                              total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                            }

                                          }

                                          return  acc1 += total
                                        }, 0)
                              }

                              amt = subamt + waitingfee;
                              
                              return acc += amt; 

                            }, 0))*(vat != null ? vat.rate : 0))/100)) + this.calcbookingreports.reduce( (acc, item) => 
                              {  
                                  let amt = 0;
                                  if(item.organisationrate.length > 0) {
                                    if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                      amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                    }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                      amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                                    }else {
                                      amt = parseInt(item.organisationrate[0].base_rate)
                                    }
                                  }
                                return acc += amt; 
                              }, 0)) | currency('')

                          }}</span>
              </div>
            </div>
          </b-alert>
        </div>
      </b-row>

      <b-row class="mt-0 pt-0">
        <b-col sm="12" xl="12" md="12" class="mx-3 mb-0 mt-2 py-0 d-flex align-center" style="height:45px;align-items:center !important;">
          <label class="form-checkbox" style="font-size:.7rem !important;font-weight:600;color:#333;text-transform:uppercase;margin-right:1rem">
            <input type="checkbox" v-model="selectAll" @click="select">
            Select All
          </label>

          <b-button variant="secondary" class="secondary" v-if="selected.length > 0"
                style="margin-right:2rem" @click="updateselected">
            Transfer records
          </b-button>

        </b-col>
      	<b-col sm="12" xl="12" md="12" class="mx-0 px-3 pt-0 mt-0">

      		<div class="dynamic-scroller invoicereport">
            <div class="empty-state" v-if="allbookingreports.length === 0">
              <div class="empty-state__content">
                <div class="empty-state__icon">
                  <img src="@/assets/data.svg" style="width:40%">
                </div>
                <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                <div class="empty-state__message" v-else>No records available.</div>
              </div>
            </div>
      			<RecycleScroller
		          ref="scroller"
		          class="scroller"
		          :items="allbookingreports"
		          :item-size="24"
		          key-field="id"
		          >
		          <template  v-slot="{ item,index }">
		            <div>
    					    <div class="tcell">
                    <label class="form-checkbox">
                      <input type="checkbox" :value="item.id" v-model="selected">
                      <i class="form-icon"></i>
                    </label>     
                  </div>
                  <div class="tcell" @click="bookingdetailsupdate(item.id)">
                      <div v-if="item.passengers.length === 0">{{ item.passenger_name }}</div>
                      <div v-else style="display:flex;flex-direction:column !important">
                        <span v-for="(passenger,index) in item.passengers" style="display:flex;" v-b-tooltip.hover.left :title="passenger.passenger_name"><b-icon class="mr-1" icon="dot"></b-icon> {{ passenger.passenger_name }}</span> 
                      </div>  
                  </div>
                  <div class="tcell">
                    <div v-if="item.passengers.length === 0">{{ item.telephone }}</div>
                    <div v-else style="display:flex;flex-direction:column !important">
                      <span v-for="(passenger,index) in item.passengers" style="display:flex;" v-b-tooltip.hover.left :title="passenger.telephone"><b-icon class="mr-1" icon="dot"></b-icon> {{ passenger.telephone }}</span>  
                    </div>
                   </div>
                  <div class="tcell">
                    {{ item.pickup_date }} {{ item.pickup_time }}
                  </div>
                  <div class="tcell">
                    <span v-b-tooltip.hover.left :title="item.travelfrom">{{ item.travelfrom }}</span>
                  </div>
                  <div class="tcell"><span v-b-tooltip.hover.left :title="item.travelto">{{ item.travelto }}</span></div>
                  <div class="tcell">
                    <span v-if="item.vehicletype !== null">{{ item.vehicletype.type }}</span>
                    <span v-else>N/A</span>
                  </div>
                  <div class="tcell" @click="editdistance(item.id)">{{ item.distance | currency('') }}</div>
                  <div class="tcell">
                    <div v-if="item.organisationrate.length > 0">
                      <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) | currency('') }}</span>
                      <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) | currency('') }}</span>
                      <span v-else>{{ item.organisationrate[0].base_rate | currency('') }}</span>
                    </div>
                    <div v-else>0.00</div>
                  </div>

                  <div class="tcell" @click="expressway(item.id)">
                    {{ item.expressway.reduce((acc1, value) => { return  acc1 += value.rate.rate}, 0) }}
                  </div>

                  <div class="tcell">
                    <div v-if="item.organisationrate.length > 0">
                      <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                      <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                      <span v-else>{{ ((item.organisationrate[0].base_rate*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                    </div>
                    <div v-else>0.00</div>
                  </div>
                  <div class="tcell">
                    <div v-if="item.organisationrate.length > 0">
                      <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ (Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) + ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                      <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ (Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) + ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                      <span v-else>{{ (parseInt(item.organisationrate[0].base_rate) + ((item.organisationrate[0].base_rate*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                    </div>
                    <div v-else>0.00</div>
                  </div>
                  <div class="tcell">{{ item.status }}</div>
                  <div class="tcell text-center">
                      <a class="list-action" :id="`popover-target-1-${item.id}-${index}`">
                      <b-icon class="my-0 py-0" style="cursor:pointer;" icon="chevron-down"></b-icon>
                    </a>
                    <b-popover
                      :target="`popover-target-1-${item.id}-${index}`"
                      triggers="hover blur"
                      placement="left"
                      class="dropdown-menu"
                    >
                      <div class="action-dropdown-menu">
                        <button @click="bookingprofile(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="person-check"></b-icon>&nbsp;  Profile</button>
                        <button @click="expressway(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="diagram-2"></b-icon>&nbsp;  Expressway</button>
                        <button @click="waiting(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="hourglass-split"></b-icon>&nbsp;  Waitings</button>
                        <button @click="voucher(item.ref_no)" class="action-dropdown-item " type="button"><b-icon class="" icon="files"></b-icon>&nbsp;  Voucher</button>
                      </div>
                    </b-popover>
                  </div>
    					  </div>
		          </template>
		        </RecycleScroller>

			  </div>
      	</b-col>
      </b-row>
    </div>

    <!-- components -->
    <Search v-model="searchdialog"/>
    <EditDistance v-model="editdistancedialog" :bookingid="bookingid"/>
    <ExpresswayDetail v-model="expresswaydialog" :expresswaydetails="expresswaydetails"/>
    <WaitingDetail v-model="waitingdialog" :waitingdetails="waitingdetails"/>
    <ClientProfile v-model="profiledialog" :profileid="profileid"/>
    <UpdateDetail v-model="updatedialog" :updatedetails="updatedetails"/>
    <UpdateAll v-model="updatealldialog" :selected="selected"/>

  </div>
</template>

<style>
	@import "@/assets/table/table";
  .modal-invoicereport{
    max-width:750px !important;
  }
	.action-dropdown-menu .action-dropdown-item:hover{
		background:var(--ab-primary);
		color:#fff;
		border-radius:var(--ab-dropdown-border-radius);
	}
	.action-dropdown-menu{
		padding-right: .5rem;
	}
  .invoicereport .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-template-columns:2% 10.691% 7.691% 7.691% 12.691% 12.691% 7.691% 4.691% 4.691% 4.691% 5.691% 5.691% 7.691% 5.691%;
     grid-gap: 0px;
     min-width:100%;
  }
  .invoicereport .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }
  .invoicereport .scroller{
    height: calc(100vh - 290px);
  }
</style>
<style scoped>
	@import "@/assets/table/table_scoped";
  .invoicereport .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-template-columns:2% 10.691% 7.691% 7.691% 12.691% 12.691% 7.691% 4.691% 4.691% 4.691% 5.691% 5.691% 7.691% 5.691%;
     grid-gap: 0px;
  }
  
</style>

<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';
import { format, parseISO } from 'date-fns'

import Search from '@/components/reports/invoicesearch'
import EditDistance from '@/components/bookings/editDistance'
import ExpresswayDetail from '@/components/bookings/expressway'
import WaitingDetail from '@/components/bookings/waiting'
import ClientProfile from '@/components/bookings/clientprofile'
import UpdateDetail from '@/components/bookings/updatedetails'
import UpdateAll from '@/components/bookings/updateall'

 export default {
  name: 'InvoiceReport',
  components: { Search,ExpresswayDetail,WaitingDetail,ClientProfile,EditDistance,UpdateDetail,UpdateAll },
  computed: {
	  mini() {
	    return this.$store.state.mini
	  },
    loader() {
    	return this.$store.state.tableloader
    },
    vat() {
      return this.$store.state.vat
    },

    bookingreport() {
      return this.$store.state.allbookingreports
      
    },

    allbookingreports() {
      return this.bookingreport.filter(item => {
        this.start = 0
        let name = "";
        if(item.passenger_name != null && item.passenger_name != 'null') {name = item.passenger_name}
          
        return name.toLowerCase().includes(this.search.toLowerCase())  || item.passengers.some(item => item.passenger_name.toLowerCase().includes(this.search.toLowerCase()) || item.telephone.includes(this.search))
      })
    },
    calcbookingreports() {
      return this.$store.state.allbookingreports.filter(item => {
        this.start = 0
          if(this.selected.includes(item.id) === true) {
            return item
          }else {
            return false
          }

      })
    },
    reportdata () {
      return this.$store.state.reportdata
    },
  },
  data: () => ({
      profileid: null,
      selected: [],
      selectAll: false,
      expresswaydialog: false,
       editdistancedialog: false,
       bookingid: null,
       expresswaydetails: [],
       updatedetails: [],
       waitingdialog: false,
       waitingdetails: [],
       profiledetails: [],
       profiledialog: false,
       updatealldialog: false,
       updatedialog: false,
      daterange: {
        start: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        end: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
      },
      searchfilter: '',
      search: '',
      dialog: false,
      searchdialog: false,
      loading: false,
      fields: [
        { text: '', value: '' },
        { text: 'Name', value: 'name' },
        { text: 'Tel', value: 'telephone' },
        { text: 'Date', value: 'date' },
        { text: 'From', value: 'from' },
        { text: 'To', value: 'to' },
        { text: 'Vehicle', value: 'vehicletype' },
        { text: 'Dist', value: 'distance' },
        { text: 'Cost', value: 'cost' },
        { text: 'Xway', value: 'expressway' },
        { text: 'Vat', value: 'vat' },
        { text: 'Total', value: 'total' },
        { text: 'Status', value: 'status' },
        { text: 'Action', value: 'action' },
       ],
      item: {
        organisation_id: null,
        project_id: null
       },
  }),

  mounted() {

  	let headertitle = `<div class="view-titleheader titlehead" style="top: 0px;">`;
      this.fields.map(function(value, key) {
            headertitle +=`<div class="theader">${value.text}</div>`;
         });
      headertitle +=`</div>`;
      $('.vue-recycle-scroller').prepend(headertitle)

      this.$store.state.reportdata = {
            organisation_id: [],
            project_id: [],
            customer_id: [],
            status: '',
            request_category: '',
            start: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            end: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
          }

    if(!this.organisations || this.organisations.length == 0 || this.allcustomers.length == 0) {
      this.getOrgCustomer()
    }
      
  },
  beforeMount() {
    if(!ability.can('view-reports', 'all')) {
      this.$router.push({ name: 'Dashboard' })
      return
    }
  },
  serverPrefetch () {},
  methods: {
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.allbookingreports) {
          this.selected.push(this.allbookingreports[i].id);
        }
      }
    },
    showSearchDialog() {
      this.searchdialog = true
    },
    bookingprofile(id) {
      this.$store.state.bookingid = id
      this.profiledialog = true
    },
    bookingdetailsupdate(id) {
      this.updatedetails = this.allbookingreports.filter(booking => booking.id === id )
      this.updatedialog = true
    },
    updateselected() {
      this.updatealldialog = true
    },
    updateValues(val) { 
      this.daterange.start = Vue.filter('date')(val.startDate)
      this.daterange.end = Vue.filter('date')(val.endDate)
      this.date = this.daterange.start+" - "+this.daterange.end
    },
    editdistance(id) {
      this.bookingid = id
      this.editdistancedialog = true
    },
    expressway(id) {
      this.expresswaydetails = this.bookingreport.filter(booking => booking.id === id )
      this.expresswaydialog = true
    },
    waiting(id) {
      this.waitingdetails = this.bookingreport.filter(booking => booking.id === id )
      this.waitingdialog = true
    },
    voucher(ref) {
      window.open(`https://e-voucher.absolutecabs.co.ke/customer/e-voucher/${ref}`, '_blank', 'noreferrer');
    },
    getOrgCustomer(){

      this.$store.state.tableloader = true

      this.$store
        .dispatch('getOrgCustomer')
    },
    exportexcel(){

      this.$store.state.tableloader = true

      const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'arraybuffer' };
        let fd = new FormData();
        fd.append('id',JSON.stringify(this.reportdata.organisation_id))
        fd.append('selected',JSON.stringify(this.selected))
        fd.append('request_category',this.reportdata.request_category)
        fd.append('project_id',JSON.stringify(this.reportdata.project_id))
        fd.append('customer_id',JSON.stringify(this.reportdata.customer_id))
        fd.append('start',this.reportdata.start)
        fd.append('end',this.reportdata.end)

      axios.post('/booking/invoice/export/excel', fd, config).then(response=>{
        this.$store.state.tableloader = false
        let data = this.reportdata.organisation_id
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        if(data.length > 0) {
          fileLink.setAttribute('download', `${data[0].name}_report_${this.reportdata.start}_to_${this.reportdata.end}.xlsx`);
         }else {
          fileLink.setAttribute('download', 'Report.xlsx');
         }
       document.body.appendChild(fileLink);
       fileLink.click();
        
      })
      .catch(error => {
        this.$store.state.tableloader = false
        console.log(error)
      })
    },
    exportpdf(){

      this.$store.state.tableloader = true
      
      const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' };
        let fd = new FormData();
        fd.append('id',JSON.stringify(this.reportdata.organisation_id))
        fd.append('selected',JSON.stringify(this.selected))
        fd.append('request_category',this.reportdata.request_category)
        fd.append('project_id',JSON.stringify(this.reportdata.project_id))
        fd.append('customer_id',JSON.stringify(this.reportdata.customer_id))
        fd.append('start',this.reportdata.start)
        fd.append('end',this.reportdata.end)

      axios.post('/booking/invoice/export/pdf', fd, config).then(response=>{
       this.$store.state.tableloader = false
       let data = this.reportdata.organisation_id
       var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
       var fileLink = document.createElement('a');
       fileLink.href = fileURL;
       if(data.length > 0) {
          fileLink.setAttribute('download', `${data[0].name}_report_${this.reportdata.start}_to_${this.reportdata.end}.pdf`);
         }else {
          fileLink.setAttribute('download', 'Report.pdf');
         }
       document.body.appendChild(fileLink);
       fileLink.click();
        
      })
      .catch(error => {
        this.$store.state.tableloader = false
        console.log(error)
      })
    },
    exportvoucher(){
        this.$store.state.tableloader = true
        
        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' };
          let fd = new FormData();
          fd.append('id',JSON.stringify(this.reportdata.organisation_id))
          fd.append('selected',JSON.stringify(this.selected))
          fd.append('request_category',this.reportdata.request_category)
          fd.append('project_id',JSON.stringify(this.reportdata.project_id))
          fd.append('customer_id',JSON.stringify(this.reportdata.customer_id))
          fd.append('start',this.reportdata.start)
          fd.append('end',this.reportdata.end)

        axios.post('/booking/invoice/voucher/pdf', fd, config).then(response=>{
         this.$store.state.tableloader = false
         let data = this.reportdata.organisation_id
         var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         if(data.length > 0) {
          fileLink.setAttribute('download', `${data[0].name}_booking_voucher_${this.reportdata.start}_to_${this.reportdata.end}.pdf`);
         }else {
          fileLink.setAttribute('download', 'booking_voucher.pdf');
         }
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(error => {
          this.$store.state.tableloader = false
          console.log(error)
        })
      },
  }
};

</script>
