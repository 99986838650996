<template>
  <div class="settingswrapper mt-0">
    <div class="mx-0 px-0" style="position:relative">
      <div class="flex" :class= "[mini ? 'minipadding' : 'expandpanding']">
      	<div class="section_title"><span class="title">Profile&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
      </div>
      <b-row class="subsection pt-3" style="display:flex;align-items:center;justify-content:center">
      	<b-col sm="12" xl="10" md="12" lg="10" class="mx-0">
          <div class="emp-profile" v-if="details !== null">

                <div class="row">
                    <div class="col-md-3">
                        <div class="profile-img" v-if="details.profile !== null">
                            <img :src="details.profile" alt=""/>
                        </div>
                        <div class="profile-img" v-else>
                            <img src="@/assets/no_user.png" alt=""/>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="profile-head">
                                    <h5>
                                        {{ details.name }}
                                    </h5>
                                    <p class="proile-rating">RATING : 
                                      <span>
                                          <star-rating v-bind:increment="0.5"
                                             v-bind:max-rating="5"
                                             v-model:rating="rating"
                                             inactive-color="#c8c8c8"
                                             active-color="#F9780D"
                                             v-bind:star-size="25">
                                       
                                          </star-rating>
                                     </span>
                                   </p>
                                   <p>
                                     Today's Commission: <div style="font-size:1.3rem;font-weight:600">{{ todaycommission | currency('',0) }}</div>
                                   </p>
                            
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-12">

                      <b-tabs>
                        <b-tab title="Personal Information" active>
                            <table class="mt-3">
                              <tr><td>Telephone</td><td>{{ details.telephone }}</td></tr>
                              <!-- <tr><td>National ID</td><td>{{ details.idno }}</td></tr>
                              <tr><td>Email</td><td>{{ details.email }}</td></tr> -->
                              <tr><td>Vehicle</td><td>{{ details.vname }}</td></tr>
                              <tr><td>Registration</td><td>{{ details.registration }}</td></tr>
                              <tr><td>Organisation</td><td><div v-if="details.organisation !== null">{{ details.organisation.name }}</div><div v-else>N/A</div></td></tr>
                            </table>
                        </b-tab>
                        <b-tab title="Customer Ratings">
                          <Rating :driverid="parseInt(this.$route.params.id)"/>
                        </b-tab>
                        <b-tab title="Documents"></b-tab>
                        <b-tab title="Commisions">
                          <Commission :driverid="parseInt(this.$route.params.id)"/>
                        </b-tab>
                        <b-tab title="Displinary Record">
                          <Disciplinary :driverid="parseInt(this.$route.params.id)"/>
                        </b-tab>
                      </b-tabs>

                    </div>
                </div>

        </div>
      	</b-col>
      </b-row>
    </div>

  </div>
</template>

<style>
	@import "@/assets/table/table";
  .modal-profile{
    max-width:480px !important;
  }
	.action-dropdown-menu .action-dropdown-item:hover{
		background:var(--ab-primary);
		color:#fff;
		border-radius:var(--ab-dropdown-border-radius);
	}
	.action-dropdown-menu{
		padding-right: .5rem;
	}

  /*profile styling*/
    .emp-profile{
        padding: 3%;
        margin-top: 3%;
        margin-bottom: 3%;
        border-radius: 0.5rem;
        background: #fff;
    }
    .profile-img{
        text-align: center;
    }
    .profile-img img{
        width: 90%;
        height: 100%;
    }

    .profile-img .file input {
        position: absolute;
        opacity: 0;
        right: 0;
        top: 0;
    }
    .profile-head{
      margin-top:4%;
    }
    .profile-head h5{
        color: #333;
    }
    .profile-head h6{
        color: #0062cc;
    }

    .proile-rating{
        font-size: 12px;
        color: #818182;
        /*margin-top: 5%;*/
    }
    .proile-rating span{
        color: #495057;
        font-size: 15px;
        font-weight: 600;
    }
    .nav-tabs{
        margin-bottom:2% !important;
    }
    .nav-tabs .nav-link{
        font-weight:600 !important;
        border: none !important;
    }
    .nav-tabs .nav-link.active{
        border: none !important;
        border-bottom:2px solid #0062cc !important;
    }
    .profile-work{
        padding: 14%;
        margin-top: -15%;
    }
    .profile-work p{
        font-size: 12px;
        color: #818182;
        font-weight: 600;
        margin-top: 10%;
    }
    .profile-work a{
        text-decoration: none;
        color: #495057;
        font-weight: 600;
        font-size: 14px;
    }
    .profile-work ul{
        list-style: none;
    }
    .profile-tab label{
        font-weight: 600;
    }
    .profile-tab p{
        font-weight: 600;
        color: #0062cc;
    }

</style>
<style scoped>
	@import "@/assets/table/table_scoped";
</style>

<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';
import StarRating from 'vue-star-rating'
import Commission from '@/components/drivers/commissions'
import Rating from '@/components/drivers/ratings'
import Disciplinary from '@/components/drivers/disciplinary'

 export default {
  name: 'Profile',
  components: {StarRating,Commission,Rating,Disciplinary},
  computed: {
	  mini() {
	    return this.$store.state.mini
	  },
    loader() {
    	return this.$store.state.tableloader
    },
    todaycommission() {
      return this.$store.state.todaycommission
    },
    totalcommission() {
      return this.$store.state.totalcommission
    },
    details() {
      return this.$store.state.profiledetails
    },
  },
  data: () => ({
      bulksmsdialog: false,
      waitingtimedialog: false,
      vatdialog: false,
      searchfilter: '',
      search: '',
      loading: false,
      fields: [
        { text: 'Name', value: 'name' },
        { text: 'Telephone', value: 'telephone' },
        { text: 'Email', value: 'email' },
        { text: 'Account Type', value: 'account' },
        { text: 'Organisation', value: 'organisation' },
        { text: 'Status', value: 'status' },
        { text: 'Action', value: 'action' },
       ],
       item: {
        apikey: '',
        partnerid: '',
        shortcode: '',
        one_trip_rate:"",
        round_trip_rate:"",
        type_id: "",
        rate: "",
        minutes: "",
        vat: ""
       },
       perPage: 15,
      currentPage: 1,
      rating:0,
       date: "", 
       documents: null,
  }),

  mounted() {
  	this.$store.state.disciplinaries = []
    this.$store.state.ratings = []
    this.$store.state.commissions = []

    this.showDetails(this.$route.params.id)
    this.fetchcommissions(this.$route.params.id)
  },
  beforeMount() {
    if(!ability.can('view-reports', 'all')) {
      this.$router.push({ name: 'Dashboard' })
      return
    }
  },

  methods: {

    showDetails(id) {
        this.$store.state.tableloader = true
        axios.get(`/driver/${id}`).then(response=>{
            this.$store.state.tableloader = false
            this.$store.state.profiledetails = response.data.details
            // this.date = this.details.expiry ? this.details.expiry != "null" : null;
            this.rating = parseInt(response.data.rating_avg)
            this.$store.state.commission_rates =  response.data.commissions
        }).catch(error=>{
            console.log(error)
            this.$store.state.tableloader = false
        })
      },
      fetchcommissions(id) {
        this.$store
            .dispatch('fetchcommissions',id)
            .then(() => {
            })
            .catch(() => {
            })
      },   

  }
};

</script>
