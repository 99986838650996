<template>
  <div class="about mt-0">
    <div class="mx-0 px-0" style="position:relative">
      <div class="flex" :class= "[mini ? 'minipadding' : 'expandpanding']">
      	<div class="section_title"><span class="title">Organisations&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
      	<div>
      		<b-button variant="primary" class="primary" size="sm" @click="editOrg(0)">Add Organisation <b-icon class="mr-1" icon="plus"></b-icon></b-button>
      	</div>
      </div>
      <b-row class="subsection">
      	<b-col sm="12" xl="12" md="12" class="mx-0 px-3">
      		<div class="dynamic-scroller organisation">
            <b-row class="mx-2 mb-2 searchsection" style="display:flex;justify-content:flex-end;align-items:center">
              <div class="px-1" style="width:200px !important;padding-top:0 !important;padding-bottom:0 !important;display:flex;">
                <b-form-input
                    placeholder="Search..."
                    size="sm"
                    v-model="search"
                  ></b-form-input>
              </div>
            </b-row>
            <div class="empty-state" v-if="allorgs.length === 0">
              <div class="empty-state__content">
                <div class="empty-state__icon">
                  <img src="@/assets/data.svg" style="width:40%">
                </div>
                <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                <div class="empty-state__message" v-else>No records available.</div>
              </div>
            </div>
      			<RecycleScroller
		          ref="scroller"
		          class="scroller"
		          :items="allorgs"
		          :item-size="24"
		          key-field="id"
		          >
		          <template  v-slot="{ item,index }">
		            <div>
    					    <div class="tcell">{{ item.name }}</div>
                  <div class="tcell">{{ item.email }}</div>
                  <div class="tcell">{{ item.telephone }}</div>
                  <div class="tcell">{{ item.address }}</div>
                  <div class="tcell">
                    <div v-if="item.vat === '1'">Include</div>
                    <div v-else>Exclude</div>
                  </div>
                  <div class="tcell">{{ item.projects_slug }}</div>
    					    <div class="tcell text-center">
    					      <a class="list-action" :id="`popover-target-1-${item.id}-${index}`">
    						  		<b-icon class="my-0 py-0" style="cursor:pointer;" icon="chevron-down"></b-icon>
    						    </a>
    						    <b-popover
      							  :target="`popover-target-1-${item.id}-${index}`"
      							  triggers="hover blur"
      							  placement="left"
      							  class="dropdown-menu"
      							>
      							  <div class="action-dropdown-menu">
      						  		<button @click="editOrg(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="pencil"></b-icon>&nbsp;  Edit</button>
      						  		<button @click="deleteItem(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="trash"></b-icon>&nbsp;  Remove</button>
      						  	</div>
      							</b-popover>
    					    </div>
    					  </div>
		          </template>
		        </RecycleScroller>
			  </div>
      	</b-col>
      </b-row>
    </div>


    <b-modal
      v-model="updatedialog"
      size="organisation"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left" v-if="item.updateID !== ''">Update Organisation</span>
          <span class="title float-left" v-else>Add Organisation</span>
        </div>
      </template>
      <b-container fluid>
        <b-row class="mx-0 my-0 py-0">
          <b-col sm="12" md="6" class="float_label mb-3">
            
              <b-form-input
                placeholder="Name"
                required
                size="sm"
                v-model="item.updatename"
              ></b-form-input>
            <label class="typo__label float_label_item">Name*</label>
          </b-col>
          <b-col sm="12" md="6" class="float_label mb-3">

              <b-form-input
                placeholder="Email"
                size="sm"
                v-model="item.updateemail"
              ></b-form-input>
            <label class="typo__label float_label_item">Email*</label>
          </b-col>
          <b-col sm="12" md="6" class="float_label mb-3">
            
              <b-form-input
                placeholder="Mobile"
                size="sm"
                v-model="item.updatetelephone"
              ></b-form-input>
            <label class="typo__label float_label_item">Mobile</label>
          </b-col>
          <b-col sm="12" md="6" class="float_label mb-3">
            
              <b-form-input
                placeholder="Address"
                size="sm"
                v-model="item.updateaddress"
              ></b-form-input>
            <label class="typo__label float_label_item">Address</label>
          </b-col>
          <b-col sm="12" md="6" class="float_label mb-3">
            
              <b-form-input
                placeholder="Kra"
                size="sm"
                v-model="item.updatekra"
              ></b-form-input>
            <label class="typo__label float_label_item">Kra</label>
          </b-col>
          <b-col sm="12" md="6" class="float_label mb-3">
            
              <b-form-input
                placeholder="Project slug"
                size="sm"
                v-model="item.updateprojects_slug"
              ></b-form-input>
            <label class="typo__label float_label_item">Slug</label>
          </b-col>
        </b-row>
        <b-row class="my-2 mx-1">
          <b-col sm="12" md="6">
            <b-form-checkbox name="check-button" switch v-model="item.updateauthorize">
              Bookings authorization?
            </b-form-checkbox>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-checkbox name="check-button" switch v-model="item.updatevat">
              Include VAT?
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="update" v-if="item.updateID !== ''">
            <b-spinner small v-if="loading"></b-spinner> Update
          </b-button>
          <b-button variant="primary" class="primary" @click="create" v-else>
            <b-spinner small v-if="loading"></b-spinner> Save
          </b-button>
      </template>
    </b-modal>

  </div>
</template>

<style>
	@import "@/assets/table/table";
  .modal-organisation{
    max-width:540px !important;
  }
	.action-dropdown-menu .action-dropdown-item:hover{
		background:var(--ab-primary);
		color:#fff;
		border-radius:var(--ab-dropdown-border-radius);
	}
	.action-dropdown-menu{
		padding-right: .5rem;
	}
  .organisation .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-template-columns: 22.28% 16.28% 12.28% 14.28% 14.28% 10.28% 10.28% !important;
     grid-gap: 0px;
  }
  .organisation .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }
</style>
<style scoped>
	@import "@/assets/table/table_scoped";
  .organisation .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-template-columns: 22.28% 16.28% 12.28% 14.28% 14.28% 10.28% 10.28% !important;
     grid-gap: 0px;
  }
  
</style>

<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';

 export default {
  name: 'orgList',
  components: {
  },
  computed: {
  	orgs() {
      return this.$store.state.organisations.filter(item => {

        this.start = 0

        return item.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    allorgs() {
      return this.orgs
    },
    otype() {
      return this.$store.state.orgtype
    },
    ocategory() {
      return this.$store.state.orgcategory
    },
	  mini() {
	    return this.$store.state.mini
	  },
    loader() {
    	return this.$store.state.tableloader
    },
  },
  data: () => ({
      search: '',
      dialog: false,
      updatedialog: false,
      loading: false,
      fields: [
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Telephone', value: 'telephone' },
        { text: 'Address', value: 'address' },
        { text: 'Vat', value: 'vat' },
        { text: 'Project Slug', value: 'slug' },
        { text: 'Action', value: 'action' },
       ],
      item:{
          category_id:"",
          name:"",
          email:"",
          telephone:"",
          type_id:"",
          country:"",
          status:"",
          base_amount:0,
          one_trip_rate:"",
          round_trip_rate:"",
          projects_slug:"",
          address:"",
          authorize: false,

          updatecategory_id:"",
          updatename:"",
          updateemail:"",
          updatetelephone:"",
          updatetype_id:"",
          updatecountry:"",
          updatestatus:"",
          updatekra:"",
          updatebase_amount:0,
          updateone_trip_rate:"",
          updateround_trip_rate:"",
          updateprojects_slug:"",
          updateaddress:"",
          updateauthorize: null,
          updatevat: null,

          upcategory: '',
          upcatype: '',

          updateID: "",
          _method:"patch"
      },
  }),

  mounted() {

  	let headertitle = `<div class="view-titleheader titlehead" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="theader">${value.text}</div>`;
         });
      headertitle +=`</div>`;
      $('.vue-recycle-scroller').prepend(headertitle)

  	if(!this.orgs || this.orgs.length == 0) {
        this.getOrganisation()
      }
  },
  serverPrefetch () {
    // this.getOrganisation()
  },
  methods: {
  	getOrganisation(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getOrganisation')
      },
     create(){

        // if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('name',this.item.updatename)
            fd.append('email',this.item.updateemail)
            fd.append('address',this.item.updateaddress)
            fd.append('telephone',this.item.updatetelephone)
            fd.append('country',this.item.updatecountry)
            fd.append('category_id',this.item.updatecategory_id)
            fd.append('type_id',this.item.updatetype_id)
            fd.append('status',this.item.updatestatus)
            fd.append('kra',this.item.updatekra)
            fd.append('base_amount',this.item.updatebase_amount)
            fd.append('authorize',this.item.updateauthorize)
            fd.append('vat',this.item.updatevat)
            fd.append('one_trip_rate',this.item.updateone_trip_rate)
            fd.append('round_trip_rate',this.item.updateround_trip_rate)
            fd.append('projects_slug',this.item.updateprojects_slug)

            axios.post('/organisation', fd, config).then(response=>{
              this.loading = false

              if(response.data.status === 200) {

                  let found = this.$store.state.organisations.find(data => data.id == response.data.organisation.id);
                  if (!found) {
                    this.$store.state.organisations.unshift(response.data.organisation)
                  }

                  this.$toastpopup('success','Success','Organisation added successfully')
                  this.updatedialog = false
                }
                this.clear()
              this.item.updatetype_id = ''
              this.item.updatename = ''
              this.item.updateemail = ''
              this.item.updatetelephone = ''
              this.item.updateaddress = ''
              this.item.updatestatus = ''
              this.item.updatecategory_id = ''
              this.item.updatecountry = ''
              this.item.updateone_trip_rate = ''
              this.item.updateround_trip_rate = ''
              this.item.updatekra = ''
              this.item.updatebase_amount = 0
              this.item.updateauthorize = null
              this.item.updatevat = null
            }).catch(error=>{
              this.loading = false
                console.log(error)
            })

          // }

        },
        clear () {
          this.item.updatetype_id = ''
          this.item.updatename = ''
          this.item.updateemail = ''
          this.item.updatetelephone = ''
          this.item.updateaddress = ''
          this.item.updatestatus = ''
          this.item.updatekra = ''
          this.item.updateauthorize = null
          this.item.updatevat = null
        },
        async update(){

          // if (this.$refs.form.validate()) {

            this.loading = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('name',this.item.updatename)
            fd.append('email',this.item.updateemail)
            fd.append('address',this.item.updateaddress)
            fd.append('telephone',this.item.updatetelephone)
            fd.append('country',this.item.updatecountry)
            fd.append('category_id',this.item.updatecategory_id)
            fd.append('type_id',this.item.updatetype_id)
            fd.append('status',this.item.updatestatus)
            fd.append('kra',this.item.updatekra)
            fd.append('base_amount',this.item.updatebase_amount)
            fd.append('authorize',this.item.updateauthorize)
            fd.append('vat',this.item.updatevat)
            fd.append('one_trip_rate',this.item.updateone_trip_rate)
            fd.append('round_trip_rate',this.item.updateround_trip_rate)
            fd.append('projects_slug',this.item.updateprojects_slug)
            fd.append('_method',this.item._method)

            await axios.post(`/organisation/${this.item.updateID}`, fd, config).then(response=>{
              this.loading = false
              if(response.data.status === 200) {

                  let index = this.$store.state.organisations.findIndex(data => data.id == response.data.organisation.id);
                  if (index > -1) {
                    this.$store.state.organisations.splice(index, 1, response.data.organisation);
                  }

                  this.$toastpopup('success','Success','Organisation updated successfully')
                  this.updatedialog = false
                }
               
              this.item.updatetype_id = ''
              this.item.updatename = ''
              this.item.updateemail = ''
              this.item.updatetelephone = ''
              this.item.updateaddress = ''
              this.item.updatestatus = ''
              this.item.updatecategory_id = ''
              this.item.updatecountry = ''
              this.item.updatekra = ''
              this.item.updateone_trip_rate = ''
              this.item.updateround_trip_rate = ''
              this.item.updatebase_amount = 0
              this.item.updateauthorize = null
              this.item.updatevat = null
              this.item.updateID = ''
            }).catch(error=>{
              this.loading = false
                console.log(error)
            })

          // }

        },
     editOrg(id) {
        let index = this.allorgs.filter(item => item.id === id )
        if(index.length > 0) {

          this.item.updatename = index[0].name
          this.item.updateemail = index[0].email
          this.item.updatetelephone = index[0].telephone
          this.item.updateaddress = index[0].address
          this.item.updatestatus = index[0].status
          this.item.updateprojects_slug = index[0].projects_slug
          this.item.updatekra = index[0].kra
          if(index[0].bookingauthorize == 1){
            this.item.updateauthorize = true
          }else {
            this.item.updateauthorize = null
          }
          if(index[0].vat == '1'){
            this.item.updatevat = true
          }else {
            this.item.updatevat = null
          }
          this.item.updateID = index[0].id
          this.updatedialog = true

        }else {
          this.item.updateID = ''
          this.updatedialog = true
          this.clear()
        }
        
      },
      deleteItem(id){
          if(confirm("Are you sure to delete organisation ?")){
              axios.delete(`/organisation/${id}`).then(response=>{
                let index = this.$store.state.organisations.findIndex(data => data.id == response.data.organisation.id);
                if (index > -1) {
                  this.$store.state.organisations.splice(index, 1);
                }
                this.$toastpopup('success','Success','Organisation deleted successfully')
              }).catch(error=>{
                  console.log(error)
              })
          }
      }
  }
};

</script>
