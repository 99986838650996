<template>
  <div class="Login">
    <div class="form">
      <div class="login_box">
        <b-form @submit.stop.prevent="create">
          <b-row>
            <b-col cols="12" class="px-2">
              <img src="/assets/images/logo/logo.png" style="width:35% !important;text-align:center">
              <h2 class="card-title fw-bold mt-3">Welcome to Absolute Cabs! 👋</h2>
                <p class="card-text mb-0">Please sign-in to your account</p>
            </b-col>
          </b-row>
          <b-form-group label="" label-for="email">
            <b-form-input
              placeholder="Email address"
              id="email"
              name="example-input-1"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              aria-describedby="email-feedback"
            ></b-form-input>
            <b-form-invalid-feedback
              id="email-feedback"
            >This is a required field</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="" label-for="password">
            <b-icon @click="showPassword = !showPassword" :icon="showPassword ? 'eye-slash':'eye'"></b-icon>
            <b-form-input
              placeholder="Password"
              id="password"
              name="example-input-1"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="password-feedback"
              :type="showPassword ? 'text':'password'"
            ></b-form-input>
            <b-form-invalid-feedback
              id="password-feedback"
            >This is a required field</b-form-invalid-feedback>
          </b-form-group>
          <p>Having trouble in signing in? <router-link :to="{ name: 'ForgotPassword' }"><span>&nbsp;Reset Password</span></router-link></p>
          <b-row>
            <b-col cols="12" style="display:flex;justify-content:flex-end">
              <b-button variant="primary" type="submit" class="primary" :disabled="$v.form.$invalid">
                <b-spinner small v-if="loading"></b-spinner> Login
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <!-- <p>Or Sign In with</p>
        <button><i class="fa-brands fa-google"></i> Google</button>
        <button><i class="fa-brands fa-facebook"></i> Facebook</button> -->
        <!-- <p class="mt-3">Don't have an account? <router-link :to="{ name: 'Signup' }"><span>&nbsp;Create an account</span></router-link></p> -->
      </div>
    </div>
  </div>
</template>
<style scoped>
input{
  font-weight:500 !important;
}
.card-title{
  font-size: 16px;
}
.form{
  background: rgba(255, 255, 255);
  width: 350px;
  margin: auto;
  text-align: center;
  border-radius: 10px;
}

h1{
  margin: 0;
}

.login_box{
  padding: 20px 20px;
}

.login_box input{
  width: 100%;
  margin: 5px auto;
  box-sizing: border-box;
  border: 1px solid;
  border-radius: 2px;
  padding: 8px 5px;
}

p{
  font-size: 13px;
  padding: 5px 0;
}

.sign_in{
  width: 100%;
  background: rgba(110, 188, 133, 0.96);
  border: 0;
  border-radius: 6px;
  padding: 8px 0;
  font-size: 15px;
  box-shadow: 1px 1px 3px 0;
}

.sign_in:hover{
  background: black;
  color: rgba(110, 188, 133, 0.96);
  transition: 0.3s ease;
}

button{
  width: 40%;
  border: 0.1px solid;
  border-radius: 6px;
  padding: 7px 5px;
  font-size: 13px;
}

button:hover{
  transition: 0.3s ease;
}

span{
  font-weight: bold;
}

span:hover{
  text-decoration: underline;
}
</style>
<script>
import { mapGetters } from 'vuex'
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: 'Login',
    components: {
    },
    data: () => ({
      showPassword: false,
      valid: true,
      fieldRules: [
      (v) => !!v || 'Field is required'
      ],
      emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      type: 'password',
      loading: false,
      disabled: false,
      showPassword: false,
      form: {
        email: "",
        password: ""
      }
    }),
    validations: {
      form: {
        email: {
          required
        },
        password: {
          required
        }
      }
    },
    computed: {
      ...mapGetters([
        'isLogged'
        ])
    },
    methods: {
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
        create() {

          this.$v.form.$touch();
          if (this.$v.form.$anyError) {
            return;
          }

          this.loading = true

          this.$store
          .dispatch('login', {
            email: this.form.email,
            password: this.form.password
          })
          .then(() => {

            this.loading = false

            if(this.$store.state.user.status === parseInt(200))
            {

              this.$toastpopup('success','Info','Login was successful')
              
              this.$router.push({ name: 'HomeView' })
              // window.location.href = '/'

            }else{
              this.$toastpopup('danger','Warning','Credentials does not match')
            }
          })
          .catch(() => {

            this.loading = false

            this.$toastpopup('danger','Warning','Credentials does not match')
          })

        }
    }
}
</script>