<template>

  <div class="Login">
    <div class="form">
      <div class="login_box">
        <b-form @submit.stop.prevent="resetPassword">
          <b-row>
            <b-col cols="12" class="px-2">
              <img src="/assets/images/logo/logo.png" style="width:35% !important;text-align:center">
              <h2 class="card-title fw-bold mt-3">Reset Password? 🔒</h2>
              <p class="card-text mb-0">Provide details below to reset your password</p>
            </b-col>
          </b-row>
          <b-form-group label="" label-for="password">
            <b-icon @click="showPassword = !showPassword" :icon="showPassword ? 'eye-slash':'eye'"></b-icon>
            <b-form-input
              placeholder="Password"
              id="password"
              name="example-input-1"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="password-feedback"
              :type="showPassword ? 'text':'password'"
            ></b-form-input>
            <b-form-invalid-feedback
              id="password-feedback"
            >This is a required field</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="" label-for="cpassword">
            <b-icon @click="showPassword = !showPassword" :icon="showPassword ? 'eye-slash':'eye'"></b-icon>
            <b-form-input
              placeholder="Confirm Password"
              id="cpassword"
              name="example-input-1"
              v-model="$v.form.password_confirm.$model"
              :state="validateState('password_confirm')"
              aria-describedby="cpassword-feedback"
              :type="showPassword ? 'text':'password'"
            ></b-form-input>
            <b-form-invalid-feedback
              id="cpassword-feedback"
            >This is a required field</b-form-invalid-feedback>
          </b-form-group>
          <b-row>
            <b-col cols="12" style="display:flex;justify-content:flex-end">
              <b-button variant="primary" type="submit" class="primary" :disabled="$v.form.$invalid">
                <b-spinner small v-if="loading"></b-spinner> Confirm password
              </b-button>
            </b-col>
            <p class="text-center" style="margin-top:1rem"><router-link :to="{ name: 'Login' }"><span>&nbsp; Back to login</span></router-link></p>
          </b-row>
        </b-form>
      </div>
    </div>
  </div>
</template>
<style scoped>
input{
  font-weight:500 !important;
}
.card-title{
  font-size: 16px;
}
.form{
  background: rgba(255, 255, 255);
  width: 350px;
  margin: auto;
  text-align: center;
  border-radius: 10px;
}

h1{
  margin: 0;
}

.login_box{
  padding: 20px 20px;
}

.login_box input{
  width: 100%;
  margin: 5px auto;
  box-sizing: border-box;
  border: 1px solid;
  border-radius: 2px;
  padding: 8px 5px;
}

p{
  font-size: 13px;
  padding: 5px 0;
}

.sign_in{
  width: 100%;
  background: rgba(110, 188, 133, 0.96);
  border: 0;
  border-radius: 6px;
  padding: 8px 0;
  font-size: 15px;
  box-shadow: 1px 1px 3px 0;
}

.sign_in:hover{
  background: black;
  color: rgba(110, 188, 133, 0.96);
  transition: 0.3s ease;
}

button{
  width: 40%;
  border: 0.1px solid;
  border-radius: 6px;
  padding: 7px 5px;
  font-size: 13px;
}

button:hover{
  transition: 0.3s ease;
}

span{
  font-weight: bold;
}

span:hover{
  text-decoration: underline;
}
</style>
<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: 'ResetPasswordForm',
    components: {
    },
    data: () => ({
      showPassword: false,
      value: String,
      cvalue: String,
      valid: true,
      type: 'password',
      fieldRules: [
      (v) => !!v || 'Field is required'
      ],
      emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      loading: false,
      disabled: false,
      form: {
        email: null,
        password: null,
        password_confirm: null,
      },
    }),
    validations: {
        form: {
          email: {
            required
          },
          password: {
            required
          },
          password_confirm: {
            required
          }
        }
      },
    computed: {
      ...mapGetters([
        'isLogged'
        ])
    },
    methods: {
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
       resetPassword() {

        this.$v.form.$touch();
          if (this.$v.form.$anyError) {
            return;
          }

        this.loading = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
         let fd = new FormData();
          fd.append('token',this.$route.params.token)
          fd.append('password',this.form.password)
          fd.append('password_confirm',this.form.password_confirm)

          axios.post("/reset", fd, config).then(response=>{

            this.loading = false

            if(response.data.status === 404) {
              this.$toastpopup('danger','Warning',response.data.message)
            }
            if(response.data.status === 400) {
              this.$toastpopup('danger','Warning',response.data.message)
            }
            if(response.data.status === 200) {
              this.$toastpopup('success','Info','Password reset was successful')
              this.$router.push({ name: 'Login' })
            }

            
          })
          .catch(() => {

            this.loading = false

            this.$toastpopup('danger','Warning','Passwords does not match')

          })

        }
    }
}
</script>