<template>
	<div>
	  <!-- client profile modal -->
    <b-modal
      v-model="show"
      size="payment"
      >
      <template #modal-title>
        <div class="w-100">
          <div class="section_title"><span class="title">Record Payment&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
        </div>
      </template>
      <b-container fluid>
        <b-row class="mx-0 my-0 py-0">
          <b-col sm="12" md="6" class="float_label mb-2">
           
              <b-form-input
                type="text"
                placeholder="Amount"
                v-model="$v.item.amount.$model"
                :state="validateState('amount')"
                size="sm"
              ></b-form-input>
            <label class="typo__label float_label_item">Amt*</label>
          </b-col> 
          <b-col cols="12" sm="12" md="6" class="float_label mb-2">
            
            <b-form-datepicker
              id="datepicker-invoice"
              :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
              :hide-header="true"
              locale="en"
              size="sm"
              placeholder="Select date"
              v-model="$v.item.date.$model"
              :state="validateState('date')"
            ></b-form-datepicker>
            <label class="typo__label float_label_item">Date*</label>
          </b-col>
          <b-col
              cols="12"
              sm="12"
              md="6"
            class="float_label mb-2"
           >
            
            <multiselect ref="modesselect" 
                    v-model="$v.item.payment_mode_id.$model"
                    :state="validateState('payment_mode_id')"
                    :close-on-select="true"
                     track-by="id" label="name" placeholder="Select payment mode" :options="modes" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Mode*</label>
          </b-col>
          <b-col sm="12" md="6" class="float_label mb-2">
            
              <b-form-input
                type="text"
                placeholder="Reference no"
                v-model="$v.item.reference_no.$model"
                :state="validateState('reference_no')"
                size="sm"
              ></b-form-input>
            <label class="typo__label float_label_item">Ref #*</label>
          </b-col> 
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="submit" :disabled="$v.item.$invalid">
            <b-spinner small v-if="loading"></b-spinner> Submit
          </b-button>
      </template>
    </b-modal>
    <!-- end client profile -->
	</div>
</template>

<style>
  .modal-payment{
    max-width:550px !important;
  }
</style>

<script>
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import Vue from 'vue'
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
	name: 'payment',
	components: {
	},
	data: () => ({
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        item:{
           amount: "",
           payment_mode_id: null,
           reference_no: "",
           date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        },
       modes: []
	}),
  validations: {
    item:{
      amount:{
        required
      },
      payment_mode_id:{
        required
      },
      reference_no:{
        required
      },
      date:{
        required
      },
    },
  },
	methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.item[name];
      return $dirty ? !$error : null;
    },
    async getData(){
        this.$store.state.componentloader = true
        await axios.get('/payment/modes').then(response=>{
            this.$store.state.componentloader = false
            if(response.data.status == parseInt(200)) {
              this.modes = response.data.modes
            }
        }).catch(error=>{
          this.$store.state.componentloader = false
          console.log(error)
        })
      },
    submit(){
      this.loading = true

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      let fd = new FormData();
      fd.append('id',this.invoiceid)
      fd.append('payment_mode_id',JSON.stringify(this.item.payment_mode_id))
      fd.append('amount',this.item.amount)
      fd.append('reference_no',this.item.reference_no)
      fd.append('date',this.item.date)

      axios.post('/storeinvoicepayment', fd, config).then(response=>{
          this.loading = false
        if(response.data.status == parseInt(200)) {

          this.getInvoices();
          this.getDetails();          

          this.$toastpopup('success','Success','Payment submitted successfully')
          this.clear()
          this.show = false
        }
          
        })
        .catch(() => {
          this.loading = false
        })
    },
    async getInvoices(){
      this.$store.state.tableloader = true
      this.$store
        .dispatch('getShowInvoices')
    },
    async getDetails() {
      this.$store.state.tableloader1 = true
      await axios.get(`/invoice/vat/${this.itemid}`).then(response=>{
        this.$store.state.tableloader1 = false
          if(response.data.status == parseInt(200)) {
            this.$store.state.vat = response.data.vat
            this.$store.state.invoicedetail = response.data.invoice
          }
      }).catch(error=>{
        this.$store.state.tableloader1 = false
          console.log(error)
      })
    },
    clear() {
      this.item.payment_mode_id = null
      this.item.amount = ""
      this.item.reference_no = ""
      this.item.date = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
    }
	},
  watch: {
      itemid: function(newVal, oldVal) {
        if(newVal !== null) {
          this.getData()
        }
      },
  },
	props: {
	     value: Boolean,
	     invoiceid: Number
	  },
	  computed: {
      loader() {
        return this.$store.state.componentloader
      },
      itemid() {
        return this.$store.state.itemid
      },
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    }
	  },
	mounted() {
    this.getData()
  },
	created() {}
}
</script>