<template>
	<div>
    <!-- document dialog -->
    <b-modal
      v-model="show"
      size="document"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Documents</span>
        </div>
      </template>
      <b-container fluid>
        <b-row  v-if="documents.length > 0">
          <b-col
          cols="12"
          sm="12"
          md="12"
          >
            <b-alert
              text
              color="primary"
              v-for="(document,index) in documents"
              :key="index"
              class="pt-3 my-1"
              >
              <b-row
                align="center"
                no-gutters
                class="my-0 py-0"
                style="display:flex;align-items:center;justify-content:space-between"
                >
                <div class="d-flex" style="font-size:.75rem">{{ document.name }}</div>
                <b-col class="shrink" style="display:flex;align-items:center;justify-content:space-between">
                  <b-btn
                    color="primary"
                    outlined
                    @click="viewdocument(document.id)"
                    class="mx-1"
                  >
                    View
                  </b-btn>
                  <b-btn
                    color="red"
                    outlined
                    :loading="loading1"
                    @click="removedocument(document.id)"
                    class="mx-1"
                  >
                    Remove
                  </b-btn>
                </b-col>
              </b-row>
            </b-alert>
          </b-col>
        </b-row>
        <b-row
          v-for="(document, indx) in docs" 
            :key="indx"
            style="display:flex;align-items:center;justify-content:space-between"
            class="mx-0 mb-3"
          >
          <b-col sm="12" md="5" class="float_label">
              
                <b-form-input
                  placeholder="Document name"
                  :name="`docs[${indx}][name]`"
                  required
                  size="sm"
                  v-model="document.name"
                ></b-form-input>
              <label class="typo__label float_label_item">Name*</label>
            </b-col>
            <b-col sm="12" md="6" class="float_label">
              <label class="typo__label no_floating">Document*</label>
              <b-form-group>
                <b-form-file id="file-small" size="sm" v-on:change="onChange($event,indx)"
                  :name="`docs[${indx}][file]`"></b-form-file>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              sm="12"
              md="1"
              class="px-0"
              >
              <div style="display:flex;align-items:center;justify-content:center">
                <b-icon @click="addDocument" class="mx-1" icon="plus-circle"></b-icon>
                <b-icon @click="removeDocument(indx)" icon="x-circle"></b-icon>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <template #modal-footer>
            <b-button variant="primary" class="primary" @click="submit">
              <b-spinner small v-if="loading"></b-spinner> Submit
            </b-button>
        </template>
    </b-modal>
    <!-- end document dialog -->

    <b-modal
      v-model="pdfdialog"
      centered 
      size="sm"
      hide-footer
      >
      <template #modal-title>
        <div class="w-100" v-if="results.length > 0">
          <div class="app-header" v-if="results[0].extension === 'pdf'">
            <template v-if="isLoading"> Loading... </template>

            <template v-else>
              <span v-if="showAllPages"> {{ pageCount }} page(s) </span>

              <span v-else>
                <button :disabled="page <= 1" @click="page--">❮</button>

                {{ page }} / {{ pageCount }}

                <button :disabled="page >= pageCount" @click="page++">❯</button>
              </span>

              <label class="right">
                <input v-model="showAllPages" type="checkbox" />

                Show all pages
              </label>
            </template>
          </div>
        </div>
      </template>
      <div class="app-content" v-if="results.length > 0">
          <vue-pdf-embed
            ref="pdfRef"
            :source="pdfSource"
            :page="page"
            :annotationLayer="annotationLayer"
            :textLayer="textLayer"
            @password-requested="handlePasswordRequest"
            @rendered="handleDocumentRender"
            v-if="results[0].extension === 'pdf'"
          />
          <v-img :src="pdfSource" style="width:200%" v-else/>
        </div>
    </b-modal>

	</div>
</template>
<style>
  .modal-document{
    max-width:480px !important;
  }
</style>
<style>
.vue-pdf-embed > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}

.app-header {
  padding: 10px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  background-color: #555;
  color: #ddd;
  width:100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.app-content {
  padding: 12px 10px;
  max-height: 70vh;
  overflow: auto;
}

.right {
  float: right;
}
</style>

<script>
import axios from 'axios'
import Vue from 'vue'
// import VuePdfApp from "vue-pdf-app";
// import "vue-pdf-app/dist/icons/main.css";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
	name: 'bookingDocument',
	components: {
    VuePdfEmbed
	},
	data: () => ({
    textLayer: true,
    annotationLayer: true,
    isLoading: true,
    pdfdialog: false,
    loading1: false,
		fieldRules: [
      (v) => !!v || 'Field is required'
      ],
    imageRules: [
      value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
    ],
    valid: true,
    isBusy: false,
    loading: false,
    disabled: false,
    docs: [
      {
        name: "",
        file: null,
      }
    ],
    pdf: "",
    filename: "",
    page: null,
    pageCount: 1,
    pdfSource:
      '',
    showAllPages: true,
    results: []
	}),
	methods: {
    addDocument () {
        this.docs.push({
          name: "",
          file: null,
        })
      },
      onChange(e,indx) {
        if(e) {
          let found = this.docs[indx];
          if (found) {
            found.file = e
          }
        }
      },
      removeDocument (index) {
        this.docs.splice(index, 1);
      },
      updateName(value,indx) {
        if(value !== null) {
          let found = this.docs[indx];
          if (found) {
            found.name = value
          }
        }
      },
      updateFile(value,indx) {
        if(value !== null) {
          let found = this.docs[indx];
          if (found) {
            found.file = e
          }
        }
      },
		async getDocuments(){
        this.$store
          .dispatch('getDocuments',this.bookingid)
          .then(() => {})
          .catch(() => {})
      },
      submit(){
          // if (this.$refs.form.validate()){

            // console.log(this.bookingid)

            this.loading = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            for( var i = 0; i < this.docs.length; i++ ){
              let file = this.docs[i].file;

              fd.append('documents[' + i + ']', file);
              fd.append('name[' + i + ']',this.docs[i].name);
            }
            fd.append('id',this.bookingid)

            axios.post('/booking/documents/save', fd, config).then(response=>{

              if(response.data.status === parseInt(201)) {
                this.$toastpopup('success','Success',"Documents added successfully")
              }

              this.docs = []
              this.docs.push({
                name: "",
                file: null,
              })

              this.show = false
              this.loading = false
              
            })
            .catch(() => {
              this.loading = false
              this.disabled = false
            })

        // }
      },
      removedocument(id){
        this.$store.state.tptableloader = true
        this.loading1 = true
        axios.get(`/remove-document/${this.bookingid}/${id}`).then(response=>{
          this.$store.state.tptableloader = false
          this.loading1 = false
          if(response.data.status == parseInt(200)) {
            let index = this.$store.state.documents.findIndex(data => data.id == response.data.document.id);
            if (index > -1) {
              this.$store.state.documents.splice(index, 1);
            }
            this.$toastpopup('success','Success',"Document removed successfully")
          }
        }).catch(error=>{
          this.$store.state.tptableloader = false
          this.loading1 = false
          console.log(error)
        })
      },
      viewdocument(id){
        this.results = this.documents.filter(booking => booking.id === id )
        if(this.results[0].extension === "pdf") {
          this.pdfSource = {
                              cMapUrl: 'https://unpkg.com/pdfjs-dist/cmaps/',
                              url: `data:application/pdf;base64,${this.results[0].file}`,
                            }
        }else{
          this.pdfSource = `data:application/pdf;base64,${this.results[0].file}`
        }
        this.filename = this.results[0].name
        this.pdfdialog = true
      },
      handleDocumentRender(args) {
        this.isLoading = false
        this.pageCount = this.$refs.pdfRef.pageCount
      },
      handlePasswordRequest(callback, retry) {
        callback(prompt(retry ? 'Enter password again' : 'Enter password'))
      }
	},
  watch: {
    showAllPages() {
      this.page = this.showAllPages ? null : 1
    }
  },
	props: {
	     value: Boolean
	  },
	  computed: {
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    },
      documents() {
        return this.$store.state.documents
      },
      tableloader() {
        return this.$store.state.tptableloader
      },
      bookingid() {
        return this.$store.state.bookingid
      },
	  },
	mounted() {},
	created() {}
}
</script>