<template>
	<b-col cols="4">
      <b-row>
        <b-col md="6" cols="12" sm="12" class="px-1" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;">
            <b-form-datepicker
              id="datepicker-pickup"
              :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
              :hide-header="true"
              locale="en"
              size="sm"
              placeholder="Search by date"
              v-model="$store.state.searchdate"
              @context="selectedDate"
              today-button
              reset-button
              close-button
            ></b-form-datepicker>
        </b-col>
        <b-col md="6" cols="12" sm="12" class="px-1" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;">
          <b-form-input
              placeholder="Search bookings"
              size="sm"
              v-model="search"
              @input.native="searchBooking($event)"
            ></b-form-input>
        </b-col>
      </b-row>
    </b-col>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'

export default {
	name: 'bookingSearch',
	components: {
	},
	data () {

    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(today)

    return{
      min: minDate,          
      valid: true,
      isBusy: false,
      loading: false,
      disabled: false,
      search: "",
      menu:false,
  	}
  },
	methods: {
		selectedDate(dateselected) {
      if(dateselected.selectedYMD === null || dateselected.selectedYMD === '') {
        this.$store.commit('clearDate')
        this.$store.state.tableloader = true
        this.$store.dispatch('getBookings')
      }else {
        this.$store.state.searchdate = dateselected.selectedYMD
        this.$store.state.tableloader = true
        this.$store.dispatch('loadbookingsbydate',dateselected.selectedYMD)
      }
      
		},
		onClearClicked () {
        this.search = ''
      },
      clearDate() {
      	
      },
      searchBooking(value) {
      	this.$store.commit('searchBooking',this.search)
      }
	},
	props: {},
	  computed: {
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    },
	    date: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$store.state.searchdate = value
	      }
	    },
	  },
	mounted() {

	},
	created() {
     
    }
}
</script>