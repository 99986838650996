// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
// import router from './router'
import routes from './router'

import Vuex from 'vuex'
import VueRouter from 'vue-router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import moment from 'moment'
import { abilitiesPlugin } from '@casl/vue';
import {ability} from '@/services/ability';
import { Can } from '@casl/vue'
import VirtualScroller, { RecycleScroller as Comp } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import Vue2Filters from 'vue2-filters'
import * as VueGoogleMaps from 'vue2-google-maps'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import VueTheMask from 'vue-the-mask'
import BookingUtils from "@/utils/booking_util"

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import { store } from '@/store/index'
// highcharts
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import HighchartsVue from 'highcharts-vue'
// import "@/assets/fonts/fontface.css"
import '@/assets/main.css'
import '@/assets/sidebarmenu.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'

Vue.component('multiselect', Multiselect)
Vue.config.productionTip = false


Vue.use(VueTheMask)
Vue.use(VirtualScroller)
Vue.use(BookingUtils)

Vue.prototype.moment = moment
Vue.filter('date', function(val) {
    if (val) {
        return val ? moment(val.toLocaleString()).format("YYYY-MM-DD") : ''
    }
})

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format("MMM Do, YYYY")
    }
})
Vue.filter('dateNo', function(value) {
    if (value) {
        return moment(String(value)).format('D')
    }
})
Vue.filter('dateName', function(value) {
    if (value) {
        return moment(String(value)).format('ddd')
    }
})
Vue.filter('dateF', function(value) {
    if (value) {
        return moment(String(value)).format("MMM Do, YYYY")
    }
})
Vue.filter('timeF', function(value) {
    if (value) {
        return moment(String(value)).format('LT')
    }
})
Vue.filter('timeremaining', function(value) {
    if (value) {
        return moment(String(value)).fromNow()
    }
})
Vue.filter('minutesremaining', function(value) {
    if (value) {
      var nowtime = moment()
      value = moment(String(value))

      var duration = moment.duration(value.diff(nowtime))
      var minutes = parseInt(duration.asMinutes())
        return minutes
        // return moment(String(value)).fromNow()
    }
})
Vue.use(Vue2Filters)

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBOq3xAvXV2SsaJPxNk3iz4XNIKPdz4gRc",
    libraries: ["places", "geometry"],
    region: 'KE'
  }
})

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(HighchartsVue)
Vue.use(Vuex)
Vue.use(VueAxios, axios)

const router = new VueRouter({
  mode: 'history',
  routes
})
router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user')

  if (to.matched.some(record => record.meta.auth) && !loggedIn) {
    next('/login')
    return
  }else {
    next()
  }
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  created () {
    const userInfo = localStorage.getItem('user')
    if (userInfo) {
      const userData = JSON.parse(userInfo)
      this.$store.commit('setUserData', userData)
    }
    axios.interceptors.response.use(
      response => response,
      error => {
        console.log(error)
        if (typeof error.response.status === 'undefined') {
          console.log('Variable is undefined');
        }else {
          if (error.response.status === 401) {
            this.$store.dispatch('logout')
          }
        }
        
        return Promise.reject(error)
      }
    )
  },
  components: { App },
  template: '<App/>'
})
