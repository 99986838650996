<template>
  <div class="about mt-0">
    <div class="mx-0 px-0" style="position:relative">
      <div class="flex" :class= "[mini ? 'minipadding' : 'expandpanding']">
      	<div class="section_title"><span class="title">Organisation Projects&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
      	<div>
      		<b-button variant="primary" class="primary" size="sm" @click="editOrg(0)">Add Project <b-icon class="mr-1" icon="plus"></b-icon></b-button>
      	</div>
      </div>
      <b-row class="subsection">
      	<b-col sm="12" xl="12" md="12" class="mx-0 px-3">
      		<div class="dynamic-scroller projects">
            <b-row class="mx-2 mb-2 searchsection" style="display:flex;justify-content:flex-end;align-items:center">
              <div class="px-1" style="width:200px !important;padding-top:0 !important;padding-bottom:0 !important;display:flex;">
                <b-form-input
                    placeholder="Search..."
                    size="sm"
                    v-model="searchfilter"
                  ></b-form-input>
              </div>
            </b-row>
            <div class="empty-state" v-if="allprojects.length === 0">
              <div class="empty-state__content">
                <div class="empty-state__icon">
                  <img src="@/assets/data.svg" style="width:40%">
                </div>
                <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                <div class="empty-state__message" v-else>No records available.</div>
              </div>
            </div>
      			<RecycleScroller
		          ref="scroller"
		          class="scroller"
		          :items="allprojects"
		          :item-size="24"
		          key-field="id"
		        >
		          <template  v-slot="{ item,index }">
		            <div>
    					    <div class="tcell">{{ item.unit }}</div>
                  <div class="tcell">{{ item.name }}</div>
                  <div class="tcell">{{ item.organisation.name }}</div>
    					    <div class="tcell text-center">
    					      	<a class="list-action" :id="`popover-target-1-${item.id}-${index}`">
      						  		<b-icon class="my-0 py-0" style="cursor:pointer;" icon="chevron-down"></b-icon>
      						    </a>
      						    <b-popover
        							  :target="`popover-target-1-${item.id}-${index}`"
        							  triggers="hover blur"
        							  placement="left"
        							  class="dropdown-menu"
        							>
        							  <div class="action-dropdown-menu">
        						  		<button @click="viewsubprojects(item.id)" v-b-toggle.sidebar-backdrop class="action-dropdown-item " type="button"><b-icon class="" icon="chevron-expand"></b-icon>&nbsp;  Sub Projects</button>
                          <button @click="editOrg(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="pencil"></b-icon>&nbsp;  Edit</button>
        						  		<button @click="deleteItem(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="trash"></b-icon>&nbsp;  Remove</button>
        						  	</div>
        							</b-popover>
    					    </div>
    					  </div>
		          </template>
		        </RecycleScroller>

			  </div>
      	</b-col>
      </b-row>
    </div>

    <!-- project modal -->
    <b-modal
      v-model="updatedialog"
      size="project"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left" v-if="item.updateID !== ''">Update Project</span>
          <span class="title float-left" v-else>Add Project</span>
        </div>
      </template>
      <b-container fluid>
        <b-row>
          <b-col
            sm="12" md="12"
            class="float_label mb-3"
           >
            
            <multiselect ref="organisationmultiselect" 
                    v-model="item.updateorganisation_id"
                     track-by="id" label="name" placeholder="Organisation" :options="organisations" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Organisation*</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6" class="float_label mb-3">
              <b-form-input
                placeholder="Code"
                size="sm"
                v-model="item.updateunit"
              ></b-form-input>
            <label class="typo__label float_label_item">Code</label>
          </b-col>
          <b-col sm="12" md="6" class="float_label mb-3">
            
              <b-form-input
                placeholder="Name"
                size="sm"
                v-model="item.updatename"
              ></b-form-input>
            <label class="typo__label float_label_item">Name</label>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="update" v-if="item.updateID !== ''">
            <b-spinner small v-if="loading"></b-spinner> Update
          </b-button>
          <b-button variant="primary" class="primary" @click="create" v-else>
            <b-spinner small v-if="loading"></b-spinner> Save
          </b-button>
      </template>
    </b-modal>
    <!-- end project modal -->

    <b-modal
      v-model="subdialog"
      size="project"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Add Item</span>
        </div>
      </template>
      <b-container fluid>
        <b-row v-for="(subcode, index) in subcodes" :key="index" class="mb-3">
          <b-col sm="12" md="5" class="float_label">
            
              <b-form-input
                placeholder="Code"
                size="sm"
                v-model="subcode.code" 
                :name="`subcodes[${index}][code]`"
              ></b-form-input>
            <label class="typo__label float_label_item">Code</label>
          </b-col>
          <b-col sm="12" md="5" class="float_label">
            
              <b-form-input
                placeholder="Name"
                size="sm"
                v-model="subcode.name" 
                :name="`subcodes[${index}][name]`"
              ></b-form-input>
            <label class="typo__label float_label_item">Name</label>
          </b-col>
          <b-col sm="12" md="1" class="addremoveicons">
            <b-icon class="mx-2" @click="addSubcode" color="#F9780D" icon="plus-circle"></b-icon>
            <b-icon color="#DC3545" @click="removeSubcode(indx)" icon="x-circle"></b-icon>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="createsub">
            <b-spinner small v-if="loading"></b-spinner> Save
          </b-button>
      </template>
    </b-modal>

    <!-- update sub project -->
    <b-modal
      v-model="subupdatedialog"
      size="project"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Update Item</span>
        </div>
      </template>
      <b-container fluid>
        <b-row class="">
          <b-col sm="12" md="12" class="float_label mb-3">
            
              <b-form-input
                placeholder="Code"
                size="sm"
                v-model="item.updatesubunit" 
              ></b-form-input>
            <label class="typo__label float_label_item">Code</label>
          </b-col>
          <b-col sm="12" md="12" class="float_label mb-3">
            
              <b-form-input
                placeholder="Name"
                size="sm"
                v-model="item.updatesubname" 
              ></b-form-input>
            <label class="typo__label float_label_item">Name</label>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="updatesub">
            <b-spinner small v-if="loading"></b-spinner> Update
          </b-button>
      </template>
    </b-modal>

    <!-- subprojects details -->
    <b-sidebar
      id="sidebar-backdrop"
      title="Sub projects"
      backdrop
      shadow
      right
      width="450px"
      no-header
      >
      <div class="dflex">
        <div class="section_title"><span class="title">Sub projects&nbsp;&nbsp;</span></div>
        <div>
          <b-button variant="light" class="light" size="sm" @click="editSub(item.projectId,0)">Add <b-icon class="mr-1" icon="plus"></b-icon></b-button>
        </div>
      </div>
      <div class="px-0 py-2">
        <table class="table bordered">
          <thead>
            <tr>
              <th style="padding-left:.7rem !important">Code</th>
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(sub,index) in subprojects" :key="index">
              <td style="padding-left:.7rem !important">{{ sub.unit }}</td>
              <td>{{ sub.name }}</td>
              <td>
                <a class="list-action" :id="`popover-target-2-${sub.id}`">
                    <b-icon class="my-0 py-0" style="cursor:pointer;" icon="chevron-down"></b-icon>
                  </a>
                  <b-popover
                    :target="`popover-target-2-${sub.id}`"
                    triggers="hover focus"
                    placement="left"
                    class="dropdown-menu"
                  >
                    <div class="action-dropdown-menu">
                      <button @click="editSub(item.projectId,sub.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="pencil"></b-icon>&nbsp;  Edit</button>
                      <button @click="deleteSubItem(sub.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="trash"></b-icon>&nbsp;  Remove</button>
                    </div>
                  </b-popover>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <template #footer="{ hide }">
       <div class="d-flex bg-light text-light align-items-end px-3 py-2">
        <b-button size="sm" @click="hide">Close</b-button>
       </div>
      </template>
    </b-sidebar>

  </div>
</template>

<style>
	@import "@/assets/table/table";
  .modal-project{
    max-width:540px !important;
  }
	.action-dropdown-menu .action-dropdown-item:hover{
		background:var(--ab-primary);
		color:#fff;
		border-radius:var(--ab-dropdown-border-radius);
	}
	.action-dropdown-menu{
		padding-right: .5rem;
	}
  .projects .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-template-columns: 10% 30% 50% 10%;
     grid-gap: 0px;
     min-width:100%;
  }
  .projects .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }
</style>
<style scoped>
	@import "@/assets/table/table_scoped";
  .projects .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-template-columns: 10% 30% 50% 10%;
     grid-gap: 0px;
  }
  
</style>

<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';

 export default {
  name: 'orgProjectList',
  components: {
  },
  computed: {
	  mini() {
	    return this.$store.state.mini
	  },
    loader() {
    	return this.$store.state.tableloader
    },
    organisations() {
      return this.$store.state.porganisations
    },
    subprojects() {
      return this.$store.state.psubprojects
    },
    sprojects () {
        return this.$store.state.pprojects.filter(item => {

            this.start = 0

          return item.name.toLowerCase().includes(this.searchfilter.toLowerCase()) || item.organisation.name.toLowerCase().includes(this.searchfilter.toLowerCase())
        })
      },

      allprojects () {
        return this.sprojects
      },
  },
  data: () => ({
      searchfilter:'',
      subdialog: false,
      subupdatedialog: false,
      subprojectsdialog: false,
      search: '',
      dialog: false,
      updatedialog: false,
      loading: false,
      subcodes: [
            {
              code: "",
              name: ""
            }
          ],
      fields: [
        { text: 'Code', value: 'unit' },
        { text: 'Name', value: 'name' },
        { text: 'Organisation', value: 'organisation' },
        { text: 'Action', value: 'action' },
        // { text: '', value: 'data-table-expand' },
       ],
      item:{
          updateorganisation_id:"",
          updatename:"",
          updateunit:"",
          updateID: "",
          projectId: "",
          _method:"patch"
      },
  }),
  beforeMount() {
      if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
    },
  mounted() {

  	let headertitle = `<div class="view-titleheader titlehead" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="theader">${value.text}</div>`;
         });
      headertitle +=`</div>`;
      $('.vue-recycle-scroller').prepend(headertitle)

  	if(!this.sprojects || this.sprojects.length == 0) {
        this.getProjects()
      }
  },
  serverPrefetch () {
    // this.getProjects()
  },
  methods: {
  	addSubcode () {
        this.subcodes.push({
          code: '',
          name: ''
        })
      },
      removeSubcode (index) {
        this.subcodes.splice(index, 1);
      },
      getRowClass(item) {
        return 'row-color' 
      },
      async getProjects(){
        this.$store.state.tableloader = true
        await axios.get('/projects').then(response=>{
            this.$store.state.tableloader = false
            this.$store.state.pprojects = response.data.projects
            this.$store.state.porganisations = response.data.organisations
        }).catch(error=>{
            console.log(error)
            this.$store.state.tableloader = false
        })
      },
      clear() {
        this.item.updatename = ''
        this.item.updateunit = ''
        this.item.updateorganisation_id = []
        this.item.updateID = ''
      },
     create(){

          this.loading = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('name',this.item.updatename)
          fd.append('unit',this.item.updateunit)
          fd.append('organisation_id',JSON.stringify(this.item.updateorganisation_id))

         axios.post('/projects', fd, config).then(response=>{
            this.loading = false
              this.$toastpopup('success','Success','Project added successfully')
              this.getProjects()
            this.clear()
          }).catch(error=>{
            this.loading = false
              console.log(error)
          })

      },
      async update(){

        this.loading = true
        this.disabled = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
           fd.append('name',this.item.updatename)
           fd.append('unit',this.item.updateunit)
          fd.append('organisation_id',JSON.stringify(this.item.updateorganisation_id))
          fd.append('_method',this.item._method)

          await axios.post(`/projects/${this.item.updateID}`, fd, config).then(response=>{
            this.loading = false
              this.$toastpopup('success','Success','Project updated successfully')
              this.updatedialog = false
              this.getProjects()
            this.clear()
          }).catch(error=>{
            this.loading = false
              console.log(error)
          })

      },
     editOrg(id) {
        let index = this.$store.state.pprojects.filter(item => item.id === id )
        if(index.length > 0) {

          this.item.updatename = index[0].name
          this.item.updateunit = index[0].unit
          this.item.updateorganisation_id = index[0].organisation
          this.item.updateID = index[0].id
          this.updatedialog = true

        }else {
          this.item.updateID = ''
          this.clear()
          this.updatedialog = true
        }
        
      },
      viewsubprojects(id) {
        this.item.projectId = id
        let index = this.$store.state.pprojects.filter(item => item.id === id )
        this.$store.state.psubprojects = index[0].subprojects
        this.subprojectsdialog = true     
      },
      deleteItem(id){
            if(confirm("Are you sure to delete project ?")){
                axios.delete(`/projects/${id}`).then(response=>{
                  this.getProjects()
                  this.$toastpopup('success','Success','Project deleted successfully')
                }).catch(error=>{
                    console.log(error)
                })
            }
        },


     createsub(){


          this.loading = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('subprojects',JSON.stringify(this.subcodes))
          fd.append('id',this.item.projectId)

          axios.post('/org/subprojects', fd, config).then(response=>{
            this.loading = false
            if(response.data.status === 200) {
            
              this.$toastpopup('success','Success','Item added successfully')
              this.subdialog = false
              this.subcodes = []
              this.addSubcode()
              this.getProjects()

            }
          }).catch(error=>{
            this.loading = false
              console.log(error)
          })

      },
  
    updatesub(){

      this.loading = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let fd = new FormData();
        fd.append('_method',this.item._method)
        fd.append('id',this.item.projectId)
        fd.append('name',this.item.updatesubname)
        fd.append('unit',this.item.updatesubunit)

        axios.post(`/org/subprojects/${this.item.updatesubID}`, fd, config).then(response=>{
          this.loading = false
          if(response.data.status === 200) {
          
              this.$toastpopup('success','Success','Item updated successfully')
              this.subupdatedialog = false
              this.item.updatesubname = ''
              this.item.updatesubunit = ''
              this.getProjects()
          }
        }).catch(error=>{
          this.loading = false
            console.log(error)
        })

    },

    editSub(id,subId) {
      let index = this.$store.state.pprojects.filter(item => item.id === id )
      if(index.length > 0) {
        let subindex = index[0].subprojects.filter(item => item.id === subId )
        if(subindex.length > 0) {

          this.item.projectId = index[0].id
          this.item.updatesubname = subindex[0].name
          this.item.updatesubunit = subindex[0].unit
          this.item.updatesubID = subindex[0].id
          this.subupdatedialog = true

        }else {
          this.item.projectId = index[0].id
          this.item.updatesubID = ''
          this.subdialog = true
        }

      }
      
    },
    deleteSubItem(id){
          if(confirm("Are you sure to delete item ?")){
              axios.delete(`/subprojects/${id}`).then(response=>{
                if(response.data.status === 200) {
                  
                  this.$toastpopup('success','Success','Item removed successfully')
                  this.getProjects()
                }
              }).catch(error=>{
                  console.log(error)
              })
          }
      }
  }
};

</script>
