<template>
  <div class="about mt-0">
    <div class="mx-0 px-0">
      <div class="flex" :class= "[mini ? 'minipadding' : 'expandpanding']">
      	<div class="section_title"><span class="title">Edit Invoice&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
      	<div>
      	</div>
      </div>
      
      <b-container fluid class="subsection">
        <b-row>
        	<b-col sm="12" xl="9" md="12" lg="10">
        		<b-form style="background:var(--vt-c-white);" class="px-3 py-3 mb-1">
              <b-row class="mb-4" style="display:flex;justify-content:space-between;align-items:center;height:2rem">
                <b-col sm="12" md="6" style="display:flex;justify-content:flex-start;align-items:center">
                  <b-form-checkbox name="check-individual" switch v-model="individualchecked">
                    Individual Bookings
                  </b-form-checkbox>
                  <b-form-checkbox name="check-invoice" class="mx-3" switch v-model="custominvoice">
                    Custom Invoice
                  </b-form-checkbox>
                  <b-form-checkbox name="check-invoice" class="mx-3" switch v-model="includevat" v-if="custominvoice">
                    Include VAT ?
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" md="4" style="display:flex;justify-content:flex-end" v-if="!custominvoice">
                    <b-button variant="light" class="secondary" @click="fetchinvoice">
                      <b-spinner small v-if="loading"></b-spinner> Select Invoice Data
                    </b-button>
                  </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" md="4" class="float_label mb-3">
                  <label class="typo__label no_floating">Date*</label>
                    <date-range-picker
                      ref="picker"
                      :showWeekNumbers="showWeekNumbers"
                      :showDropdowns="showDropdowns"
                      :autoApply="autoApply"
                      opens="right"
                      :always-show-calendars="alwaysshowcalendars"
                      v-model="dateRange"
                      @select="updateValues"
                    >
                        <template v-slot:input="picker" style="min-width: 100%;">
                            <b-form-input
                              placeholder="Select date range"
                              size="sm"
                              type="text"
                              v-model="rangedate"
                            ></b-form-input>
                        </template>
                    </date-range-picker>
                </b-col>
                <b-col cols="12"
                  sm="12" md="4"
                  class="float_label mb-3"
                 >
                  
                  <multiselect ref="requestmultiselect" 
                          v-model="requests.request_category" placeholder="Select request category" :options="requestTypes" :searchable="true" :allow-empty="false" :show-labels="false">
                    <span slot="noResult">Oops! Record not found.</span>
                  </multiselect>
                  <label class="typo__label float_label_item">Request*</label>
                </b-col> 
                <b-col cols="12" sm="12" md="4" class="float_label mb-3">
                  
                    <b-form-input
                      type="text"
                      placeholder="Invoice #"
                      v-model="requests.invoice_no"
                      size="sm"
                    ></b-form-input>
                  <label class="typo__label float_label_item">Inv #*</label>
                </b-col> 
                 <b-col
                    v-if="individualchecked"
                      cols="12"
                      sm="12"
                      md="4"
                    class="float_label mb-3"
                   >
                    
                    <multiselect ref="customermultiselect" 
                            v-model="requests.customer_id"
                            :close-on-select="true"
                            :multiple="true"
                            :preserve-search="false"
                             track-by="id" label="name" placeholder="Select passenger" :options="customers" :searchable="true" :allow-empty="true" :show-labels="false">
                      <span slot="noResult">Oops! Record not found.</span>
                      <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                    </multiselect>
                    <label class="typo__label float_label_item">Passenger*</label>
                  </b-col> 
                  <b-col
                    v-if="!individualchecked"
                      cols="12"
                      sm="12"
                      md="4"
                    class="float_label mb-3"
                   >
                    
                    <multiselect ref="orgmultiselect" 
                            v-model="requests.organisation_id"
                            :close-on-select="true"
                            :multiple="true"
                             track-by="id" label="name" placeholder="Select organisation" :options="organisations" :searchable="true" :allow-empty="true" :show-labels="false">
                      <span slot="noResult">Oops! Record not found.</span>
                      <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                    </multiselect>
                    <label class="typo__label float_label_item">Organisation*</label>
                  </b-col> 
                  <b-col
                    v-if="!individualchecked"
                      cols="12"
                      sm="12"
                      md="4"
                    class="float_label mb-3"
                   >
                    
                    <multiselect ref="project_idmultiselect" 
                            v-model="requests.project_id"
                            :close-on-select="true"
                            :multiple="true"
                            :preserve-search="false"
                             track-by="id" label="name" placeholder="Select project" :options="projects" :searchable="true" :allow-empty="true" :show-labels="false">
                      <span slot="noResult">Oops! Record not found.</span>
                      <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                    </multiselect>
                    <label class="typo__label float_label_item">Project</label>
                  </b-col> 
                  <b-col sm="12" md="4" class="float_label mb-3">
                    
                      <b-form-datepicker
                        id="datepicker-invoice"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        :hide-header="true"
                        locale="en"
                        size="sm"
                        placeholder="Select date"
                        v-model="date"
                      ></b-form-datepicker>
                      <label class="typo__label float_label_item">Inv Date*</label>
                  </b-col>
                  <b-col sm="12" md="4" class="float_label mb-3">
                    
                      <b-form-datepicker
                        id="datepicker-due"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        :hide-header="true"
                        locale="en"
                        size="sm"
                        placeholder="Select date"
                        v-model="duedate"
                      ></b-form-datepicker>
                      <label class="typo__label float_label_item">Due Date*</label>
                  </b-col>
                  <b-col sm="12" md="4" class="float_label mb-3">
            
                    <b-form-input
                      type="text"
                      placeholder="Subject"
                      v-model="requests.subject"
                      size="sm"
                    ></b-form-input>
                  <label class="typo__label float_label_item">Subject</label>
                </b-col> 
           <!--      <b-col sm="12" md="4" class="float_label mb-3" v-if="custominvoice">
                  
                    <b-form-input
                      type="text"
                      placeholder="Invoice amount"
                      v-model="requests.amount"
                      size="sm"
                    ></b-form-input>
                  <label class="typo__label float_label_item">Inv Amt</label>
                </b-col>  -->
              </b-row>
    		    </b-form>
        	</b-col>
        </b-row>
        <b-row v-if="!custominvoice">
          <b-col sm="12" xl="9" md="12" lg="10">
            <div style="height:3rem">
              <label class="form-checkbox" style="font-size:.7rem !important;font-weight:600;color:#333;text-transform:uppercase;margin:1rem">
                <input type="checkbox" v-model="selectAll" @click="select">
                Select All
              </label>

              <b-button variant="secondary" class="secondary" v-if="selected.length > 0"
                style="margin-right:2rem" @click="updateselected">
                Transfer records
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-bottom:5rem !important" v-if="!custominvoice">
          <b-col sm="12" xl="9" md="12" lg="10">
            <div class="dynamic-scroller newinvoice">
              <RecycleScroller
                ref="scroller"
                class="scroller"
                :items="bookings"
                :item-size="24"
                key-field="id"
              >
                <template  v-slot="{ item,index }">
                  <div>
                    <div class="tcell">
                      <label class="form-checkbox">
                        <input type="checkbox" :value="item.id" v-model="selected">
                        <i class="form-icon"></i>
                      </label>
                    </div>
                    <div class="tcell" @click="bookingdetailsupdate(item.id)">
                      <span v-if="item.passenger_name !== null">
                        {{ item.passenger_name }}
                      </span>
                    </div>
                    <div class="tcell">{{ item.pickup_date }} {{ item.pickup_time }}</div>
                    <div class="tcell">
                      <span v-if="item.vehicletype !== null">{{ item.vehicletype.type }}</span>
                      <span v-else>N/A</span>
                    </div>
                    <div class="tcell" @click="editdistance(item.id)">{{ item.distance | currency('') }}</div>
                    <div class="tcell">
                      <div v-if="item.organisationrate.length > 0">
                        <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) | currency('') }}</span>
                        <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) | currency('') }}</span>
                        <span v-else>{{ item.organisationrate[0].base_rate | currency('') }}</span>
                      </div>
                      <div v-else>0.00</div>
                    </div>
                    <div class="tcell" @click="expressway(item.id)">
                      {{ item.expressway.reduce((acc1, value) => { return  acc1 += value.rate.rate}, 0) }}
                    </div>
                    <div class="tcell">
                      <div v-if="item.organisationrate.length > 0">
                        <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                        <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                        <span v-else>{{ ((item.organisationrate[0].base_rate*(vat != null ? vat.rate : 0))/100) | currency('') }}</span>
                      </div>
                      <div v-else>0.00</div>
                    </div>
                    <div class="tcell">
                      <div v-if="item.organisationrate.length > 0">
                        <span v-if="parseInt(item.organisationrate[0].base_rate) === 0">{{ (Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) + ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                        <span v-else-if="parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)">{{ (Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)) + ((Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                        <span v-else>{{ (parseInt(item.organisationrate[0].base_rate) + ((item.organisationrate[0].base_rate*(vat != null ? vat.rate : 0))/100)) | currency('') }}</span>
                      </div>
                      <div v-else>0.00</div>
                    </div>
                    <div class="tcell text-center">
                        <a class="list-action" :id="`popover-target-1-${item.id}-${index}`">
                        <b-icon class="my-0 py-0" style="cursor:pointer;" icon="chevron-down"></b-icon>
                      </a>
                      <b-popover
                        :target="`popover-target-1-${item.id}-${index}`"
                        triggers="hover blur"
                        placement="left"
                        class="dropdown-menu"
                      >
                        <div class="action-dropdown-menu">
                          <button @click="bookingprofile(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="person-check"></b-icon>&nbsp;  Profile</button>
                          <button @click="expressway(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="diagram-3"></b-icon>&nbsp;  Expressway</button>
                          <button @click="waiting(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="hourglass"></b-icon>&nbsp;  Waitings</button>
                          <button @click="voucher(item.ref_no)" class="action-dropdown-item " type="button"><b-icon class="" icon="files-alt"></b-icon>&nbsp;  E-voucher</button>
                        </div>
                      </b-popover>
                    </div>
                  </div>
                </template>
              </RecycleScroller>
          </div>
          </b-col>
        </b-row>

        <b-row v-if="custominvoice">
          <b-col sm="12" xl="9" md="12" lg="10">
            <div class="pb-1" style="background:var(--vt-c-white);">
              <b-row class="mb-2 pt-2 mx-1">
                <b-col sm="12" md="12">
                  <span class="title" style="font-size:.95rem">Items Section</span>
                </b-col>
              </b-row>
              <b-row class="mb-4 mx-1" v-for="(item, indx) in items" :key="indx">
                <b-col sm="12" md="5" class="float_label">
                    <b-form-input
                      type="text"
                      placeholder="Item Details"
                      v-model="item.details" 
                      :name="`items[${indx}][details]`"
                      required
                      size="sm"
                    ></b-form-input>
                    <label class="typo__label float_label_item">Item Details</label>
                </b-col>
                <b-col sm="12" md="2" class="float_label">
                    <b-form-input
                      type="text"
                      placeholder="Qnty"
                      v-model="item.quantity" 
                      :name="`items[${indx}][quantity]`"
                      required
                      size="sm"
                      @input="onQuantityInput($event,indx)"
                    ></b-form-input>
                    <label class="typo__label float_label_item">Qnty</label>
                </b-col>
                <b-col sm="12" md="2" class="float_label">
                    <b-form-input
                      type="text"
                      placeholder="Rate"
                      v-model="item.rate" 
                      :name="`items[${indx}][rate]`"
                      required
                      size="sm"
                      min="1"
                      @input="onRateInput($event,indx)"
                    ></b-form-input>
                    <label class="typo__label float_label_item">Rate</label>
                </b-col>
                <b-col sm="12" md="2" class="float_label">
                    <b-form-input
                      type="text"
                      placeholder="Amount"
                      v-model="item.amount" 
                      :name="`items[${indx}][amount]`"
                      required
                      size="sm"
                      :readonly="readonly"
                    ></b-form-input>
                    <label class="typo__label float_label_item">Amount</label>
                </b-col>
                <b-col sm="12" md="1" class="addremoveicons mt-1">
                  <b-icon class="mx-2" @click="addItem" color="#F9780D" icon="plus-circle"></b-icon>
                  <b-icon color="#DC3545" @click="removeItem(indx)" icon="x-circle"></b-icon>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <div class="flex-footer" :class= "[mini ? 'minipadding' : 'expandpanding']">
        <div>
          <b-button variant="primary" class="primary" @click="save">
            <b-spinner small v-if="loading2"></b-spinner> Update Invoice
          </b-button>

          <b-button variant="secondary" class="secondary mx-2">
            <router-link link :to="{name: 'invoiceList'}" style="color:#333 !important">Close</router-link>
          </b-button>
        </div>
        <b-alert show variant="secondary" class="secondary summarySubtotals px-4">
          <div class="summaryTotals" v-if="!custominvoice">
            <div class="totals">
              <span class="font-normal"> SubTotal</span>
              <span class="font-medium">
                <p  v-if="calcbookingreports.length > 0">
                    {{

                      (calcbookingreports.reduce( (acc, item) => 
                        {  
                            let amt = 0;
                            if(item.organisationrate.length > 0) {
                              if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                              }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                              }else {
                                amt = parseInt(item.organisationrate[0].base_rate)
                              }
                            }
                          return acc += amt; 
                        }, 0) + calcbookingreports.reduce( (acc, item) => 
                      {  

                        let amt = 0, waitingfee = 0, subamt = 0;
                        if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                          if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                              waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                          }
                        }

                        if(item.waiting.length > 0) {
                          subamt = item.waiting.reduce((acc1, value) => { 
                                    let total = 0;
                                    if(value.starttime !== null && value.endtime !== null) {
                                      
                                      if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                        total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                      }

                                    }

                                    return  acc1 += total
                                  }, 0)
                        }

                        amt = subamt + waitingfee;
                        
                        return acc += amt; 

                      }, 0)) | currency('')

                    }}
                  </p>
                  <p>0.00</p>
              </span>
            </div>
            <div class="mx-5 arrow-right"></div>
            <div class="totals">
              <span class="font-normal">Vat({{ vat != null ? vat.rate : 0 }}%)</span>
              <span class="font-medium">
                <p v-if="calcbookingreports.length > 0">
                  {{

                    ((((calcbookingreports.reduce( (acc, item) => 
                      {  
                          let amt = 0;
                          if(item.organisation.vat !== '0') {
                            if(item.organisationrate.length > 0) {
                              if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                amt = Math.round(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)));
                              }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                amt = Math.round(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate)));
                              }else {
                                amt = parseInt(item.organisationrate[0].base_rate)
                              }
                            }
                          }
                        return acc += amt; 
                      }, 0) + calcbookingreports.reduce( (acc, item) => 
                    {  

                      let amt = 0, waitingfee = 0, subamt = 0;
                      if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                        if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                            waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                        }
                      }

                      if(item.waiting.length > 0) {
                        subamt = item.waiting.reduce((acc1, value) => { 
                                  let total = 0;
                                  if(value.starttime !== null && value.endtime !== null) {
                                    
                                    if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                      total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                    }

                                  }

                                  return  acc1 += total
                                }, 0)
                      }

                      amt = subamt + waitingfee;
                      
                      return acc += amt; 

                    }, 0))*(vat != null ? vat.rate : 0))/100)) | currency('')

                  }}
                </p>
                <p v-else>
                  0.0
                </p>
              </span>
            </div>
            <div class="mx-5 arrow-right"></div>
            <div class="totals">
              <span class="font-normal">Total</span>
              <span class="font-medium">
                <p v-if="calcbookingreports.length > 0">
                  {{

                    (calcbookingreports.reduce( (acc, item) => 
                    {  

                      let amt = 0, waitingfee = 0, subamt = 0;
                      if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                        if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                            waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                        }
                      }

                      if(item.waiting.length > 0) {
                        subamt = item.waiting.reduce((acc1, value) => { 
                                  let total = 0;
                                  if(value.starttime !== null && value.endtime !== null) {
                                    
                                    if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                      total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                    }

                                  }

                                  return  acc1 += total
                                }, 0)
                      }

                      amt = subamt + waitingfee;
                      
                      return acc += amt; 

                    }, 0) + calcbookingreports.reduce( (acc, item) => 
                    {  

                      let amt = 0;

                      if(item.expressway.length > 0) {
                        amt = Math.round(item.expressway.reduce((acc1, value) => { return  acc1 += value.rate.rate}, 0))
                      }
                      
                      return acc += amt; 

                    }, 0) + ((((calcbookingreports.reduce( (acc, item) => 
                      {  
                          let amt = 0;
                          if(item.organisation.vat !== '0') {
                            if(item.organisationrate.length > 0) {
                              if(parseInt(item.organisationrate[0].base_rate) === 0) {
                                amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                              }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                                amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                              }else {
                                amt = parseInt(item.organisationrate[0].base_rate)
                              }
                            }
                          }
                        return acc += amt; 
                      }, 0) + calcbookingreports.reduce( (acc, item) => 
                    {  

                      let amt = 0, waitingfee = 0, subamt = 0;
                      if(item.waitingfee !== null && item.driver_pickup_date !== null && item.driver_arrival_date !== null) {
                        if(parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(item.waitingfee.minutes)) {

                            waitingfee = Math.round((parseFloat(moment.duration(moment(item.driver_pickup_date+" "+item.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(item.driver_arrival_date+" "+item.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(item.waitingfee.minutes)) * item.waitingfee.rate);

                        }
                      }

                      if(item.waiting.length > 0) {
                        subamt = item.waiting.reduce((acc1, value) => { 
                                  let total = 0;
                                  if(value.starttime !== null && value.endtime !== null) {
                                    
                                    if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseFloat(value.rate.minutes)) {
                                      total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseFloat(value.rate.minutes)) * value.rate.rate);
                                    }

                                  }

                                  return  acc1 += total
                                }, 0)
                      }

                      amt = subamt + waitingfee;
                      
                      return acc += amt; 

                    }, 0))*(vat != null ? vat.rate : 0))/100)) + calcbookingreports.reduce( (acc, item) => 
                      {  
                          let amt = 0;
                          if(item.organisationrate.length > 0) {
                            if(parseInt(item.organisationrate[0].base_rate) === 0) {
                              amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                            }else if(parseInt(item.organisationrate[0].base_rate) > 0 && parseInt(((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate))) > parseInt(item.organisationrate[0].base_rate)) {
                              amt = Math.round((item.distance)*(item.organisationrate.length === 0 ? 0 : item.organisationrate[0].rate));
                            }else {
                              amt = parseInt(item.organisationrate[0].base_rate)
                            }
                          }
                        return acc += amt; 
                      }, 0)) | currency('')

                  }}
                </p>
                <p v-else>
                  0.00
                </p>
              </span>
            </div>
          </div>
          <div class="summaryTotals" v-else>
            <div class="totals">
              <span class="font-normal"> SubTotal</span>
              <span class="font-medium">
                <p v-if="calcitems.length > 0">
                    {{

                      (calcitems.reduce( (acc, item) => 
                      {  

                        return acc += parseFloat(item.quantity)*parseFloat(item.rate); 

                      }, 0)) | currency('')

                    }}
                  </p>
                  <p v-else>0.00</p>
              </span>
            </div>
            <div class="mx-5 arrow-right"></div>
            <div class="totals">
              <span class="font-normal">Vat({{ vat != null ? vat.rate : 0 }}%)</span>
              <span class="font-medium">
                <p v-if="calcitems.length > 0">
                  {{

                    ((((calcitems.reduce( (acc, item) => 
                      {  

                        return acc += parseFloat(item.quantity)*parseFloat(item.rate); 

                      }, 0))*(vat != null && includevat == true ? vat.rate : 0))/100)) | currency('')

                  }}
                </p>
                <p v-else>
                  0.0
                </p>
              </span>
            </div>
            <div class="mx-5 arrow-right"></div>
            <div class="totals">
              <span class="font-normal">Total</span>
              <span class="font-medium">
                <p v-if="calcitems.length > 0">
                  {{

                    (calcitems.reduce( (acc, item) => 
                      {  

                        return acc += parseFloat(item.quantity)*parseFloat(item.rate); 

                      }, 0) + ((((calcitems.reduce( (acc, item) => 
                      {  

                        return acc += parseFloat(item.quantity)*parseFloat(item.rate); 

                      }, 0))*(vat != null && includevat == true ? vat.rate : 0))/100)) ) | currency('')

                  }}
                </p>
                <p v-else>
                  0.00
                </p>
              </span>
            </div>
          </div>
        </b-alert>
      </div>

    </div>

    <!-- components -->
    <EditDistance v-model="editdistancedialog" :bookingid="bookingid"/>
    <ExpresswayDetail v-model="expresswaydialog" :expresswaydetails="expresswaydetails"/>
    <WaitingDetail v-model="waitingdialog" :waitingdetails="waitingdetails"/>
    <ClientProfile v-model="profiledialog" :profiledetails="profiledetails"/>
    <UpdateDetail v-model="updatedialog" :updatedetails="updatedetails"/>
    <UpdateAll v-model="updatealldialog" :selected="selected"/>

  </div>
</template>

<style>
  @import "@/assets/table/table";
  .modal-invoice{
    max-width:750px !important;
  }
  .action-dropdown-menu .action-dropdown-item:hover{
    background:var(--ab-primary);
    color:#fff;
    border-radius:var(--ab-dropdown-border-radius);
  }
  .action-dropdown-menu{
    padding-right: .5rem;
  }
  .newinvoice .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-template-columns: 5% 20% 15% 10% 8% 8% 8% 8% 8% 10% !important;
     grid-gap: 0px;
     min-width:100%;
  }
  .newinvoice .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }
  .newinvoice .scroller {
    height: 45vh !important;
  }
</style>
<style scoped>
  @import "@/assets/table/table_scoped";
  .newinvoice .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-template-columns: 5% 20% 15% 10% 8% 8% 8% 8% 8% 10% !important;
     grid-gap: 0px;
  }
  
</style>

<script>
import Pusher from 'pusher-js'
import $ from 'jquery';
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import Vue from 'vue'

import {ability} from '@/services/ability'
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import DateRangePicker from 'vue2-daterange-picker'

import EditDistance from '@/components/bookings/editDistance'
import ExpresswayDetail from '@/components/bookings/expressway'
import WaitingDetail from '@/components/bookings/waiting'
import ClientProfile from '@/components/bookings/clientprofile'
import UpdateDetail from '@/components/bookings/updatedetails'
import UpdateAll from '@/components/bookings/updateall'

 export default {
  mixins: [validationMixin],
  name: 'editInvoice',
  components: { DateRangePicker,ExpresswayDetail,WaitingDetail,ClientProfile,EditDistance,UpdateDetail,UpdateAll },
  data() {
    return {
      selected: [],
      selectAll: false,
      daterange: {
          start: '',
          end: ''
        },
      dateRange: {
        startDate: null,
        endDate: null
       },
      includevat: false,
      readonly: true,
      singleDatePicker: true,
      showWeekNumbers: true,
      showDropdowns: true,
      autoApply: true,
      alwaysshowcalendars: true,
      rangedate: "",
      searchrequestcategory: "",
      date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      fetchloading: false,
      menu: false,
      duedate: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      menu2: false,
      searchfilter:'',
      individualchecked: false,
      custominvoice: false,
      searchorg:null,
      search:"",
      customer: null,
      searchproject: null,
      valid: true,
      dialog: false,
      updatedialog: false,
      requestTypes: ['Client', 'Delivery'],
      fields: [
        { text: '', value: '' },
        { text: 'Name', value: 'name' },
        { text: 'Date', value: 'date' },
        { text: 'Vehicle', value: 'vehicletype' },
        { text: 'Distance', value: 'distance' },
        { text: 'Cost', value: 'cost' },
        { text: 'Xway', value: 'expressway' },
        { text: 'Vat', value: 'vat' },
        { text: 'Total', value: 'total' },
        { text: 'Action', value: 'action' }
       ],
      requests:{
         organisation_id: [],
         project_id: [],
         customer_id: [],
         invoice_no: "",
         subject: "",
         request_category: "",
         amount: "",
         id: null,
         _method:"patch"
      },
      isBusy: false,
      loading: false,
      loading1: false,
      loading2: false,
       disabled: false,
       organisations: [],
       customers: [],
       expresswaydialog: false,
       editdistancedialog: false,
       bookingid: null,
       expresswaydetails: [],
       waitingdialog: false,
       waitingdetails: [],
       profiledetails: [],
       profiledialog: false,
       updatedetails: [],
       updatedialog: false,
       updateall: [],
       items: [],
       updatealldialog: false,
    }
  },

  mounted(){

      let headertitle = `<div class="view-titleheader titlehead" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="theader">${value.text}</div>`;
         });
      headertitle +=`</div>`;
      $('.vue-recycle-scroller').prepend(headertitle)

      this.$store.state.invoicebookings = []
      this.requests.id = this.$route.params.id
      this.getDetails()
      this.getInvoices()

  },
   watch: {
        allinvoices: function(newVal, oldVal) {
          let data = newVal
            if(data !== null) {
              
              this.requests.organisation_id = []
              this.requests.project_id = []
              this.requests.customer_id = []
              for (let i in data.organisations) {
                  this.requests.organisation_id.push(data.organisations[i].organisation);
              }
              for (let i in data.projects) {
                  this.requests.project_id.push(data.projects[i].project);
              }
              if(data.passengers.length > 0) {
                this.individualchecked = true
                for (let i in data.passengers) {
                  this.requests.customer_id.push(data.passengers[i].passenger);
                }
              }

              if(data.custom_invoice === "true") {
                this.custominvoice = true
                this.requests.amount = data.amount
              }
              if(data.includevat === "true") {
                this.includevat = true
              }
              
              this.requests.invoice_no = data.invoice_no
              this.requests.subject = data.subject
              this.date = data.date
              this.duedate = data.due_date
              this.requests.request_category = data.request_category

              this.rangedate = Vue.filter('date')(data.start_date)+" - "+Vue.filter('date')(data.end_date)
              this.daterange.start = Vue.filter('date')(data.start_date)
              this.daterange.end = Vue.filter('date')(data.end_date)

              if(data.custom_invoice === "false") {
                this.fetchinvoice()
              }

            }
        },
    },
  computed: {
    loader() {
      return this.$store.state.tableloader
    },
    mini() {
      return this.$store.state.mini
    },
    vat() {
      return this.$store.state.vat
    },
    allbookings() {
      return this.$store.state.invoicebookings
    },
    bookings() {
      return this.allbookings.filter(item => {
        this.start = 0
        let name = "";
        if(item.passenger_name != null && item.passenger_name != 'null') {name = item.passenger_name}
          
        return name.toLowerCase().includes(this.search.toLowerCase())  || item.passengers.some(item => item.passenger_name.toLowerCase().includes(this.search.toLowerCase()) || item.telephone.includes(this.search))
      })
    },
    calcbookingreports() {
      return this.$store.state.invoicebookings.filter(item => {
        this.start = 0
          if(this.selected.includes(item.id) === true) {
            return item
          }else {
            return false
          }

      })
    },
    allinvoices() {
      return this.$store.state.selectedinvoice
    },
    projects() {
      if(this.requests.organisation_id.length > 0){
        let projects = []
        let data = this.organisations.filter(organisation =>  this.requests.organisation_id.map(item => parseInt(item.id)).includes(organisation.id) )
        for (let i in data) {
          if(data[i].projects.length > 0) {
            projects.push(...data[i].projects);
          }
        }

        return projects
      }else{
        return []
      }       
    },
    calcitems() {
      return this.items
    },
  },
  methods: {

    onQuantityInput(value,index) {
      let found = this.items[index];
      if (found) {
        found.amount = parseFloat(found.quantity)*parseFloat(found.rate)
      }
    },
    onRateInput(value,index) {
      let found = this.items[index];
      if (found) {
        found.amount = parseFloat(found.quantity)*parseFloat(found.rate)
      }
    },

    addItem () {
      this.items.push({
        details: "",
        quantity: "1",
        rate: "0",
        amount: "0"
      })
    },
    removeItem (index) {
      this.items.splice(index, 1);
    },

    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.bookings) {
          this.selected.push(this.bookings[i].id);
        }
      }
    },
    bookingprofile(id) {
      this.profiledetails = this.bookings.filter(booking => booking.id === id )
      this.profiledialog = true
    },
    bookingdetailsupdate(id) {
      this.updatedetails = this.bookings.filter(booking => booking.id === id )
      this.updatedialog = true
    },
    updateselected() {
      this.updatealldialog = true
    },
    editdistance(id) {
      this.bookingid = id
      this.editdistancedialog = true
    },
    expressway(id) {
      this.expresswaydetails = this.bookings.filter(booking => booking.id === id )
      this.expresswaydialog = true
    },
    waiting(id) {
      this.waitingdetails = this.bookings.filter(booking => booking.id === id )
      this.waitingdialog = true
    },
    voucher(ref) {
      window.open(`https://e-voucher.absolutecabs.co.ke/customer/e-voucher/${ref}`, '_blank', 'noreferrer');
    },
    changeStatus(item) {
      this.individualchecked != this.individualchecked
      if(this.individualchecked) {
        this.requests.organisation_id = []
        this.requests.project_id = []
      }
    },
    updateValues(val) { 
      this.daterange.start = Vue.filter('date')(val.startDate)
      this.daterange.end = Vue.filter('date')(val.endDate)
      this.rangedate = this.daterange.start+" - "+this.daterange.end
    },
    async getInvoices(){
        await axios.get(`/invoice/fetch-invoice/${this.requests.id}`).then(response=>{
            if(response.data.status == parseInt(200)) {
              this.$store.state.selectedinvoice = response.data.invoice
              this.$store.state.vat = response.data.vat
            }
        }).catch(error=>{
            console.log(error)
        })
      },
      async getDetails(id) {
        this.$store.state.tableloader = true
        await axios.get(`/invoice/details/${this.requests.id}`).then(response=>{
          this.$store.state.tableloader = false
            if(response.data.status == parseInt(200)) {
              this.$store.state.vat = response.data.vat
              this.organisations = response.data.organisations
              this.customers = response.data.customers
              response.data.items.forEach((value, index) => {
                  this.items.push({
                    details: value.details,
                    quantity: value.quantity,
                    rate: value.rate,
                    amount: value.amount
                  })
              });
            }
        }).catch(error=>{
          this.$store.state.tableloader = false
            console.log(error)
        })
      },

      async save(){

          this.loading2 = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('organisation_id',JSON.stringify(this.requests.organisation_id))
          fd.append('project_id',JSON.stringify(this.requests.project_id))
          fd.append('customer_id',JSON.stringify(this.requests.customer_id))
          fd.append('invoice_no',this.requests.invoice_no)
          fd.append('bookings',JSON.stringify(this.selected))
          fd.append('items',JSON.stringify(this.items))
          fd.append('subject',this.requests.subject)
          fd.append('amount',this.requests.amount)
          fd.append('date',this.date)
          fd.append('start',this.daterange.start)
          fd.append('end',this.daterange.end)
          fd.append('duedate',this.duedate)
          fd.append('request_category',this.requests.request_category)
          fd.append('custominvoice',this.custominvoice)
          fd.append('includevat',this.includevat)
          fd.append('_method',this.requests._method)

          axios.post(`/invoices/${this.$route.params.id}`, fd, config).then(response=>{

            if(response.data.status == parseInt(200)) {

              let index = this.$store.state.invoices.findIndex(data => data.id == response.data.invoice.id);
              if (index > -1) {
                this.$store.state.invoices.splice(index, 1, response.data.invoice);
              }

              this.$toastpopup('success','Success','Invoice Updated successfully')

              this.$router.push({ name: 'invoiceList' })
            }
              this.loading2 = false
            })
            .catch(error => {
              this.loading2 = false
            })
      },

      async fetchinvoice() {

        this.loading = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let fd = new FormData();
        fd.append('organisation_id',JSON.stringify(this.requests.organisation_id))
        fd.append('project_id',JSON.stringify(this.requests.project_id))
        fd.append('customer_id',JSON.stringify(this.requests.customer_id))
        fd.append('request_category',this.requests.request_category)
        fd.append('start',this.daterange.start)
        fd.append('end',this.daterange.end)

        await axios.post('/edit-invoice/bookings', fd, config).then(response=>{
            this.loading = false

            if(response.data.status == parseInt(200)) {
              this.$store.dispatch('InvoiceBookings', response.data)
            }
        }).catch(error=>{
            this.loading = false
            console.log(error)
        })
      },

  }
};

</script>
