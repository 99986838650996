<script>
import axios from 'axios'
import { Chart } from 'highcharts-vue'

 export default {
  name: 'App',
  components: {
    highcharts:Chart
  },

  computed: {
    mini() {
      return this.$store.state.mini
    },
    loader() {
      return this.$store.state.tableloader
    },
    dashboard_stats() {
      return this.$store.state.dashboard_stats
    }
  },
  data: () => ({
    name: '',
    stackedColumnOption: {
      chart: {
          type: 'column',
          height: 380
      },
      title: {
          text: '',
          align: 'left'
      },
      xAxis: {
          categories: []
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Bookings'
          },
          stackLabels: {
              enabled: true
          }
      },
      legend: {
          enabled: false
        },
      tooltip: {
          headerFormat: '<b>{point.x}</b><br/>',
          pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      // plotOptions: {
      //   series: {
      //       stacking: 'normal',
      //       dataLabels: {
      //           enabled: true
      //       }
      //   }
      // },
      plotOptions: {
          column: {
              stacking: 'normal',
              dataLabels: {
                  enabled: true
              }
          }
      },
      series: []
    },
    lineChartOptions: {
        chart: {
            type: 'line',
            zoomType: 'x'
        },
        title: {
            text: '',
            align: 'left'
        },

        subtitle: {
            text: '',
            align: 'left'
        },

        yAxis: {
            title: {
                text: 'Total Bookings'
            }
        },
        legend: {
          enabled: false
        },
        xAxis: {
            categories: []
        },

        // legend: {
        //     layout: 'vertical',
        //     align: 'right',
        //     verticalAlign: 'middle'
        // },

        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true
                },
                enableMouseTracking: true
            }
        },

        series: [],

        accessibility: {
            screenReaderSection: {
                beforeChartFormat: '<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>'
            }
        },

    },
    chartOptions: {
      chart: {
          type: 'column'
      },
      title: {
          text: ''
      },
      labels: {
          items: []
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Bookings'
          }
      },
      legend: {
        enabled: false
      },
      xAxis: {
          categories: []
      },
      tooltip: {
          headerFormat: '<span style="font-size:11px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: '</table>',
          shared: false,
          useHTML: true
      },
      plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
      },
      series: []
    },
    pieOptions: {
      chart: {
          type: 'pie',
          marginTop: 0,
          height: 240
      },
      title: {
          text: ''
      },
      tooltip: {
      headerFormat: '',
      pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
          'Bookings: <b>{point.y}</b><br/>'
      },
       plotOptions: {
          pie: {
              size: 140
          }
      },
      series: []
    },
    monthname:"",
    currentyear: ""
  }),
  created() {
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

    const d = new Date();
    this.monthname = month[d.getMonth()];
    this.currentyear = d.getFullYear();
  },
  mounted() {
    if(!this.dashboard_stats || this.dashboard_stats.length == 0) {
      this.getDashboard()
    }
    this.getCharts();
  },
  serverPrefetch () {
    },
  methods: {
    async getCharts(){
      this.$store.state.tableloader = true
      await axios.get('/dashboard/charts').then(response=>{
          this.$store.state.tableloader = false
          // this.chartOptions.xAxis.categories = response.data.months
          this.stackedColumnOption.xAxis.categories = response.data.months
          // this.chartOptions.series = response.data.series
          this.stackedColumnOption.series = response.data.series

          this.pieOptions.series = response.data.todayseries

      }).catch(error=>{
          this.$store.state.tableloader = false
          console.log(error)
      })
    },

    async getDashboard(){
      this.$store
        .dispatch('getDashboard')
    },
  }
};
</script>

<template>
  <div class="dashboardwrapper mt-0">
    <div class="mx-0 px-0" style="position:relative">
      <div class="flex" :class= "[mini ? 'minipadding' : 'expandpanding']">
        <div class="section_title"><span class="title" style="font-weight:500 !important;font-size:18px !important;">Welcome, {{ $store.state.user.user.name.split(' ')[0] }}&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
      </div>
      <div class="dashboard-container pt-2 mx-3">
        <b-row>
          <b-col sm="12" md="6">
            <b-card class="mx-2 my-3 stats">
              <template #header>
                <h6 class="mb-0">Today Bookings</h6>
              </template>
              <b-card-text>
                <highcharts class="todaych" id="container" :options="pieOptions" ref="piechart"></highcharts>
              </b-card-text>
            </b-card>
          </b-col>
          <b-col sm="12" md="6">
            <b-card class="mx-2 my-3 stats">
              <template #header>
                <h6 class="mb-0">Booking Statistics {{ currentyear }}</h6>
              </template>
              <b-card-text>
                <table>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Time frame
                      </th>
                      <th class="text-left">
                        Bookings
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                    >
                      <td>Today</td>
                      <td>{{ dashboard_stats !== null ? dashboard_stats.today_booking:0 | currency('',0) }}</td>
                    </tr>
                    <tr
                    >
                      <td>This Week</td>
                      <td>{{ dashboard_stats !== null ? dashboard_stats.weeks_booking:0 | currency('',0) }}</td>
                    </tr>
                    <tr
                    >
                      <td>This Month</td>
                      <td>{{ dashboard_stats !== null ? dashboard_stats.months_booking:0 | currency('',0) }}</td>
                    </tr>
                    <tr
                    >
                      <td>This Year</td>
                      <td>{{ dashboard_stats !== null ? dashboard_stats.years_booking:0 | currency('',0) }}</td>
                    </tr>
                  </tbody>
                </table>
              </b-card-text>
            </b-card>
          </b-col>
          <b-col sm="12" md="12">
            <b-card class="mx-2 my-3 orgstats">
              <template #header>
                <h6 class="mb-0">Monthly Organisation Bookings {{ currentyear }}</h6>
              </template>
              <b-card-text>
                <highcharts class="hc" id="container" :options="stackedColumnOption" ref="chart"></highcharts>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<style>
.dashboard-container{
  margin-bottom: 50px;
  margin-top: 60px;
} 
@media (min-width: 1280px){

  .dashboard-container{
    max-width:1000px;
  } 

}
@media (max-width: 1280px){

  .dashboard-container{
    max-width:100%;
  } 

}
.dashboardwrapper .card-header h6{
  font-weight: 400 !important;
}
.dashboardwrapper .card-body{
  padding: 0;
  margin:.7rem 0;
}
.dashboardwrapper .stats .card-body{
  height:15rem;
}
.dashboardwrapper .orgstats .card-body{
  height:24rem;
}
.dashboardwrapper table{
  border:0 !important;
}
.dashboardwrapper td,.dashboardwrapper th{
  padding: .6rem;
}
.dashboardwrapper td:first-child,.dashboardwrapper th:first-child{
  border-left: 0 !important;
}
.dashboardwrapper td:last-child,.dashboardwrapper th:last-child{
  border-right: 0 !important;
}
</style>