<template>
	<div>
	  <!-- client profile modal -->
    <b-modal
      v-model="show"
      size="transfer"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Transfer Records</span>
        </div>
      </template>
      <b-container fluid>
        <b-row class="mt-0">
          <b-col
            sm="12" md="12"
            class="float_label"
           >
            
            <multiselect ref="organisationmultiselect" 
                    v-model="organisation_id"
                     track-by="id" label="name" placeholder="Organisation" :options="organisations" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Organisation*</label>
          </b-col>
        </b-row>
        <b-row class="mt-3 mb-0 pb-0">
          <b-col
            cols="12"
            sm="12"
            md="12"
            class="my-0 py-0"
            >
            <b-form-checkbox name="check-button" switch v-model="updateaccount">
              Apply changes to individual account ?
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="submit">
            <b-spinner small v-if="loading"></b-spinner> Submit
          </b-button>
      </template>
    </b-modal>
    <!-- end client profile -->
	</div>
</template>

<style>
  .modal-transfer{
    max-width:500px !important;
  }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'

export default {
	name: 'updatedetails',
	components: {
	},
	data: () => ({
    tableloader: false,
    loading1: false,
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        organisation_id: null,
        updateaccount: null,
	}),
	methods: {
      getAddBookings(){

        this.$store.state.addbookingloader = true

        this.$store
          .dispatch('getAddBookings')
          .then(() => {
            
          })
          .catch(() => {
            this.isBusy = false
          })
      },
      submit(){
          // if (this.$refs.form.validate()) {

            this.loading = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('selected',JSON.stringify(this.selected))
            fd.append('organisation_id',JSON.stringify(this.organisation_id))
            fd.append('updateaccount',this.updateaccount)

            axios.post('/transfer/records', fd, config).then(response=>{

              if(response.data.status === parseInt(200)) {
                this.$toastpopup('success','Success',"Transfer was successful")
              }


              this.show = false
              this.loading = false
              
            })
            .catch(() => {
              this.loading = false
            })

        // }
      },

	},
	props: {
	     value: Boolean,
	     selected: Array
	  },
	  computed: {
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    },
      organisations() {
        return this.$store.state.bookingorganisations
      },
      addbookingloader() {
        return this.$store.state.addbookingloader
      },
	  },
	mounted() {
    if(this.organisations.length == 0){
      this.getAddBookings()
    }
  },
  watch: {},
	created() {}
}
</script>