<template>
	<div>
	  <!-- client profile modal -->
    <b-modal
      v-model="show"
      size="update"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Update Details</span>
        </div>
      </template>
      <b-container fluid v-if="updatedetails.length > 0">
        <b-row class="mt-0">
          <b-col sm="12" md="12" class="float_label mb-3">
            
              <b-form-input
                placeholder="Passenger"
                required
                size="sm"
                v-model="customer_name"
              ></b-form-input>
            <label class="typo__label float_label_item">Passenger*</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12" md="12"
            class="float_label mb-3"
           >
            
            <multiselect ref="organisationmultiselect" 
                    v-model="organisation_id"
                     track-by="id" label="name" placeholder="Organisation" :options="organisations" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Organisation*</label>
          </b-col>
        </b-row>
        <b-row class="mb-0 pb-0">
          <b-col
            cols="12"
            sm="12"
            md="12"
            class="my-0 py-0"
            >
            <b-form-checkbox name="check-button" switch v-model="updateaccount">
              Apply changes to individual account ?
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col
            cols="12"
            sm="12"
            md="12"
            class="my-0 py-0"
            >
            <b-form-checkbox name="check-button" switch v-model="updateallbookings">
              Apply changes to all bookings ?
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="submit">
            <b-spinner small v-if="loading"></b-spinner> Submit
          </b-button>
      </template>
    </b-modal>
    <!-- end client profile -->
	</div>
</template>

<style>
  .modal-update{
    max-width:450px !important;
  }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'

export default {
	name: 'updatedetails',
	components: {
	},
	data: () => ({
    tableloader: false,
    loading1: false,
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        customer_id: "",
        customer_name: "",
        organisation_id: null,
        updateaccount: null,
        updateallbookings: null,
	}),
	methods: {
      getAddBookings(){

        this.$store.state.addbookingloader = true

        this.$store
          .dispatch('getAddBookings')
          .then(() => {
            
          })
          .catch(() => {
            this.isBusy = false
          })
      },
      submit(){
          // if (this.$refs.form.validate()) {

            this.loading = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('customer_id',this.customer_id)
            fd.append('customer_name',this.customer_name)
            fd.append('organisation_id',JSON.stringify(this.organisation_id))
            fd.append('updateaccount',this.updateaccount)
            fd.append('updateallbookings',this.updateallbookings)
            fd.append('id',this.updatedetails[0].id)

            axios.post('/report/updatedetails', fd, config).then(response=>{

              if(response.data.status === parseInt(200)) {
                this.$toastpopup('success','Success',"Details successfully")
              }


              this.show = false
              this.loading = false
              
            })
            .catch(() => {
              this.loading = false
            })

        // }
      },

	},
	props: {
	     value: Boolean,
	     updatedetails: Array
	  },
	  computed: {
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    },
      organisations() {
        return this.$store.state.bookingorganisations
      },
      customers() {
        return this.$store.state.bookingcustomers
      },
      addbookingloader() {
        return this.$store.state.addbookingloader
      },
	  },
	mounted() {
    if(this.customers.length == 0 || this.organisations.length == 0){
      this.getAddBookings()
    }
  },
  watch: {
      updatedetails: function(newVal, oldVal) {
          this.organisation_id = this.updatedetails[0].organisation_id === null || this.updatedetails[0].organisation_id === "null" ? null : this.updatedetails[0].organisation
          this.customer_id = this.updatedetails[0].passenger_id
          this.customer_name = this.updatedetails[0].passenger_name === undefined ? this.updatedetails[0].name : this.updatedetails[0].passenger_name
      },
  },
	created() {}
}
</script>