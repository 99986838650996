<template>
	<div>
	  <!-- assign vehicle modal -->
    <b-modal
      v-model="show"
      size="sm"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Assign Vehicle</span>
        </div>
      </template>
      <b-container fluid>
        <b-row>
          <b-col cols="12" sm="12" md="12">
            <b-alert show variant="info">
              <div v-for="(assign, index) in toassinguser" :key="index">
                <div v-if="assign.passengers.length === 0"><span>{{ assign.name }}</span></div>
                <div v-else style="display:flex;flex-direction:column !important">
                  <span v-for="(passenger,index) in assign.passengers" style="display:flex;"><b-icon icon="dot"></b-icon> {{ passenger.passenger_name }}</span>
                </div>
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col
            sm="12" md="12"
            class="float_label mb-2"
           >
            
            <multiselect ref="drivermultiselect" 
                    v-model="toassignuserdriver"
                    @input="(event) => onchangeDriver(event)"
                     track-by="id" label="name" :custom-label="customdriver" placeholder="Select driver" :options="freeDrivers" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.telephone }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Driver*</label>
          </b-col> 

          <b-col
            sm="12" md="12"
            class="float_label mb-2"
           >
            
            <multiselect ref="vehiclemultiselect" 
                    v-model="toassignuservehicle"
                    @input="(event) => onchangeVehicle(event)"
                     track-by="id" label="registration" placeholder="Select vehicle" :custom-label="customvehicle" :options="freeVehicles" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.registration }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Vehicle*</label>
          </b-col> 

        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="assignCar">
            <b-spinner small v-if="loading"></b-spinner> Submit
          </b-button>
      </template>
    </b-modal>
  <!-- end assign vehicle -->
	</div>
</template>
<style scoped>

</style>
<script>
import axios from 'axios'
import Vue from 'vue'

export default {
	name: 'assignvehicle',
	components: {
	},
	data: () => ({
		messageclientdialog: false,
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        searchvehicle:null,
        searchdriver:null,
        toassignuserdriver: null,
        toassignuservehicle: null,

	}),
	methods: {
    customdriver ({ name, telephone }) {
      return `${name} — [ ${telephone} ]`
    },
    customvehicle ({ name, registration }) {
      return `${name} — [ ${registration} ]`
    },
    async getAddBookings(){

        this.$store.state.addbookingloader = true

        this.$store
          .dispatch('getAddBookings')
          .then(() => {
            this.isBusy = false
          })
          .catch(() => {
            this.isBusy = false
          })
      },
		onchangeVehicle(val) {

	        if (this.toassignuservehicle !== null && this.toassignuserdriver !== null && this.toassignuserid !== null) {
	          this.assignCar()
	        }

	      },
	      onchangeDriver(val) {
	        if (this.toassignuservehicle !== null && this.toassignuserdriver !== null && this.toassignuserid !== null) {
	          this.assignCar()
	        }
	      },
		assignCar(){

	        this.loading = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('vehicle_id', JSON.stringify(this.toassignuservehicle))
          fd.append('driver_id', JSON.stringify(this.toassignuserdriver))
          fd.append('id',this.toassignuserid)

          axios.post('/assign-client-vehicle', fd, config).then(response=>{
            if(response.data.status === parseInt(404)) {
              this.$toastpopup('error','Error',response.data.message)
            }
            if(response.data.status === parseInt(401)) {
              this.$toastpopup('warning','Warning',response.data.message)
            }

            if(response.data.status === parseInt(200)) {

              this.$toastpopup('success','Success',response.data.driver)
            }

            this.toassignuserdriver = null
            this.toassignuservehicle = null

            this.show = false
            this.loading = false
            
          })
          .catch(() => {
            this.loading = false
          })

        }
	},
	props: {
	     value: Boolean,
	     toassignuserid: Number,
	     toassigneduservehicle: Number,
	     toassinguser: Array
	  },
	  computed: {
      addbookingloader() {
        return this.$store.state.addbookingloader
      },
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    },
	    freeVehicles() {
	      return this.$store.state.freevehicles
	    },
	    drivers() {
	      return this.$store.state.bookingdrivers
	    },
	    freeDrivers() {
	      return this.drivers
	    },
	  },
	mounted() {
    if(this.drivers.length == 0){
        this.getAddBookings()
      }
	},
	created() {
     
    }
}
</script>