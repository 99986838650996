<template>
	<div>
	<!-- assign message client -->
	<b-modal
      v-model="show"
      size="sm"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Send Message</span>
        </div>
      </template>
      <b-container fluid v-if="profiledetails.length > 0">
        <b-row>
          <b-col
          cols="12"
          sm="12"
          md="12"
          class="mb-3"
          >
            <div class="" style="display:flex;flex-direction:column;align-items:end;justify-content:flex-end">
	            <b-button class="mb-2" size="sm" pill variant="info"
    	          @click="resendmessage"
    			    >
    			      <b-spinner small v-if="resendloading"></b-spinner> Resend booking message
    			   </b-button>

    			    <b-button pill size="sm" variant="info"
    			      :loading="driverloading"
    	          @click="drivermessage"
    			    >
    			      <b-spinner small v-if="driverloading"></b-spinner> Resend driver details
    			    </b-button>
			     </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" class="mb-0">
            <b-alert show variant="info"><b-icon icon="dot"></b-icon> {{ profiledetails[0].passengers.length > 0 ? profiledetails[0].passengers[0].passenger_name : profiledetails[0].name }}</b-alert>
          </b-col>
          <b-col sm="12" md="12" class="float_label mt-3">
            
            <b-form-textarea
              id="textarea-small"
              size="sm"
              placeholder="Message"
              v-model="messagetext"
            ></b-form-textarea>
            <label class="typo__label float_label_item">Message</label>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="sendmessage">
            <b-spinner small v-if="loading"></b-spinner> Submit
          </b-button>
      </template>
    </b-modal>
    <!-- end message client -->
	</div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'

export default {
	name: 'sendmessage',
	components: {
	},
	data: () => ({
		messageclientdialog: false,
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        resendloading: false,
        driverloading: false,
        messagetext: "",

	}),
	methods: {
		sendmessage(){

	        // if (this.$refs.form.validate()) {

	          this.loading = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('id',this.messageuserid)
            fd.append('messagetext',this.messagetext)

            axios.post('/send-client-message', fd, config).then(response=>{
                if(response.data.status === parseInt(200)) {
                  this.$toastpopup('success','Success',"Message sent successfully")
                }
                this.show = false
                this.loading = false
              })
              .catch(() => {
                this.loading = false
              })

          // }

        },
        resendmessage(){

	        this.resendloading = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('id',this.messageuserid)
            fd.append('messagetext',this.messagetext)

            axios.post('/resend-client-message', fd, config).then(response=>{
                if(response.data.status === parseInt(200)) {
                  this.$toastpopup('success','Success',"Message sent successfully")
                }
                this.show = false
                this.resendloading = false
              })
              .catch(() => {
                this.resendloading = false
              })

        },
        drivermessage(){

	        this.driverloading = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('id',this.messageuserid)
            fd.append('messagetext',this.messagetext)

            axios.post('/resend-driver-message', fd, config).then(response=>{
                if(response.data.status === parseInt(200)) {
                  this.$toastpopup('success','Success',"Message sent successfully")
                }
                if(response.data.status === parseInt(404)) {
                  this.$toastpopup('warning','Warning',"No driver has been dispatched")
                }
                this.show = false
                this.driverloading = false
              })
              .catch(() => {
                this.driverloading = false
              })

        },
	},
	props: {
	     value: Boolean,
	     messageuserid: Number,
	     profiledetails: Array
	  },
	  computed: {
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    }
	  },
	mounted() {

	},
	created() {
     
    }
}
</script>