<template>
	<div>
	  <!-- client profile modal -->
    <b-modal
      v-model="show"
      size="expressway"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Add Expressway</span>
        </div>
      </template>
      <b-container fluid v-if="expresswaydetails.length > 0">
        <b-row>
          <b-col
          cols="12"
          sm="12"
          md="12"
          v-if="expresswaydetails[0].expressway.length > 0"
          >
            <b-alert
              variant="primary"
            >
              <b-row
                align="center"
                no-gutters
                v-for="(express,index) in expresswaydetails[0].expressway"
                :key="index"
                class="my-0 py-0"
                style="display:flex;align-items:center;justify-content:space-between"
                >
                <div class="d-flex" style="font-size:.75rem">{{ express.rate.entrance.name }} | {{ express.rate.exit.name }}</div>
                <b-col class="shrink">
                  <b-btn
                    variant="denger"
                    outlined
                    :loading="loading1"
                    size="sm"
                    @click="removeexpress(express.id)"
                  >
                    Remove
                  </b-btn>
                </b-col>
              </b-row>
            </b-alert>
          </b-col>
        </b-row>
        <b-row
            v-for="(expressway, indx) in expressways" 
              :key="indx"
              style="display:flex;align-items:center;justify-content:space-between"
              class="mx-0"
            >

            <b-col
              sm="12" md="5"
              class="float_label mb-3"
             >
              
              <multiselect ref="entrancemultiselect" 
                      v-model="expressway.entrance_id"
                      :name="`expressways[${indx}][entrance_id]`"
                      @change="(event) => updateEntrance(event,indx)"
                       track-by="id" label="name" placeholder="Entrance" :options="entrances" :searchable="true" :allow-empty="true" :show-labels="false">
                <span slot="noResult">Oops! Record not found.</span>
                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
              </multiselect>
              <label class="typo__label float_label_item">Entry*</label>
            </b-col> 
            <b-col
              sm="12" md="5"
              class="float_label mb-3"
             >
              
              <multiselect ref="entrancemultiselect" 
                      v-model="expressway.exit_id"
                      :name="`expressways[${indx}][exit_id]`"
                      @change="(event) => updateExit(event,indx)"
                       track-by="id" label="name" placeholder="Exit" :options="exits" :searchable="true" :allow-empty="true" :show-labels="false">
                <span slot="noResult">Oops! Record not found.</span>
                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
              </multiselect>
              <label class="typo__label float_label_item">Exit*</label>
            </b-col> 
            <b-col
              cols="12"
              sm="12"
              md="2"
              >
                <div style="display:flex;align-items:center;justify-content:center">
                  <b-icon @click="addExpressway" class="mx-3" icon="plus-circle"></b-icon>
                  <b-icon @click="removeExpressway(indx)" icon="x-circle"></b-icon>
                </div>
            </b-col>
          </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="submit">
            <b-spinner small v-if="loading"></b-spinner> Submit
          </b-button>
      </template>
    </b-modal>
    <!-- end client profile -->
	</div>
</template>

<style>
  .modal-expressway{
    max-width:550px !important;
  }
</style>

<script>
import axios from 'axios'
import Vue from 'vue'

export default {
	name: 'expressway',
	components: {
	},
	data: () => ({
    tableloader: false,
    loading1: false,
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        entrance_id:"",
        exit_id:"",
        searchentrance: "",
        searchexit: "",
        expressways: [
          {
            entrance_id: "",
            exit_id: "",
          }
        ],
	}),
	methods: {
    addExpressway () {
        this.expressways.push({
          entrance_id: "",
          exit_id: "",
        })
      },
      removeExpressway (index) {
        this.expressways.splice(index, 1);
      },
      updateEntrance(value,indx) {
        if(value !== null) {
          let found = this.expressways[indx];
          if (found) {
            found.entrance_id = value
          }
        }
      },
      updateExit(value,indx) {
        if(value !== null) {
          let found = this.expressways[indx];
          if (found) {
            found.exit_id = value
          }
        }
      },
		async getExpressway(){
        this.$store
          .dispatch('getExpressway')
          .then(() => {
            this.isBusy = false
          })
          .catch(() => {
            this.isBusy = false
          })
      },
      submit(){
          // if (this.$refs.form.validate()) {

            this.loading = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('expressways',JSON.stringify(this.expressways))
            fd.append('id',this.expresswaydetails[0].id)

            axios.post('/booking/saveexpressway', fd, config).then(response=>{

              if(response.data.status === parseInt(201)) {
                this.$toastpopup('success','Success',"Expressway added successfully")
              }

              this.expressways = []
              this.expressways.push({
                entrance_id: "",
                exit_id: "",
              })

              this.show = false
              this.loading = false
              
            })
            .catch(() => {
              this.loading = false
            })

        // }
      },
      removeexpress(id){
        this.tableloader = true
        this.loading1 = true
        axios.get(`/remove-expressway/${this.expresswaydetails[0].id}/${id}`).then(response=>{
          this.tableloader = false
          this.loading1 = false
          if(response.data.status == parseInt(200)) {
            this.show = false
            this.$toastpopup('success','Success',"Expressway removed successfully")
          }
        }).catch(error=>{
          this.tableloader = false
          this.loading1 = false
          this.show = false
          console.log(error)
        })
      }
	},
	props: {
	     value: Boolean,
	     expresswaydetails: Array
	  },
	  computed: {
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    },
      entrances() {
        return this.$store.state.entrances
      },
      exits() {
        return this.$store.state.exits
      },
	  },
	mounted() {
    this.getExpressway()
  },
	created() {}
}
</script>