<template>
  <div class="about mt-0">
    <div class="mx-0 px-0" style="position:relative">
      <div class="flex" :class= "[mini ? 'minipadding' : 'expandpanding']">
      	<div class="section_title"><span class="title">Vehicles&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
      	<div>
      		<b-button variant="primary" class="primary" size="sm" @click.stop="dialog = true">Add Vehicle <b-icon class="mr-1" icon="plus"></b-icon></b-button>
      	</div>
      </div>
      <b-row class="subsection">
      	<b-col sm="12" xl="12" md="12" class="mx-0 px-3">
      		<div class="dynamic-scroller vehicle">
            <b-row class="mx-2 mb-2 searchsection" style="display:flex;justify-content:flex-end;align-items:center">
              <div class="px-1" style="width:200px !important;padding-top:0 !important;padding-bottom:0 !important;display:flex;">
                <b-form-input
                    placeholder="Search..."
                    size="sm"
                    v-model="searchfilter"
                  ></b-form-input>
              </div>
            </b-row>
            <div class="empty-state" v-if="allvehicles.length === 0">
              <div class="empty-state__content">
                <div class="empty-state__icon">
                  <img src="@/assets/data.svg" style="width:40%">
                </div>
                <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                <div class="empty-state__message" v-else>No records available.</div>
              </div>
            </div>
      			<RecycleScroller
		          ref="scroller"
		          class="scroller"
		          :items="allvehicles"
		          :item-size="24"
		          key-field="id"
		        >
		          <template  v-slot="{ item,index }">
		            <div>
    					    <div class="tcell"> {{ item.name }}</div>
                  <div class="tcell">{{ item.registration }}</div>
                  <div class="tcell">{{ item.ownership == null ? "N/A" : item.ownership.ownership }}</div>
                  <div class="tcell">{{ item.type == null ? "N/A" : item.type.type }}</div>
                  <div class="tcell">{{ item.make == null ? "N/A" : item.make.make }}</div>
                  <div class="tcell">{{ item.model == null ? "N/A" : item.model.model }}</div>
    					    <div class="tcell text-center">
    					      <a class="list-action" :id="`popover-target-1-${item.id}-${index}`">
    						  		<b-icon class="my-0 py-0" style="cursor:pointer;" icon="chevron-down"></b-icon>
    						    </a>
    						    <b-popover
      							  :target="`popover-target-1-${item.id}-${index}`"
      							  triggers="hover blur"
      							  placement="left"
      							  class="dropdown-menu"
      							>
      							  <div class="action-dropdown-menu">
      						  		<button @click="editOrg(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="pencil"></b-icon>&nbsp;  Edit</button>
      						  		<button @click="deleteItem(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="trash"></b-icon>&nbsp;  Remove</button>
      						  	</div>
      							</b-popover>
    					    </div>
    					  </div>
		          </template>
		        </RecycleScroller>

			  </div>
      	</b-col>
      </b-row>
    </div>

    <!-- new vehicle -->
    <b-modal
      v-model="dialog"
      size="vehicle"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Add Vehicle</span>
        </div>
      </template>
      <b-container fluid>
        <b-row class="mx-0 my-0 py-0">
          <b-col sm="12" md="6" class="float_label mb-3">
            
              <b-form-input
                placeholder="Name"
                required
                size="sm"
                v-model="item.name"
              ></b-form-input>
            <label class="typo__label float_label_item">Name*</label>
          </b-col>
          <b-col sm="12" md="6" class="float_label mb-3">
            
              <b-form-input
                placeholder="Registration"
                size="sm"
                v-model="item.registration"
              ></b-form-input>
            <label class="typo__label float_label_item">Registration*</label>
          </b-col>
          <b-col
            sm="12" md="6"
            class="float_label mb-3"
           >
            
            <multiselect ref="type_idmultiselect" 
                    v-model="item.type_id"
                     track-by="id" label="type" placeholder="Vehicle Type" :options="vtype" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.type }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Type*</label>
          </b-col>
          <b-col
            sm="12" md="6"
            class="float_label mb-3"
           >
            
            <multiselect ref="make_idmultiselect" 
                    v-model="item.make_id"
                     track-by="id" label="make" placeholder="Vehicle Make" :options="vmake" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.make }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Make*</label>
          </b-col>
          <b-col
            sm="12" md="6"
            class="float_label mb-3"
           >
            
            <multiselect ref="make_idmultiselect" 
                    v-model="item.model_id"
                     track-by="id" label="model" placeholder="Vehicle Model" :options="vmodel" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.model }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Model*</label>
          </b-col>
          <b-col sm="12" md="6" class="float_label mb-3">
            
              <b-form-input
                placeholder="Color"
                size="sm"
                v-model="item.color"
              ></b-form-input>
            <label class="typo__label float_label_item">Color</label>
          </b-col>
          <b-col
            sm="12" md="6"
            class="float_label mb-3"
           >
            
            <multiselect ref="vownershipmultiselect" 
                    v-model="item.ownership_id"
                     track-by="id" label="ownership" placeholder="Vehicle Ownership" :options="vownership" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.ownership }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Ownership*</label>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="create">
            <b-spinner small v-if="loading"></b-spinner> Save
          </b-button>
      </template>
    </b-modal>

    <!-- update vehicle -->
    <b-modal
      v-model="updatedialog"
      size="vehicle"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Update Vehicle</span>
        </div>
      </template>
      <b-container fluid>
        <b-row class="mx-0 my-0 py-0">
          <b-col sm="12" md="6" class="float_label mb-3">
            
              <b-form-input
                placeholder="Name"
                required
                size="sm"
                v-model="item.updatename"
              ></b-form-input>
            <label class="typo__label float_label_item">Name*</label>
          </b-col>
          <b-col sm="12" md="6" class="float_label mb-3">
            
              <b-form-input
                placeholder="Registration"
                size="sm"
                v-model="item.updateregistration"
              ></b-form-input>
            <label class="typo__label float_label_item">Registration*</label>
          </b-col>
          <b-col
            sm="12" md="6"
            class="float_label mb-3"
           >
            
            <multiselect ref="type_idmultiselect" 
                    v-model="item.updatetype_id"
                     track-by="id" label="type" placeholder="Vehicle Type" :options="vtype" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.type }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Type*</label>
          </b-col>
          <b-col
            sm="12" md="6"
            class="float_label mb-3"
           >
           
            <multiselect ref="make_idmultiselect" 
                    v-model="item.updatemake_id"
                     track-by="id" label="make" placeholder="Vehicle Make" :options="vmake" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.make }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Make*</label>
          </b-col>
          <b-col
            sm="12" md="6"
            class="float_label mb-3"
           >
            
            <multiselect ref="make_idmultiselect" 
                    v-model="item.updatemodel_id"
                     track-by="id" label="model" placeholder="Vehicle Model" :options="vmodel" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.model }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Model*</label>
          </b-col>
          <b-col sm="12" md="6" class="float_label mb-3">
            
              <b-form-input
                placeholder="Color"
                size="sm"
                v-model="item.updatecolor"
              ></b-form-input>
            <label class="typo__label float_label_item">Color</label>
          </b-col>
          <b-col
            sm="12" md="6"
            class="float_label mb-3"
           >
            
            <multiselect ref="vownershipmultiselect" 
                    v-model="item.updateownership_id"
                     track-by="id" label="ownership" placeholder="Vehicle Ownership" :options="vownership" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.ownership }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Ownership*</label>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="update">
            <b-spinner small v-if="loading"></b-spinner> Update
          </b-button>
      </template>
    </b-modal>

  </div>
</template>

<style>
	@import "@/assets/table/table";
  .modal-vehicle{
    max-width:750px !important;
  }
	.action-dropdown-menu .action-dropdown-item:hover{
		background:var(--ab-primary);
		color:#fff;
		border-radius:var(--ab-dropdown-border-radius);
	}
	.action-dropdown-menu{
		padding-right: .5rem;
	}
  .vehicle .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-auto-columns: minmax(14.28%, 14.28%) !important;
     grid-gap: 0px;
     min-width:100%;
  }
  .vehicle .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }
</style>
<style scoped>
	@import "@/assets/table/table_scoped";
  .vehicle .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-auto-columns: minmax(14.28%, 14.28%) !important;
     grid-gap: 0px;
  }
  
</style>

<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';

 export default {
  name: 'vehicleList',
  components: {
  },
  computed: {
	  mini() {
	    return this.$store.state.mini
	  },
    loader() {
    	return this.$store.state.tableloader
    },
    vehicles() {
      return this.$store.getters.vehicles(this.searchfilter)
    },
    // vehicles: {
    //   get: () => {
    //      return $store.state.vehicles
    //   },
    //   cache: false
    // },
  //   message: {
  //   get () {
  //     return this.$store.state.obj.message
  //   },
  //   set (value) {
  //     this.$store.commit('updateMessage', value)
  //   }
  // }
    // allvehicles() {
    //   return this.vehicles.filter(item => {

    //     this.start = 0

    //     return item.name.toLowerCase().includes(this.searchfilter.toLowerCase()) || item.type !== null ? item.type.type.toLowerCase().includes(this.searchfilter.toLowerCase()) : false || item.registration.toLowerCase().includes(this.searchfilter.toLowerCase())
    //   })
    // },
    allvehicles() {
      return this.vehicles
    },
    vtype() {
      return this.$store.state.vehicletype
    },
    vmake() {
      return this.$store.state.make
    },
    vmodel() {
      return this.$store.state.model
    },
    vownership() {
      return this.$store.state.ownership
    }
  },
  data: () => ({
      searchfilter: '',
      search: '',
      dialog: false,
      updatedialog: false,
      loading: false,
      fields: [
        { text: 'Name', value: 'name' },
        { text: 'Registration', value: 'registration' },
        { text: 'Ownership', value: 'ownership' },
        { text: 'Type', value: 'type' },
        { text: 'Make', value: 'make' },
        { text: 'Model', value: 'model' },
        { text: 'Action', value: 'action' },
       ],
       previewImage: null,
      item:{
          name: "",
          registration:"",
          type_id:"",
          make_id:"",
          ownership_id:"",
          model_id:"",
          color:"",
          chasis:"",
          

          updatename: "",
          updateregistration:"",
          updatetype_id:null,
          updatemake_id:null,
          updateownership_id:null,
          updatemodel_id:null,
          updatecolor:"",
          updatechasis:"",

          updateID: "",
          _method:"patch"
      },
  }),

  mounted() {

  	let headertitle = `<div class="view-titleheader titlehead" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="theader">${value.text}</div>`;
         });
      headertitle +=`</div>`;
      $('.vue-recycle-scroller').prepend(headertitle)
  },
  beforeMount() {
    if(!ability.can('vehicle-management', 'all')) {
      this.$router.push({ name: 'Dashboard' })
      return
    }
  },
  created () {
    if(!this.allvehicles || this.allvehicles.length == 0 || this.vtype.length == 0 || this.vmake.length == 0 || this.vmodel.length == 0 || this.vownership.length == 0) {
      this.getVehicle()
    }
  },
  watch: {
    // vehicles: function(newVal, oldVal) {
    //     if(newVal !== null) {
    //       console.log(newVal)
    //     }
    //   }
  },
  methods: {
  	getVehicle(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getVehicles')
    
    },
    clear () {
        this.item.updatename = ''
        this.item.updateregistration = ''
        this.item.updatetype_id = null
        this.item.updatemake_id = null
        this.item.updateownership_id = null
        this.item.updatemodel_id = null
        this.item.updatecolor = ''
        this.item.updatechasis = ''

        this.item.name = ''
        this.item.registration = ''
        this.item.type_id = null
        this.item.make_id = null
        this.item.ownership_id = null
        this.item.model_id = null
        this.item.color = ''
        this.item.chasis = ''
        this.item.updateID = ''
      },
     create(){

        this.loading = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let fd = new FormData();
        fd.append('name',this.item.name)
        fd.append('registration',this.item.registration)
        fd.append('type_id',JSON.stringify(this.item.type_id))
        fd.append('make_id',JSON.stringify(this.item.make_id))
        fd.append('ownership_id',JSON.stringify(this.item.ownership_id))
        fd.append('model_id',JSON.stringify(this.item.model_id))
        fd.append('color',this.item.color)
        fd.append('chasis',this.item.chasis)

        axios.post('/vehicle', fd, config).then(response=>{
          this.loading = false
          if(response.data.status === 200) {

            let found = this.$store.state.vehicles.find(data => data.id == response.data.vehicle.id);
            if (!found) {
              this.$store.state.vehicles.unshift(response.data.vehicle)
            }

            this.$toastpopup('success','Success','Vehicle addded successfully')
            this.clear()
            this.dialog = false
          }
          
        }).catch(error=>{
          this.loading = false
            console.log(error)
        })

      },
     update(){

        this.loading = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let fd = new FormData();
         fd.append('name',this.item.updatename)
         fd.append('registration',this.item.updateregistration)
        fd.append('type_id',JSON.stringify(this.item.updatetype_id))
        fd.append('make_id',JSON.stringify(this.item.updatemake_id))
        fd.append('ownership_id',JSON.stringify(this.item.updateownership_id))
        fd.append('model_id',JSON.stringify(this.item.updatemodel_id))
        fd.append('color',this.item.updatecolor)
        fd.append('chasis',this.item.updatechasis)
        
        fd.append('_method',this.item._method)

        axios.post(`/vehicle/${this.item.updateID}`, fd, config).then(response=>{
          this.loading = false
          if(response.data.status === 200) {

            let index = this.$store.state.vehicles.findIndex(data => data.id == response.data.vehicle.id);
            if (index > -1) {
              // this.$store.state.vehicles[index] = response.data.vehicle;
              this.$set(this.$store.state.vehicles, index, response.data.vehicle);
              // this.$store.state.vehicles.splice(index, 1);
              // this.vehicles[index] = response.data.vehicle;
              // this.$store.state.vehicles.unshift(response.data.vehicle)
            }
            // console.log(this.$store.state.vehicles[index])

            this.$toastpopup('success','Success','Vehicle updated successfully')
            this.updatedialog = false
            this.clear()
          }
            
        }).catch(error=>{
          this.loading = false
            console.log(error)
        })

      },
    editOrg(id) {
      let index = this.allvehicles.filter(item => item.id === id );
        this.item.updatename = index[0].name
        this.item.updateregistration = index[0].registration
        this.item.updatetype_id = index[0].type
        this.item.updatemake_id = index[0].make
        this.item.updateownership_id = index[0].ownership
        this.item.updatemodel_id = index[0].model
        this.item.updatecolor = index[0].color
        this.item.updatechasis = index[0].chasis
        
      this.item.updateID = index[0].id
      this.updatedialog = true
    },
    deleteItem(id){
          if(confirm("Are you sure to delete vehicle ?")){
              axios.delete(`/vehicle/${id}`).then(response=>{
                let index = this.$store.state.vehicles.findIndex(data => data.id == response.data.vehicle.id);
                if (index > -1) {
                  this.$store.state.vehicles.splice(index, 1);
                }
                this.$toastpopup('success','Success','Vehicle deleted successfully')
              }).catch(error=>{
                  console.log(error)
              })
          }
      }
  }
};

</script>
