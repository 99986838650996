<template>
	<div>
	  <!-- assign vehicle modal -->
    <b-modal
      v-model="show"
      size="search"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Search Statement</span>
        </div>
      </template>
      <b-container fluid>
        <b-row>

          <b-col cols="12" sm="12" md="12" class="float_label mb-3">
            
              <date-range-picker
                ref="picker"
                :showWeekNumbers="showWeekNumbers"
                :showDropdowns="showDropdowns"
                :autoApply="autoApply"
                opens="right"
                :always-show-calendars="alwaysshowcalendars"
                v-model="dateRange"
                @select="updateValues"
              >
                  <template v-slot:input="picker" style="min-width: 100%;">
                      <b-form-input
                        placeholder="Select date range"
                        size="sm"
                        type="text"
                        v-model="rangedate"
                      ></b-form-input>
                  </template>
              </date-range-picker>
              <label class="typo__label float_label_item">Date*</label>
          </b-col>
          <b-col
            cols="12"
            sm="12"
            md="12"
            class="float_label mb-3"
           >
            
            <multiselect ref="orgmultiselect" 
                    :close-on-select="true"
                    v-model="$v.item.organisation_id.$model"
                    :state="validateState('organisation_id')"
                     track-by="id" label="name" placeholder="Select organisation" :options="organisations" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Organisation*</label>
          </b-col> 

        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="fetchreport" :disabled="$v.item.$invalid">
            <b-spinner small v-if="loading"></b-spinner> Submit
          </b-button>
      </template>
    </b-modal>
  <!-- end assign vehicle -->
	</div>
</template>
<style>
  .modal-search{
    max-width:450px !important;
  }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
	name: 'statementdialog',
	components: { DateRangePicker },
	data: () => ({
    valid: true,
    isBusy: false,
    loading: false,
    disabled: false,
    item: {
      organisation_id: null,
      project_id: null
   },
   dateRange: {
      startDate: null,
      endDate: null
     },
    singleDatePicker: true,
    showWeekNumbers: true,
    showDropdowns: true,
    autoApply: true,
    alwaysshowcalendars: true,
    date: "",
    dates: {},
    daterange: {
      start: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      end: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
    },
    rangedate: "",

	}),
  validations: {
    item:{
      organisation_id:{
        required
      },
    },
  },
	methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.item[name];
      return $dirty ? !$error : null;
    },
    updateValues(val) { 
      this.daterange.start = Vue.filter('date')(val.startDate)
      this.daterange.end = Vue.filter('date')(val.endDate)
      this.rangedate = this.daterange.start+" - "+this.daterange.end
    },
    fetchreport(){
      this.loading = true

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      let fd = new FormData();
      fd.append('organisation_id',JSON.stringify(this.item.organisation_id))
      fd.append('project_id',JSON.stringify(this.item.project_id))
      fd.append('start',this.daterange.start)
      fd.append('end',this.daterange.end)

      axios.post('/organisations/statement', fd, config).then(response=>{
        this.loading = false
        if(response.data.status == parseInt(200)) {
          this.$store.state.statements = response.data.statements
          this.$store.state.statementorganisation = this.item.organisation_id
          this.$store.state.statementdata = {
              organisation_id: this.item.organisation_id.id,
              project_id: null,
              start: this.daterange.start,
              end: this.daterange.end
            }
          this.show = false
        }
        
      })
      .catch(() => {
        this.loading = false
        console.log("Unexpected error occured")
      })
    },
	},
	props: {
     value: Boolean
  },
  computed: {
    organisations() {
      return this.$store.state.minorganisations
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    },
  },
	mounted() {},
	created() {
     
    }
}
</script>