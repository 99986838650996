<template>
	<div>
	  <!-- client profile modal -->
    <b-modal
      v-model="show"
      size="waitingtime"
      >
      <template #modal-title>
        <div class="w-100">
          <div class="section_title"><span class="title">Update Waiting&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
        </div>
      </template>
      <b-container fluid>
        <b-row class="mx-0 my-0 py-0">
          <b-col
              cols="12"
              sm="12"
              md="12"
            class="float_label mb-2"
           >
            
            <multiselect ref="VehicleTypeselect" 
                    v-model="$v.item.type_id.$model"
                    :state="validateState('type_id')"
                    :close-on-select="true"
                     track-by="id" label="type" placeholder="Select vehicle" :options="settingsVehicleType" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.type }}</template>
            </multiselect>
            <label class="typo__label float_label_item">VehicleType*</label>
          </b-col>
          <b-col sm="12" md="12" class="float_label mb-2">
   
              <b-form-input
                type="text"
                placeholder="Free minutes"
                v-model="$v.item.minutes.$model"
                :state="validateState('minutes')"
                size="sm"
              ></b-form-input>
            <label class="typo__label float_label_item">Free Minutes*</label>
          </b-col>
          <b-col sm="12" md="12" class="float_label mb-2">
    
              <b-form-input
                type="text"
                placeholder="Rate per minute"
                v-model="$v.item.rate.$model"
                :state="validateState('rate')"
                size="sm"
              ></b-form-input>
            <label class="typo__label float_label_item">Rate/Minute*</label>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="savecharges" :disabled="$v.item.$invalid">
            <b-spinner small v-if="loading"></b-spinner> Submit
          </b-button>
      </template>
    </b-modal>
    <!-- end client profile -->
	</div>
</template>

<style>
  .modal-waitingtime{
    max-width:550px !important;
  }
</style>

<script>
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import Vue from 'vue'
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
	name: 'waitingtime',
	components: {
	},
	data: () => ({
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        item: {
          type_id: null,
          minutes: "",
          rate: ""
        }
	}),
  validations: {
    item: {
        type_id: { required },
        minutes: { required },
        rate: { required }
      }
  },
	methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.item[name];
      return $dirty ? !$error : null;
    },
    getSettingsDetails(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getConfig')
          .then(() => {
            this.$store.state.tableloader = false
          })
          .catch(() => {
            this.$store.state.tableloader = false
          })
      },
    savecharges(){

      this.loading = true

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      let fd = new FormData();
      fd.append('type_id',this.item.type_id.id)
      fd.append('rate',this.item.rate)
      fd.append('minutes',this.item.minutes)

      axios.post('/rate-charges', fd, config).then(response=>{
        if(response.data.status == parseInt(200)) {

          this.$toastpopup('success','Success','Waiting updated successfully')

          this.getSettingsDetails()

          this.show = false
        }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    deleteRate(id){
        if(confirm("Are you sure to remove waiting time ?")){
            axios.get(`/delete-charge/${id}`).then(response=>{
              this.$toastpopup('success','Success',response.data.message)
              this.getSettingsDetails()
            }).catch(error=>{
                console.log(error)
            })
        }
    }
	},
	props: {
	     value: Boolean
	  },
	  computed: {
      loader() {
        return this.$store.state.componentloader
      },
      settingsVehicleType() {
        return this.$store.state.settingsVehicleType
      },
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    }
	  },
	mounted() {},
	created() {}
}
</script>