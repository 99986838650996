<template>
  <div class="about mt-0">
    <div class="mx-0 px-0" style="position:relative">
      <div class="flex" :class= "[mini ? 'minipadding' : 'expandpanding']">
      	<div class="section_title"><span class="title">Credit Notes&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
      	<div>
      		<b-button variant="primary" class="primary" size="sm"><router-link link :to="{name:'newCreditNote'}">Add Credit Note <b-icon class="mr-1" icon="plus"></b-icon></router-link></b-button>
      	</div>
      </div>
      <b-row class="subsection">
      	<b-col sm="12" xl="12" md="12" class="mx-0 px-3">
      		<div class="dynamic-scroller credit">
            <b-row class="mx-2 mb-2 searchsection" style="display:flex;justify-content:flex-end;align-items:center">
              <div class="px-1" style="width:200px !important;padding-top:0 !important;padding-bottom:0 !important;display:flex;">
                <b-form-input
                    placeholder="Search..."
                    size="sm"
                    v-model="searchfilter"
                  ></b-form-input>
              </div>
            </b-row>
            <div class="empty-state" v-if="allcreditnotes.length === 0">
              <div class="empty-state__content">
                <div class="empty-state__icon">
                  <img src="@/assets/data.svg" style="width:40%">
                </div>
                <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                <div class="empty-state__message" v-else>No records available.</div>
              </div>
            </div>
      			<RecycleScroller
		          ref="scroller"
		          class="scroller"
		          :items="allcreditnotes"
		          :item-size="24"
		          key-field="id"
		        >
		          <template  v-slot="{ item,index }">
		            <div>
    					    <div class="tcell"> {{ moment(item.date).format('MMM Do YYYY') }}</div>
                  <div class="tcell">
                    <RouterLink style="color:#F98525 !important;font-weight:500 !important;text-decoration:none !important;font-size: 10px !important;" link
                            :to='{name:"showCreditNote",params:{id:item.id}}'>{{ item.credit_note_no }}</RouterLink>
                          </div>
                  <div class="tcell">
                    <div v-if="item.organisation !== null">
                      <span class="badge__secondary" v-b-tooltip.hover.left :title="item.organisation.name">{{ item.organisation.name }}</span>
                    </div>
                    <span v-else>Private</span>
                  </div>
                  <div class="tcell">
                    <RouterLink v-if="item.invoice !== null" style="color:#F98525 !important;font-weight:500 !important;text-decoration:none !important;font-size: 10px !important;" link
                            :to='{name:"showInvoice",params:{id:item.invoice.id}}'>{{ item.invoice.invoice_no }}</RouterLink>
                  </div>
                  <div class="tcell">{{ item.status }}</div>
                  <div class="tcell">{{ item.notes }}</div>
                  <div class="tcell">KES <span style="font-weight:600 !important">{{ item.amount | currency(' ') }}</span></div>
    					    <div class="tcell text-center">
    					      	<a class="list-action" :id="`popover-target-1-${item.id}-${index}`">
    						  		<b-icon class="my-0 py-0" style="cursor:pointer;" icon="chevron-down"></b-icon>
    						    </a>
    						    <b-popover
      							  :target="`popover-target-1-${item.id}-${index}`"
      							  triggers="hover blur"
      							  placement="left"
      							  class="dropdown-menu"
      							>
      							  <div class="action-dropdown-menu">
      						  		<button v-if="item.invoice == null"
                              @click="linkcredit(item.id,item.organisation_id, item.credit_note_no)" class="action-dropdown-item " type="button"><b-icon class="" icon="link-45deg"></b-icon>&nbsp;  Link credit note</button>
                        <button class="action-dropdown-item " type="button"><router-link link :to='{name:"editCreditNote",params:{id:item.id}}'><b-icon class="" icon="pencil"></b-icon>&nbsp;  Edit</router-link></button>
      						  		<button @click="deleteCredit(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="trash"></b-icon>&nbsp;  Remove</button>
      						  	</div>
      							</b-popover>
    					    </div>
    					  </div>
		          </template>
		        </RecycleScroller>

			  </div>
      	</b-col>
      </b-row>
    </div>

    <!-- components -->
    <LinkCreditNote v-model="creditnotedialog"/>

  </div>
</template>

<style>
	@import "@/assets/table/table";
  .modal-credit{
    max-width:750px !important;
  }
	.action-dropdown-menu .action-dropdown-item:hover{
		background:var(--ab-primary);
		color:#fff;
		border-radius:var(--ab-dropdown-border-radius);
	}
	.action-dropdown-menu{
		padding-right: .5rem;
	}
  .credit .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-auto-columns: minmax(12.5%, 12.5%) !important;
     grid-gap: 0px;
     min-width:100%;
  }
  .credit .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }
</style>
<style scoped>
	@import "@/assets/table/table_scoped";
  .credit .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-auto-columns: minmax(12.5%, 12.5%) !important;
     grid-gap: 0px;
  }
  
</style>

<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';
import LinkCreditNote from '@/components/creditnote/linkcreditnote'

 export default {
  name: 'creditnotesList',
  components: { LinkCreditNote },
  computed: {
	  mini() {
	    return this.$store.state.mini
	  },
    loader() {
    	return this.$store.state.tableloader
    },
    creditnotes() {
      return this.$store.state.creditnotes.filter(item => {

        this.start = 0

        let invoice_no = "";
        let credit_note_no = "";
        let date = "";
        if(item.date !== null) {
          date = item.date
        }
        if(item.credit_note_no !== null) {
          credit_note_no = item.credit_note_no
        }
        if(item.invoice !== null) {
          invoice_no = item.invoice.invoice_no
        }
        return credit_note_no.toLowerCase().includes(this.searchfilter.toLowerCase()) || invoice_no.toLowerCase().includes(this.searchfilter.toLowerCase()) || date.includes(this.searchfilter)
      })
    },
    allcreditnotes() {
      return this.creditnotes
    },
  },
  data: () => ({
      searchfilter: '',
      search: '',
      dialog: false,
      creditnotedialog: false,
      loading: false,
      fields: [
       {
        text: 'DATE',
        align: 'start',
        value: 'date',
      },
      { text: 'CREDIT NOTE#', value: 'creditnote' },
      { text: 'ORGANISATION', value: 'organisation' },
      { text: 'INVOICE NO', value: 'invoiceno' },
      { text: 'STATUS', value: 'status' },
      { text: 'NOTES', value: 'note' },
      { text: 'AMOUNT', value: 'amount' },
      { text: 'ACTION', value: 'action' },
     ],
     item: {
      invoice_id: null,
      id: null
     }
  }),

  mounted() {

  	let headertitle = `<div class="view-titleheader titlehead" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="theader">${value.text}</div>`;
         });
      headertitle +=`</div>`;
      $('.vue-recycle-scroller').prepend(headertitle)

  	if(!this.allcreditnotes || this.allcreditnotes.length == 0) {
        this.getCreditNotes()
      }
      
  },
  beforeMount() {
    if(!ability.can('organisation-management', 'all')) {
        this.$router.push({ name: 'Dashboard' })
        return
      }
  },
  serverPrefetch () {
    // return this.getCreditNotes()
  },
  methods: {
  	async getCreditNotes(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getCreditNotes')
      },
  
        clear () {
          if(this.$refs.form != undefined) {
            this.$refs.form.reset()
          }
        
        },
      deleteCredit(id){
            if(confirm("Are you sure to remove credit note ?")){
                axios.delete(`/credit-notes/${id}`).then(response=>{
                  let index = this.$store.state.creditnotes.findIndex(data => data.id == response.data.creditnote.id);
                  if (index > -1) {
                    this.$store.state.creditnotes.splice(index, 1);
                  }
                  this.$toastpopup('success','Success','Credit note removed successfully')
                }).catch(error=>{
                    console.log(error)
                })
            }
        },

      linkcredit(id, organisationId, creditNo) {
        this.$store.state.linkcreditdata = {
          id: id,
          organisationid: organisationId,
          tolinkreceipt: creditNo
        }
        this.creditnotedialog = true
      }
  }
};

</script>
