export default{
	install(vue,options) {

		vue.prototype.$toastpopup = function(variant,title,message) {
			 this.$bvToast.toast(message, {
                title: title,
                variant: variant,
                autoHideDelay: 5000,
                solid: true,
              })
		}


		vue.prototype.$toRadians = function(degrees) {
			return degrees * Math.PI / 180
		}

		vue.prototype.$toDegrees = function(radians) {
			return radians * (180 / Math.PI);
		}

		vue.prototype.$bearing = function(begin, end) {
			  let lat = parseFloat(Math.abs(begin.lat() - end.lat()));
	          let lng = parseFloat(Math.abs(begin.lng() - end.lng()));

	          if (begin.lat() < end.lat() && begin.lng() < end.lng()){
	              return parseFloat((this.$toDegrees(Math.atan(lng / lat))));
	          }else if(begin.lat() >= end.lat() && begin.lng() < end.lng()){
	              return parseFloat(((90 - this.$toDegrees(Math.atan(lng / lat))) + 90));
	          }else if(begin.lat() >= end.lat() && begin.lng() >= end.lng()){
	              return parseFloat((this.$toDegrees(Math.atan(lng / lat)) + 180));
	          }else if(begin.lat() < end.lat() && begin.lng() >= end.lng()){
	              return parseFloat(((90 - this.$toDegrees(Math.atan(lng / lat))) + 270));
	          }else{
	            return -1;
	          }
		}

		vue.prototype.$distance = function(olat,olng,dlat,dlng,resp) {
			  
			var origin = new google.maps.LatLng(olat,olng);       
	        var destination = new google.maps.LatLng(dlat,dlng);
	        var service = new google.maps.DistanceMatrixService();

	         service.getDistanceMatrix(
	          {
	            origins: [origin],
	            destinations: [destination],
	            travelMode: 'DRIVING',            
	            unitSystem: google.maps.UnitSystem.IMPERIAL,
	            durationInTraffic: true,
	            avoidHighways: false,
	            avoidTolls: false
	          }, (responseDis, status) => {
	            if (status !== google.maps.DistanceMatrixStatus.OK || status != "OK"){
	              console.log('Error:', status);
	              // OR
	              return 0;
	            }else{
	              var totalDistance = responseDis.rows[0].elements[0].distance.value;
	              var totalDuration = responseDis.rows[0].elements[0].duration.value;

	              this.$store.state.distance = parseFloat(totalDistance*0.001).toFixed(2);   
	              this.$store.state.distanceTime = parseInt(totalDuration)/60;   
	              if(resp === "stop1") {
	                this.$store.state.stop1 = parseFloat(totalDistance*0.001).toFixed(2);
	                this.$store.state.stop1Time = parseInt(totalDuration)/60;     
	              }else if(resp === "stop2") {
	                this.$store.state.stop2 = parseFloat(totalDistance*0.001).toFixed(2); 
	                this.$store.state.stop2Time = parseInt(totalDuration)/60;    
	              }else if(resp === "end") {
	                this.$store.state.end = parseFloat(totalDistance*0.001).toFixed(2);  
	                this.$store.state.endTime = parseInt(totalDuration)/60;  
	              }            
	            }
	          });
         
		}

		vue.prototype.$loaddata = function() {

			let box = document.getElementById('scroller');

			var throttleTimer;
			const throttle = (callback, time) => {
			  if (throttleTimer) return;

			  throttleTimer = true;

			  setTimeout(() => {
			    callback();
			    throttleTimer = false;
			  }, time);
			};


			const handleInfiniteScroll = event => {
			  throttle(() => {
			    const {scrollHeight, scrollTop, clientHeight} = event.target;

			    if (Math.abs(scrollHeight - clientHeight - scrollTop) < 100) {

			    	if(this.$store.state.searchdate === "") {

			    	  this.$store.state.tableloader = true

			          this.$store
			          .dispatch('loadBookingData',this.$store.state.allbookings.length)
			          .then(() => {})
			          .catch(() => {})

			    	}

			    }
		
			    if (this.$store.state.bookingcount === this.$store.state.allbookings.length) {
			      removeInfiniteScroll();
			    }
			  }, 500);
			};

			const removeInfiniteScroll = () => {
			  box.removeEventListener('scroll', handleInfiniteScroll);
			};

			box.addEventListener('scroll', handleInfiniteScroll);

		}


	}
}