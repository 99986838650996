<template>
  <div class="about mt-0">
    <div class="mx-0 px-0" style="position:relative">
      <div class="flex" :class= "[mini ? 'minipadding' : 'expandpanding']">
      	<div class="section_title"><span class="title">Customers&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
      	<div>
      		<b-button variant="primary" class="primary" size="sm" @click.stop="dialog = true">Add Customer <b-icon class="mr-1" icon="plus"></b-icon></b-button>
      	</div>
      </div>
      <b-row class="subsection">
      	<b-col sm="12" xl="12" md="12" class="mx-0 px-3">
      		<div class="dynamic-scroller customer">
            <b-row class="mx-2 mb-2 searchsection" style="display:flex;justify-content:flex-end;align-items:center">
              <div class="px-1" style="width:200px !important;padding-top:0 !important;padding-bottom:0 !important;display:flex;">
                <b-form-input
                    placeholder="Search..."
                    size="sm"
                    v-model="searchfilter"
                  ></b-form-input>
              </div>
            </b-row>
            <div class="empty-state" v-if="allcustomers.length === 0">
              <div class="empty-state__content">
                <div class="empty-state__icon">
                  <img src="@/assets/data.svg" style="width:40%">
                </div>
                <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                <div class="empty-state__message" v-else>No records available.</div>
              </div>
            </div>
      			<RecycleScroller
		          ref="scroller"
		          class="scroller"
		          :items="allcustomers"
		          :item-size="24"
		          key-field="id"
		          >
		          <template  v-slot="{ item,index }">
		            <div>
    					    <div class="tcell"> {{ item.name }}</div>
                  <div class="tcell">{{ item.telephone }}</div>
                  <div class="tcell">{{ item.email }}</div>
                  <div class="tcell">
                    <span
                      class="badge__secondary"
                    >{{ item.account_type }}</span>
                  </div>
                  <div class="tcell">
                    <span
                      v-if="item.organisation"
                    >
                      {{ item.organisation.name }}
                    </span>
                    <span v-else>N/A</span>
                  </div>
                  <div class="tcell">
                    <span
                      class="badge__secondary"
                      v-if="item.reviewer && item.reviewer.length > 0"
                    >
                      <small v-for="review in item.reviewer" :key="review.id">{{ review.name }}</small>
                    </span>
                    <span v-else></span>
                  </div>
    					    <div class="tcell text-center">
    					      	<a class="list-action" :id="`popover-target-1-${item.id}-${index}`">
    						  		<b-icon class="my-0 py-0" style="cursor:pointer;" icon="chevron-down"></b-icon>
    						    </a>
    						    <b-popover
      							  :target="`popover-target-1-${item.id}-${index}`"
      							  triggers="hover blur"
      							  placement="left"
      							  class="dropdown-menu"
      							>
      							  <div class="action-dropdown-menu">
      						  		<button @click="editOrg(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="pencil"></b-icon>&nbsp;  Edit</button>
      						  		<button @click="deleteItem(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="trash"></b-icon>&nbsp;  Remove</button>
      						  	</div>
      							</b-popover>
    					    </div>
    					  </div>
		          </template>
		        </RecycleScroller>

			  </div>
      	</b-col>
      </b-row>
    </div>

<!-- new customer -->
    <b-modal
      v-model="dialog"
      size="customer"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Add Customer</span>
        </div>
      </template>
      <b-container fluid>
        <b-row class="mx-0 my-0 py-0">
          <b-col sm="12" md="12" class="float_label mb-3">
            
              <b-form-input
                placeholder="Name"
                required
                size="sm"
                v-model="item.name"
              ></b-form-input>
            <label class="typo__label float_label_item">Name*</label>
          </b-col>
          <b-col sm="12" md="12" class="float_label mb-3">
            
              <b-form-input
                placeholder="Mobile"
                size="sm"
                v-model="item.telephone"
              ></b-form-input>
            <label class="typo__label float_label_item">Mobile*</label>
          </b-col>
          <b-col sm="12" md="12" class="float_label mb-3">
            
              <b-form-input
                placeholder="Email"
                size="sm"
                v-model="item.email"
              ></b-form-input>
            <label class="typo__label float_label_item">Email*</label>
          </b-col>
          <b-col
            sm="12" md="12"
            class="float_label mb-3"
           >
            
            <multiselect ref="organisationmultiselect" 
                    v-model="item.organisation_id"
                     track-by="id" label="name" placeholder="Organisation" :options="organisations" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Organisation*</label>
          </b-col>
          <b-col sm="12" md="12" class="mb-3">
            <b-form-checkbox name="check-button" switch v-model="item.reviewer">
              Reviewer/Approver
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="create">
            <b-spinner small v-if="loading"></b-spinner> Save
          </b-button>
      </template>
    </b-modal>

    <!-- update customer -->
    <b-modal
      v-model="updatedialog"
      size="customer"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Update Customer</span>
        </div>
      </template>
      <b-container fluid>
        <b-row class="mx-0 my-0 py-0">
          <b-col sm="12" md="12" class="float_label mb-3">
            
              <b-form-input
                placeholder="Name"
                required
                size="sm"
                v-model="item.updatename"
              ></b-form-input>
            <label class="typo__label float_label_item">Name*</label>
          </b-col>
          <b-col sm="12" md="12" class="float_label mb-3">
            
              <b-form-input
                placeholder="Mobile"
                size="sm"
                v-model="item.updatetelephone"
              ></b-form-input>
            <label class="typo__label float_label_item">Mobile*</label>
          </b-col>
          <b-col sm="12" md="12" class="float_label mb-3">
            
              <b-form-input
                placeholder="Email"
                size="sm"
                v-model="item.updateemail"
              ></b-form-input>
            <label class="typo__label float_label_item">Email*</label>
          </b-col>
          <b-col
            sm="12" md="12"
            class="float_label mb-3"
           >
            
            <multiselect ref="organisationmultiselect" 
                    v-model="item.updateorganisation_id"
                     track-by="id" label="name" placeholder="Organisation" :options="organisations" :searchable="true" :allow-empty="true" :show-labels="false">
              <span slot="noResult">Oops! Record not found.</span>
              <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
            </multiselect>
            <label class="typo__label float_label_item">Organisation*</label>
          </b-col>
          <b-col sm="12" md="12" class="mb-3">
            <b-form-checkbox name="check-button" switch v-model="item.updatereviewer">
              Reviewer/Approver
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="update">
            <b-spinner small v-if="loading"></b-spinner> Update
          </b-button>
      </template>
    </b-modal>

  </div>
</template>

<style>
	@import "@/assets/table/table";
  .modal-customer{
    max-width:480px !important;
  }
	.action-dropdown-menu .action-dropdown-item:hover{
		background:var(--ab-primary);
		color:#fff;
		border-radius:var(--ab-dropdown-border-radius);
	}
	.action-dropdown-menu{
		padding-right: .5rem;
	}
  .customer .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-template-columns: 17.28% 8.28% 18.28% 10.28% 23.28% 10.28% 12.28%;
     grid-gap: 0px;
     min-width:100%;
  }
  .customer .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }
</style>
<style scoped>
	@import "@/assets/table/table_scoped";
  .customer .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-template-columns: 17.28% 8.28% 18.28% 10.28% 23.28% 10.28% 12.28%;
     grid-gap: 0px;
  }
  
</style>

<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';

 export default {
  name: 'customerList',
  components: {
  },
  computed: {
	  mini() {
	    return this.$store.state.mini
	  },
    loader() {
    	return this.$store.state.tableloader
    },
    customers() {
      return this.$store.state.customers.filter(item => {

        this.start = 0

        let customer = "";
        let org = "";
        if(item.name !== null) {
          customer = item.name
        }
        if(item.organisation !== null) {
          org = item.organisation.name
        }
        return customer.toLowerCase().includes(this.searchfilter.toLowerCase()) || org.toLowerCase().includes(this.searchfilter.toLowerCase())
      })
    },
    allcustomers() {
      return this.customers
    },
    organisations() {
      return this.$store.state.minorganisations
    }
  },
  data: () => ({
      searchfilter: '',
      search: '',
      dialog: false,
      updatedialog: false,
      loading: false,
      fields: [
        { text: 'Name', value: 'name' },
        { text: 'Telephone', value: 'telephone' },
        { text: 'Email', value: 'email' },
        { text: 'Account Type', value: 'account' },
        { text: 'Organisation', value: 'organisation' },
        { text: 'Status', value: 'status' },
        { text: 'Action', value: 'action' },
       ],
       item:{
            name:"",
            telephone:"",
            idno:"",
            email:"",
            organisation_id:null,
            reviewer: false,
            updatereviewer: false,

            updatename:"",
            updatetelephone:"",
            updateidno:"",
            updateemail:"",
            updateorganisation_id:null,

            updateID: "",
            _method:"patch"
        },
  }),

  mounted() {

  	let headertitle = `<div class="view-titleheader titlehead" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="theader">${value.text}</div>`;
         });
      headertitle +=`</div>`;
      $('.vue-recycle-scroller').prepend(headertitle)

  	if(!this.allcustomers || this.allcustomers.length == 0) {
        this.getCustomers()
      }
  },
  beforeMount() {
    if(!ability.can('user-management', 'all')) {
      this.$router.push({ name: 'Dashboard' })
      return
    }
  },

  methods: {
  	getCustomers(){
      this.$store.state.tableloader = true
      this.$store
        .dispatch('getCustomers')
    },
    create(){

        this.loading = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let fd = new FormData();
        fd.append('name',this.item.name)
        fd.append('telephone',this.item.telephone)
        fd.append('email',this.item.email)
        fd.append('idno',this.item.idno)
        fd.append('reviewer',this.item.reviewer)
        fd.append('organisation_id',JSON.stringify(this.item.organisation_id))

        axios.post('/admin/customer/store', fd, config).then(response=>{
            if(response.data.status === 200) {

              let found = this.$store.state.customers.find(data => data.id == response.data.user.id);
              if (!found) {
                this.$store.state.customers.unshift(response.data.user)
              }

              this.$toastpopup('success','Success','Customer added successfully')
              this.updatedialog =false
              this.clear()
            }
            this.loading = false
            this.dialog = false

          })
          .catch(error => {
            this.loading = false
            this.dialog = false
          })

    },
    clear () {
      this.item.name = ''
      this.item.telephone = ''
      this.item.email = ''
      this.item.idno = ''
      this.item.organisation_id = null
      this.item.reviewer = false
      this.item.updateID = ''
    },
    update(){

      this.loading = true

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      let fd = new FormData();
      fd.append('name',this.item.updatename)
      fd.append('telephone',this.item.updatetelephone)
      fd.append('email',this.item.updateemail)
      fd.append('idno',this.item.updateidno)
      fd.append('reviewer',this.item.updatereviewer)
      fd.append('organisation_id',JSON.stringify(this.item.updateorganisation_id))
      fd.append('_method',this.item._method)

      axios.post(`/admin/customer/update/${this.item.updateID}`, fd, config).then(response=>{
          if(response.data.status === 200) {

            let index = this.$store.state.customers.findIndex(data => data.id == response.data.user.id);
            if (index > -1) {
              this.$store.state.customers.splice(index, 1, response.data.user);
            }

            this.$toastpopup('success','Success','Customer updated successfully')
            this.updatedialog =false
          }
          this.loading = false

        })
        .catch(error => {
          this.loading = false
          this.dialog = false
        })

    },
    editOrg(id) {
      let index = this.customers.filter(item => item.id === id );

      this.item.updatename = index[0].name
      this.item.updatetelephone = index[0].telephone
      this.item.updateemail = index[0].email
      this.item.updateidno = index[0].idno
      if(index[0].organisation !== null)
       this.item.updateorganisation_id = index[0].organisation
      this.item.updateID = index[0].id
      if(index[0].reviewer && index[0].reviewer.length > 0)
        this.item.updatereviewer = true
      else
        this.item.updatereviewer = false
      this.updatedialog = true

    },
    deleteItem(id){
            if(confirm("Are you sure to remove customer ?")){

              axios.delete(`/customers/${id}`).then(response=>{
                let index = this.$store.state.customers.findIndex(data => data.id == response.data.user.id);
                  if (index > -1) {
                    this.$store.state.customers.splice(index, 1);
                  }
                this.$toastpopup('success','Success','Customer removed successfully')
              })
              .catch(error => {
                console.log(error)
              })
              
            }
        }
  }
};

</script>
