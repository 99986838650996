<template>
	<div>

    <div class="fill body list-body d-flex scroll-y">
      <div class="scroll-y scrollbox">
        <ul class="content-list" id="itemList">
          <li v-for="(item,index) in itemsForList" :key="index">
            <div class="list-title">
              <span>{{ item.customer.name }}</span>
              <span></span>
              <star-rating v-bind:increment="0.5"
                                   v-bind:max-rating="5"
                                   v-model:rating="item.stars"
                                   inactive-color="#c8c8c8"
                                   active-color="#F9780D"
                                   v-bind:star-size="15">
                      </star-rating>
            </div>
            <div class="list-subtitle">
              <span>{{ item.comment !== null ? item.comment : '' }}</span>
              <span>{{ moment(item.created_at).format('MMM Do YYYY, H:mm:ss A') }}</span>
            </div>
          </li>
        </ul>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="itemList"
          v-if="ratings.length > 0"
        ></b-pagination>
      </div>
    </div>

	</div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import StarRating from 'vue-star-rating'

export default {
	name: 'ratings',
	components: {StarRating},
	data: () => ({
    overlay:false,
		fieldRules: [
      (v) => !!v || 'Field is required'
      ],
    valid: true,
    isBusy: false,
    loading: false,
    disabled: false,
     perPage: 15,
      currentPage: 1,

	}),
	methods: {
		fetchratings() {
      this.overlay = true
      this.$store
          .dispatch('fetchratings',this.driverid)
          .then(() => {
            this.overlay = false
          })
          .catch(() => {
            this.overlay = false
          })
      },
	},
	props: {
	     value: Boolean,
	     driverid: Number,
	  },
	  computed: {
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    },
      ratings () {
        return this.$store.state.ratings
      },
      rows() {
        return this.ratings.length
      },
      itemsForList() {
        return this.ratings.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage,
        );
      }
	  },
	mounted() {
    if(this.ratings.length == 0)
      this.fetchratings()
	},
	created() {
     
    }
}
</script>