<template>
  <b-col cols="4">
    <b-row>
      <b-col md="6" cols="12" sm="12" class="px-1" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;">
          <b-form-datepicker
            id="datepicker-pickup"
            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
            :hide-header="true"
            locale="en"
            size="sm"
            placeholder="Search by date"
            v-model="$store.state.todaysearchdate"
            @context="selectedDate"
          ></b-form-datepicker>
      </b-col>
      <b-col md="6" cols="12" sm="12" class="px-1" style="padding-top:0 !important;padding-bottom:0 !important;display:flex;">
        <b-form-input
            placeholder="Search bookings"
            size="sm"
            v-model="search"
            @input.native="searchBooking($event)"
          ></b-form-input>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import moment from 'moment'

export default {
	name: 'todayTookingSearch',
	components: {
	},
	data() { 

    return {
  		messageclientdialog: false,
      valid: true,
      isBusy: false,
      loading: false,
      disabled: false,
      search: "",
      menu:false,
  	}
  },
	methods: {
		selectedDate(dateselected) {
			this.$store.state.todaysearchdate = dateselected.selectedYMD
			this.$store.state.tableloader = true
      if(dateselected.selectedYMD !== null && dateselected.selectedYMD !== '') {
        this.$store.dispatch('loadtodaybookingsbydate',dateselected.selectedYMD)
      }
		},
		onClearClicked () {
        this.search = ''
      },
       cleardate () {
        this.$store.commit('clearDate')
        this.$store.state.tableloader = true
		    this.$store.dispatch('getTodayBookings')
        this.menu = false
      },
      clearDate() {
      	this.$store.commit('clearDate')
      	this.$store.state.tableloader = true
		    this.$store.dispatch('getTodayBookings')
      },
      searchBooking() {
      	this.$store.commit('todaysearchBooking',this.search)
      }
	},
	props: {},
	  computed: {
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    },
	    date: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$store.state.todaysearchdate = value
	      }
	    },
	  },
	mounted() {
    this.$store.state.todaysearchdate = moment().format('YYYY-MM-DD')
	},
	created() {
     
    }
}
</script>