<template>
	<div class="profile">
    <b-modal
      v-model="show"
      size="profile" 
      hide-footer
      >
      <template #modal-title>
        <div class="w-100">
          <div class="section_title"><span class="title">Request Summary&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
        </div>
      </template>
        <table class="table bordered" v-if="profiledetails !== null">
          <tbody>
           <!--  <tr>
              <td>Category</td>
              <td colspan="3">{{ profiledetails.request_category }}</td>
            </tr> -->
            <tr>
              <td>Booking made at</td>
              <td>{{ moment(profiledetails.created_at).format("YYYY-MM-DD H:m:s") }}</td>
              <td>Trip Type</td>
              <td>
                <b-badge pill variant="dark" v-if="profiledetails.trip_type === '1'">One Way</b-badge>
                <b-badge pill variant="dark" v-else>Wait and Return</b-badge>
              </td>
            </tr>
            <tr>
              <td>Ref No</td>
              <td>{{ profiledetails.ref_no }}</td>
              <td>Status</td>
              <td>
                <b-badge pill variant="warning" v-if="profiledetails.status === 'Waiting'">{{ profiledetails.status }}</b-badge>
                <b-badge pill variant="info" v-if="profiledetails.status === 'Active'">{{ profiledetails.status }}</b-badge>
                <b-badge pill variant="secondary" v-if="profiledetails.status === 'Cancelled'">{{ profiledetails.status }}</b-badge>
                <b-badge pill variant="primary" v-if="profiledetails.status !== 'Waiting' && profiledetails.status !== 'Active' && profiledetails.status !== 'Cancelled'">{{ profiledetails.status }}</b-badge>
              </td>
            </tr>
            <tr>
              <td>Name</td>
              <td>
                <div v-if="profiledetails.passengers.length === 0">{{ profiledetails.passenger_name }}</div>
                <div v-else style="display:flex;flex-direction:column !important">
                  <div v-for="(passenger,index) in profiledetails.passengers" style="display:flex;"><b-icon class="mr-1" icon="dot"></b-icon> {{ passenger.passenger_name }}</div>
                </div>
              </td>
              <td>Telephone</td>
              <td>
                <div v-if="profiledetails.passengers.length === 0">{{ profiledetails.telephone }}</div>
                <div v-else style="display:flex;flex-direction:column !important">
                  <div v-for="(passenger,index) in profiledetails.passengers" style="display:flex;"><b-icon class="mr-1" icon="dot"></b-icon> {{ passenger.telephone }}</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Organisation</td>
              <td colspan="3">
                <div v-if="profiledetails.organisation !== null">{{ profiledetails.organisation.name }}</div>
                <div v-else>Private</div>
              </td>
            </tr>
            <tr>
              <td>Pickup Date</td>
              <td colspan="3">{{ profiledetails.pickup_date }} {{ profiledetails.pickup_time }}</td>
            </tr>
            <tr v-if="profiledetails.projects.length > 0">
              <td>Projects</td>
              <td colspan="3">
                <div style="display:flex;flex-direction:column !important">
                  <div style="display:flex;" v-for="(project, index) in profiledetails.projects" :key="index">
                    {{ project.project.unit }} - {{ project.project.name }}
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="profiledetails.subprojects.length > 0">
              <td>Projects</td>
              <td colspan="3">
                <b-badge pill variant="secondary"
                  v-for="(project, index) in profiledetails.subprojects"
                  :key="index"
                  size="sm"
                >
                   <div style="display:flex;color:#fff">
                      ({{ project.subproject.project.unit }}) {{ project.subproject.name }}
                    </div>
                </b-badge>
              </td>
            </tr>
            <tr>
              <td>From</td>
              <td colspan="3">{{ profiledetails.from === undefined ? profiledetails.travelfrom : profiledetails.from }}</td>
            </tr>
            <tr>
              <td>Stop</td>
              <td colspan="3">
                <div style="display:flex;flex-direction:column !important">
                  <div v-if="profiledetails.travelstop1 !== null" style="display:flex;">
                    <b-icon class="mr-2" icon="dot"></b-icon> {{ profiledetails.travelstop1 }}
                  </div>
                  <div v-if="profiledetails.travelstop2 !== null" style="display:flex;align-items:center">
                    <b-icon icon="three-dots-vertical"></b-icon>
                  </div>
                  <div v-if="profiledetails.travelstop2 !== null" style="display:flex;align-items:center">
                    <b-icon class="mr-2" icon="dot"></b-icon> {{ profiledetails.travelstop2 }}
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>To</td>
              <td colspan="3">{{ profiledetails.to === undefined ? profiledetails.travelto : profiledetails.to }}</td>
            </tr>
            <tr>
              <td>Assigned Vehicle</td>
              <td colspan="3">
                <div v-if="profiledetails.vehicles.length > 0"><div v-for="(res,i) in profiledetails.vehicles" :key="i">{{ res.vehicle.name }} - {{ res.vehicle.registration }},</div></div>
                <div v-else>N/A</div>
              </td>
            </tr>
            <tr>
              <td>Driver</td>
              <td colspan="3">
                <div v-if="profiledetails.drivers.length > 0"><div v-for="(res,i) in profiledetails.drivers" :key="i">{{ res.driver.name }} - {{ res.driver.telephone }},</div></div><div v-else> N/A </div>
              </td>
            </tr>
            <tr>
              <td>Flight</td>
              <td colspan="3">
                {{ profiledetails.flight }}
              </td>
            </tr>
            <tr>
              <td>Remarks</td>
              <td colspan="3">
                <div
                  v-if="profiledetails.remarks !== null"
                >
                  {{ profiledetails.remarks }}
                </div>
              </td>
            </tr>
            <tr>
              <td>Booked By</td>
              <td colspan="3">
                <div
                  v-if="profiledetails.orderedby !== null"
                >
                  {{ profiledetails.orderedby }}
                </div>
              </td>
            </tr>
            <tr v-if="profiledetails.authorization == 'false'">
              <td>Approvers</td>
              <td colspan="3">
                <div style="display:flex;flex-direction:column !important">
                  <div style="display:flex;flex-direction:column !important" v-for="(project, index) in profiledetails.project" :key="index">
                    <div class="my-1" style="display:flex;" v-for="(approver, i) in project.approvers" :key="i">
                      <div v-if="approver.approver != null">{{ approver.approver.name }} - {{ approver.approver.telephone }}</div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Cancellation</td>
              <td colspan="3">
                <div
                  v-if="profiledetails.cancellation_reason !== null"
                >
                  {{ profiledetails.cancellation_reason }}
                </div>
              </td>
            </tr>
            <tr>
              <td>E-voucher</td>
              <td colspan="3">
                <a :href="`https://e-voucher.absolutecabs.co.ke/customer/e-voucher/${profiledetails.ref_no}`" target="_blank">View voucher</a>
              </td>
            </tr>
            <tr>
              <td>Driver E-sign</td>
              <td>
                <img
                    :src="profiledetails.driver_signature"
                    style="width:180px"
                    v-if="profiledetails.driver_signature !== null"
                  />
              </td>
              <td>Client E-sign</td>
              <td>
                <img
                    :src="profiledetails.signature"
                    style="width:180px"
                    v-if="profiledetails.signature !== null"
                  />
              </td>
            </tr>
            <tr v-if="profiledetails.logs.length > 0">
              <td>Logs</td>
              <td colspan="3">
                <ul class="py-0 my-0">
                  <li v-for="(log,index) in profiledetails.logs"><b-icon class="mr-1" icon="dot"></b-icon> {{ log.action }} by <u>{{ log.user.name }}</u></li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
    </b-modal>

	</div>
</template>
<style>
  .modal-profile{
    max-width:600px !important;
  }
</style>
<script>
import axios from 'axios'
import Vue from 'vue'

export default {
	name: 'profile',
	components: {
	},
	data: () => ({
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        labelpickupl: "",
        labeldropl:"",
        directionsService: null,
        directionsDisplay: null,
        coords: {lat: 0, lng: 0},
        destination: {lat: 0, lng: 0},

	}),
	methods: {
		
	},
	props: {
	     value: Boolean,
	     profileid: Number
	  },
	  computed: {
      loader() {
        return this.$store.state.componentloader
      },
      bookingid() {
        return this.$store.state.bookingid
      },
      profiledetails() {
        return this.$store.state.profiledetails
      },
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    }
	  },
	mounted() {
    
	},
	created() {},
  watch: {
      bookingid: function(newVal, oldVal) {
        if(newVal !== null) {
          this.$store.state.profiledetails = null
          this.getprofiledetails()
        }
      },
  },
  methods: {
    getprofiledetails() {
      this.$store.state.componentloader = true
      axios.get(`/booking/profile/${this.bookingid}`).then(response=>{
        this.$store.state.componentloader = false
        if(response.data.status == parseInt(200)) {
          this.$store.state.profiledetails = response.data.profile
        }
        
      })
      .catch(() => {
        this.$store.state.componentloader = false
        console.log("Unexpected error occured")
      })
    }
  }
}
</script>