<template>
	<div>
	  <!-- client profile modal -->
    <b-modal
      v-model="show"
      size="bulksms"
      >
      <template #modal-title>
        <div class="w-100">
          <div class="section_title"><span class="title">Update Credentials&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
        </div>
      </template>
      <b-container fluid>
        <b-row class="mx-0 my-0 py-0">
          <b-col sm="12" md="12" class="float_label mb-2">
        
              <b-form-input
                type="text"
                placeholder="Api Key"
                v-model="$v.item.apikey.$model"
                :state="validateState('apikey')"
                size="sm"
              ></b-form-input>
            <label class="typo__label float_label_item">Api Key*</label>
          </b-col> 
          <b-col sm="12" md="12" class="float_label mb-2">
      
              <b-form-input
                type="text"
                placeholder="Partner ID"
                v-model="$v.item.partnerid.$model"
                :state="validateState('partnerid')"
                size="sm"
              ></b-form-input>
            <label class="typo__label float_label_item">Partner ID*</label>
          </b-col>
          <b-col sm="12" md="12" class="float_label mb-2">
  
              <b-form-input
                type="text"
                placeholder="Shortcode"
                v-model="$v.item.shortcode.$model"
                :state="validateState('shortcode')"
                size="sm"
              ></b-form-input>
            <label class="typo__label float_label_item">Shortcode*</label>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="create" :disabled="$v.item.$invalid">
            <b-spinner small v-if="loading"></b-spinner> Submit
          </b-button>
      </template>
    </b-modal>
    <!-- end client profile -->
	</div>
</template>

<style>
  .modal-bulksms{
    max-width:550px !important;
  }
</style>

<script>
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import Vue from 'vue'
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
	name: 'bulksms',
	components: {
	},
	data: () => ({
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        item: {
          apikey: "",
          partnerid: "",
          shortcode: ""
        }
	}),
  validations: {
    item: {
        apikey: { required },
        partnerid: { required },
        shortcode: { required }
      }
  },
	methods: {
    getSettingsDetails(){
        this.$store.state.tableloader = true
        this.$store
          .dispatch('getConfig')
          .then(() => {
            this.$store.state.tableloader = false
          })
          .catch(() => {
            this.$store.state.tableloader = false
          })
      },
    validateState(name) {
      const { $dirty, $error } = this.$v.item[name];
      return $dirty ? !$error : null;
    },
    create(){

      this.loading = true

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      let fd = new FormData();
      fd.append('apikey',this.item.apikey)
      fd.append('partnerid',this.item.partnerid)
      fd.append('shortcode',this.item.shortcode)

      axios.post('/bulksms', fd, config).then(response=>{
        if(response.data.status == parseInt(200)) {

          this.$toastpopup('success','Success','Credentials updated successfully')

          this.getSettingsDetails()

          this.show = false
        }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
	},
	props: {
	     value: Boolean
	  },
	  computed: {
      loader() {
        return this.$store.state.componentloader
      },
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    }
	  },
	mounted() {},
	created() {}
}
</script>