import { render, staticRenderFns } from "./bookingDocument.vue?vue&type=template&id=2281d37e"
import script from "./bookingDocument.vue?vue&type=script&lang=js"
export * from "./bookingDocument.vue?vue&type=script&lang=js"
import style0 from "./bookingDocument.vue?vue&type=style&index=0&id=2281d37e&prod&lang=css"
import style1 from "./bookingDocument.vue?vue&type=style&index=1&id=2281d37e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports