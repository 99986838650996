<template>
  <div class="about mt-0">
    <div class="mx-0 px-0" style="position:relative">
      <div class="flex" :class= "[mini ? 'minipadding' : 'expandpanding']">
      	<div class="section_title"><span class="title">Today Bookings&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
        <bookingcounts/>
      	<div>
      		<b-button class="mx-2" variant="main" size="sm"><router-link link :to="{name: 'returnBooking'}">Return Booking <b-icon class="mr-1" icon="plus"></b-icon></router-link></b-button>
      		<b-button variant="primary" class="primary" size="sm"><router-link link :to="{name: 'AddBookingView'}">Add Booking <b-icon class="mr-1" icon="plus"></b-icon></router-link></b-button>
          <b-button variant="secondary" id="popover-allbooking-show-more" class="secondary mx-2" size="sm"><b-icon icon="three-dots"></b-icon></b-button>
            <b-popover
              target="popover-allbooking-show-more"
              triggers="hover blur"
              placement="bottom"
              class="dropdown-menu"
            >
              <div class="action-dropdown-menu">
                <button  class="action-dropdown-item" @click="showSearchDialog" type="button"><b-icon class="" icon="search"></b-icon>&nbsp;  Search</button>
              </div>
            </b-popover>
      	</div>
      </div>
      <b-row class="subsection">
      	<b-col sm="12" xl="12" md="12" class="mx-0 px-3">
      		<div class="dynamic-scroller todaybooking">
            <b-row class="mx-2 searchsection" style="display:flex;justify-content:center;align-items:center">
              <b-alert fade dismissible class="mb-2" :show="showDismissibleAlert" variant="success" style="width:100%">
                <div class="alert-heading tflex tflex-align-center tflex-space-between">
                  <em><b>Search results</b></em>
                </div>
                <p>
                  Available records: {{ booking.length | currency('') }}
                </p>
                <hr class="my-1">
                <p class="mb-0" style="display:flex;align-items:center;cursor:pointer;color:#F9760B !important">
                  <em>The results are as the provided search request  </em>
                </p>
              </b-alert>
            </b-row>
            <b-row class="mx-2 mb-2 searchsection" style="display:flex;justify-content:flex-end;align-items:center">
              <TodayBookingSearch/>
            </b-row>
            <div class="empty-state" v-if="allbookings.length === 0">
                  <div class="empty-state__content">
                    <div class="empty-state__icon">
                      <img src="@/assets/data.svg" style="width:40%">
                    </div>
                    <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                    <div class="empty-state__message" v-else>No records available.</div>
                  </div>
                </div>
      			<RecycleScroller
		          ref="scroller"
		          class="scroller"
		          :items="allbookings"
		          :item-size="24"
		          key-field="id"
			        >
		            <template v-slot="{ item,index }">
			            <div class="trow">
			            	<div class="tcell" :class="item.color" @click="bookingdetailsupdate(item.id)" style="cursor:pointer">
				                <div v-if="item.passengers.length === 0" class="name">{{ item.name }}</div>
				                  <div v-else style="display:flex;flex-direction:row !important;align-items:flex-start;justify-content:flex-start">
				                    <span v-for="(passenger,index) in item.passengers" class="name" style="display:flex;"> {{ passenger.passenger_name }}</span>,
				                  </div>
				              </div>
				              <div class="tcell" :class="item.color">
				                {{ item.time }}
				              </div>
				              <div class="tcell" :class="item.color">
				                {{ item.date }}
				              </div>
				              <div class="tcell" :class="item.color">
				                <div v-if="item.passengers.length === 0">{{ item.telephone }}</div>
				                  <div v-else style="display:flex;flex-direction:row !important">
				                    <span v-for="(passenger,index) in item.passengers" style="display:flex;"> {{ passenger.telephone }}</span>,
				                  </div>
				              </div>
				              <div class="tcell" :class="item.color">
				                {{ item.organisation_name }}
				              </div>
				          
				              <div class="tcell" :class="item.color">
				                {{ item.from }}
				              </div>
				              <div class="tcell" :class="item.color">
				                {{ item.to }}
				              </div>
				             
				              <div class="tcell text-center" :class="item.color">
				              	<a class="list-action" :id="`popover-target-1-${item.id}-${index}`">
      							  		<b-icon class="my-0 py-0" style="cursor:pointer;" icon="chevron-down"></b-icon>
      							    </a>
      							    <b-popover
        								  :target="`popover-target-1-${item.id}-${index}`"
        								  triggers="hover blur"
        								  placement="left"
        								  class="dropdown-menu"
        								>
        								  <div class="action-dropdown-menu">
        							  		<button @click="bookingprofile(item.id)" class="action-dropdown-item " type="button"><b-icon class="" icon="person-check"></b-icon>&nbsp;  Profile</button>
        							  		<button @click="assignvehicle(item.id)" v-if="item.status != 'Cancelled' && item.status != 'Dropped' && item.authorization == 'true' && item.authorization != 'declined'" class="action-dropdown-item " type="button">
        							  			<b-icon class="" icon="truck"></b-icon>&nbsp;  Assign Vehicle</button>
        							  		<button class="action-dropdown-item " type="button"><router-link link :to='{name:"bookingEdit",params:{id:item.id}}'><b-icon class="" icon="pencil"></b-icon>&nbsp;  Edit</router-link></button>
        							  		<button @click="tripprocess(item.id, 'confirmtrip')"
        						                                      v-if="item.status == 'Active' && item.authorization == 'true' && item.authorization != 'declined'" class="action-dropdown-item " type="button"><b-icon class="" icon="check2-all"></b-icon>&nbsp;  Confirm</button>
        						            <button @click="undocancel(item.id)"
        						                                        v-if="item.status == 'Cancelled' && item.authorization == 'true' && item.authorization != 'declined'" class="action-dropdown-item " type="button"><b-icon class="" icon="arrow-clockwise"></b-icon>&nbsp;  Reinstate</button>
        						            <button @click="reassignride(item.id)"
        						                                        v-if="item.authorization == 'true' && item.authorization != 'declined'" class="action-dropdown-item " type="button"><b-icon class="" icon="arrow-counterclockwise"></b-icon>&nbsp;  Unallocate/Rollback</button>
        						            <button @click="messageclient(item.id)"
        						                                        v-if="item.authorization == 'true' && item.authorization != 'declined'" class="action-dropdown-item " type="button"><b-icon class="" icon="chat-dots"></b-icon>&nbsp;  Send Message</button>
        							  		<button @click="cancelbooking(item.id)"
        						                                      v-if="item.status !== 'Cancelled' && item.authorization == 'true' && item.authorization != 'declined'" class="action-dropdown-item " type="button"><b-icon class="" icon="x"></b-icon>&nbsp;  Cancel</button>
        							  		<button @click="bookingdocument(item.id)" class="action-dropdown-item" type="button"><b-icon class="" icon="file-earmark-pdf"></b-icon>&nbsp;  Documents</button>
        							  	</div>
        								</b-popover>
				              </div>
				              <div class="tcell" :class="item.color">
				                  <div v-if="item.vehicles.length > 0" class="name"><span v-for="(res,i) in item.vehicles" :key="i">{{ res.vehicle.name }} - {{ res.vehicle.registration }},</span></div>
				                  <div v-else>Pending</div>
				              </div>
				              <div class="tcell" :class="item.color">
				                  {{ item.vehicletype_type }}
				              </div>
				              
				              <div class="tcell" :class="item.color">
				                <span v-if="item.status && item.authorization == 'true'">{{ item.status }}</span>
				                <span
				                  v-if="item.authorization == 'false'"
				                >
				                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-lock" viewBox="0 0 16 16">
                            <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m0 5.996V14H3s-1 0-1-1 1-4 6-4c.564 0 1.077.038 1.544.107a4.524 4.524 0 0 0-.803.918A10.46 10.46 0 0 0 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664zM9 13a1 1 0 0 1 1-1v-1a2 2 0 1 1 4 0v1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zm3-3a1 1 0 0 0-1 1v1h2v-1a1 1 0 0 0-1-1"/>
                          </svg>
				                </span>
				                <span
				                      v-if="item.authorization == 'declined'"
				                    >
				                      Cancelled
				                    </span><br>
				                <small style="font-size:.69rem;" v-if="item.authorization == 'declined'" v-for="res in item.declined" :key="res.id">
				                          ::{{ res.reason }}
				                        </small>
				              </div>
                      <div class="tcell" :class="item.color">
                        {{ item.request_category }}
                      </div>

				              <div class="tcell" :class="item.color">
				                <span
				                  v-if="item.remarks !== null"
				                >
				                  {{ item.remarks }}
				                </span>
				              </div>
                      <div class="tcell" :class="item.color">
                          {{ item.flight }}
                      </div>
			            </div>
			        </template>
			      </RecycleScroller>

			  </div>
      	</b-col>
      </b-row>
    </div>

    <!-- components -->
    <SearchDialog v-model="searchdialog"/>
    <ClientProfile v-model="profiledialog" :profileid="profileid"/>
    <CancelBooking v-model="canceldialog" :selected="selected"/>
    <UpdateDetail v-model="updatedialog" :updatedetails="updatedetails"/>
    <BookingDocument v-model="bookingdocumentdialog" v-if="bookingid !== null"/>
    <SendMessage v-model="messagecliendivialog" :messageuserid="messageuserid" :profiledetails="profiledetails"/>
    <AssignVehicle v-model="assignvehicledialog" :toassinguser="toassinguser" :toassignuserid="toassignuserid" :toassigneduservehicle="toassigneduservehicle"/>

  </div>
</template>

<style>
	@import "@/assets/table/table";
	.action-dropdown-menu .action-dropdown-item:hover{
		background:var(--ab-primary);
		color:#fff;
		border-radius:var(--ab-dropdown-border-radius);
	}
	.action-dropdown-menu{
		padding-right: .5rem;
	}
</style>
<style scoped>
	@import "@/assets/table/table_scoped";
</style>

<script>
import Pusher from 'pusher-js'
import CancelBooking from '@/components/bookings/cancelbooking'
import ClientProfile from '@/components/bookings/clientprofile'
import UpdateDetail from '@/components/bookings/updatedetails'
import BookingDocument from '@/components/bookings/bookingDocument'
import SendMessage from '@/components/bookings/sendmessage'
import AssignVehicle from '@/components/bookings/assignvehicle'
import TodayBookingSearch from '@/components/bookings/todayBookingSearch'
import SearchDialog from '@/components/bookings/alltodaybookingsearch'
import bookingcounts from '@/components/bookings/bookingcounts'

import Vue from 'vue'
import axios from 'axios'
import $ from 'jquery';
import { mapState } from 'vuex'

 export default {
  name: 'TodayBookingView',
  components: {
  	CancelBooking, ClientProfile, TodayBookingSearch,UpdateDetail,BookingDocument,SendMessage,AssignVehicle,SearchDialog,bookingcounts
  },

  computed: {
  	...mapState({
        searchFilter: 'searchFilter'
      }),
      showDismissibleAlert() {
        return this.$store.state.showTodayDismissibleAlert
      },
  	 booking() {
        return this.$store.state.todaybookings
      },
	  allbookings() {

      var data = []

        for (var i = 0; i < this.booking.length; i++) {

          var elem = this.booking[i];
          var minutes = Vue.filter('minutesremaining')(this.booking[i].date+' '+this.booking[i].time)

          if(minutes >= 0 && minutes <= 15) {
            elem.color = 'in15'
          }else if(minutes > 15 && minutes <= 30) {
            elem.color = 'in30'
          }else if(minutes > 30 && minutes <= 120) {
            elem.color = 'in2'
          }else if(minutes > 120) {
            elem.color = 'over2'
          }else if(minutes < 0 && minutes >= -15) {
            elem.color = 'over15'
          }else if(minutes < -15) {
            elem.color = 'late'
          }else {
            elem.color = "waiting"
          }

          // if(this.booking[i].status == "Waiting") {
          //   elem.color = "waiting"
          // }

          if(this.booking[i].status == 'Cancelled') {
            elem.color = 'cancelled'
          }

          data.push(elem)

        }

        this.$store.commit('in15count', data.filter(res => res.color === 'in15').length)
        this.$store.commit('over15count', data.filter(res => res.color === 'over15').length)
        this.$store.commit('in30count', data.filter(res => res.color === 'in30').length)
        this.$store.commit('in2count', data.filter(res => res.color === 'in2').length)
        this.$store.commit('over2count', data.filter(res => res.color === 'over2').length)
        this.$store.commit('latecount', data.filter(res => res.color === 'late').length)
        this.$store.commit('cancelledcount', data.filter(res => res.color === 'cancelled').length)
        // this.$store.commit('dayscount', data.filter(res => res.color === 'black').length)
        this.$store.commit('allcount', data.length)

        return data.filter(row => {

          this.start = 0

          const colorfilter = row.color.toString().toLowerCase()
          const status = row.status.toString().toLowerCase()

          let searchTerm = ''
          if(this.searchFilter !== null) {
            searchTerm = this.searchFilter.toLowerCase()
          }

          return colorfilter.includes(searchTerm) || status.includes(searchTerm)

        }).filter(item => {

          this.start = 0

          let name = "",ref_no = "",time = "",date = "",telephone = "",organisation = "",from = "", to = "",vehicletype = "",orderedby = "",status = "",category = "";
          if(item.ref_no != null && item.ref_no != 'null') {ref_no = item.ref_no}
          if(item.name != null && item.name != 'null') {name = item.name}
          if(item.time != null && item.time != 'null') {time = item.time}
          if(item.date != null && item.date != 'null') {date = item.date}
          if(item.telephone != null && item.telephone != 'null') {telephone = item.telephone}
          if(item.from != null && item.from != 'null') {from = item.from}
          if(item.to != null && item.to != 'null') {to = item.to}
          if(item.organisation_name != null && item.organisation_name != 'null') {organisation = item.organisation_name}
          if(item.vehicletype != null && item.vehicletype != 'null') {vehicletype = item.vehicletype_type}
          if(item.orderedby != null && item.orderedby != 'null') {orderedby = item.orderedby}
          if(item.status != null && item.status != 'null') {status = item.status}
          if(item.request_category != null && item.request_category != 'null') {category = item.request_category}

            if(this.search == null || this.search == 'null') {
              this.search = ''
            }

          return name.toLowerCase().includes(this.search.toLowerCase()) || ref_no.toLowerCase().includes(this.search.toLowerCase()) || time.includes(this.search) || date.includes(this.search) || telephone.includes(this.search) || organisation.toLowerCase().includes(this.search.toLowerCase()) || from.toLowerCase().includes(this.search.toLowerCase()) || to.toLowerCase().includes(this.search.toLowerCase()) || vehicletype.toLowerCase().includes(this.search.toLowerCase()) || orderedby.toLowerCase().includes(this.search.toLowerCase()) || status.toLowerCase().includes(this.search.toLowerCase()) || item.passengers.some(itm => itm.passenger_name !== null ? itm.passenger_name.toLowerCase().includes(this.search.toLowerCase()) : false || itm.telephone !== null ? itm.telephone.includes(this.search) : false) || category.toLowerCase().includes(this.search.toLowerCase())
        })
	  },
	  booksearch() {
        return this.$store.state.todaysearchbooking 
      },
	  mini() {
	    return this.$store.state.mini
	  },
	  bookingid() {
        return this.$store.state.bookingid
      },
      loader() {
      	return this.$store.state.tableloader
      }
  },
  watch: {
      booksearch: function(newVal, oldVal) {
          this.search = newVal
      }
  },
  data() {

    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(today)

      return {
        searchdialog: false,
        min: minDate,
        search: '',
        dynamic: true,
        activityId: null,
        fields: [
          { text: 'Passenger', align: 'start', value: 'name' },
          { text: 'Time', value: 'time', filterable: false },
          { text: 'Date', value: 'date', filterable: false },
          { text: 'Telephone', value: 'telephone' },
          { text: 'Company', value: 'organisation_name' },
          { text: 'From', value: 'from' },
          { text: 'To', value: 'to' },
          { text: 'Action', value: 'action' },
          { text: 'Assigned Vehicle', value: 'assigned' },
          { text: 'Car Option', value: 'vehicletype_type' },
          { text: 'Status', value: 'status' },
          { text: 'Category', value: 'request_category' },
          { text: 'Remarks', value: 'remarks' },
          { text: 'Flight', value: 'flight' },
         ],
         selectedRow: [],
        profiledialog: false,
        messageclientdialog: false,
        bookingdocumentdialog: false,
        selected:null,
      reason: '',
      updatedetails: [],
      updatedialog: false,
      profiledialog: false,
      profiledetails: [],
      canceldialog: false,
      progressdialog: false,
      messagecliendivialog:false,
      assignvehicledialog: false,
      reassignvehicledialog: false,
      assignuservehicle: null,
      toassinguser:[],
      toassignuserid: null,
      toassignuserdriver: "",
      toassignuservehicle: "",
      toassigneduservehicle: null,
      messageuserid: null,
      profileid: null,
    }
  },
  created() {
    if(this.booking.length == 0) {
      this.getTodayBookings()
    }
    
  },
  mounted() {

  	let headertitle = `<div class="view-titleheader titlehead" style="top: 0px;">`;
      this.fields.map(function(value, key) {
           headertitle +=`<div class="theader">${value.text}</div>`;
         });
      headertitle +=`</div>`;
      $('.vue-recycle-scroller').prepend(headertitle)

      this.pusher()
  },
  serverPrefetch () {},
  methods: {
    pusher() {
        // add booking
        var pusher = new Pusher('3e66b926064c907de413', {
          cluster: 'ap2'
        });
        var channel = pusher.subscribe('createBooking');

        channel.bind('bookingCreated', data => {
            
            this.$store.dispatch('bookingCreated', data.data)
            .then(() => {

              var audio = new Audio('/assets/tone.mp3')
              audio.play()

            }).catch(() => {
            }) 

        })
        // edit booking
        var editchannel = pusher.subscribe('editBooking');
        editchannel.bind('bookingEdit', data => {
            this.$store.dispatch('bookingEdit', data.data)
        })
      },
  	showSearchDialog() {
        this.searchdialog = true
      },
    getTodayBookings(){
    	this.$store.state.tableloader = true
        this.$store
          .dispatch('getTodayBookings')
      },
	   bookingdocument(id) {
        let details = this.allbookings.filter(booking => booking.id === id )

        this.$store.state.bookingid = parseInt(details[0].id)
        this.$store.state.documents = []

        this.$store
          .dispatch('getDocuments',parseInt(details[0].id))
          .then(res => { console.log("res") })
          .catch(error => { console.log("error") })

        this.bookingdocumentdialog = true
      },
	  bookingprofile(id) {
	      this.$store.state.bookingid = id
	      this.profiledialog = true
	    },
    bookingdetailsupdate(id) {
        this.updatedetails = this.allbookings.filter(booking => booking.id === id )
        this.updatedialog = true
      },
    cancelbooking(id) {
      let index = this.booking.filter(item => item.id === id )
        if(index.length > 0) {

          this.selected = index[0]
          this.canceldialog = true

        }
    },

    messageclient(id) {
        let data = this.allbookings.filter(booking => booking.id === id )

        this.messageuserid = id
        this.profiledetails = data

        this.messagecliendivialog = true
      },
      assignvehicle(id) {

        let data = this.allbookings.filter(booking => booking.id === id )
        this.toassinguser = data
        this.toassignuserid = id
        this.toassigneduservehicle = data[0].vehicle_type_id
        this.assignvehicledialog = true

        axios.get(`/fetchfreevehicles/booking/${id}`).then(response=>{

          if(response.data.status == parseInt(200)) {
            this.$store.state.freevehicles = response.data.freevehicles
          }
          
        })
        .catch(error => {
          console.log(error)
        })

      },
      reassignride(id) {

        this.progressdialog = true

        axios.get(`/booking/unallocate-ride/${id}`).then(response=>{
          this.progressdialog = false
          if(response.data.status == parseInt(200)) {
            this.$toastpopup('success','Success',response.data.message)
          }else {
            this.$toastpopup('warning','Warning',"An error occured")
          }
          
        })
        .catch(error => {
          this.progressdialog = true
          this.$toastpopup('warning','Warning',"An error occured")
        })

      },
      tripprocess(id, status){

        this.progressdialog = true

        navigator.geolocation.getCurrentPosition(geolocation => {

            axios.get(`/booking/trip-process/${id}/${status}/${geolocation.coords.latitude}/${geolocation.coords.longitude}`).then(response=>{
              this.progressdialog = false
              if(response.data.status == parseInt(200)) {
                this.$store.dispatch('bookingEdit', response.data.data)
                this.$toastpopup('success','Success',response.data.message)
              }else {
                this.$toastpopup('warning','Warning',"An error occured")
              }
              
            })
            .catch(error => {
              this.progressdialog = true
              this.$toastpopup('warning','Warning',"An error occured")
            })

        })

      },
      undocancel(id){

        this.progressdialog = true

        axios.get(`/reinstate/booking/${id}`).then(response=>{

          this.progressdialog = false

          if(response.data.status == parseInt(200)) {
            this.$store.dispatch('bookingEdit', response.data.data)
            this.$toastpopup('success','Success',response.data.message)
          }else {
            this.$toastpopup('warning','Warning',"An error occured")
          }
          
        })
        .catch(error => {
          this.progressdialog = false
          this.$toastpopup('warning','Warning',"An error occured")
        })
      },
  }
};

</script>
