<template>

  <div class="mt-0">

    <b-row>
      <b-col sm="12" xl="12" md="12" class="mx-0 px-3">
        <div class="dynamic-scroller commission">
          <RecycleScroller
            ref="scroller"
            class="scroller"
            :items="commissions"
            :item-size="24"
            key-field="id"
            >
            <template  v-slot="{ item,index }">
              <div>
                <div class="tcell"> {{ moment(item.date).format('MMM Do, YYYY') }}</div>
                <div class="tcell">
                  <span v-if="item.fuel">{{ item.fuel | currency('') }}</span>
                  <span v-else>0</span>
                </div>
                <div class="tcell">
                  <span v-if="item.toll">{{ item.toll | currency('') }}</span>
                  <span v-else>0</span>
                </div>
                <div class="tcell">
                  <span v-if="item.miscellaneous">{{ item.miscellaneous | currency('') }}</span>
                  <span v-else>0</span>
                </div>
                <div class="tcell">
                  {{ 

                      (item.bookings.reduce((acc,i) => {

                        let amount = 0;
                        if(item.date === i.booking.pickup_date && i.booking.status === 'Dropped' && i.booking.organisation_id !== null && i.booking.organisationrate.length > 0) {
                          amount = i.booking.distance * (i.booking.organisationrate.filter((itm) => {
                                return parseInt(itm.vehicle_type_id) === parseInt(i.booking.vehicle_type_id);
                            }).length > 0 ? parseInt(i.booking.organisationrate.filter((itm) => {
                                return parseInt(itm.vehicle_type_id) === parseInt(i.booking.vehicle_type_id);
                            })[0].rate) : 0);
                        }

                        return acc += amount; 

                      },0)) | currency(' ',0)

                  }}
                </div>
                <div class="tcell">
                  {{ 

                      (((item.bookings.reduce((acc,i) => {

                        let amount = 0;
                        if(item.date === i.booking.pickup_date && i.booking.status === 'Dropped' && i.booking.organisation_id !== null && i.booking.organisationrate.length > 0) {
                          amount = i.booking.distance * (i.booking.organisationrate.filter((itm) => {
                           return parseInt(itm.vehicle_type_id) === parseInt(i.booking.vehicle_type_id);
                         }).length > 0 ? parseInt(i.booking.organisationrate.filter(itm => {
                              return parseInt(itm.vehicle_type_id) === parseInt(i.booking.vehicle_type_id);
                            })[0].rate) : 0);
                        }

                        return acc += amount; 

                      },0) - (parseInt(item.fuel !== 0 ? 0 : item.fuel) + parseInt(item.toll !== 0 ? 0 : item.toll) + parseInt(item.miscellaneous !== 0 ? 0 : item.miscellaneous))) * (
                    item.commission != null ? item.commission.rate : 0))/100) | currency(' ',0)

                  }}
                </div>
              </div>
            </template>
          </RecycleScroller>
      </div>
      </b-col>
    </b-row>

    <!-- add kodal -->
    <b-modal
      v-model="dialog"
      size="commission"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">New Rate</span>
        </div>
      </template>
      <b-container fluid>
        <b-row>
          <b-col sm="12" md="12" class="float_label mb-3">
            
              <b-form-input
                placeholder="Rate*"
                size="sm"
                v-model="item.rate"
              ></b-form-input>
            <label class="typo__label float_label_item">Rate*</label>
          </b-col>
          <b-col sm="12" md="12" class="float_label mb-3">
            
              <b-form-input
                placeholder="Topup*"
                size="sm"
                v-model="item.topup"
              ></b-form-input>
            <label class="typo__label float_label_item">Topup*</label>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="create">
            <b-spinner small v-if="loading"></b-spinner> Save
          </b-button>
      </template>
    </b-modal>

    <!-- update modal -->
    <b-modal
      v-model="updatedialog"
      size="commission"
      >
      <template #modal-title>
        <div class="w-100">
          <span class="title float-left">Update Rate</span>
        </div>
      </template>
      <b-container fluid>
        <b-row>
          <b-col sm="12" md="12" class="float_label mb-3">
            
              <b-form-input
                placeholder="Rate*"
                size="sm"
                v-model="item.updaterate"
              ></b-form-input>
            <label class="typo__label float_label_item">Rate*</label>
          </b-col>
          <b-col sm="12" md="12" class="float_label mb-3">
            
              <b-form-input
                placeholder="Topup*"
                size="sm"
                v-model="item.updatetopup"
              ></b-form-input>
            <label class="typo__label float_label_item">Topup*</label>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="update">
            <b-spinner small v-if="loading"></b-spinner> Update
          </b-button>
      </template>
    </b-modal>

  </div>


<!-- 	<div>
    <v-row>

      <v-col
        cols="12"
        md="5"
        >
        <v-row>
          <v-col cols="12">
            <p style="font-size:.95rem !important;display:flex;align-items:center;justify-content:space-between" class="mx-1"><strong>Rates</strong>
              <v-btn
                color="#000000"
                class="white--text"
                depressed
                @click.stop="dialog = true"
              >
              Rate
                <v-icon
                  right
                  dark
                >
                  mdi-plus
                </v-icon>
              </v-btn>
            </p>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Date
                    </th>
                    <th class="text-left">
                      Rate(%)
                    </th>
                    <th class="text-left">
                      Topup
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item,i) in rates"
                    :key="i"
                  >
                    <td>{{ item.start_date }}</td>
                    <td>{{ item.rate }}</td>
                    <td>{{ item.topup | currency('',2) }}</td>
                    <td>
                      <v-icon
                          small
                          class="mr-2"
                          @click="edit(item.id)"
                        >
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          small
                          @click="deleteItem(item.id)"
                        >
                          mdi-delete
                        </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

        </v-row>
      </v-col>


    </v-row>

	</div> -->
</template>
<style>
  @import "@/assets/table/table";
  .modal-commission{
    max-width:500px !important;
  }
  .action-dropdown-menu .action-dropdown-item:hover{
    background:var(--ab-primary);
    color:#fff;
    border-radius:var(--ab-dropdown-border-radius);
  }
  .action-dropdown-menu{
    padding-right: .5rem;
  }
  .commission .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-template-columns: 16.666667% 16.666667% 16.666667% 16.666667% 16.666667% 16.666667%;
     grid-gap: 0px;
     min-width:100%;
  }
  .commission .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }
  .commission .scroller{
    height: calc(100vh - 600px);
  }
</style>
<style scoped>
  @import "@/assets/table/table_scoped";
  .commission .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-template-columns: 16.666667% 16.666667% 16.666667% 16.666667% 16.666667% 16.666667%;
     grid-gap: 0px;
  }
  
</style>
<script>
import axios from 'axios'
import Vue from 'vue'
import $ from 'jquery';

export default {
	name: 'commissions',
	components: {},
	data: () => ({
    overlay:false,
    searchfilter: "",
		fieldRules: [
      (v) => !!v || 'Field is required'
      ],
      dialog: false,
      updatedialog: false,
    valid: true,
    isBusy: false,
    loading: false,
    disabled: false,
    fields: [
      { text: 'Date', value: 'cdate' },
      { text: 'Fuel', value: 'fuel' },
      { text: 'Toll', value: 'toll' },
      { text: 'Miscellaneous', value: 'miscellaneous' },
      { text: 'Total Amt.', value: 'amount' },
      { text: 'Commision', value: 'commision' },
     ],
     item:{
        rate: "",
        updaterate: "",
        topup: "",
        updatetopup: "",

        updateID: "",
        _method:"patch"
    },

	}),
	methods: {
		fetchcommissions() {
      this.overlay = true
      this.$store
          .dispatch('fetchcommissions',this.driverid)
          .then(() => {
            this.overlay = false
          })
          .catch(() => {
            this.overlay = false
          })
      },
       create(){

          if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('rate',this.item.rate)
          fd.append('topup',this.item.topup)
          fd.append('driver_id',this.$route.params.id)

          axios.post('/commissions', fd, config).then(response=>{
            if(response.data.status == parseInt(200)) {

              this.$toast.success('Commission rate updated successfully','Success',{position: 'topRight',timeout: 7000,})

              this.$store.state.commission_rates = response.data.commissions
              this.clear()
              this.dialog = false
            }
              this.loading = false
              this.disabled = false
            })
            .catch(() => {
              this.loading = false
              this.disabled = false
            })

          }
      },
     clear () {
        this.$refs.form.reset()
      },
    edit(id) {
      let index = this.rates.filter(item => item.id === id );
      this.item.updaterate = index[0].rate
      this.item.updatetopup = index[0].topup
      this.item.updateID = index[0].id
      this.updatedialog = true
    },
    update(){

      if (this.$refs.form.validate()) {

      this.loading = true
      this.disabled = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let fd = new FormData();
        fd.append('rate',this.item.updaterate)
        fd.append('topup',this.item.updatetopup)
        fd.append('_method',this.item._method)

        axios.post(`/commissions/${this.item.updateID}`, fd, config).then(response=>{
          this.loading = false
          this.disabled = false
          if(response.data.status === parseInt(200))
            this.$toast.success("Rate updated successfully",'Success',{position: 'topRight',timeout: 7000,})
            this.updatedialog = false

            let index = this.$store.state.commission_rates.findIndex(data => data.id == response.data.commission.id);
            if (index > -1) {
              this.$store.state.commission_rates.splice(index, 1, response.data.commission);
            }

          this.clear()
          this.item.updateID = ''
        }).catch(error=>{
          this.loading = false
          this.disabled = false
            console.log(error)
        })

      }

    },
	},
	props: {
     driverid: Number,
  },
  computed: {
    commissions() {
      return this.$store.state.commissions
    },
    rates() {
        return this.$store.state.commission_rates
      },
  },
	mounted() {
    if(this.commissions.length == 0){
      this.fetchcommissions()
    }

    let headertitle = `<div class="view-titleheader titlehead" style="top: 0px;">`;
    this.fields.map(function(value, key) {
         headertitle +=`<div class="theader">${value.text}</div>`;
       });
    headertitle +=`</div>`;
    $('.vue-recycle-scroller').prepend(headertitle)
	},
	created() {
     
    }
}
</script>