<template>
  <div class="about mt-0">
    <div class="mx-0 px-0" style="position:relative">
      <div class="flex" :class= "[mini ? 'minipadding' : 'expandpanding']">
      	<div class="section_title"><span class="title">Invoice Details&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
      	<div>
      		<b-button variant="primary" class="primary" size="sm"><router-link link :to="{name:'newInvoice'}">Add Invoice <b-icon class="mr-1" icon="plus"></b-icon></router-link></b-button>
          <b-button variant="secondary" id="popover-invoice-show-more" class="secondary mx-2" size="sm"><b-icon icon="three-dots"></b-icon></b-button>
          <b-popover
            target="popover-invoice-show-more"
            triggers="hover blur"
            placement="bottom"
            class="dropdown-menu"
          >
            <div class="action-dropdown-menu">
              <button  class="action-dropdown-item" @click="showSearchDialog" type="button"><b-icon class="" icon="search"></b-icon>&nbsp;  Search</button>
              <button  class="action-dropdown-item" @click="exportpdf" type="button"><b-icon class="" icon="file-earmark-pdf"></b-icon>&nbsp;  Pdf</button>
              <button  class="action-dropdown-item" @click="exportexcel" type="button"><b-icon class="" icon="file-earmark-excel"></b-icon>&nbsp;  Excel</button>
            </div>
          </b-popover>

      	</div>
      </div>

      <b-row class="subsection mb-0 pb-0">
        <div class="mx-3">
          <b-alert show variant="secondary" class="secondary summaryAlert px-4">
            <div class="mb-2">
              <span class="summaryTitle">Payment Summary <span v-if="orgname !== null">| {{ orgname.name }}</span></span>
            </div>
            <div class="summaryTotals">
              <div class="totals">
                <span class="font-normal">Cummulative Total</span>
                <span class="font-medium">KES{{ 
                      allinvoices.reduce((acc, val) => acc + val.amount, 0) | currency(' ')
                    }}</span>
              </div>
              <div class="mx-5 arrow-right"></div>
              <div class="totals">
                <span class="font-normal">Cummulative Balance</span>
                <span class="font-medium">KES{{ 
                      (
                      (allinvoices.reduce((acc, val) => acc + parseFloat(val.amount), 0)) - 
                      ((allinvoices.reduce( (acc, item) => 
                      {  
                        return acc += item.payments.reduce((acc1, val) => acc1 + parseFloat(val.paidamount), 0); 
                      }, 0))+allinvoices.reduce( (acc, item) => 
                      {  
                        return acc += item.creditnotes.reduce((acc1, val) => acc1 + parseFloat(val.amount), 0);

                      }, 0))+
                      ((allinvoices.reduce( (acc, item) => 
                      {  
                        return acc += item.debitnotes.reduce((acc1, val) => acc1 + parseFloat(val.amount), 0);

                      }, 0)
                      )+(allinvoices.reduce( (acc, item) => 
                      {  
                        return acc += item.payments.reduce((acc1, val) => acc1 + val.reversals.reduce((acc2, val2) => acc2 + parseFloat(val2.amount), 0), 0);

                      }, 0)
                      ))
                      ) | currency(' ')
                    }}</span>
              </div>
            </div>
          </b-alert>
        </div>
      </b-row>

      <b-row class="mt-0 pt-0">
      	<b-col sm="12" xl="12" md="12" class="mx-0 px-3 pt-0 mt-0">

      		<div class="dynamic-scroller invoicedetails">
            <div class="empty-state" v-if="allinvoices.length === 0">
              <div class="empty-state__content">
                <div class="empty-state__icon">
                  <img src="@/assets/data.svg" style="width:40%">
                </div>
                <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                <div class="empty-state__message" v-else>No records available.</div>
              </div>
            </div>
      			<RecycleScroller
		          ref="scroller"
		          class="scroller"
		          :items="allinvoices"
		          :item-size="24"
		          key-field="id"
		        >
		          <template  v-slot="{ item,index }">
		            <div>
    					    <div class="tcell"> {{ moment(item.date).format('MMM Do YYYY') }}</div>
                  <div class="tcell">
                    <RouterLink style="color:#F98525 !important;font-weight:500 !important;text-decoration:none !important;font-size: 10px !important;" link
                            :to='{name:"showInvoice",params:{id:item.id}}'>{{ item.invoice_no }}</RouterLink>
                   </div>
                  <div class="tcell">
                    <div v-if="item.organisations.length > 0">
                      <span class="badge__secondary" v-b-tooltip.hover.left :title="organisation.organisation.name" v-for="(organisation,index) in item.organisations" :key="index">{{ organisation.organisation.name }}</span>
                    </div>
                    <span v-else>Private</span>
                  </div>
                  <div class="tcell">{{ item.status }}</div>
                  <div class="tcell">{{ moment(item.due_date).format('MMM Do YYYY') }}</div>
                  <div class="tcell">
                    <RouterLink style="color:#F98525 !important;font-weight:600 !important;text-decoration:none !important;font-size: 10px !important;display:flex;align-items:center" link
                          :to='{name:"showInvoice",params:{id:item.id}}'>KES<span style="color:#F98525 !important;">
                          {{ item.amount | currency(' ') }}</span></RouterLink>
                  </div>
                  <div class="tcell">
                    KES<span>{{ 
                            (((item.amount - item.payments.reduce((acc, val) => acc + val.paidamount, 0))-item.creditnotes.reduce((acc, val) => acc + val.amount, 0))+(item.debitnotes.reduce((acc, val) => acc + val.amount, 0)+item.payments.reduce((acc, val) => acc + val.reversals.reduce((acc1, val1) => acc1 + val1.amount, 0), 0))) | currency(' ') }}</span>
                  </div>
    					  </div>
		          </template>
		        </RecycleScroller>

			  </div>
      	</b-col>
      </b-row>
    </div>

    <!-- components -->
    <Search v-model="searchdialog"/>

  </div>
</template>

<style>
	@import "@/assets/table/table";
  .modal-invoicedetails{
    max-width:750px !important;
  }
	.action-dropdown-menu .action-dropdown-item:hover{
		background:var(--ab-primary);
		color:#fff;
		border-radius:var(--ab-dropdown-border-radius);
	}
	.action-dropdown-menu{
		padding-right: .5rem;
	}
  .invoicedetails .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-auto-columns: minmax(14.28%, 14.28%) !important;
     grid-gap: 0px;
     min-width:100%;
  }
  .invoicedetails .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }
  .invoicedetails .scroller{
    height: calc(100vh - 240px);
  }
</style>
<style scoped>
	@import "@/assets/table/table_scoped";
  .invoicedetails .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-auto-columns: minmax(14.28%, 14.28%) !important;
     grid-gap: 0px;
  }
  
</style>

<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';
import { format, parseISO } from 'date-fns'

import Search from '@/components/invoices/search'

 export default {
  name: 'invoiceDetail',
  components: { Search },
  computed: {
	  mini() {
	    return this.$store.state.mini
	  },
    loader() {
    	return this.$store.state.tableloader
    },
    sinvoices () {
      return this.$store.state.invoicedetails
    },
    allinvoices () {
      return this.sinvoices
    },
    orgname () {
      return this.$store.state.invoiceorganisation
    },
    invoicedata () {
      return this.$store.state.invoicedetailsdata
    }
  },
  data: () => ({
      daterange: {
        start: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        end: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
      },
      searchfilter: '',
      search: '',
      dialog: false,
      searchdialog: false,
      loading: false,
      fields: [
         {
          text: 'DATE',
          align: 'start',
          value: 'date',
        },
        { text: 'INVOICE#', value: 'invoiceno' },
        { text: 'ORGANISATION NAME', value: 'organisation' },
        { text: 'STATUS', value: 'status' },
        { text: 'DUE DATE', value: 'duedate' },
        { text: 'AMOUNT', value: 'amount' },
        { text: 'BALANCE DUE', value: 'balance' },
       ],
       reversefields: [
         {
          text: 'DATE',
          align: 'start',
          value: 'date',
        },
        { text: 'Time', value: 'time' },
        { text: 'Ref', value: 'ref' },
        { text: 'From', value: 'from' },
        { text: 'To', value: 'to' },
        { text: 'Passenger', value: 'passenger' },
        { text: 'Amount', value: 'amount' },

       ],
      item: {
        organisation_id: null,
        project_id: null
       },
  }),

  mounted() {

  	let headertitle = `<div class="view-titleheader titlehead" style="top: 0px;">`;
      this.fields.map(function(value, key) {
            headertitle +=`<div class="theader">${value.text}</div>`;
         });
      headertitle +=`</div>`;
      $('.vue-recycle-scroller').prepend(headertitle)

  	this.getInvoices()
    if(!this.minorganisations || this.minorganisations == 0) {
      this.getOrganisation()
    }
      
  },
  beforeMount() {
    if(!ability.can('view-reports', 'all')) {
      this.$router.push({ name: 'Dashboard' })
      return
    }
  },
  serverPrefetch () {
    // return this.getInvoices()
  },
  methods: {
    showSearchDialog() {
      this.searchdialog = true
    },
  	updateValues(val) { 
        this.daterange.start = Vue.filter('date')(val.startDate)
        this.daterange.end = Vue.filter('date')(val.endDate)
        this.date = this.daterange.start+" - "+this.daterange.end
      },
      getOrganisation(){
        // this.$store.state.tableloader = true
        axios.get('/minorganisations').then(response=>{
            // this.$store.state.tableloader = false
            if(response.data.status == parseInt(200)) {
              this.$store.state.minorganisations = response.data.organisations
            }
        }).catch(error=>{
            // this.$store.state.tableloader = false
            console.log(error)
        })
      },
      exportexcel(){

        this.$store.state.tableloader = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'arraybuffer' };
          let fd = new FormData();
          fd.append('organisation_id',this.invoicedata.organisation_id)
          fd.append('start',this.invoicedata.start)
          fd.append('end',this.invoicedata.end)

        axios.post('/invoice-details/export/excel', fd, config).then(response=>{
          this.$store.state.tableloader = false
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'Invoice_details.xlsx');
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(error => {
          this.$store.state.tableloader = false
          console.log("Unexpected error occured")
        })
      },
      exportpdf(){
        this.$store.state.tableloader = true
        
        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' };
          let fd = new FormData();
          fd.append('organisation_id',this.invoicedata.organisation_id)
          fd.append('start',this.invoicedata.start)
          fd.append('end',this.invoicedata.end)

        axios.post('/invoice-details/export/pdf', fd, config).then(response=>{
         this.$store.state.tableloader = false
         var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         fileLink.setAttribute('download', 'Invoice_details.pdf');
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(error => {
          this.$store.state.tableloader = false
          console.log("Unexpected error occured")
        })
      },
      getInvoices(){

          this.$store.state.tableloader = true

          axios.get('/organisations/allinvoice-details').then(response=>{
            this.$store.state.tableloader = false
            if(response.data.status == parseInt(200)) {
              this.$store.state.invoicedetails = response.data.invoices
            }
            
          })
          .catch(error => {
            this.$store.state.tableloader = false
            console.log(error)
          })

      },
  }
};

</script>
