<template>
	<div>
	  <!-- client profile modal -->
    <b-modal
      v-model="show"
      size="reverse"
      >
      <template #modal-title>
        <div class="w-100">
          <div class="section_title"><span class="title">Reverse Invoice&nbsp;&nbsp;<span v-if="reversedetails !== null" style="font-size:14px !important;color:#888888">{{ reversedetails.invoice_no }}&nbsp;&nbsp;</span></span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
        </div>
      </template>
      <b-container fluid>
        <div class="dynamic-scroller reverse">
          <b-row class="mx-2 mb-2 searchsection" style="display:flex;justify-content:flex-end;align-items:center">
              <div class="px-1" style="width:200px !important;padding-top:0 !important;padding-bottom:0 !important;display:flex;">
                <b-form-input
                    placeholder="Search..."
                    size="sm"
                    v-model="search"
                  ></b-form-input>
              </div>
            </b-row>
            <div class="empty-state" v-if="reversebookings.length === 0">
              <div class="empty-state__content">
                <div class="empty-state__icon">
                  <img src="@/assets/data.svg" style="width:40%">
                </div>
                <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                <div class="empty-state__message" v-else>No records available.</div>
              </div>
            </div>
            <RecycleScroller
              ref="scroller"
              class="scroller"
              :items="reversebookings"
              :item-size="24"
              key-field="id"
              >
              <template  v-slot="{ item,index }">
                <div>
                  <div class="tcell">
                    <label class="form-checkbox">
                      <input type="checkbox" :value="item.booking.id" v-model="selected">
                      <i class="form-icon"></i>
                    </label>
                  </div>
                  <div class="tcell">
                    <span v-if="item.booking.passenger_name !== null">
                      {{ item.booking.passenger_name }}
                    </span>
                  </div>
                  <div class="tcell">{{ item.booking.pickup_date }} {{ item.booking.pickup_time }}</div>
                  <div class="tcell">{{ item.booking.travelfrom }}</div>
                  <div class="tcell">{{ item.booking.travelto }}</div>
                  <div class="tcell">
                      <div v-if="item.booking.organisationrate.length > 0">
                          <span v-if="parseInt(item.booking.organisationrate[0].base_rate) === 0">{{ item.booking.distance | currency('') }} | KES {{ ((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate)) | currency('') }}</span>
                          <span v-else-if="parseInt(item.booking.organisationrate[0].base_rate) > 0 && parseInt(((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate))) > parseInt(item.booking.organisationrate[0].base_rate)">KES {{ ((item.booking.distance)*(item.booking.organisationrate.length === 0 ? 0 : item.booking.organisationrate[0].rate)) | currency('') }}</span>
                          <span v-else>KES {{ item.booking.organisationrate[0].base_rate | currency('') }}</span>
                      </div>
                      <div v-else>{{ item.booking.distance | currency('') }} | 0.00</div>
                  </div>
                </div>
              </template>
            </RecycleScroller>
          </div>
      </b-container>
      <template #modal-footer>
          <b-button variant="primary" class="primary" @click="submit">
            <b-spinner small v-if="loading"></b-spinner> Submit
          </b-button>
      </template>
    </b-modal>
    <!-- end client profile -->
	</div>
</template>

<style>
  @import "@/assets/table/table";
  .modal-reverse{
    max-width:70% !important;
  }
  .reverse .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-template-columns: 5% 15% 15% 25% 25% 15% !important;
     grid-gap: 0px;
     min-width:100%;
  }
  .reverse .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }
  .reverse .scroller {
    height: 45vh !important;
  }
</style>
<style scoped>
  @import "@/assets/table/table_scoped";
  .reverse .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-template-columns: 5% 15% 15% 25% 25% 15% !important;
     grid-gap: 0px;
  }
  
</style>
<script>
import axios from 'axios'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
import Vue from 'vue'
import $ from 'jquery';

export default {
	name: 'reverse',
	components: {
	},
	data: () => ({
		fieldRules: [
          (v) => !!v || 'Field is required'
          ],
        valid: true,
        isBusy: false,
        loading: false,
        disabled: false,
        search: "",
        item:{
           amount: "",
           payment_mode_id: null,
           reference_no: "",
           date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        },
       selected: [],
       fields: [
         {
          text: '',
          value: '',
        },
        { text: 'Passenger', value: 'passenger' },
        { text: 'Date', value: 'date' },
        { text: 'From', value: 'from' },
        { text: 'To', value: 'to' },
        { text: 'Amount', value: 'amount' },

       ],
	}),
	methods: {
    select() {
      this.selected = [];
      for (let i in this.reversebookings) {
        this.selected.push(this.reversebookings[i].id);
      }
    },
    async getData(){
        this.$store.state.componentloader = true

        this.reverseinvoicesdialog = true

        axios.get(`/reverse-invoices/${this.reversedetails.invoice_id}`).then(response=>{
          this.$store.state.componentloader = false
          this.$store.state.reversebookings = response.data.bookings

        }).catch(error=>{
          this.$store.state.componentloader = false
            console.log(error)
        })
      },
    submit(){
      this.loading = true

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      let fd = new FormData();
      fd.append('invoice_id',this.reversedetails.invoice_id)
      fd.append('bookings',JSON.stringify(this.selected))

      axios.post('/submit-reverse-invoices', fd, config).then(response=>{
        if(response.data.status == parseInt(200)) {

          this.$toastpopup('success','Success','Invoice reversed successfully')

          this.show = false

          this.getInvoices()

        }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    getInvoices(){
      this.$store.state.tableloader = true
      this.$store
        .dispatch('getInvoices')
    },
	},
  watch: {
      reversedetails: function(newVal, oldVal) {
        if(newVal !== null) {
          this.getData()
        }
      },
  },
	props: {
	     value: Boolean,
	     invoiceid: Number
	  },
	  computed: {
      loader() {
        return this.$store.state.componentloader
      },
      reversedetails() {
        return this.$store.state.reversedetails
      },
      reversebookings() {
        return this.$store.state.reversebookings.filter(item => {

          this.start = 0

          let name = "",time = "",date = "",from = "", to = "";
          if(item.booking.name != null && item.booking.name != 'null') {name = item.booking.name}
          if(item.booking.time != null && item.booking.time != 'null') {time = item.booking.time}
          if(item.booking.date != null && item.booking.date != 'null') {date = item.booking.date}
          if(item.booking.from != null && item.booking.from != 'null') {from = item.booking.from}
          if(item.booking.to != null && item.booking.to != 'null') {to = item.booking.to}
         
            if(this.search == null || this.search == 'null') {
              this.search = ''
            }

          return name.toLowerCase().includes(this.search.toLowerCase()) || time.includes(this.search) || date.includes(this.search) || from.toLowerCase().includes(this.search.toLowerCase()) || to.toLowerCase().includes(this.search.toLowerCase()) || item.booking.passengers.some(itm => itm.passenger_name !== null ? itm.passenger_name.toLowerCase().includes(this.search.toLowerCase()) : false || itm.telephone !== null ? itm.telephone.includes(this.search) : false)
        })
      },
	    show: {
	      get () {
	        return this.value
	      },
	      set (value) {
	         this.$emit('input', value)
	      }
	    }
	  },
	mounted() {
    if(this.reversedetails !== null){
      this.getData()
    }
    
  },
	created() {}
}
</script>