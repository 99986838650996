<template>
  <div class="about mt-0">
    <div class="mx-0 px-0" style="position:relative">
      <div class="flex" :class= "[mini ? 'minipadding' : 'expandpanding']">
      	<div class="section_title"><span class="title">Statements&nbsp;&nbsp;</span> <b-spinner class="spin" v-show="loader" label="Loading..."></b-spinner></div>
      	<div>
          <b-button variant="secondary" id="popover-invoice-show-more" class="secondary mx-2" size="sm"><b-icon icon="three-dots"></b-icon></b-button>
          <b-popover
            target="popover-invoice-show-more"
            triggers="hover blur"
            placement="bottom"
            class="dropdown-menu"
          >
            <div class="action-dropdown-menu">
              <button  class="action-dropdown-item" @click="showSearchDialog" type="button"><b-icon class="" icon="search"></b-icon>&nbsp;  Search</button>
              <button  class="action-dropdown-item" @click="exportpdf" type="button"><b-icon class="" icon="file-earmark-pdf"></b-icon>&nbsp;  Pdf</button>
              <button  class="action-dropdown-item" @click="exportexcel" type="button"><b-icon class="" icon="file-earmark-excel"></b-icon>&nbsp;  Excel</button>
            </div>
          </b-popover>

      	</div>
      </div>

      <b-row class="subsection mb-0 pb-0">
        <div class="mx-3">
          <b-alert show variant="secondary" class="secondary summaryAlert px-4">
            <div class="mb-2">
              <span class="summaryTitle">Payment Summary <span v-if="orgname !== null">| {{ orgname.name }}</span></span>
            </div>
            <div class="summaryTotals">
              <div class="totals">
                <span class="font-normal">Cummulative Total</span>
                <span class="font-medium">KES{{ 
                      allstatements.reduce( (acc, item) => 
                          {  

                            if(item.invoice != null) {
                               acc += parseFloat(item.invoice.amount); 
                            }else {
                               acc += 0;
                            }

                            return acc

                          }, 0) | currency(' ')
                    }}</span>
              </div>
              <div class="mx-5 arrow-right"></div>
              <div class="totals">
                <span class="font-normal">Cummulative Balance</span>
                <span class="font-medium">KES{{ 
                        
                          allstatements.reduce( (acc, item) => 
                          {  

                            if(item.invoice != null) {
                               acc += parseFloat(item.invoice.amount); 
                            }else {
                               acc += 0;
                            }

                            return acc

                          }, 0) 
                          -
                          (allstatements.reduce( (acc, item) => 
                          {  
                            if(item.payment != null){
                               acc += parseFloat(item.payment.paidamount); 
                            }else {
                               acc += 0;
                            }

                            return acc

                          }, 0)
                          +
                          allstatements.reduce( (acc, item) => 
                          {  
                            if(item.creditnote != null){
                               acc += parseFloat(item.creditnote.amount); 
                            }else {
                               acc += 0;
                            }

                            return acc

                          }, 0))
                          +
                          ((allstatements.reduce( (acc, item) => 
                          {  
                            if(item.debitnote != null){
                               acc += parseFloat(item.debitnote.amount); 
                            }else {
                               acc += 0;
                            }

                            return acc

                          }, 0)
                          +
                          allstatements.reduce( (acc, item) => 
                          {  
                            if(item.reversal != null){
                               acc += parseFloat(item.reversal.amount); 
                            }else {
                               acc += 0;
                            }

                            return acc

                          }, 0)))
                           | currency(' ')
                        }}</span>
              </div>
            </div>
          </b-alert>
        </div>
      </b-row>

      <b-row class="mt-0 pt-0">
      	<b-col sm="12" xl="12" md="12" class="mx-0 px-3 pt-0 mt-0">

      		<div class="dynamic-scroller statements">
            <div class="empty-state" v-if="allstatements.length === 0">
              <div class="empty-state__content">
                <div class="empty-state__icon">
                  <img src="@/assets/data.svg" style="width:40%">
                </div>
                <div class="empty-state__message" v-if="loader">Data loading...please wait</div>
                <div class="empty-state__message" v-else>No records available.</div>
              </div>
            </div>
      			<RecycleScroller
		          ref="scroller"
		          class="scroller"
		          :items="allstatements"
		          :item-size="24"
		          key-field="id"
		        >
		          <template  v-slot="{ item,index }">
		            <div>
    					    <div class="tcell">{{ moment(item.posting_date).format('MMMM Do YYYY') }}</div>
                  <div class="tcell">
                    <p class="text--primary mb-0" style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;" v-if="item.invoice != null">{{ item.invoice.invoice_no }}</p>
                    <p class="text--primary mb-0" style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;" v-if="item.payment != null">{{ item.payment.payment_no }}</p>
                    <p class="text--primary mb-0" style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;" v-if="item.creditnote != null">{{ item.creditnote.credit_note_no }}</p>
                    <p class="text--primary mb-0" style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;" v-if="item.debitnote != null">{{ item.debitnote.debit_note_no }}</p>
                    <p class="text--primary mb-0" style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;" v-if="item.reversal != null">{{ item.reversal.reversal_no }}</p>
                   </div>
                  <div class="tcell">
                    <p class="text--primary mb-0" v-if="item.invoice != null" style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;">{{ item.invoice.amount | currency(' ') }}</p>
                          <p class="text--primary mb-0" v-else style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;">{{ 0 | currency(' ') }}</p>
                  </div>
                  <div class="tcell">
                    <p class="text--primary mb-0" v-if="item.payment != null" style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;">{{ item.payment.paidamount | currency(' ') }}</p>
                          <p class="text--primary mb-0" v-else style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;">{{ 0 | currency(' ') }}</p>
                  </div>
                  <div class="tcell">
                    <p class="text--primary mb-0" v-if="item.creditnote != null" style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;">{{ item.creditnote.amount | currency(' ') }}</p>
                          <p class="text--primary mb-0" v-else style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;">{{ 0 | currency(' ') }}</p>
                  </div>
                  <div class="tcell">
                    <p class="text--primary mb-0" v-if="item.debitnote != null" style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;">{{ item.debitnote.amount | currency(' ') }}</p>
                          <p class="text--primary mb-0" v-else style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;">{{ 0 | currency(' ') }}</p>
                  </div>
                  <div class="tcell">
                    <p class="text--primary mb-0" v-if="item.reversal != null" style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;">{{ item.reversal.amount | currency(' ') }}</p>
                          <p class="text--primary mb-0" v-else style="text-transform:uppercase;font-size:10px !important;font-weight:500 !important;">{{ 0 | currency(' ') }}</p>
                  </div>
    					  </div>
		          </template>
              <template #after>
                <div class="titlehead" style="top: 0px;">
                  <div class="theader"></div>
                  <div class="theader"></div>
                  <div class="theader">
                    <p class="text--primary mb-0" style="color:#222 !important;text-transform:uppercase;font-size:11px !important;font-weight:600 !important;border-top:1.5px solid #333;border-bottom:1.5px solid #333">{{ 
                      allstatements.reduce( (acc, item) => 
                      {  

                        if(item.invoice != null) {
                           acc += parseFloat(item.invoice.amount); 
                        }else {
                           acc += 0;
                        }

                        return acc

                      }, 0) | currency('') }}
                    </p>
                  </div>
                  <div class="theader">
                    <p class="text--primary mb-0" style="color:#222 !important;text-transform:uppercase;font-size:11px !important;font-weight:600 !important;border-top:1.5px solid #333;border-bottom:1.5px solid #333">{{ 
                            allstatements.reduce( (acc, item) => 
                            {  

                              if(item.payment != null) {
                                 acc += parseFloat(item.payment.paidamount); 
                              }else {
                                 acc += 0;
                              }

                              return acc

                            }, 0) | currency('') }}
                    </p>
                  </div>
                  <div class="theader">
                    <p class="text--primary mb-0" style="color:#222 !important;text-transform:uppercase;font-size:11px !important;font-weight:600 !important;border-top:1.5px solid #333;border-bottom:1.5px solid #333">{{ 
                            allstatements.reduce( (acc, item) => 
                            {  

                              if(item.creditnote != null) {
                                 acc += parseFloat(item.creditnote.amount); 
                              }else {
                                 acc += 0;
                              }

                              return acc

                            }, 0) | currency('') }}
                    </p>
                  </div>
                  <div class="theader">
                    <p class="text--primary mb-0" style="color:#222 !important;text-transform:uppercase;font-size:11px !important;font-weight:600 !important;border-top:1.5px solid #333;border-bottom:1.5px solid #333">{{ 
                            allstatements.reduce( (acc, item) => 
                            {  

                              if(item.debitnote != null) {
                                 acc += parseFloat(item.debitnote.amount); 
                              }else {
                                 acc += 0;
                              }

                              return acc

                            }, 0) | currency('') }}
                    </p>
                  </div>
                  <div class="theader">
                    <p class="text--primary mb-0" style="color:#222 !important;text-transform:uppercase;font-size:11px !important;font-weight:600 !important;border-top:1.5px solid #333;border-bottom:1.5px solid #333">{{ 
                            allstatements.reduce( (acc, item) => 
                            {  

                              if(item.reversal != null) {
                                 acc += parseFloat(item.reversal.amount); 
                              }else {
                                 acc += 0;
                              }

                              return acc

                            }, 0) | currency('') }}
                    </p>
                  </div>
                </div>
              </template>
		        </RecycleScroller>

			  </div>
      	</b-col>
      </b-row>
    </div>

    <!-- components -->
    <Search v-model="searchdialog"/>

  </div>
</template>

<style>
	@import "@/assets/table/table";
  .modal-statements{
    max-width:750px !important;
  }
	.action-dropdown-menu .action-dropdown-item:hover{
		background:var(--ab-primary);
		color:#fff;
		border-radius:var(--ab-dropdown-border-radius);
	}
	.action-dropdown-menu{
		padding-right: .5rem;
	}
  .statements .titlehead{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
    grid-auto-columns: minmax(14.28%, 14.28%) !important;
     grid-gap: 0px;
     min-width:100%;
  }
  .statements .vue-recycle-scroller.direction-vertical .vue-recycle-scroller__item-wrapper {
    min-width:100%;
  }
  .statements .scroller{
    height: calc(100vh - 240px);
  }
</style>
<style scoped>
	@import "@/assets/table/table_scoped";
  .statements .vue-recycle-scroller__item-view > div{
    width:100%;
    display: grid !important;
     grid-auto-flow: column !important;
     grid-auto-columns: minmax(14.28%, 14.28%) !important;
     grid-gap: 0px;
  }
  
</style>

<script>
import axios from 'axios'
import {ability} from '@/services/ability'
import $ from 'jquery';
import { format, parseISO } from 'date-fns'

import Search from '@/components/statement/search'

 export default {
  name: 'invoiceDetail',
  components: { Search },
  computed: {
	  mini() {
	    return this.$store.state.mini
	  },
    loader() {
    	return this.$store.state.tableloader
    },
    statements () {
      return this.$store.state.statements
    },
    allstatements () {
      return this.statements
    },
    orgname () {
      return this.$store.state.statementorganisation
    },
    minorganisations () {
      return this.$store.state.minorganisations
    },
    statementdata () {
      return this.$store.state.statementdata
    }
  },
  data: () => ({
      daterange: {
        start: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        end: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
      },
      searchfilter: '',
      search: '',
      dialog: false,
      searchdialog: false,
      loading: false,
      fields: [
        { text: 'DATE',value: 'date' },
        { text: 'REF NO', value: 'refno' },
        { text: 'INVOICE AMOUNT', value: 'invoiceamount' },
        { text: 'PAYMENT AMOUNT', value: 'paymentamount' },
        { text: 'CREDIT AMOUNT', value: 'creditamount' },
        { text: 'DEBIT AMOUNT', value: 'debitamount' },
        { text: 'REVERSAL AMOUNT', value: 'reversalamount' },
        // { text: 'BALANCE', value: 'balance' },
       ],
       reversefields: [
         {
          text: 'DATE',
          align: 'start',
          value: 'date',
        },
        { text: 'Time', value: 'time' },
        { text: 'Ref', value: 'ref' },
        { text: 'From', value: 'from' },
        { text: 'To', value: 'to' },
        { text: 'Passenger', value: 'passenger' },
        { text: 'Amount', value: 'amount' },

       ],
      item: {
        organisation_id: null,
        project_id: null
       },
  }),

  mounted() {

  	let headertitle = `<div class="view-titleheader titlehead" style="top: 0px;">`;
      this.fields.map(function(value, key) {
            headertitle +=`<div class="theader">${value.text}</div>`;
         });
      headertitle +=`</div>`;
      $('.vue-recycle-scroller').prepend(headertitle)
      
    this.$store.state.statementorganisation = null
    if(!this.minorganisations || this.minorganisations == 0) {
      this.getOrganisation()
    }
    if(!this.allstatements || this.allstatements == 0) {
      this.getStatement()
    }
      
  },
  beforeMount() {
    if(!ability.can('view-reports', 'all')) {
      this.$router.push({ name: 'Dashboard' })
      return
    }
  },
  methods: {
    showSearchDialog() {
      this.searchdialog = true
    },
  	updateValues(val) { 
        this.daterange.start = Vue.filter('date')(val.startDate)
        this.daterange.end = Vue.filter('date')(val.endDate)
        this.date = this.daterange.start+" - "+this.daterange.end
      },
      getOrganisation(){
        // this.$store.state.tableloader = true
        axios.get('/minorganisations').then(response=>{
            // this.$store.state.tableloader = false
            if(response.data.status == parseInt(200)) {
              this.$store.state.minorganisations = response.data.organisations
            }
        }).catch(error=>{
            // this.$store.state.tableloader = false
            console.log(error)
        })
      },
      getStatement(){

        this.$store.state.tableloader = true

        axios.get('/organisations/allstatement').then(response=>{
          this.$store.state.tableloader = false
          if(response.data.status == parseInt(200)) {
            this.$store.state.statements = response.data.statements
          }
          
        })
        .catch(error => {
          this.$store.state.tableloader = false
          console.log(error)
        })

      },
      exportexcel(){

        this.$store.state.tableloader = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'arraybuffer' };
          let fd = new FormData();
          fd.append('organisation_id',this.statementdata.organisation_id)
          fd.append('project_id',this.statementdata.project_id)
          fd.append('start',this.statementdata.start)
          fd.append('end',this.statementdata.end)

        axios.post('/statement/export/fileexcel', fd, config).then(response=>{
          this.$store.state.tableloader = false
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'customer_statement.xlsx');
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(error => {
          this.$store.state.tableloader = false
          console.log(error)
        })
      },
      exportpdf(){

        this.$store.state.tableloader = true
        
        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' };
          let fd = new FormData();
          fd.append('organisation_id',this.statementdata.organisation_id)
          fd.append('project_id',this.statementdata.project_id)
          fd.append('start',this.statementdata.start)
          fd.append('end',this.statementdata.end)

        axios.post('/statement/export/filepdf', fd, config).then(response=>{
         this.$store.state.tableloader = false
         var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         fileLink.setAttribute('download', 'customer_statement.pdf');
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(error => {
          this.$store.state.tableloader = false
          console.log(error)
        })
      },
  }
};

</script>
